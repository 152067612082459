import { FundSearchRequestModel } from '@alcon-db-models/FundSearchRequestModel';
import { ChangeDetectorRef, Directive, Input, OnDestroy, OnInit } from '@angular/core';
import { DataBindingDirective, GridComponent, GridDataResult } from '@progress/kendo-angular-grid';
import { FilterDescriptor } from '@progress/kendo-data-query';
import { LcpComparisonSearchService } from '@services/lcp-comparison-search.service';
import { UserPreferencesService } from '@services/user-preferences.service';
import { Subscription } from 'rxjs';
import { JsonUtilities } from '../shared/json-utilities';
import { SelectSearchBindingBaseDirective } from './select-search-binding.base.directive';
import { UserPreferenceEnabledDataBindingDirective } from './user-preference-enabled-data-binding.directive';
import { SandboxFileUploadSearchRequestModel } from '@alcon-db-models/SandboxFileUploadSearchRequestModel';
import { LcpComparisonRequestModel } from '@alcon-db-models/LcpComparisonRequestModel';

@Directive({
  selector: '[lcpComparisonSearchBindingDirective]'
})
export class LcpComparisonSearchBindingDirective  extends UserPreferenceEnabledDataBindingDirective implements OnInit, OnDestroy {

  @Input() preferenceKey: string = "LcpComparisonSearch";

  private serviceSubscription?: Subscription;

  constructor(
    grid: GridComponent,
    userPreferencesService: UserPreferencesService,
    changeDetectorRef: ChangeDetectorRef,
    public lcpComparisonSearchService: LcpComparisonSearchService
    ) {
    super(grid, userPreferencesService, changeDetectorRef);
  }

  public ngOnInit(): void {

    this.lcpComparisonSearchService.next([] as any as GridDataResult);

    this.serviceSubscription = this.lcpComparisonSearchService.subscribe((result) => {
      result = JsonUtilities.convertDatesAndCopy(result);
      this.grid.loading = false;
      this.grid.data = result;
      this.notifyDataChange();
      this.grid.loading = false;
    });

    super.ngOnInit();
  }

  public ngOnDestroy(): void {
    if (this.serviceSubscription) {
        this.serviceSubscription.unsubscribe();
    }
    super.ngOnDestroy();
  }

  public rebind(): void {
    this.grid.loading = true;
    this.lcpComparisonSearchService.query(this.getSearchRequest());
  }

  public resetPage(): void {
    this.state.skip = 0;
    this.rebind();
  }

  public getSearchRequest(doIgnorePagination: boolean = false): LcpComparisonRequestModel {

    const request = <LcpComparisonRequestModel>{};
    const filters: FilterDescriptor[] = this.state.filter?.filters as FilterDescriptor[];
    // TODO: all directives should enumerate filters (like below) instead of hard coding them.  Create base search directive?
    filters?.forEach(x => { if (typeof(x.field) === 'string') { (request as any)[x.field] = x.value; }});

    return ({
      ...request,
      personID: null,
      take: doIgnorePagination ? null : this.state.take,
      skip: doIgnorePagination ? 0 : this.state.skip,
      sortColumn: this.state.sort?.[0]?.dir ? this.state.sort?.[0]?.field : null,
      sortAscending: this.state.sort?.[0]?.dir ? this.state.sort[0].dir != 'desc' : null,
    });
  }
}

