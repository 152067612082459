import { ChangeDetectorRef, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { takeUntil } from 'rxjs/operators';
import { ActivityForDropdownService } from './activity-for-dropdown.service';
import { CommitmentFormBaseService } from './commitment-form-base.service';
import { CommitmentUpsertServiceService } from './commitment-upsert-service.service';
import { CommitmentWithDetailsService } from './commitment-with-details.service';
import { DraftWithDetailsService } from './draft-with-details.service';
import { TerritoryForCommitmentWithBalanceService } from './territory-for-commitment-with-balance.service';

@Injectable()
export class CommitmentEditService extends CommitmentFormBaseService {

  // --------------------------------------------------------------------------
  constructor(
    store: Store,
    commitmentWithDetailsService: CommitmentWithDetailsService,
    territoryForCommitmentWithBalanceService: TerritoryForCommitmentWithBalanceService,
    commitmentUpsertService : CommitmentUpsertServiceService,
    draftWithDetailsService : DraftWithDetailsService,
    activityForDropdownService: ActivityForDropdownService,
    changeDetectorRef: ChangeDetectorRef
  ) {

    super(store,commitmentWithDetailsService,draftWithDetailsService,commitmentUpsertService,territoryForCommitmentWithBalanceService,activityForDropdownService, changeDetectorRef);

    this.commitment$.pipe(takeUntil(this._destroy$)).subscribe(x => {
      this.updateForm(x)
    });
  }
}

