import { ClaimWithDetailsModel } from '@alcon-db-models/ClaimWithDetailsModel';
import { StatusCode } from '@alcon-db-models/Enums';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Optional, Output } from '@angular/core';
import { ClaimSubjectBaseService } from '@services/claim-subject-base.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'acb-alcon-view-claim-details-compact',
  template: `
    <div fxLayout="row wrap" fxFill fxLayoutAlign="stretch stretch">
      <div class="acb-details-layout"  fxLayout="row wrap">
        <div class="acb-view-field">
          <kendo-label text="Claimed"></kendo-label>
          <div style="font-weight:bold">{{ claim?.claimedAmount | currency }}</div>
        </div>
        <div class="acb-view-field">
          <kendo-label text="Committed"></kendo-label>
          <div>{{ claim?.commitmentAmount | currency }}</div>
        </div>
        <div class="acb-view-field acb-span-2">
          <kendo-label text="Activity"></kendo-label>
          <div>{{ claim?.activity }}</div>
        </div>
        <div class="acb-view-field">
          <kendo-label text="Pay Type"></kendo-label>
          <div>{{ claim?.payType ? claim?.payType : 'Unknown' }}</div>
        </div>
        <div class="acb-view-field">
          <kendo-label text="Deduction"></kendo-label>
          <div>{{ claim?.deduction }}</div>
        </div>
        <div class="acb-view-field">
          <kendo-label text="Invoice"></kendo-label>
          <div>{{ claim?.invoice }}</div>
        </div>
      </div>
      <div class="acb-view-comment" fxFlex>
        <kendo-label text="Comment"></kendo-label>
        <ng-container *ngIf="claim?.comments?.length">
          <div>{{ claim!.comments![claim!.comments!.length - 1].commentBody }}</div>
        </ng-container>
      </div>
    </div>
  `,
  styleUrls:  ['./view-claim-details-compact.component.scss']
})
export class ViewClaimDetailsCompactComponent implements OnInit, OnDestroy {

  @Input() public claim?: ClaimWithDetailsModel | null;
  @Input() public doShowLessForReview: boolean = false;

  @Output() viewCommitment: EventEmitter<number|undefined> = new EventEmitter();

  private destroy$: Subject<void> = new Subject<void>();

  constructor(
    @Optional() private claimSubjectBaseService?: ClaimSubjectBaseService
  ) {
    if (claimSubjectBaseService) {
      claimSubjectBaseService.claim$.pipe(takeUntil(this.destroy$)).subscribe(x => {
        this.claim = x
      });
    }
  }

  getStatusColor(claim?: ClaimWithDetailsModel | null) {
    switch (claim?.statusCodeID as StatusCode) {
      case StatusCode.Paid:
      case StatusCode.Partialpaid:
          return '#00AE44';
      case StatusCode.Denied:
        return '#9B1A2F';
      case StatusCode.Inprocess:
        return '#0063b7';
      case StatusCode.Closed:
        return '#FF6B12';
      case StatusCode.Canceled:
      case StatusCode.Void:
        return '#9B1A2F';
      default:
        return '#484844';
    }
  }

  getPaidStyle(claim?: ClaimWithDetailsModel | null) {
    return claim?.paidAmount ? { color: '#00AE44', 'font-weight': 'bold' }  : {};
  }

  ngOnInit(): void {
  }

  onCommitmentClick() {
    this.viewCommitment.emit(this.claim?.commitmentID ?? undefined);
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
