import { createReducer, on } from '@ngrx/store';
import * as AppPersistedSettingsActions from './app-persisted-settings.actions';
import { initialState } from './app-persisted-settings.state'
import * as AppActions from './../app-session/app-session.actions';

export const reducer = createReducer(
  initialState,
  on(AppPersistedSettingsActions.updatePersistedSettings, state => state),
  on(AppPersistedSettingsActions.updateAccessTokenInfo, (state, action) => ({ ...state, accessTokenInfo: action.data })),
  on(AppActions.userLoggedOut, (state) => ({ ...initialState })),
);

