
import { CustomerInfoModel } from '@alcon-db-models/CustomerInfoModel';
import { Injectable } from '@angular/core';
import { EntityCollectionServiceBase, EntityCollectionServiceElementsFactory } from '@ngrx/data';

@Injectable({
  providedIn: 'root'
})
export class CustomerInfoService extends EntityCollectionServiceBase<CustomerInfoModel> {
  constructor(serviceElementsFactory: EntityCollectionServiceElementsFactory) {
    super('CustomerInfo', serviceElementsFactory);
  }
}

