import { Directive, ElementRef } from '@angular/core';

@Directive({
  selector: '[multipleIntegerTextbox]'
})
export class MultipleIntegerTextboxDirective {

  constructor(private elementRef: ElementRef) { }

  private checkText(text:string | undefined | null) {
    return text ? /[0-9 ,]/.test(text) : false;
  }

  ngAfterViewInit() {

    let inputControl = this.elementRef?.nativeElement;

    inputControl.addEventListener('paste', (e: ClipboardEvent) => {
      if (this.checkText(e?.clipboardData?.getData('Text'))) {
        return true;
      }
      e.cancelBubble = true;
      e.preventDefault();
      return false;
    });

    inputControl.addEventListener('keydown', (e: KeyboardEvent) => {
      if (e.ctrlKey || ['Enter', 'Tab', 'Backspace', 'Delete', 'Home', 'End', 'Escape', 'ArrowRight', 'ArrowLeft' ].some(x => x == e.code) || this.checkText(e.key)) {
        return true;
      }
      e.cancelBubble = true;
      e.preventDefault();
      return false;
    });
  }
}
