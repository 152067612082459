import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, RequiredValidator, Validators } from '@angular/forms';
import { EmailRequestMode } from 'src/app/shared/static';

@Component({
  selector: 'acb-alcon-edit-email',
  template: `
    <form [formGroup]="form" style="width: 100%;" class="k-form">
      <acb-alcon-section-with-legend [doShowLegend]="false" class="acb-section-05">
        <div fxLayout="row" fxLayoutGap="2em">
          <kendo-formfield fxFlex>
            <kendo-label [for]="emailAddress" text="Email Address" [optional]="false"></kendo-label>
            <input formControlName="emailAddress" kendoTextBox #emailAddress autocomplete="off" />
          </kendo-formfield>
          <kendo-formfield *appFieldExtentions fxLayout="row" fxLayoutAlign="center center">
            <kendo-label [for]="optIn" class="acb-form-label-and-checkbox" text="Opt In" [optional]="false"><input formControlName="optIn" type="checkbox" kendoCheckBox #optIn /></kendo-label>
          </kendo-formfield>
        </div>
      </acb-alcon-section-with-legend>
    </form>
    <kendo-dialog-actions>
      <button kendoButton class="acb-cancel" (click)="onCancel()" >Cancel</button>
      <button kendoButton [primary]="true" (click)="onSave()" [disabled]="!this.form.valid" >{{emailRequestMode === 'edit' ? 'Update' : 'Add'}}</button>
    </kendo-dialog-actions>

  `,
  styleUrls: ['./edit-email.component.scss']
})
export class EditEmailComponent implements OnInit {

  @Input() emailRequestMode: EmailRequestMode = 'edit';
  @Input() email?: EmailRequestResult;

  @Output() cancel: EventEmitter<any> = new EventEmitter();
  @Output() save: EventEmitter<EmailRequestResult> = new EventEmitter();

  public form: UntypedFormGroup = new UntypedFormGroup({
    emailAddress: new UntypedFormControl(null, [Validators.required]),
    optIn: new UntypedFormControl(true, [Validators.required])
  });

  constructor() { }

  ngOnInit(): void {
    if (this.email && this.email.emailAddress) {
      this.form.patchValue(this.email)
    }
  }

  public onCancel() {
    this.cancel.emit(null);
  }

  public onSave() {
    this.save.emit(this.form.value as EmailRequestResult);
  }
}

export class EmailRequestResult {
  constructor(public emailAddress: string, public optIn: boolean ) { }
}

