<kendo-upload 
    class="file-upload" 
    [autoUpload]="true"
    [saveUrl]="'./api/sandbox/UploadLcpFile'" 
    [multiple]="false" 
    [(ngModel)]="files" 
    [showFileList]="false"
    (upload)="onUpload($event)" 
    (remove)="onRemove($event)" 
    (success)="onSuccess($event)" 
    (error)="onError($event)"
    (select)="onSelect($event)" 
    (valueChange)="onValueChange($event)" 
    (complete)="onComplete($event)"
    [restrictions]="{
        allowedExtensions: ['.csv','.dat', '.txt'],
        maxFileSize: 104857600
    }"
    >
    <kendo-upload-messages select="Add File" dropFilesHere="...or drop files here to upload"></kendo-upload-messages>
</kendo-upload>