import { createAction, props } from '@ngrx/store';
import { AppPersistedSettingsState } from './app-persisted-settings.state'
import { TokenInfoModel } from '@alcon-db-models/TokenInfoModel';


export enum ActionTypes {
  UpdatePersistedSettings = '[App Command3] Update : Persisted Settings',
  PersistedSettingsUpdated = '[App Event3] Persisted Settings Were Updated',
  UpdateDoKeepMeLoggedIn = '[App Command3] Update : Do Keep Me Logged In',
  UpdateAccessTokenInfo = '[App Command3] Update : Access Token Info',
  NoOp = '[Auth Event3] No Op'
}

export const updatePersistedSettings = createAction(ActionTypes.UpdatePersistedSettings, props<AppPersistedSettingsState>());

export const updateAccessTokenInfo = createAction(ActionTypes.UpdateAccessTokenInfo, props<{ data: TokenInfoModel}>());
export const updateDoKeepMeLoggedIn = createAction(ActionTypes.UpdateDoKeepMeLoggedIn, props<{ data: boolean}>());

export const persistedSettingsUpdated = createAction(ActionTypes.PersistedSettingsUpdated, props<{ data: AppPersistedSettingsState}>());
