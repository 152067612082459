<kendo-dialog-titlebar class="acb-edit-approval-rule-titlebar" (close)="onClose()">
  {{ viewMode == 'edit' ? 'Edit Fund' : 'New Fund' }}
</kendo-dialog-titlebar>
<acb-alcon-window-notifications [windowNotifications]="notifications" [fxHide]="!fundForm.errors">
</acb-alcon-window-notifications>
<form [formGroup]="fundForm" style="width: 100%;" class="k-form">
  <acb-alcon-section-with-legend [doShowLegend]="false" class="acb-section-05">
    <div class="acb-inner-panel" fxLayout="row wrap" fxLayoutGap="2em" fxLayoutAlign="start top">

      <kendo-formfield *appFieldExtentions fxFlex="1 0 16em" fxFlex.lt-sm="auto">
        <kendo-label [for]="displayName" text="Fund Name"></kendo-label>
        <input formControlName="displayName" kendoTextBox #displayName autocomplete="off"/>
        <kendo-formhint>Fund's name</kendo-formhint>
        <kendo-formerror>Error: name required</kendo-formerror>
      </kendo-formfield>

      <kendo-formfield *appFieldExtentions fxFlex="0 0 12em" fxFlex.lt-sm="auto">
        <kendo-label [for]="startDate" text="Start Date" [optional]="false"></kendo-label>
        <kendo-datepicker
          formControlName="startDate"
          #startDate
          autocomplete="off"
          [format]="{ displayFormat: 'MM/dd/yyyy', inputFormat: 'MM/dd/yy'  }"
          >
        </kendo-datepicker>
        <kendo-formhint>&nbsp;</kendo-formhint>
      </kendo-formfield>

      <kendo-formfield *appFieldExtentions fxFlex="0 0 12em" fxFlex.lt-sm="auto">
        <kendo-label [for]="endDate" text="End Date"></kendo-label>
        <kendo-datepicker
          formControlName="endDate"
          #endDate
          autocomplete="off"
          [format]="{ displayFormat: 'MM/dd/yyyy', inputFormat: 'MM/dd/yy'  }"
          >
        </kendo-datepicker>
        <kendo-formhint>&nbsp;</kendo-formhint>
      </kendo-formfield>

      <ng-container *ngIf="this.expenseTypes$ | async as expenseTypesData">
        <kendo-formfield *appFieldExtentions fxFlex="0 0 8em"  fxFlex.lt-sm="grow">
          <kendo-label [for]="expenseTypeID" text="Expense Type"></kendo-label>
          <kendo-combobox
            [data]="expenseTypesData"
            [textField]="'displayName'"
            [valueField]="'id'"
            [filterable]="true"
            [formControlName]="'expenseTypeID'"
            [valuePrimitive]="true"
            #expenseTypeID
            autocomplete="off"
            >
          </kendo-combobox>
          <kendo-formhint>&nbsp;</kendo-formhint>
        </kendo-formfield>
      </ng-container>

      <ng-container *ngIf="this.activitiesForDropdown$  | async as activityForDropdownData">
        <kendo-formfield *appFieldExtentions fxFlex="0 0 calc(33% - 2em)" fxFlex.lt-md="0 0 calc(50% - 2em)" fxFlex.lt-sm="grow">
          <kendo-label [for]="activities" text="Activities"></kendo-label>
          <kendo-multiselect
            formControlName="activities"
            [data]="activityForDropdownData"
            [textField]="'displayName'"
            [valueField]="'activityID'"
            #activities
            [autoClose]="false"
            [valuePrimitive]="true"
            >
          </kendo-multiselect>
          <kendo-formhint>&nbsp;</kendo-formhint>
        </kendo-formfield>
      </ng-container>

      <ng-container *ngIf="this.fundGroupsForDropdown$  | async as fundGroupForDropdownData">
        <kendo-formfield *appFieldExtentions fxFlex="0 0 calc(33% - 2em)" fxFlex.lt-md="0 0 calc(50% - 2em)" fxFlex.lt-sm="grow">
          <kendo-label [for]="fundGroupIDs" text="Fund Groups"></kendo-label>
          <kendo-multiselect
            formControlName="fundGroupIDs"
            [data]="fundGroupForDropdownData"
            [textField]="'displayName'"
            [valueField]="'fundGroupID'"
            #fundGroupIDs
            [autoClose]="false"
            [valuePrimitive]="true"
            >
          </kendo-multiselect>
          <kendo-formhint>&nbsp;</kendo-formhint>
        </kendo-formfield>
      </ng-container>

      <ng-container *ngIf="this.primaryFundGroupsForDropdown$  | async as primaryFundGroupsForDropdownData">
        <kendo-formfield *appFieldExtentions fxFlex="0 0 calc(33% - 2em)" fxFlex.lt-md="0 0 calc(50% - 2em)" fxFlex.lt-sm="grow">
          <kendo-label [for]="primaryFundGroupID" text="Primary Fund Group"></kendo-label>
          <kendo-combobox
            [data]="primaryFundGroupsForDropdownData"
            [textField]="'displayName'"
            [valueField]="'fundGroupID'"
            [filterable]="true"
            [formControlName]="'primaryFundGroupID'"
            [valuePrimitive]="true"
            #primaryFundGroupID
            autocomplete="off"
            >
          </kendo-combobox>
          <kendo-formhint>&nbsp;</kendo-formhint>
        </kendo-formfield>
      </ng-container>

      <div class="acb-flex-break"></div>

      <ng-container *ngIf="this.accessRoles$ | async as accessRolesData">
        <kendo-formfield fxFlex="0 0 calc(33% -2em)" fxFlex.sm="0 0 calc(100% - 2em)" *appFieldExtentions>
          <kendo-label [for]="accessRoles" text="Access Roles"></kendo-label>
          <kendo-multiselect
            formControlName="accessRoles"
            #accessRoles min="0"
            [data]="accessRolesData"
            [textField]="'displayName'"
            [valueField]="'id'"
            [valuePrimitive]="true"
            >
          </kendo-multiselect>
          <kendo-formhint>Who can view this fund</kendo-formhint>
        </kendo-formfield>
      </ng-container>

      <ng-container *ngIf="this.fundNotificationTypes$ | async as fundNotificationTypeData">
        <kendo-formfield fxFlex="0 0 calc(33% -2em)" fxFlex.sm="0 0 calc(100% - 2em)" *appFieldExtentions>
          <kendo-label [for]="fundNotifications" text="Fund Notifications"></kendo-label>
          <kendo-multiselect
            formControlName="fundNotifications"
            #fundNotifications 
            min="0"
            [data]="fundNotificationTypeData"
            [textField]="'displayName'"
            [valueField]="'id'"
            [valuePrimitive]="true"
            >
          </kendo-multiselect>
          <kendo-formhint>Who can view this fund</kendo-formhint>
        </kendo-formfield>
      </ng-container>

      <div class="acb-flex-break"></div>

      <ng-container>
        <kendo-formfield *appFieldExtentions fxLayout="row" fxLayoutAlign="center center">
          <kendo-label [for]="isRollback" class="acb-form-label-and-checkbox" text="Rollback?"><input formControlName="isRollback" type="checkbox" kendoCheckBox #isRollback /></kendo-label>
        </kendo-formfield>
      </ng-container>

      <ng-container>
        <kendo-formfield *appFieldExtentions fxLayout="row" fxLayoutAlign="center center">
          <kendo-label [for]="isPostAudit" class="acb-form-label-and-checkbox" text="Post Audit?"><input formControlName="isPostAudit" type="checkbox" kendoCheckBox #isPostAudit /></kendo-label>
        </kendo-formfield>
      </ng-container>

      <ng-container>
        <kendo-formfield *appFieldExtentions fxLayout="row" fxLayoutAlign="center center">
          <kendo-label [for]="isPopRequired" class="acb-form-label-and-checkbox" text="POP Required?"><input formControlName="isPopRequired" type="checkbox" kendoCheckBox #isPopRequired /></kendo-label>
        </kendo-formfield>
      </ng-container>

      <ng-container>
        <kendo-formfield *appFieldExtentions fxLayout="row" fxLayoutAlign="center center">
          <kendo-label [for]="allowsOnlyLocalTransferAllocation" class="acb-form-label-and-checkbox" text="Only Local Transfer Allocation?"><input formControlName="allowsOnlyLocalTransferAllocation" type="checkbox" kendoCheckBox #allowsOnlyLocalTransferAllocation /></kendo-label>
        </kendo-formfield>
      </ng-container>

      <ng-container>
        <kendo-formfield *appFieldExtentions fxLayout="row" fxLayoutAlign="center center">
          <kendo-label [for]="allowsInternalDeduction" class="acb-form-label-and-checkbox" text="Internal Deduction Allowed?"><input formControlName="allowsInternalDeduction" type="checkbox" kendoCheckBox #allowsInternalDeduction /></kendo-label>
        </kendo-formfield>
      </ng-container>

      <ng-container>
        <kendo-formfield *appFieldExtentions fxLayout="row" fxLayoutAlign="center center">
          <kendo-label [for]="isVenueAware" class="acb-form-label-and-checkbox" text="Venue?"><input formControlName="isVenueAware" type="checkbox" kendoCheckBox #isVenueAware /></kendo-label>
        </kendo-formfield>
      </ng-container>

      <ng-container>
        <kendo-formfield *appFieldExtentions fxLayout="row" fxLayoutAlign="center center">
          <kendo-label [for]="doesUseCommitmentActivities" class="acb-form-label-and-checkbox" text="Force Commitment Activities?"><input formControlName="doesUseCommitmentActivities" type="checkbox" kendoCheckBox #doesUseCommitmentActivities /></kendo-label>
        </kendo-formfield>
      </ng-container>

      <ng-container>
        <kendo-formfield *appFieldExtentions fxLayout="row" fxLayoutAlign="center center">
          <kendo-label [for]="commitmentActivitiesRequired" class="acb-form-label-and-checkbox" text="Commitment Activities Required?"><input formControlName="commitmentActivitiesRequired" type="checkbox" kendoCheckBox #commitmentActivitiesRequired /></kendo-label>
        </kendo-formfield>
      </ng-container>

    </div>
  </acb-alcon-section-with-legend>
</form>

<kendo-dialog-actions>
  <button kendoButton class="acb-cancel" (click)="onCancel()"><span class="k-icon k-i-cancel"></span>Cancel</button>
  <button kendoButton [primary]="true" (click)="onSave()" [disabled]="!this.fundForm.valid" ><span class="k-icon k-i-checkmark"></span>{{ viewMode == 'edit' ? 'Update' : 'Save' }}</button>
</kendo-dialog-actions>


