import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { GridComponent } from '@progress/kendo-angular-grid';
import { Observable, combineLatest, of } from 'rxjs';
import { PageExpansionService } from '@services/page-expansion.service';
import { Store } from '@ngrx/store';
import { debounceTime, first, map } from 'rxjs/operators';
import { ExcelExportData } from '@progress/kendo-angular-excel-export';
import { ClaimSearchBindingDirective } from 'src/app/directives/claim-search-binding.directive';
import { ClaimSearchService } from '@services/claim-search.service';
import { FormGroup, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { StaticTypeModel } from '@alcon-db-models/StaticTypeModel';
import { SearchFlavor } from 'src/app/shared/static';
import { StatusCodeGroup } from '@alcon-db-models/Enums';
import { StatusCodeWithGroupsModel } from '@alcon-db-models/StatusCodeWithGroupsModel';
import { ClaimSearchModel } from '@alcon-db-models/ClaimSearchModel';
import { SearchFilterService } from '@services/search-filter.service';
import { JsonUtilities } from 'src/app/shared/json-utilities';
import { UserPreferenceEnabledGridBaseComponent } from '../user-preference-enabled-grid.base.component';
import { Router } from '@angular/router';
import { SearchFiltersComponent } from '../search-filters/search-filters.component';
import { selectCurrentPersonFeatures } from '@app-store/root.selectors';
import { FeatureModel } from '@alcon-db-models/FeatureModel';
import { faShekelSign } from '@fortawesome/free-solid-svg-icons';
import { selectBusinessRules } from '@app-store/app-session/app-session.selectors';
import { ClaimSearchRequestModel } from '@alcon-db-models/ClaimSearchRequestModel';

@Component({
  selector: 'acb-alcon-claim-search',
  templateUrl: './claim-search.component.html',
  styleUrls: ['./claim-search.component.scss'],
  providers: [
    SearchFilterService
  ]
})
export class ClaimSearchComponent extends UserPreferenceEnabledGridBaseComponent implements OnInit, AfterViewInit  {

  @ViewChild('resultsGrid') public resultsGrid?: GridComponent;
  @ViewChild('searchFilters') public searchFilters?: SearchFiltersComponent;

  private _bindingDirective?: ClaimSearchBindingDirective;
  @ViewChild(ClaimSearchBindingDirective) public set bindingDirective(value: ClaimSearchBindingDirective | undefined) {
    this._bindingDirective = value;
    if (this._bindingDirective) {
      this._bindingDirective.preferenceKey = "ClaimSearch_" + this.searchFlavor;
    }
  }
  public get bindingDirective() { return this._bindingDirective; }

  @Output() viewCommitment: EventEmitter<any> = new EventEmitter();
  @Output() audit: EventEmitter<number[]> = new EventEmitter();
  @Output() check: EventEmitter<number[]> = new EventEmitter();
  @Output() pend: EventEmitter<number[]> = new EventEmitter();
  @Output() void: EventEmitter<number[]> = new EventEmitter();
  @Output() cancel: EventEmitter<number[]> = new EventEmitter();
  @Output() reverse: EventEmitter<{ claimID: number, commitmentID: number }> = new EventEmitter();
  @Output() resetClaims: EventEmitter<number[]> = new EventEmitter();
  @Output() updateReceivedDate: EventEmitter<ClaimSearchRequestModel> = new EventEmitter();

  @Input() searchFlavor: SearchFlavor = 'search';

  public yesNoList: { name: string, value: boolean | undefined }[] = [
    { name: '', value: undefined }
  , { name: 'Yes', value: true }
  , { name: 'No', value: false }
  ];
 

  public currentUserFeatures$ = this.store.select(selectCurrentPersonFeatures);
  public claimStatusCodes$: Observable<StatusCodeWithGroupsModel[]>;
  public payTypes$: Observable<StaticTypeModel[]>;
  public doShowEpansionAlert: boolean = false;
  public canEditOwnClaims: boolean = false;
  public canEditClaims: boolean = false;
  public canCloneClaims: boolean = false;
  public canReverseClaim: boolean = false;
  public auditableSelectedKeys: number[] = [];
  public checkableSelectedKeys: number[] = [];
  public voidableSelectedKeys: number[] = [];
  public cancellableSelectedKeys: number[] = [];
  public pendableSelectedKeys: number[] = [];
  public resetableSelectedKeys: number[] = [];

  public selectedItems: ClaimSearchModel[] = [];

  public navigationState: any;


  private _lastStatusCodeValue?: StaticTypeModel | null;
  private _lastStatusCodesValue?: StaticTypeModel[] | null;


  constructor(
    store: Store,
    pageExpansionService: PageExpansionService,
    private claimSearchService: ClaimSearchService,
    changeDetectorRef: ChangeDetectorRef,
    public changeDetectorRef2: ChangeDetectorRef,
    searchFilterService: SearchFilterService,
    router: Router,
  ) {
    super(store, pageExpansionService, changeDetectorRef, searchFilterService, router);

    this.navigationState = router.getCurrentNavigation()?.extras?.state;

    

    console.debug(this.navigationState);

    store.select(selectBusinessRules).pipe(first()).subscribe(x => {
      this.isEventAndVenueAware = x.EventAndVenueAware;
    })

    this.searchForm = new UntypedFormGroup({
      name: new UntypedFormControl(),
      claimIDs: new UntypedFormControl(),
      commitmentIDs: new UntypedFormControl(),
      statusCodes: new UntypedFormControl(),
      _statusCode: new UntypedFormControl(),
      doExcludeCanceledAndDenied: new UntypedFormControl(), 
      payeeName: new UntypedFormControl(),
      territories: new UntypedFormControl(),
      customerCode: new UntypedFormControl(),
      customerName: new UntypedFormControl(),
      minReceivedDate: new UntypedFormControl(),
      maxReceivedDate: new UntypedFormControl(),
      minProcessedDate: new UntypedFormControl(),
      maxProcessedDate: new UntypedFormControl(),
      minReleasedDate: new UntypedFormControl(),
      maxReleasedDate: new UntypedFormControl(),
      minClearedDate: new UntypedFormControl(),
      maxClearedDate: new UntypedFormControl(),
      startDate: new UntypedFormControl(),
      endDate: new UntypedFormControl(),
      fundYears: new UntypedFormControl(),
      funds: new UntypedFormControl(),
      activities: new UntypedFormControl(),
      invoiceNumber: new UntypedFormControl(),
      deduction: new UntypedFormControl(),
      payTypeID: new UntypedFormControl(),
      minAmount: new UntypedFormControl(),
      maxAmount: new UntypedFormControl(),
      canCopy: new UntypedFormControl(),
      canReverse: new UntypedFormControl(),
      canVoid: new UntypedFormControl(),
      canCancel: new UntypedFormControl(),
      canEdit: new UntypedFormControl(),
      canAudit: new UntypedFormControl(),
      canCheck: new UntypedFormControl(),
      canPend: new UntypedFormControl(),
    });

    this.currentUserFeatures$.pipe(first(x => x?.length > 0)).subscribe(x => {
      this.canReverseClaim = x.some((x:FeatureModel) => x.code == 'canreverseclaims');
    });

    this.claimStatusCodes$ = this.statusCodesWithGroups$.pipe(map(x => x.filter(y => y.statusCodeGroups?.some(z => z == StatusCodeGroup.Claim))));
    this.payTypes$ = this.staticTypes$.pipe(map(x => x.filter(y => y.tableName == 'PayType')));

    this.searchForm.valueChanges.pipe(debounceTime(100)).subscribe((x:any) => {

      const statusCodeFC = this.searchForm.get('_statusCode') as UntypedFormControl;
      const statusCodesFC = this.searchForm.get('statusCodes') as UntypedFormControl;
      if (!statusCodeFC || !statusCodesFC) return;

      if (!(this.searchFilters?.isExpanded)) {
        statusCodesFC.setValue(statusCodeFC.value ? [statusCodeFC.value] : [], { emitEvent: false });
      }

      // NOTE: We're now clearing the form when switching between the expanded states.  No need for expansion alert or a complicated status code "sync"

      // this.doShowEpansionAlert =
      //   this.searchForm.get('payeeName')?.value ||
      //   this.searchForm.get('territories')?.value ||
      //   this.searchForm.get('customerCode')?.value ||
      //   this.searchForm.get('customerName')?.value ||
      //   this.searchForm.get('startDate')?.value ||
      //   this.searchForm.get('endDate')?.value ||
      //   this.searchForm.get('fundYears')?.value ||
      //   this.searchForm.get('funds')?.value ||
      //   this.searchForm.get('activities')?.value ||
      //   (this.searchForm.get('minAmount')?.value ?? 0) != 0 ||
      //   (this.searchForm.get('maxAmount')?.value ?? 0) != 0 ||
      //   false;


      // if (this._lastStatusCodeValue != statusCodeFC.value) {
      //   if (!statusCodesFC.value?.length || !statusCodesFC.value.some((x: StatusCodeWithGroupsModel) => x.statusCodeID == statusCodeFC.value?.statusCodeID)) {
      //     if (!statusCodeFC.value) {
      //       statusCodesFC.setValue(null)
      //     } else if (statusCodesFC.value?.length) {
      //       statusCodesFC.value.push(statusCodeFC.value);
      //     } else {
      //       statusCodesFC.setValue([statusCodeFC.value]);
      //     }
      //   }
      // } else if (this._lastStatusCodesValue != statusCodesFC.value) {
      //   if (!statusCodesFC.value?.length) {
      //     statusCodeFC.setValue(null);
      //   } else if (!statusCodesFC.value.some((x: StatusCodeWithGroupsModel) => x.statusCodeID == statusCodeFC.value?.statusCodeID)) {
      //     statusCodeFC.setValue(statusCodesFC.value[0]);
      //   }
      // }
      //Ewww
      // this._lastStatusCodeValue = statusCodeFC.value;
      // this._lastStatusCodesValue = statusCodesFC.value;
    });
  }

  public getVisibility(visible: boolean = false) {
    return visible ? 'visible' : 'hidden';
  }

  public resetSelected() {
    this.selectedItems = [];
    this.selectedKeys = [];
    this.auditableSelectedKeys = [];
    this.checkableSelectedKeys = [];
    this.voidableSelectedKeys = [];
    this.cancellableSelectedKeys = [];
    this.pendableSelectedKeys = [];
    this.resetableSelectedKeys = [];
    this.onResultsGridSelectedKeysChange([]);
    this.changeDetectorRef2.detectChanges();
  }


  ngOnInit(): void {
    //super.ngOnInit();


  }

  ngAfterViewInit() {

    const loadData = () => {
      this.changeDetectorRef2.detectChanges();
      super.ngAfterViewInit();
    };

    if (this.navigationState && this.searchFilters) {
      const value:any = { fundYears: this.navigationState.fundYears };
      combineLatest([
        this.activityForDropdownModel$.pipe(first()),
        this.fundsForDropdown$.pipe(first()),
        this.territoriesForDropdownSource$.pipe(first())
      ]).pipe(first()).subscribe(([a,f,t]) => {

        value.funds = f.filter(y => (this.navigationState.funds ?? []).some((z:string) => y.fundID?.toString() == z));
        value.territories = t.filter(y => (this.navigationState.territories ?? []).some((z:string) => y.territoryID?.toString() == z));

        switch (this.navigationState?.drillthroughSubject?.toLowerCase()) {
          case 'activity':
            value.activities = [a.find(y => y.activityID?.toString() == this.navigationState?.drillthroughSubjectID)];
            break;
          case 'customercode':
            value.customerCode = this.navigationState?.drillthroughSubjectID
            break;
          default:
            (this.searchForm as FormGroup).patchValue(value);
            this.searchFilters!.isExpanded = true;
            loadData();
        }
        (this.searchForm as FormGroup).patchValue(value);
        this.searchFilters!.isExpanded = true;
        loadData();
      });
    } else {
      loadData();
    } 
  }  

  public onViewClaim(event: any, claim: any) {
    this.viewEntity.emit(claim?.claimID);
  }

  public onEditClaim(event: any, claim: any) {
    this.editEntity.emit(claim?.claimID);
  }

  public onPrintClaim(event: any, claim: any) {
    this.printEntity.emit(claim?.claimID);
  }

  public onViewCommitment(event: any, claim: any) {
    this.viewCommitment.emit(claim?.commitmentID);
  }

  public onReverseClaim(event: any, claim: any) {
    this.reverse.emit({ claimID: claim?.claimID, commitmentID: claim?.commitmentID });
  }

  public onAuditClaim() {
    this.audit.emit(this.auditableSelectedKeys);
  }
  public onCheckClaim() {
    this.check.emit(this.checkableSelectedKeys);
  }
  public onPendClaim() {
    this.pend.emit(this.pendableSelectedKeys);
  }
  public onVoidClaim() {
    this.void.emit(this.voidableSelectedKeys);
  }
  public onCancelClaim() {
    this.cancel.emit(this.cancellableSelectedKeys);
  }
  public onResetClaim() {
    this.resetClaims.emit(this.resetableSelectedKeys);
  }

  public onUpdateReceivedDate() {

    let request = this._bindingDirective?.getSearchRequest(true);
    if (!request)
      return;

    this.updateReceivedDate.emit(request);
  }

  public onResultsGridSelectedKeysChange(event: any[]) {

    const boundItems: ClaimSearchModel[] = this.bindingDirective?.claimSearchService?.value?.data ?? [];

    this.selectedItems = [
      ...this.selectedItems.filter(x => x.claimID ? this.selectedKeys.includes(x.claimID) : false),
      ...this.selectedKeys.filter(x => !this.selectedItems.some(y => y.claimID == x)).map(x => boundItems.find(y => y.claimID == x) ?? {}).filter(x => x != null)
    ]

    this.auditableSelectedKeys = this.selectedItems.filter(x => x.canAudit && x.claimID).map(x => x!.claimID ?? 0);
    this.checkableSelectedKeys = this.selectedItems.filter(x => x.canCheck && x.claimID).map(x => x!.claimID ?? 0);
    this.voidableSelectedKeys = this.selectedItems.filter(x => x.canVoid && x.claimID).map(x => x!.claimID ?? 0);
    this.cancellableSelectedKeys = this.selectedItems.filter(x => x.canCancel && x.claimID).map(x => x!.claimID ?? 0);
    this.pendableSelectedKeys = this.selectedItems.filter(x => x.canPend && x.claimID).map(x => x!.claimID ?? 0);
    this.resetableSelectedKeys = this.selectedItems.filter(x => x.canReset && x.claimID).map(x => x!.claimID ?? 0);
  }

  public onFiltersExpandChanged(isExpanded: boolean) {
    this.searchForm.reset();
  }

  public onClearButtonClick() {
    this.selectedKeys = [];
  }

  public allData(): Observable<ExcelExportData> {
    return this.bindingDirective ?
      this.claimSearchService.searchForExcelExport(this.bindingDirective.getSearchRequest(true) ?? {}) :
      of({});
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
