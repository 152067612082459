import { Injectable } from '@angular/core';
import { EntityCollectionServiceBase, EntityCollectionServiceElementsFactory } from '@ngrx/data';
import { TerritoryForDropdownModel } from '@alcon-db-models/TerritoryForDropdownModel'

@Injectable({
  providedIn: 'root'
})
export class TerritoryForDropdownService extends EntityCollectionServiceBase<TerritoryForDropdownModel> {
  constructor(serviceElementsFactory: EntityCollectionServiceElementsFactory) {
    super('TerritoryForDropdown', serviceElementsFactory);
  }
}
