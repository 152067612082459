
import { FundSearchRequestModel } from '@alcon-db-models/FundSearchRequestModel';
import { ChangeDetectorRef, Directive, Input, OnDestroy, OnInit } from '@angular/core';
import { DataBindingDirective, GridComponent, GridDataResult } from '@progress/kendo-angular-grid';
import { FilterDescriptor } from '@progress/kendo-data-query';
import { FundSearchService } from '@services/fund-search.service';
import { UserPreferencesService } from '@services/user-preferences.service';
import { Subscription } from 'rxjs';
import { JsonUtilities } from '../shared/json-utilities';
import { SelectSearchBindingBaseDirective } from './select-search-binding.base.directive';
import { UserPreferenceEnabledDataBindingDirective } from './user-preference-enabled-data-binding.directive';

@Directive({
  selector: '[fundSearchBindingDirective]'
})
export class FundSearchBindingDirective  extends UserPreferenceEnabledDataBindingDirective implements OnInit, OnDestroy {

  @Input() preferenceKey: string = "FundSearch";

  private serviceSubscription?: Subscription;

  constructor(
    grid: GridComponent,
    userPreferencesService: UserPreferencesService,
    changeDetectorRef: ChangeDetectorRef,
    public FundSearchService: FundSearchService
    ) {
    super(grid, userPreferencesService, changeDetectorRef);
  }

  public ngOnInit(): void {

    this.FundSearchService.next([] as any as GridDataResult);

    this.serviceSubscription = this.FundSearchService.subscribe((result) => {
      result = JsonUtilities.convertDatesAndCopy(result);
      this.grid.loading = false;
      this.grid.data = result;
      this.notifyDataChange();
      this.grid.loading = false;
    });

    super.ngOnInit();
  }

  public ngOnDestroy(): void {
    if (this.serviceSubscription) {
        this.serviceSubscription.unsubscribe();
    }
    super.ngOnDestroy();
  }

  public rebind(): void {
    this.grid.loading = true;
    this.FundSearchService.query(this.getSearchRequest());
  }

  public getSearchRequest(doIgnorePagination: boolean = false): FundSearchRequestModel {

    const request = <FundSearchRequestModel>{};
    const filters: FilterDescriptor[] = this.state.filter?.filters as FilterDescriptor[];
    // TODO: all directives should enumerate filters (like below) instead of hard coding them.  Create base search directive?
    filters?.forEach(x => { if (typeof(x.field) === 'string') { (request as any)[x.field] = x.value; }});

    return ({
      ...request,
      personID: null,
      take: doIgnorePagination ? null : this.state.take,
      skip: doIgnorePagination ? 0 : this.state.skip,
      sortColumn: this.state.sort?.[0]?.dir ? this.state.sort?.[0]?.field : null,
      sortAscending: this.state.sort?.[0]?.dir ? this.state.sort[0].dir != 'desc' : null,
    });
  }
}

