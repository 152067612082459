import { AttachementModel } from '@alcon-db-models/AttachementModel';
import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FileInfo } from '@progress/kendo-angular-upload';
import { Subject } from 'rxjs';

@Component({
  selector: 'acb-alcon-select-attachmentss-dialog',
  template: `
   <kendo-dialog-titlebar class="acb-select-attachments-titlebar" (close)="onClose()">
      Edit Attachments
    </kendo-dialog-titlebar>
    <acb-alcon-select-attachments
      [attachments]="attachments"
      [doShowLegend]="false"
      [fileRequired]="fileRequired"
      [subjectAttachmentPath]="subjectAttachmentPath"
      [claimCommitmentAttachmentPath]="claimCommitmentAttachmentPath"
      [canRemoveExistingAttachments]="canRemoveExistingAttachments"
      (selectedChange)="onAttachmentsSelectedChange($event)"
      >
    </acb-alcon-select-attachments>
    <kendo-dialog-actions>
      <button kendoButton class="acb-cancel" (click)="onCancel()"><span class="k-icon k-i-cancel"></span>Cancel</button>
      <button kendoButton [primary]="true" (click)="onApply()" [disabled]="!valid" ><span class="k-icon k-i-checkmark"></span>Apply</button>
    </kendo-dialog-actions>
  `,
  styleUrls: ['./select-attachments-dialog.component.scss']
})
export class SelectAttachmentsDialogComponent implements OnInit, AfterViewInit, OnDestroy {

  @Input() attachments: AttachementModel[] = [];
  @Input() fileRequired: boolean = false;
  @Input() subjectAttachmentPath?: string;
  @Input() claimCommitmentAttachmentPath?: string;
  @Input() canRemoveExistingAttachments?: boolean = false;

  @Output() cancel: EventEmitter<any> = new EventEmitter();
  @Output() apply: EventEmitter<any> = new EventEmitter();

  public files: FileInfo[] = [];
  public valid: boolean = true;
  private _destroy$: Subject<void> = new Subject<void>();

  constructor() {}

  ngOnInit(): void { }

  ngAfterViewInit(): void { }

  public onCancel() {
    this.cancel.emit(null);
  }

  public onApply() {
    this.apply.emit(this.attachments );
  }

  public onClose() {
    this.cancel.emit(null);
  }

  public onAttachmentsSelectedChange = ((stagedAttachments: AttachementModel[]):void | null => {
    this.attachments = [...stagedAttachments];
  }).bind(this);

  ngOnDestroy() {
    this._destroy$.next();
    this._destroy$.complete();
  }
}
