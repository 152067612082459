import { ManageUserSearchRequestModel } from '@alcon-db-models/ManageUserSearchRequestModel';
import { AfterViewInit, ChangeDetectorRef, Directive, OnDestroy, OnInit } from '@angular/core';
import { GridComponent } from '@progress/kendo-angular-grid';
import { FilterDescriptor } from '@progress/kendo-data-query';
import { ManageUserSearchService } from '@services/manage-user-search.service';
import { UserPreferencesService } from '@services/user-preferences.service';
import { Subscription } from 'rxjs';
import { UserPreferenceEnabledDataBindingDirective } from './user-preference-enabled-data-binding.directive';

@Directive({
  selector: '[manageUserSearchBindingDirective]'
})
export class ManageUserSearchBindingDirective extends UserPreferenceEnabledDataBindingDirective implements OnInit, OnDestroy, AfterViewInit {

  protected preferenceKey: string = "ManageUserSearch";
  private serviceSubscription?: Subscription;

  constructor(
    grid: GridComponent,
    userPreferencesService: UserPreferencesService,
    changeDetectorRef: ChangeDetectorRef,
    public manageUserSearchService: ManageUserSearchService
    ) {
    super(grid, userPreferencesService, changeDetectorRef);
  }

  public ngOnInit(): void {
    this.serviceSubscription = this.manageUserSearchService.subscribe((result) => {
      this.grid.loading = false;
      this.grid.data = result;
      this.notifyDataChange();
      this.grid.loading = false;
    });

    super.ngOnInit();
  }

  ngAfterViewInit(): void {
    super.ngAfterViewInit()
  }

  public ngOnDestroy(): void {
    if (this.serviceSubscription) {
        this.serviceSubscription.unsubscribe();
    }
    super.ngOnDestroy();
  }

  public rebind(): void {
    this.grid.loading = true;
    this.manageUserSearchService.query(this.getSearchRequest());
  }

  public getSearchRequest(doIgnorePagination: boolean = false): ManageUserSearchRequestModel {

    const filters: FilterDescriptor[] = this.state.filter?.filters as FilterDescriptor[];

    const getValue = function(col:string) {
      let descriptor: FilterDescriptor;
      return (descriptor = filters?.find(x => x.field == col) as FilterDescriptor) ? descriptor?.value : null
    };

    return ({
      personID: null,
      take: doIgnorePagination ? null : this.state.take,
      skip: doIgnorePagination ? 0 : this.state.skip,
      sortColumn: this.state.sort?.[0]?.dir ? this.state.sort?.[0]?.field : null,
      sortAscending: this.state.sort?.[0]?.dir ? this.state.sort[0].dir != 'desc' : null,
      name: getValue('name'),
      userName: getValue('userName'),
      firstName: getValue('firstName'),
      lastName: getValue('lastName'),
      isActive: getValue('isActive'),
      isLoginable: getValue('isLoginable'),
      mustUpdatePassword: getValue('mustUpdatePassword'),
      accessRoleIDs: getValue('accessRoleIDs'),
      businessRoleIDs: getValue('businessRoleIDs'),
    });
  }
}

