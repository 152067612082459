import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { DialogContentBase, DialogRef } from '@progress/kendo-angular-dialog';

@Component({
  selector: 'acb-alcon-request-name',
  template: `
    <kendo-dialog-titlebar class="acb-request-name-titlebar" (close)="onCancel()">
      {{title}}
    </kendo-dialog-titlebar>
    <form [formGroup]="form" style="width: 100%;" class="k-form">
      <acb-alcon-section-with-legend [doShowLegend]="false" class="acb-section-05">
        <div fxLayout="column" fxLayoutGap=".5em" fxLayoutAlign="start start">
          <kendo-formfield fxFlex style="width: 20em">
            <kendo-label [for]="name" text="Name" [optional]="true"></kendo-label>
            <input formControlName="name" kendoTextBox #name autocomplete="off" />
          </kendo-formfield>
          <kendo-formfield fxFlex style="width: 30em" *ngIf="doShowDescription">
            <kendo-label [for]="description" [optional]="true">{{descriptionLabel}}</kendo-label>
            <input formControlName="description" kendoTextBox #description autocomplete="off" />
          </kendo-formfield>
        </div>
      </acb-alcon-section-with-legend>
    </form>
    <kendo-dialog-actions>
      <button kendoButton class="acb-cancel" (click)="onCancel()" >Cancel</button>
      <button kendoButton [primary]="true" (click)="onSave()" >Save</button>
    </kendo-dialog-actions>
  `,
  styleUrls: ['./request-name.component.scss']
})
export class RequestNameComponent extends DialogContentBase implements OnInit {

  @Input() title?: string = 'Name';
  @Input() nameLabel?: string = 'Name';
  @Input() descriptionLabel?: string = 'Description';
  @Input() doShowDescription?: boolean = false;
  @Input() nameValue?: string;
  @Input() descriptionValue?: string;


  @Output() cancel: EventEmitter<any> = new EventEmitter();
  @Output() save: EventEmitter<any> = new EventEmitter();

  public form: UntypedFormGroup = new UntypedFormGroup({
    name: new UntypedFormControl(),
    description: new UntypedFormControl(),
  });

  constructor(public dialog : DialogRef) {
    super(dialog);
  }

  ngOnInit(): void {
    this.form.patchValue({
      name: this.nameValue,
      description: this.descriptionValue
    })
  }

  public onCancel() {
    this.cancel.emit(null);
  }

  public onSave() {
    this.save.emit(this.form.value);
  }

}
