
import { Injectable } from '@angular/core';
import { EntityCollectionServiceBase, EntityCollectionServiceElementsFactory } from '@ngrx/data';
import { DashboardItemForDropdownModel } from '@alcon-db-models/DashboardItemForDropdownModel'

@Injectable({
  providedIn: 'root'
})
export class DashboardItemForDropdownService extends EntityCollectionServiceBase<DashboardItemForDropdownModel> {
  constructor(serviceElementsFactory: EntityCollectionServiceElementsFactory) {
    super('DashboardItemForDropdown', serviceElementsFactory);
  }
}
