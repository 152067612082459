
import { Injectable } from '@angular/core';
import { EntityCollectionServiceBase, EntityCollectionServiceElementsFactory } from '@ngrx/data';
import { ClaimDetailAttachmentModel } from '@alcon-db-models/ClaimDetailAttachmentModel';

@Injectable({
  providedIn: 'root'
})
export class ClaimAttachmentsService extends EntityCollectionServiceBase<ClaimDetailAttachmentModel> {
  constructor(serviceElementsFactory: EntityCollectionServiceElementsFactory) {
    super('ClaimDetailAttachment', serviceElementsFactory);
  }
}
