import { Component, Input, OnInit } from '@angular/core';
import { WindowNotification } from './window-notifications.component';

@Component({
  selector: 'acb-alcon-window-notification',
  template: `
    <div
      *ngIf="windowNotification"
      class="acb-message"
      [ngClass]="{
        'acb-info-notification': windowNotification!.type == 'info',
        'acb-success-notification': windowNotification!.type == 'success',
        'acb-warning-notification': windowNotification!.type == 'warning',
        'acb-error-notification': windowNotification!.type == 'error'
      }"
      (click)="windowNotification.actionButtonSpec?.onClick()"
      >
      <span
        class="k-icon"
        [ngClass]="{
          'k-i-info': windowNotification!.type == 'info',
          'k-i-success': windowNotification!.type == 'success',
          'k-i-warning': windowNotification!.type == 'warning',
          'k-i-error': windowNotification!.type == 'error'
        }"
        >
      </span>
      <span *ngIf="windowNotification!.type == 'warning'">
        Warning!
      </span>
      <span *ngIf="windowNotification!.type == 'error'">
        Error!
      </span>
      {{ windowNotification!.message }}
      <button *ngIf="windowNotification.actionButtonSpec?.text && windowNotification.actionButtonSpec?.onClick" kendoButton >{{ windowNotification.actionButtonSpec!.text }}</button>
    </div>
  `,
  styleUrls: ['./window-notification.component.scss']
})
export class WindowNotificationComponent implements OnInit {

  @Input() windowNotification?:WindowNotification

  constructor() { }

  ngOnInit(): void {
  }

}
