import { TerritorySearchWithDefaultRequestModel } from '@alcon-db-models/TerritorySearchWithDefaultRequestModel';
import { Directive, Input } from '@angular/core';
import { GridComponent } from '@progress/kendo-angular-grid';
import { FilterDescriptor } from '@progress/kendo-data-query';
import { AppUxService } from '@services/app-ux.service';
import { TerritorySelectSearchService } from '@services/territory-select-search.service';
import { SelectSearchBindingBaseDirective } from './select-search-binding.base.directive';

@Directive({
  selector: '[territorySelectSearchBinding]'
})
export class TerritorySelectSearchBindingDirective extends SelectSearchBindingBaseDirective {

  @Input() public customerID?: number;
  @Input() public fundID?: number;

  constructor(private territorySelectSearchService: TerritorySelectSearchService, grid: GridComponent, appUxService: AppUxService) {
    super(territorySelectSearchService, grid, appUxService);
  }

  public getSearchRequest(doIgnorePagination: boolean = false): TerritorySearchWithDefaultRequestModel {

    this.beforeBind.emit(null);

    const filters: FilterDescriptor[] = this.state.filter?.filters as FilterDescriptor[];

    const getValue = function(col:string) {
      let descriptor: FilterDescriptor;
      return (descriptor = filters?.find(x => x.field == col) as FilterDescriptor) ? descriptor?.value : null
    };

    return ({
      personID: null,
      take: doIgnorePagination ? null : this.state.take,
      skip: doIgnorePagination ? 0 : this.state.skip,
      sortColumn: this.state.sort?.[0]?.dir ? this.state.sort?.[0]?.field : null,
      sortAscending: this.state.sort?.[0]?.dir ? this.state.sort[0].dir != 'desc' : null,
      territoryCodeOrName: getValue('territoryCodeOrName'),
      customerID: this.customerID ,
      fundID: this.fundID
    });
  }
}


