import { CustomerEmailModel } from '@alcon-db-models/CustomerEmailModel';
import { Injectable } from '@angular/core';
import { DialogRef, DialogService, WindowService } from '@progress/kendo-angular-dialog';
import { Observable, of, race } from 'rxjs';
import { first, map, take } from 'rxjs/operators';
import { EditEmailComponent } from '../components/edit-email/edit-email.component';
import { RequestConfirmationComponent } from '../components/request-confirmation/request-confirmation.component';
import { ViewErrorResultComponent } from '../components/view-error-result/view-error-result.component';
import { AppWindowServiceBase } from './app-window-service.base';

@Injectable({
  providedIn: 'root'
})
export class AppUxService extends AppWindowServiceBase {

  constructor(private windowService:WindowService, private dialogService: DialogService) {
    super()
  }

  // --------------------------------------------------------------------------
  public openErrorDialog(message: string | string[] = "Unknown error"): { dialogRef: DialogRef, component: ViewErrorResultComponent } | null {

    if (typeof message === 'string' || message instanceof String)
      message = [message as string];

    const dialogRef: DialogRef = this.dialogService.open({
      content: ViewErrorResultComponent,
      minWidth: 320,
      minHeight: 200,
    });

    const component = (dialogRef.content.instance as ViewErrorResultComponent);
    if (!component) {
      dialogRef.close();
      return null;
    }

    component.message = message;
    component.close.pipe(first()).subscribe(() => {
      dialogRef.close();
    })

    return { dialogRef: dialogRef, component: component };
  }

  // --------------------------------------------------------------------------
  public openConfirmation(
    title: string = "Confirmation",
    body: string = "Are you sure?",
    width: number | string = 480,
    height: number | string = 300,
    titleClass?: string,
    sectionWithLegendClass?: string,
    makeYesTheDefault?: boolean,
    ): Promise<boolean|undefined> {

    const dialogRef: DialogRef = this.dialogService.open({
      content: RequestConfirmationComponent,
      width: width,
      minWidth: 240,
      minHeight: 100,
      height: height,
    });

    const requestConfirmationComponent = (dialogRef?.content?.instance as RequestConfirmationComponent);
    if (!requestConfirmationComponent) {
      dialogRef.close();
      return Promise.resolve(false)
    }

    makeYesTheDefault = (makeYesTheDefault ?? false);
    Object.assign(requestConfirmationComponent, { title, body, titleClass, sectionWithLegendClass, makeYesTheDefault })

    return race(
      requestConfirmationComponent.yes.pipe(take(1), map(x => {
        dialogRef.close();
        return true;
      })),
      requestConfirmationComponent.no.pipe(take(1),map(() => {
        dialogRef.close();
        return false;
      }))
    ).toPromise();
  }

  // --------------------------------------------------------------------------
  public openAddCustomerEmail(): { dialogRef: DialogRef, component: EditEmailComponent } | null {

    const dialogRef: DialogRef = this.dialogService.open({
      title: 'Add Email',
      content: EditEmailComponent,
      width: 600,
      minWidth: 240,
      minHeight: 100,
      autoFocusedElement: '[formcontrolname="emailAddress"]'
    });

    const editEmailComponent = (dialogRef?.content?.instance as EditEmailComponent);
    if (!editEmailComponent) {
      dialogRef.close();
      return null;
    }

    editEmailComponent.emailRequestMode = 'add';

    return { dialogRef: dialogRef, component: editEmailComponent };
  }

  // --------------------------------------------------------------------------
  public openEditCustomerEmail(email: CustomerEmailModel): { dialogRef: DialogRef, component: EditEmailComponent } | null {

    const dialogRef: DialogRef = this.dialogService.open({
      title: 'Edit Email',
      content: EditEmailComponent,
      width: 600,
      minWidth: 240,
      minHeight: 100,
      autoFocusedElement: '[formcontrolname="emailAddress"]'
    });

    const editEmailComponent = (dialogRef?.content?.instance as EditEmailComponent);
    if (!editEmailComponent) {
      dialogRef.close();
      return null;
    }

    editEmailComponent.emailRequestMode = 'edit';

    if (email.emailAddress) {
      editEmailComponent.email = { emailAddress: email.emailAddress, optIn: !email.allowBitField };
    }

    return { dialogRef: dialogRef, component: editEmailComponent }
  }
}


