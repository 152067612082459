import { ActivityForDropdownModel } from '@alcon-db-models/ActivityForDropdownModel';
import { AuditInstructionWithDetailsModel } from '@alcon-db-models/AuditInstructionWithDetailsModel';
import { CustomerForDropdownModel } from '@alcon-db-models/CustomerForDropdownModel';
import { FundGroupForDropdownModel } from '@alcon-db-models/FundGroupForDropdownModel';
import { FundGroupInfoModel } from '@alcon-db-models/FundGroupInfoModel';
import { FundSearchModel } from '@alcon-db-models/FundSearchModel';
import { FundWithDetailsModel } from '@alcon-db-models/FundWithDetailsModel';
import { TerritoryForDropdownModel } from '@alcon-db-models/TerritoryForDropdownModel';
import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { selectStaticTypes } from '@app-store/app-session/app-session.selectors';
import { Store } from '@ngrx/store';
import { MultiSelectComponent, PreventableEvent } from '@progress/kendo-angular-dropdowns';
import { ActivityForDropdownService } from '@services/activity-for-dropdown.service';
import { AppUxService } from '@services/app-ux.service';
import { AuditInstructionWithDetailsService } from '@services/audit-instruction-with-details.service';
import { CustomerForDropdownService } from '@services/customer-for-dropdown.service';
import { FundGroupForDropdownService } from '@services/fund-group-for-dropdown.service';
import { FundSearchService } from '@services/fund-search.service';
import { FundWithDetailsService } from '@services/fund-with-details.service';
import { SearchFilterService } from '@services/search-filter.service';
import { Observable, of, ReplaySubject, Subject } from 'rxjs';
import { catchError, debounceTime, filter, first, map, takeUntil, withLatestFrom } from 'rxjs/operators';
import { firstWithLoadingOverlayAndErrorHandling, ServiceResponse } from 'src/app/shared/acb-stream';
import { JsonUtilities } from 'src/app/shared/json-utilities';
import { StaticTypeModel } from '../../../../../../../Libraries/ACB.Alcon.Data/Exports/StaticTypeModel';
import { ViewMode } from '../components.module';
import { WindowNotification } from '../window-notifications/window-notifications.component';

@Component({
  selector: 'acb-alcon-edit-audit-instruction',
  templateUrl: './edit-audit-instruction.component.html',
  styleUrls: ['./edit-audit-instruction.component.scss'],
  providers: [
    SearchFilterService
  ]
})
export class EditAuditInstructionComponent implements OnInit, OnDestroy {

  @Input() public viewMode:ViewMode = "edit";
  @Input() customersForDropdownData: CustomerForDropdownModel[] = [];

  private _auditInstructionID?:number | null | undefined;
  @Input() set auditInstructionID(value:number | null | undefined) {
    if (this._auditInstructionID = value) {
      this.auditInstructionWithDetailsService.getByKey(value).pipe(first()).subscribe(x => {
        if (x.auditInstructionID) {
          this.auditInstructionWithDetails$.next(x);
        }
      });
    } else {
      this.auditInstructionForm.reset({});
      this.auditInstructionWithDetails = undefined;
    }
  }

  public isValid: boolean = false;
  public notifications:WindowNotification[] = [];
  public auditInstructionWithDetails$: ReplaySubject<AuditInstructionWithDetailsModel> = new ReplaySubject<AuditInstructionWithDetailsModel>(1);
  public auditInstructionWithDetails: AuditInstructionWithDetailsModel | undefined = undefined;
  public destroy$: Subject<void> = new Subject<void>();
  public territoriesForDropdownData: TerritoryForDropdownModel[] = [];
  public customers: {customerID:number, customer:string }[] = [{customerID:0, customer:"" }]
  //public customersForDropdown$: Observable<CustomerForDropdownModel[]>;


  @Output() cancel: EventEmitter<any> = new EventEmitter();
  @Output() saved: EventEmitter<FundSearchModel> = new EventEmitter();
  @Output() selectCustomer: EventEmitter<{(customerID: number, customer: string): void}> = new EventEmitter();

  public auditInstructionForm = new UntypedFormGroup({
    auditInstructionID: new UntypedFormControl(null),
    instructionTitle: new UntypedFormControl(null),
    instructionBody: new UntypedFormControl(null),
    startDate: new UntypedFormControl(null),
    endDate: new UntypedFormControl(null),
    statusCodeID: new UntypedFormControl(null),
    visibilityTypeID: new UntypedFormControl(null),
    customerIDs: new UntypedFormControl(null),
    territoryIDs: new UntypedFormControl(null),
  });

  constructor(
    private store: Store,
    private auditInstructionWithDetailsService: AuditInstructionWithDetailsService,
    private appUxService: AppUxService,
    private changeDetectorRef: ChangeDetectorRef,
    private searchFilterService: SearchFilterService,
    private customerForDropdownService: CustomerForDropdownService
    ) {
    this.auditInstructionWithDetails$.pipe(takeUntil(this.destroy$)).subscribe(x => {
      let ai: AuditInstructionWithDetailsModel = this.auditInstructionWithDetails = x ? JsonUtilities.convertDatesAndCopy(x) : undefined;
      this.auditInstructionForm.patchValue({...ai});
    });
    this.auditInstructionForm.statusChanges.pipe(takeUntil(this.destroy$)).subscribe(x => {
      this.isValid = this.auditInstructionForm.valid;
      changeDetectorRef.detectChanges();
    });
    this.searchFilterService.territoriesForDropdown$.pipe(filter(x => Boolean(x)), takeUntil(this.destroy$)).subscribe(x => {
      this.territoriesForDropdownData =  [...[{ territoryID: null, displayName: '' }], ...(x ?? [])];
    })
    // if (!this.customersForDropdownData?.length) {
    //   customerForDropdownService.getAll().pipe(first()).subscribe(x => this.customersForDropdownData = x);
    // }
  }

  public validateForm(): boolean {
    this.auditInstructionForm.markAllAsTouched();
    return this.auditInstructionForm.valid;
  }

  public updateErrors() {
    this.notifications.length = 0;
    // if (this.fundForm.errors?.noScope) {
    //   this.notifications.push({ type:'error', isVisible:true, message: 'Must select at least one Fund or Territory'});
    // }
  }

  public addCustomer = ((customerID: number, customer: string) => {
    const customerIDs:number[] = ((this.auditInstructionForm.value.customerIDs ?? []) as number[]).filter(x => x != customerID);
    this.customers.push({ customerID: customerID, customer: customer });
    this.auditInstructionForm.patchValue({ customerIDs: [...customerIDs, customerID]  });
    this.changeDetectorRef.detectChanges();
  }).bind(this);


  ngOnInit(): void {
  }

  public onSelectCustomer(event:PreventableEvent, component: MultiSelectComponent) {
    this.selectCustomer.emit(this.addCustomer);
    event.preventDefault();
    component.blur();
    this.changeDetectorRef.detectChanges();
    return false;
  }

  public onClose() {
    this.cancel.emit(null);
  }

  public onSave() {

    if (!this.validateForm()) {
      this.updateErrors();
      return;
    }

    // const fundGroupIDs = (this.fundForm.controls.fundGroupIDs?.value ?? []) as number[];
    // const primaryFundGroupID = (this.fundForm.controls.primaryFundGroupID?.value ?? []) as number;

    // let fundGroups: FundGroupInfoModel[] = [];
    // this.fundGroupsForDropdown$.pipe(first()).subscribe(x => fundGroups = x.filter(y => fundGroupIDs.some(z => z == y.fundGroupID)) as FundGroupInfoModel[]);
    const instruction: AuditInstructionWithDetailsModel = {...this.auditInstructionWithDetails, ...this.auditInstructionForm.value};


    this.auditInstructionWithDetailsService.upsert(instruction).pipe(
      firstWithLoadingOverlayAndErrorHandling<AuditInstructionWithDetailsModel>()
    ).subscribe((x:ServiceResponse<AuditInstructionWithDetailsModel>) => {
      if (x.hasError) {
        this.appUxService.openErrorDialog(x.errorMessage);
      } else {
        this.saved.emit(x.response ?? undefined);
      }
    });

    // this.auditInstructionWithDetailsService.upsert(instruction).pipe(
    //   first(),
    //   catchError(x => {
    //     this.appUxService.openErrorDialog([ x?.error?.length ? x.error[0].Error : 'Unknown error']);
    //     return of(false);
    //   })).subscribe(y => {
    //     if (y)
    //       this.saved.emit(y as AuditInstructionWithDetailsModel);
    //   })
  }

  public onCancel() {
    this.cancel.emit(null);
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

}

