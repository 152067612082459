import { CustomerSearchWithDefaultModel } from '@alcon-db-models/CustomerSearchWithDefaultModel';
import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Optional, Output, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { GridDataResult } from '@progress/kendo-angular-grid';
import { WizardFeatureService } from '@services/wizard-feature.service';
import { take } from 'rxjs/operators';
import { PayeeSelectSearchBindingDirective } from 'src/app/directives/payee-select-search-binding.directive';
import { SelectComponentBase } from '../select.component.base';

@Component({
  selector: 'acb-alcon-select-payee-customer',
  template: `
    <div fxLayout="column" style="height: 100%;">
      <form [formGroup]="searchForm" style="width: 100%;" class="k-form">
        <acb-alcon-section-with-legend [doShowLegend]="windowMode == 'page'" class="acb-section-02" [sectionTitle]="'Select Payee'">
          <div fxLayout="row wrap" fxLayoutGap="2em" fxLayoutAlign="start start">
            <kendo-formfield fxFlex >
              <kendo-label [for]="customerCodeOrName" text="Number or Name"></kendo-label>
              <input formControlName="customerCodeOrName" kendoTextBox #customerCodeOrName autocomplete="off" />
              <kendo-formhint>&nbsp;</kendo-formhint>
            </kendo-formfield>
            <div fxFlex='0 0 6em' style="text-align: right; padding: 36px 0 0 0;" class="acb-search-button-wrapper">
              <button type="submit" kendoButton (click)="onSearch()" style="width:100%">Search</button>
            </div>
          </div>
        </acb-alcon-section-with-legend>
      </form>
      <kendo-grid
        payeeSelectSearchBinding
        [doInitResults]= "true"
        [selectable] = "{
          enabled: true,
          mode: 'single'
        }"
        [pageSize]="windowMode == 'dialog' ? windowHeigth > 800 ? 10 : 5 : 5"
        [pageable]="{
          buttonCount: 3,
          pageSizes: true,
          responsive: false,
          info: false
        }"
        [sortable]="true"
        [resizable]="true"
        (beforeBind)="updateFilter()"
        kendoGridSelectBy="customerID"
        [(selectedKeys)] = "selectedKeys"
        (selectionChange)="onSelectionChange($event)"
        #resultsGrid
        fxFlex
        [ngClass]="{
          'acb-grid-has-selection': !!getSelectedItem(),
          'acb-grid-window-mode-page': windowMode == 'page',
          'acb-grid-window-mode-dialog': windowMode == 'dialog',
          'acb-grid-page-size-5': resultsGrid.pageSize == 5,
          'acb-grid-page-size-10': resultsGrid.pageSize == 10,
          'acb-grid-page-size-15': resultsGrid.pageSize == 15
        }"
      >
        <!-- <ng-template kendoGridToolbarTemplate>
          <div fxLayout="row" fxLayoutAlign=" center" >
            <div fxFlexAlign="center" class="acb-toolbar-adjacent">
              <button
                kendoButton
                type="button"
                [icon]="'plus-outline'"
                (click)="onNewPayee()"
                fxFlexOffset=".25em"
                >New Payee</button>
            </div>
          </div>
        </ng-template> -->
        <kendo-grid-command-column [width]="32">
          <ng-template kendoGridCellTemplate let-dataItem="dataItem" let-rowIndex="rowIndex">
            <input [kendoGridSelectionCheckbox]="rowIndex" kendoCheckBox/>
          </ng-template>
        </kendo-grid-command-column>
        <kendo-grid-column title="Number" field="customerCode" [width]="110">
        </kendo-grid-column>
        <kendo-grid-column title="Name" field="customer" [width]="180">
        </kendo-grid-column>
        <kendo-grid-column title="Address 1" field="locationLine1" [width]="180" [sortable]="false">
        </kendo-grid-column>
        <kendo-grid-column title="Address 2" field="locationLine2" [width]="120" [sortable]="false">
        </kendo-grid-column>
        <kendo-grid-column title="City" field="city" [width]="120" [sortable]="false">
        </kendo-grid-column>
        <kendo-grid-column title="State / Provice" field="stateProvince" [width]="110" [sortable]="false">
        </kendo-grid-column>
        <kendo-grid-column title="Zip" field="postalCodeValue" [width]="80" [sortable]="false">
        </kendo-grid-column>
        <kendo-grid-column title="Country" field="country" [width]="100" [sortable]="false">
        </kendo-grid-column>
        <kendo-grid-messages
          [pagerItemsPerPage]="'per page'"
          [pagerItems]="'funds'"
          [pagerOf]="'of'"
          [pagerPage]="''"
          >
        </kendo-grid-messages>
        <ng-template #pagerTemplate kendoPagerTemplate let-totalPages="totalPages" let-currentPage="currentPage" let-total="total" let-pageSize="pageSize">
          <div fxLayout="row" fxLayoutAlign="start center" style="width: 100%;">
            <kendo-pager-prev-buttons></kendo-pager-prev-buttons>
            <kendo-pager-next-buttons></kendo-pager-next-buttons>
            <kendo-pager-page-sizes [pageSizes]="[5,10,15]" fxHide.xs="true"></kendo-pager-page-sizes>
            <div fxFlex></div>
            <div style="font-size: smaller">{{total}} payees</div>
          </div>
        </ng-template>
      </kendo-grid>
    </div>
  `,
  styleUrls: ['./select-payee-customer.component.scss']
})
export class SelectPayeeCustomerComponent extends SelectComponentBase<CustomerSearchWithDefaultModel> implements OnInit {

  @ViewChild(PayeeSelectSearchBindingDirective) public set customerSelectSearchBinding(value:PayeeSelectSearchBindingDirective) {
    this.bindingDirective = value;
    this.rebind();
  }

  @Output() newPayee: EventEmitter<any> = new EventEmitter();

  @Input() public set payeeSpec(value: { customerID?: number | null | undefined, payeeCustomerID?: number | null | undefined } | null | undefined) {

    if (!value) return;

    if (value.payeeCustomerID)
      this.selectedKeys[0] = value.payeeCustomerID;

    this.searchForm.patchValue({ prependPayeeCustomerID: value.payeeCustomerID, customerID: value.customerID  });

    const data: CustomerSearchWithDefaultModel[] = (this.resultsGrid?.data as GridDataResult)?.data;
    let payee = value.payeeCustomerID && data?.length ? data.find(x => x.customerID == value.payeeCustomerID) : null;
    if (payee) {
      this.doSelectionChange(payee);
    } else {
      this.bindingDirective?.dataChange.pipe(take(1)).subscribe(x => {
        this.doSelectionChange(x?.data?.find(y => y?.customerID == value.payeeCustomerID) as CustomerSearchWithDefaultModel ?? null);
      });
      super.onSearch();
    }
  };

  protected formFieldsToClearOnSelect:string[] = ['prependPayeeCustomerID'];

  public searchForm: UntypedFormGroup = new UntypedFormGroup({
    customerCodeOrName: new UntypedFormControl(),
    customerID: new UntypedFormControl(),
    prependPayeeCustomerID: new UntypedFormControl()
  });

  private _selectedPayee:CustomerSearchWithDefaultModel | null = null;

  constructor(
    changeDetectorRef: ChangeDetectorRef,
    @Optional() wizardFeatureService?: WizardFeatureService
    ) {
    super(wizardFeatureService, changeDetectorRef);
  }

  ngOnInit(): void {
  }

  public getSelectedItem():CustomerSearchWithDefaultModel | null {
    return this._selectedPayee;
  }

  protected doSelectionChange(customer: CustomerSearchWithDefaultModel | null) {
    this._selectedPayee = customer;
    this.searchForm.patchValue({ prependPayeeCustomerID: this._selectedPayee?.customerID ?? null });
    this.selectedChange.emit(this._selectedPayee);
  }

  public onNewPayee() {
    this.newPayee.emit();
  }
}

