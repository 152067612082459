import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { AccessControlBaseService } from './access-controlled-base.service';
import { CommitmentApprovalRuleUpsertRequestModel } from '@alcon-db-models/CommitmentApprovalRuleUpsertRequestModel'
import { Observable } from 'rxjs';
import { CommitmentApprovalRuleSearchModel } from '@alcon-db-models/CommitmentApprovalRuleSearchModel';
import { firstWithLoadingOverlayAndErrorHandling, ServiceResponse } from '../shared/acb-stream';

@Injectable({
  providedIn: 'root'
})
export class CommitmentApprovalRuleUpsertService  extends AccessControlBaseService {

  constructor(protected http: HttpClient, protected router: Router, protected store: Store) {
    super();
  }

  public upsertCommitmentApprovalRule(request: CommitmentApprovalRuleUpsertRequestModel ): Observable<ServiceResponse<CommitmentApprovalRuleSearchModel>> {
    return this.post<CommitmentApprovalRuleSearchModel>('CommitmentApprovalRuleUpsert', request).pipe(
      firstWithLoadingOverlayAndErrorHandling<CommitmentApprovalRuleSearchModel>()
    );
  }

  public createCommitmentApprovalRule(request: CommitmentApprovalRuleUpsertRequestModel ): Observable<ServiceResponse<CommitmentApprovalRuleSearchModel>> {
    return this.upsertCommitmentApprovalRule(request);
  }

  public saveCommitmentApprovalRule(request: CommitmentApprovalRuleUpsertRequestModel ): Observable<ServiceResponse<CommitmentApprovalRuleSearchModel>> {
    return this.upsertCommitmentApprovalRule(request);
  }
}
