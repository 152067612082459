import { ClaimWithDetailsModel } from '@alcon-db-models/ClaimWithDetailsModel';
import { ClaimUpsertRequestModel } from '@alcon-db-models/ClaimUpsertRequestModel';
import { PayTypeForDropdownModel } from '@alcon-db-models/PayTypeForDropdownModel';
import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormArray, UntypedFormGroup } from '@angular/forms';
import { Observable, Subject, BehaviorSubject } from 'rxjs';
import { filter, first, map, takeUntil } from 'rxjs/operators';
import { PayType, SpecialClaimType, StatusCode } from '@alcon-db-models/Enums';
import { StaticTypeModel } from '@alcon-db-models/StaticTypeModel';
import { Store } from '@ngrx/store';
import { selectAuditCodesWithGroups, selectBusinessRules, selectStaticTypes } from '@app-store/app-session/app-session.selectors';
import { ActivityForDropdownService } from '@services/activity-for-dropdown.service'
import { ActivityForDropdownModel } from '@alcon-db-models/ActivityForDropdownModel';
import { ClaimFormBaseService } from '@services/claim-form-base.service';
import { MultiSelectComponent } from '@progress/kendo-angular-dropdowns';
import { PayTypeForDropdownService } from '@services/paytype-for-dropdown.service'
import { selectCurrentPersonFeatures } from '@app-store/root.selectors';
import { FeatureModel } from '@alcon-db-models/FeatureModel';
import { AuditCodeWithCompositeNameModel } from '../core/core.module';
import { ApplicationService } from '@services/application.service';

export enum EditClaimMode { Create, Edit, Review }

@Component({
  selector: 'acb-alcon-edit-claim-details',
  template: `
    <form [formGroup]="detailForm" style="width: 100%;" class="k-form">
      <acb-alcon-section-with-legend [sectionTitle]="'Claim Details'" class="acb-section-04" [doShowLegend]="doShowLegend">

        <div fxLayout="row wrap" class="acb-row-wrap">

          <div fxFlex="0 0 8em" fxFlex.lt-sm="auto" class="acb-edit-detail-commitment-id">
            <kendo-label text="Commitment"></kendo-label>
            <button (click)="onCommitmentClick()">{{ claim?.commitmentID }}</button>
          </div>

          <div fxFlex="0 1 6em" fxFlex.lt-sm="auto" class="acb-edit-detail-available">
            <kendo-label text="Available"></kendo-label>
            <div [class]="getAvailableClass()">
              {{ getAvailableAmount() | currency }}
            </div>
          </div>

          <ng-container>
            <kendo-formfield *appFieldExtentions fxFlex="0 0 10em" fxFlex.lt-sm="auto">
              <kendo-label [for]="claimedAmount" text="Amount"></kendo-label>
              <kendo-numerictextbox [spinners]="false" [decimals]="2" [format]="'c2'" #claimedAmount [formControlName]="'claimedAmount'" required autocomplete="off"></kendo-numerictextbox>
              <kendo-formhint>Claimed amount</kendo-formhint>
              <kendo-formerror>Error: invalid amount</kendo-formerror>
            </kendo-formfield>
          </ng-container>

          <kendo-formfield *appFieldExtentions fxFlex="0 0 10em" fxFlex.lt-sm="auto">
            <kendo-label [for]="payType" text="Pay Type"></kendo-label>
            <kendo-dropdownlist
              [data]="payTypes$ | async"
              [textField]="'displayName'"
              [valueField]="'payTypeID'"
              [formControlName]="'payType'"
              [valuePrimitive]="false"
              #payType
              required
              >
            </kendo-dropdownlist>
            <kendo-formhint>Form of payment</kendo-formhint>
            <kendo-formerror>Error: pay type required</kendo-formerror>
          </kendo-formfield>

          <ng-container *ngIf="doShowDeduction">
            <kendo-formfield *appFieldExtentions fxFlex="1 0 8em" fxFlex.lt-sm="auto">
              <kendo-label [for]="deduction" text="Deduction ID"></kendo-label>
              <!-- <input formControlName="deduction" kendoTextBox #deduction autocomplete="off" /> -->
              <kendo-textbox formControlName="deduction" #deduction autocomplete="off"></kendo-textbox>
              <kendo-formhint>&nbsp;</kendo-formhint>
              <kendo-formerror>Error: deduction required</kendo-formerror>
            </kendo-formfield>
          </ng-container>

          <!-- TODO: Smells bad that this copied into the next section -->
          <ng-container *ngIf="editMode == editClaimModes.Edit && !doShowDeduction">
            <kendo-formfield *appFieldExtentions fxFlex="1 0 9em" fxFlex.lt-sm="auto">
              <kendo-label [for]="receivedDate" text="Received Date"></kendo-label>
              <kendo-datepicker
                formControlName="receivedDate"
                #receivedDate
                required
                autocomplete="off"
                [rangeValidation]="true"
                [format]="{ displayFormat: 'MM/dd/yyyy', inputFormat: 'MM/dd/yy'  }"
                >
              </kendo-datepicker>
              <kendo-formhint>&nbsp;</kendo-formhint>
              <kendo-formerror>&nbsp;</kendo-formerror>
            </kendo-formfield>
          </ng-container>

        </div>

        <div fxLayout="row wrap"  class="acb-row-wrap">

          <ng-container *ngIf="fundIsVenueAware">
            <kendo-formfield *appFieldExtentions fxFlex="1 0 calc(33% - 2em)">
              <kendo-label [for]="wereBenefitsReceived" class="acb-benefits-received acb-form-label-and-checkbox" text="Benefits Received?"><input formControlName="wereBenefitsReceived" type="checkbox" kendoCheckBox #wereBenefitsReceived /></kendo-label>
              <span class="acb-call-out-asterisk">* If any changes to benefits received, please comment below</span>
            </kendo-formfield>
          </ng-container>

          <!-- TODO:  (specialClaimTypes$ | async) x 2 ? -->
          <ng-container *ngIf="canReverseClaim && (specialClaimTypes$ | async) as specialClaimTypesData">

            <ng-container *ngIf="(specialClaimTypesData ? specialClaimTypesData.length : 0) > 1">
              <kendo-formfield *appFieldExtentions fxFlex="0 0 calc(33% - 2em)">
                <kendo-label [for]="specialClaimTypeID" text="Special Claim Type"></kendo-label>
                <kendo-dropdownlist
                  [data]="specialClaimTypesData"
                  [textField]="'displayName'"
                  [valueField]="'id'"
                  [formControlName]="'specialClaimTypeID'"
                  [valuePrimitive]="true"
                  #specialClaimTypeID
                  >
                </kendo-dropdownlist>
                <kendo-formhint>Reversal, etc.</kendo-formhint>
              </kendo-formfield>
            </ng-container>

            <ng-container *ngIf="detailForm.controls.specialClaimTypeID?.value == reversalSpecialClaimType">
              <kendo-formfield *appFieldExtentions fxFlex="0 0 calc(33% - 2em)">
                <kendo-label [for]="reversalRelatedClaims" text="Reversal Related Claims"></kendo-label>
                <kendo-multiselect
                  formControlName="reversalRelatedClaims"
                  [data]="claimsForReversalRelatedClaims"
                  #reversalRelatedClaims
                  [autoClose]="false"
                  [valuePrimitive]="true"
                  [ngClass]="'acb-reversal-related-claims'"
                  [ngClass.lt-lg]="'acb-multiselect-small'"
                >
                <ng-template kendoMultiSelectTagTemplate let-dataItem>
                  <span (mousedown)="$event.stopPropagation(); onReversalRelatedClaimClick($event,dataItem)">
                    <i class="fa-solid fa-eye"></i>{{ dataItem }}
                  </span>
                </ng-template>
                ></kendo-multiselect>
                <kendo-formhint>&nbsp;</kendo-formhint>
                <kendo-formerror>Error: required for reversal</kendo-formerror>
              </kendo-formfield>
            </ng-container>
          </ng-container>

        </div>

        <div fxLayout="row wrap"  class="acb-row-wrap" *ngIf="editMode == editClaimModes.Edit && canEditAfterAudit && wasAudited">

          <kendo-formfield *appFieldExtentions fxFlex="1 0 9em" fxFlex.lt-sm="auto">
            <kendo-label [for]="startDate" text="Start Date"></kendo-label>
            <kendo-datepicker
              formControlName="startDate"
              #startDate
              required
              autocomplete="off"
              [rangeValidation]="true"
              [format]="{ displayFormat: 'MM/dd/yyyy', inputFormat: 'MM/dd/yy'  }"
              >
            </kendo-datepicker>
            <kendo-formhint>&nbsp;</kendo-formhint>
            <kendo-formerror>&nbsp;</kendo-formerror>
          </kendo-formfield>

          <kendo-formfield *appFieldExtentions fxFlex="1 0 9em" fxFlex.lt-sm="auto">
            <kendo-label [for]="endDate" text="End Date"></kendo-label>
            <kendo-datepicker
              formControlName="endDate"
              #endDate
              required
              autocomplete="off"
              [rangeValidation]="true"
              [format]="{ displayFormat: 'MM/dd/yyyy', inputFormat: 'MM/dd/yy'  }"
              >
            </kendo-datepicker>
            <kendo-formhint>&nbsp;</kendo-formhint>
            <kendo-formerror>&nbsp;</kendo-formerror>
          </kendo-formfield>

          <kendo-formfield *appFieldExtentions fxFlex="4em" fxFlex.lt-sm="auto">
            <kendo-label [for]="numberOfAds" text="# Ads"></kendo-label>
            <kendo-numerictextbox
              [spinners]="true"
              [decimals]="0"
              [min]="0"
              #numberOfAds
              [format]="'#'"
              [formControlName]="'numberOfAds'"
              autocomplete="off">
            </kendo-numerictextbox>
            <kendo-formhint>&nbsp;</kendo-formhint>
            <kendo-formerror>&nbsp;</kendo-formerror>
          </kendo-formfield>

          <kendo-formfield *appFieldExtentions fxFlex="1 0 12em" fxFlex.lt-sm="auto">
            <kendo-label [for]="activityDescription" text="Activity Description"></kendo-label>
            <input formControlName="activityDescription" kendoTextBox #activityDescription autocomplete="off" />
            <kendo-formhint>&nbsp;</kendo-formhint>
            <kendo-formerror>&nbsp;</kendo-formerror>
          </kendo-formfield>

        </div>

        <div fxLayout="row wrap"  class="acb-row-wrap">

          <div fxLayout="column" fxFlex>

            <div fxLayout="row wrap"  class="acb-row-wrap">

              <ng-container *ngIf="editMode == editClaimModes.Edit && doShowDeduction">
                <kendo-formfield *appFieldExtentions fxFlex="9em" fxFlex.lt-sm="auto">
                  <kendo-label [for]="receivedDate" text="Received Date"></kendo-label>
                  <kendo-datepicker
                    formControlName="receivedDate"
                    #receivedDate
                    required
                    autocomplete="off"
                    [rangeValidation]="true"
                    [format]="{ displayFormat: 'MM/dd/yyyy', inputFormat: 'MM/dd/yy'  }"
                    >
                  </kendo-datepicker>
                  <kendo-formhint>&nbsp;</kendo-formhint>
                  <kendo-formerror>&nbsp;</kendo-formerror>
                </kendo-formfield>
              </ng-container>


              <ng-container *ngIf="editMode == editClaimModes.Edit && canEditAfterAudit && wasAudited && (this.auditCodes$ | async) as auditCodesData">
                <kendo-formfield *appFieldExtentions fxFlex="1 0 14em" fxFlex.lt-sm="auto">
                  <kendo-label [for]="currentAuditCodeIDs" text="Current Audit Codes"></kendo-label>
                  <kendo-multiselect
                    formControlName="currentAuditCodeIDs"
                    [data]="auditCodesData"
                    [textField]="'compositeName'"
                    [valueField]="'auditCodeID'"
                    [valuePrimitive]="true"
                    [filterable]="true"
                    (filterChange)="onApproveAuditCodeFilter($event)"
                    #currentAuditCodeIDs
                    >
                  </kendo-multiselect>
                  <kendo-formhint>&nbsp;</kendo-formhint>
                  <kendo-formerror>&nbsp;</kendo-formerror>
                  <ng-template #standardError><kendo-formerror>Error: code(s) required</kendo-formerror></ng-template>
                </kendo-formfield>
              </ng-container>


              <kendo-formfield *appFieldExtentions fxFlex="1 0 14em" fxFlex.lt-sm="auto">
                <kendo-label [for]="activity" text="Activity"></kendo-label>
                <kendo-combobox
                  [data]="activitiesForDropdown$ | async"
                  [textField]="'displayName'"
                  [valueField]="'activityID'"
                  [virtual]="{ itemHeight: 28 }"
                  [filterable]="true"
                  [formControlName]="'activity'"
                  (filterChange)="onActivityDropdownFilter($event)"
                  required
                  #activity
                  >
                </kendo-combobox>
                <kendo-formhint>Advertisement type/media</kendo-formhint>
                <kendo-formerror>Error: activity required</kendo-formerror>
              </kendo-formfield>

            </div>

            <div fxFlex class="acb-pop-text" fxLayout="column" *ngIf="editMode != editClaimModes.Create">
              <div>Proof of Performance</div>
              <div [innerHtml]="detailForm.get('activity')?.value?.proofOfPerformance" class="acb-inserted-html" fxFlex>
              </div>
            </div>

            <ng-container  *ngIf="editMode == editClaimModes.Create && doesUseClaimDoctors">
              <kendo-formfield *appFieldExtentions>
                <kendo-label [for]="comment" text="Comment" [optional]="true"></kendo-label>
                <textarea formControlName="comment" kendoTextArea #comment rows="3" style="resize: vertical;"></textarea>
                <kendo-formhint>&nbsp;</kendo-formhint>
                <kendo-formerror *ngIf="detailForm.controls.comment.errors?.maxlength">Error: exceeds 2000 character limit</kendo-formerror>
              </kendo-formfield>
            </ng-container>

            <ng-container  *ngIf="editMode == editClaimModes.Edit && canEditAfterAudit && wasAudited">
              <kendo-formfield *appFieldExtentions>
                <kendo-label [for]="currentStatusComment" text="Current Status Comment" [optional]="true"></kendo-label>
                <textarea formControlName="currentStatusComment" kendoTextArea #currentStatusComment rows="3" style="resize: vertical;"></textarea>
                <kendo-formhint>&nbsp;</kendo-formhint>
                <kendo-formerror *ngIf="detailForm.controls.currentStatusComment.errors?.maxlength">Error: exceeds 2000 character limit</kendo-formerror>
              </kendo-formfield>
            </ng-container>

          </div>
          <div fxLayout="column" fxFlex>

            <kendo-formfield *appFieldExtentions>
              <kendo-label [for]="invoiceNumbers" text="Invoice Numbers"></kendo-label>
              <kendo-multiselect
                class="acb-invoice-numbers"
                formControlName="invoiceNumbers"
                #invoiceNumbers
                [allowCustom]="false"
                [popupSettings]="{popupClass: 'acb-popup-hidden' }"
                (blur)="onInvoiceNumbersBlur()"
                required
                >
                ></kendo-multiselect>
              <kendo-formhint>&nbsp;</kendo-formhint>
              <kendo-formerror *ngIf="detailForm.controls.invoiceNumbers.errors?.segMaxLength;">Error: one or more exceed 64 character limit</kendo-formerror>
              <kendo-formerror *ngIf="detailForm.controls.invoiceNumbers.errors?.required;">Error: invoice number required</kendo-formerror>
            </kendo-formfield>

            <ng-container  *ngIf="editMode != editClaimModes.Create">
              <kendo-formfield *appFieldExtentions>
                <kendo-label [for]="comment" text="Comment" [optional]="true"></kendo-label>
                <textarea formControlName="comment" kendoTextArea #comment rows="3" style="resize: vertical;"></textarea>
                <kendo-formhint>&nbsp;</kendo-formhint>
                <kendo-formerror *ngIf="detailForm.controls.comment.errors?.maxlength">Error: exceeds 2000 character limit</kendo-formerror>
              </kendo-formfield>
            </ng-container>

            <div fxFlex *ngIf="doesUseClaimDoctors">
              <kendo-label text="Doctors"></kendo-label>
              <button
                kendoButton
                class="acb-add-doctor-button"
                [ngClass] = "{ 'acb-error': (!doctorsFormArray.valid && !doctorsFormArray.pristine) || (claimDoctorsRequired && !doctorsFormArray.length && !detailForm.valid && !detailForm.pristine)  }"
                (click)="this.addDoctorHandler()"
                [disabled]="this.doctorsFormArray.disabled"
                >Add
              </button>
              <div gdColumns="repeat(auto-fit, minmax(22em, 1fr))" class="acb-edit-commitment-doctor-list">
                <acb-alcon-edit-claim-details-doctor
                  *ngFor="let doctorForm of $any(doctorsFormArray?.controls); index as i;"
                  [form]="doctorForm"
                  (delete)="removeDoctorHandler($event)"
                  >
                </acb-alcon-edit-claim-details-doctor>
              </div>
            </div>
          </div>
        </div>
        <ng-container  *ngIf="editMode == editClaimModes.Create && !doesUseClaimDoctors">
            <kendo-formfield *appFieldExtentions class="acb-comments-without-doctors">
              <kendo-label [for]="comment" text="Comment" [optional]="true"></kendo-label>
              <textarea formControlName="comment" kendoTextArea #comment rows="3" style="resize: vertical;"></textarea>
              <kendo-formhint>&nbsp;</kendo-formhint>
              <kendo-formerror *ngIf="detailForm.controls.comment.errors?.maxlength">Error: exceeds 2000 character limit</kendo-formerror>
            </kendo-formfield>
          </ng-container>
      </acb-alcon-section-with-legend>
    </form>
  `,
  styleUrls: ['./edit-Claim-details.component.scss']
})
export class EditClaimDetailsComponent implements OnInit, OnDestroy, AfterViewInit {

  doesUseClaimDoctors: boolean = false;
  claimDoctorsRequired: boolean = false;

  public doctorsFormArray: UntypedFormArray = new UntypedFormArray([]);

  @Input() editMode: EditClaimMode = EditClaimMode.Edit;
  @Input() claim?: ClaimWithDetailsModel;
  @Input() doShowLegend: boolean = true;
  @Input() canReverse: boolean = false;

  @Output() claimChange: EventEmitter<ClaimUpsertRequestModel | ClaimWithDetailsModel | any> = new  EventEmitter<ClaimUpsertRequestModel | ClaimWithDetailsModel | any>();
  @Output() validChange: EventEmitter<boolean> = new  EventEmitter<boolean>(false);
  @Output() viewCommitment: EventEmitter<number> = new EventEmitter();
  @Output() viewClaim: EventEmitter<number> = new EventEmitter();

  @ViewChild('invoiceNumbers') invoiceNumbers?: MultiSelectComponent;

  public destroy$: Subject<void> = new Subject<void>();
  public editClaimModes = EditClaimMode;
  public payTypes = PayType;
  public reversalSpecialClaimType = SpecialClaimType.Reversal;

  public canEditAfterAudit: boolean = false;
  public canReverseClaim: boolean = false;
  public wasAudited: boolean = false;
  public fundIsVenueAware:boolean = false;

  public currentUserFeatures$ = this.store.select(selectCurrentPersonFeatures);
  public activitiesForDropdownSource$: BehaviorSubject<ActivityForDropdownModel[]> = new BehaviorSubject(<ActivityForDropdownModel[]>[]);
  public activitiesForDropdown$: Observable<ActivityForDropdownModel[]> = new BehaviorSubject(<ActivityForDropdownModel[]>[]);
  public staticTypes$: Observable<StaticTypeModel[]>;
  public payTypes$: BehaviorSubject<PayTypeForDropdownModel[]> = new BehaviorSubject(<PayTypeForDropdownModel[]>[]);
  public auditCodes$: Observable<AuditCodeWithCompositeNameModel[]>;
  public claimsForReversalRelatedClaims: number[] = [];

  public auditCodesSource$: Observable<AuditCodeWithCompositeNameModel[]>;
  public specialClaimTypes$: Observable<StaticTypeModel[]>;


  public detailForm: UntypedFormGroup;

  public get doShowDeduction() {
    const payTypeID = this.detailForm.get('payType')?.value?.payTypeID;
    return payTypeID == PayType.Deduction || (payTypeID == PayType.Internal && this.claim?.fundAllowsInternalDeduction);
  }

  private _lastPayeeCustomerID:number|undefined|null;
  private _lastFundID:number|undefined|null;

  constructor(
    activityForDropdownService: ActivityForDropdownService,
    payTypeForDropdownService: PayTypeForDropdownService,
    changeDetectorRef: ChangeDetectorRef,
    private applicationService: ApplicationService,
    private store: Store,
    private claimFormService: ClaimFormBaseService
    ) {

    this.auditCodes$ = this.auditCodesSource$ = store.select(selectAuditCodesWithGroups).pipe(first(), map(x => x.map(y => new AuditCodeWithCompositeNameModel(y))));

    store.select(selectBusinessRules).pipe(first()).subscribe(x => {
      this.doesUseClaimDoctors = x.DoesUseClaimDoctors;
      this.claimDoctorsRequired = x.DoesUseClaimDoctors && x.ClaimDoctorsRequired;
    })

    this.detailForm = this.claimFormService.form.controls.claimDetails as UntypedFormGroup;
    this.staticTypes$ = this.store.select(selectStaticTypes);

    this.currentUserFeatures$.pipe(first(x => x?.length > 0)).subscribe(x => {
      this.canEditAfterAudit = x.some((x:FeatureModel) => x.code == 'caneditafteraudit');
      this.canReverseClaim = x.some((x:FeatureModel) => x.code == 'canreverseclaims');
    });

    //TODO: extract
    this.specialClaimTypes$ = this.staticTypes$.pipe(
      map(x => x.filter(y => y.tableName == 'SpecialClaimType')),
      map(x => [{}, ...x]),
      map(x => x.filter(y => this.canReverse || y.id != SpecialClaimType.Reversal))
      );

    claimFormService.claim$.pipe(takeUntil(this.destroy$)).subscribe(x => {

      this.specialClaimTypes$ = this.staticTypes$.pipe(
        map(x => x.filter(y => y.tableName == 'SpecialClaimType')),
        map(x => [{}, ...x]),
        map(x => x.filter(y => this.canReverse || y.id != SpecialClaimType.Reversal))
        );

      this.fundIsVenueAware = x.fundIsVenueAware ?? false;

      this.doctorsFormArray = this.detailForm.controls.doctors as UntypedFormArray;

      const doUpdateFund = x?.fundID != this._lastFundID;
      const doUpdatePaytype = x?.payeeCustomerID != this._lastPayeeCustomerID;
      this.claim = x;

      this.wasAudited = x?.statusCodeID != StatusCode.Canceled
        && x?.statusCodeID != StatusCode.Inprocess

      if (doUpdateFund) {
        if (x?.fundID) {
          activityForDropdownService.getWithQuery("fundID=" + x.fundID).pipe(first()).subscribe(y => {
            this.activitiesForDropdownSource$.next(y);
          });
        } else {
          this.activitiesForDropdownSource$.next([]);
        }
      }

      if (doUpdatePaytype) {
        if (x?.payeeCustomerID) {
          payTypeForDropdownService.getWithQuery("customerID=" + x?.payeeCustomerID).pipe(first()).subscribe(y => {
            this.payTypes$.next(y);
          });
        } else {
          this.payTypes$.next([]);
        }
        this.onActivityDropdownFilter(undefined);
      }

      this._lastPayeeCustomerID = this.claim?.payeeCustomerID;
      this._lastFundID = this.claim?.fundID;

      if (this.claim.commitmentID)
        this.applicationService.getReversibleClaimsForCommitment(this.claim.commitmentID)
          .pipe(first())
          .subscribe(x => this.claimsForReversalRelatedClaims = x?.filter(y => y != this.claim?.claimID) ?? []);
      else
        this.claimsForReversalRelatedClaims = [];

      changeDetectorRef.markForCheck();
    });
  }

  public getAvailableClass() {
    const val = this.getAvailableAmount();
    return val == 0 ? '' : val > 0 ? 'acb-good' : 'acb-danger';
  }

  public getAvailableAmount() {
    return (this.claim?.commitmentAvailableAmount ?? 0) - (this.detailForm?.value?.claimedAmount ?? 0);
  }

  ngOnInit(): void {

  }

  public onCommitmentClick() {
    this.viewCommitment.emit(this.claim?.commitmentID ?? undefined);
  }

  public onActivityDropdownFilter(value?: string) {
    if (!value) {
      this.activitiesForDropdown$ = this.activitiesForDropdownSource$;
      return;
    }
    this.activitiesForDropdown$ = this.activitiesForDropdownSource$.pipe(
      map(x => x
        .filter(y => y.displayName!.toLowerCase()
          .indexOf(value.toLowerCase()) > -1)));
  }

  private _invoiceNumbersText?: string;
  ngAfterViewInit() {
    const input = document.querySelector(".acb-invoice-numbers input") as HTMLInputElement;
    if (input) {
      input.addEventListener("keyup", e => {
        if (e.key === 'Enter' && this._invoiceNumbersText) {
          this.addInvoiceValue(this._invoiceNumbersText);
          this._invoiceNumbersText = undefined;
          input.value = "";
          e.cancelBubble = true;
          e.stopImmediatePropagation();
        }
      });
      input.addEventListener("input", () => {
        this._invoiceNumbersText = input.value;
      });
    }
  }

  public onInvoiceNumbersBlur() {
    if (this._invoiceNumbersText) {
      this.addInvoiceValue(this._invoiceNumbersText);
    }
    this._invoiceNumbersText = undefined;
  }

  public onApproveAuditCodeFilter(value: string) {
    if (!value) {
      this.auditCodes$ = this.auditCodesSource$;
      return;
    }
    this.auditCodes$ = this.auditCodesSource$.pipe(
      map(x => x
        .filter(y => y.compositeName!.toLowerCase()
          .indexOf(value.toLowerCase()) > -1)));
  }

  public onReversalRelatedClaimClick(event: Event, claimID: number) {
    event.stopPropagation();
    event.cancelBubble = true;
    event.preventDefault();
    event.stopImmediatePropagation();
    if (claimID)
      this.viewClaim.emit(claimID);
    return false;
  }



  private addInvoiceValue(value:string) {
    const invoiceNumbersFormControl = this.detailForm.controls.invoiceNumbers;
    if (value && invoiceNumbersFormControl) {
      const current: string[] = invoiceNumbersFormControl.value ?? [];
      const values = value.split(",").filter(x => !current.includes(x));
      if (values.length) {
        invoiceNumbersFormControl.patchValue([
          ...current,
          ...values
        ]);
        invoiceNumbersFormControl.updateValueAndValidity();
      }
    }
  }


  public validateForm(): boolean {
    this.detailForm.markAllAsTouched();
    return this.detailForm.valid;
  }

  public getErrors(): string[] {

    //TODO: Refactor, merge with kendo-formerror.  String resource?
    const errors: string[] = [];
    if (this.detailForm.controls.claimedAmount.errors)
      errors.push("Claimed amount is required");

    if (this.detailForm.controls.invoiceNumbers.errors)
      errors.push("One or more invoice number exceeds 64 character limit");

    if (this.detailForm.controls.activity.errors)
      errors.push("Activity is required");

      if (this.detailForm.controls.activityDescription.errors)
      errors.push("Activity Description is required");

    if (this.detailForm.controls.comment.errors)
      errors.push("Comment exceeds 2000 character limit");

    if (this.detailForm.controls.payType.errors)
      errors.push("Pay type is required");

    return errors;
  }

  addDoctorHandler() {
    this.claimFormService.addDoctor();
  }

  removeDoctorHandler(evt:any) {
    this.claimFormService.removeDoctor(evt);
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}

