import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { selectStaticTypes } from 'src/app/store/app-session/app-session.selectors';
import { first, map, tap } from 'rxjs/operators';
import { FeatureModel } from '@alcon-db-models/FeatureModel';
import { Observable } from 'rxjs';
import { RowClassArgs } from '@progress/kendo-angular-treelist';
import { FeatureType, FeatureMandateType, StatusCode, VisibilityType } from '@alcon-db-models/Enums';
import { StaticTypeModel } from '@alcon-db-models/StaticTypeModel';

import { FeatureWithChildren } from '../core/core.module'

@Component({
  selector: 'acb-alcon-feature-tree',
  templateUrl: './feature-tree.component.html',
  styleUrls: ['./feature-tree.component.scss']
})
export class FeatureTreeComponent implements OnInit {

  public FeatureMandateType = FeatureMandateType;

  public staticTypes$: Observable<StaticTypeModel[]>;
  public accessRoles$: Observable<StaticTypeModel[]>;

  @Input() features: FeatureWithChildren[] = [];
  @Input() doShowAllAccessRoles: boolean = false;
  @Input() isLoading: boolean = false;
  @Input() initiallyExpanded: boolean = false;
  @Input() labelForCreateNew: string = "Create New Feature";

  @Output() createFeature: EventEmitter<PointerEvent> = new EventEmitter();
  @Output() editFeature: EventEmitter<{pointerEvent: PointerEvent, dataItem:FeatureWithChildren, cellContext:any, isNew:boolean}> = new EventEmitter();

  public StatusCode = StatusCode;
  public VisibilityType = VisibilityType;

  constructor(private store: Store) {
    this.staticTypes$ = this.store.select(selectStaticTypes);
    this.accessRoles$ = this.staticTypes$.pipe(map(x => x.filter(y => y.tableName == 'AccessRole')));
  }

  public getRowClass(context: RowClassArgs) {

    let className = "acb-feature-row";
    let feature: FeatureModel = context.dataItem as FeatureModel;
    if (feature) {

      switch(feature.featureType) {
        case FeatureType.Container:
          className += ' acb-feature-row-container';
          break;
        case FeatureType.Route:
          className += ' acb-feature-row-route';
          break;
        case FeatureType.Resource:
          className += ' acb-feature-row-resource';
          break;
        case FeatureType.System:
          className += ' acb-feature-row-system';
          break;
        }

      switch(feature.featureMandateType) {
        case FeatureMandateType.System:
          className += ' acb-feature-mandate-system';
          break;
        case FeatureMandateType.User:
          className += ' acb-feature-mandate-user';
          break;
        case FeatureMandateType.External:
          className += ' acb-feature-mandate-external';
          break;
        }
    }
    return className;
  }

  public lookUpAccessRoleName(accessRoleID: number, doReplaceSpaces: boolean = false): string | null {
    let name: string = "";
    this.accessRoles$.pipe(first(), select(x => x.find(y => y.id == accessRoleID)), tap(x => name = x?.displayName ?? "")).subscribe();
    return doReplaceSpaces ? name?.replace(/ /g, '\u00a0') ?? null : name;
  }

  ngOnInit(): void {
  }

}
