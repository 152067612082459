import { CommitmentWithDetailsModel } from '@alcon-db-models/CommitmentWithDetailsModel';
import { OnDestroy, Optional } from '@angular/core';
import { Component, Input, OnInit } from '@angular/core';
import { CommitmentSubjectBaseService } from '@services/commitment-subject-base.service';
import * as moment from 'moment';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ViewMode } from '../components.module';
import { PhaseActualYear } from './view-phasing-year.component';

@Component({
  selector: 'acb-alcon-view-commitment-phasing',
  template: `
  <acb-alcon-section-with-legend
    *ngIf="viewMode === 'view'"
    #section
    [sectionTitle]="'Phasing / Performance'"
    class="acb-section-10"
    style="width: 100%; height: 100%;"
    [isCollapsible]="isCollapsible"
    >
    <kendo-sparkline
      legendContent
      *ngIf="getHasFirstActualAmounts()"
      [data]="firstActualAmounts"
      type="area"
      [chartArea]="{ height: 18, background: 'transparent' }"
      [seriesColors]="['#d1edf0']"
      [tooltip]="{ background: '#003595', color: '#f9f9f8', format: '{0:c0}' }"
      >
    </kendo-sparkline>
    <div *ngIf="phaseActualYears">
      <div class="acb-phase" *ngFor="let phaseActualYear of phaseActualYears;">
        <acb-alcon-view-phasing-year [phaseActualYear]="phaseActualYear" >
        </acb-alcon-view-phasing-year>
      </div>
    </div>
  </acb-alcon-section-with-legend>
  <div *ngIf="viewMode === 'print'">
    <div *ngIf="phaseActualYears">
      <div class="acb-phase" *ngFor="let phaseActualYear of phaseActualYears;">
        <acb-alcon-view-phasing-year [phaseActualYear]="phaseActualYear" >
        </acb-alcon-view-phasing-year>
      </div>
    </div>
  </div>
  <div *ngIf="viewMode === 'edit'">
    <ng-container *ngIf="phaseActualYears">
      <div *ngFor="let phaseActualYear of phaseActualYears;">
        <kendo-grid
          class="acb-results-grid acb-display-grid"
          [data]= "getAltViewQuarters(phaseActualYear)"
          [pageable]="false"
          [sortable]="false"
          [reorderable]="false"
          [selectable] = "false"
          [resizable]="true"
          >
          <kendo-grid-column title="Period" field="quarter" [width]="60"></kendo-grid-column>
          <kendo-grid-column title="Projection" field="phaseAmount" format="{0:c}" class="acb-grid-column-currency" ></kendo-grid-column>
        </kendo-grid>
      </div>
    </ng-container>
  </div>
  `,
  styleUrls: ['./view-commitment-phasing.component.scss']
})
export class ViewCommitmentPhasingComponent implements OnInit, OnDestroy {

  public  firstActualAmounts:number[] = [];
  private _phaseActualYears:PhaseActualYear[] = [];
  public get phaseActualYears():PhaseActualYear[] { return this._phaseActualYears };
  public destroy$: Subject<void> = new Subject<void>();

  @Input() viewMode:ViewMode = 'view';
  @Input() isCollapsible: boolean = true;

  private _commitment?: CommitmentWithDetailsModel;
  @Input() public set commitment(value: CommitmentWithDetailsModel | undefined) {

    const commitment = this._commitment = value;
    if (!commitment) {
      this._phaseActualYears = [];
      this.firstActualAmounts = [];
      return;
    }

    //FIXME: Details are updating after we pull the dates.  This can cause extranous years...
    const commitmentYears = [commitment.startDate && commitment.startDate.getFullYear ? commitment.startDate.getFullYear() : 0];
    const actualYears: number[] = commitment.actuals?.length ? commitment.actuals.map(y => (y.activityPeriod && y.activityPeriod.getFullYear ? y.activityPeriod.getFullYear() : 0)) ?? [] : [];
    const phaseYears: number[] = commitment.phases?.length ? commitment.phases.map(y => y.year ?? 0) ?? [] : [];
    const years = [...new Set(commitmentYears.concat(actualYears, phaseYears).filter(Boolean))];
    const phaseTotal = (commitment.phases?.length ? [0].concat.apply([], commitment.phases.map(x => x.amounts ?? [0])) : [0]).reduce((a,b) => a + b);
    const actualTotal = commitment.actuals?.length ? commitment.actuals.map(x => x.actualPaidAmount ?? 0).reduce((a,b) => a + b) : 0;
    const performance = phaseTotal ? actualTotal ? actualTotal / phaseTotal : 0 : undefined;

    this._phaseActualYears = years.map(year => ({
      year: year,
      quarterAndMonths: Array(12).fill(0).map((_x, monthIndex) => {
        const quarterIndex = Math.floor(monthIndex / 3);
        const phaseModel = commitment.phases?.length ? commitment.phases.find(x => x.year === year && (x.amounts?.length ?? 0 > monthIndex)) : undefined;
        const actualModel = commitment.actuals?.length ? commitment.actuals.find(x => x.activityPeriod?.getTime && x.activityPeriod.getFullYear() === year && x.activityPeriod.getMonth() === monthIndex) : undefined;
        const phase = phaseModel ? phaseModel.amounts![monthIndex] : 0.00;
        const actual = actualModel?.actualPaidAmount ?? 0.00;
        const performance = phase > 0 ? actual > 0 ? actual / phase : 0 : undefined;
        return {
          quarter: 'Q' + (quarterIndex + 1),
          quarterNumber: quarterIndex,
          month: moment.monthsShort(monthIndex),
          monthNumber: monthIndex,
          phaseAmount: phase,
          actualAmount: actual,
          performanceAmount: performance
        }
      }),
      phaseTotal: phaseTotal,
      actualTotal: actualTotal,
      performance: performance
    }));
    this.firstActualAmounts = this._phaseActualYears?.length ? this._phaseActualYears[0].quarterAndMonths.map(x => x.actualAmount) : [];
  }
  public get commitment() { return this._commitment; };

  constructor(@Optional() commitmentSubjectBaseService?: CommitmentSubjectBaseService) {
    if (commitmentSubjectBaseService) {
      commitmentSubjectBaseService.commitment$.pipe(takeUntil(this.destroy$)).subscribe(x => this.commitment = {...x});
    }
  }

  public getHasFirstActualAmounts = () => this.firstActualAmounts.some(Boolean);

  public getAltViewQuarters(phaseActualYear:PhaseActualYear) {
    return Array(4).fill(0).map((x,i) => {
      const phase = phaseActualYear.quarterAndMonths.filter(y => y.quarterNumber == i).map(y => y.phaseAmount).reduce((a,v) => a + v) ?? 0;
      const actual = phaseActualYear.quarterAndMonths.filter(y => y.quarterNumber == i).map(y => y.actualAmount).reduce((a,v) => a + v) ?? 0;
      const performance = phase > 0 && actual > 0 ? actual / phase : 0;
      return ({
        quarter: 'Q' + (i + 1),
        phaseAmount: phaseActualYear.quarterAndMonths.filter(y => y.quarterNumber == i).map(y => y.phaseAmount).reduce((a,v) => a + v),
        actualAmount: phaseActualYear.quarterAndMonths.filter(y => y.quarterNumber == i).map(y => y.actualAmount).reduce((a,v) => a + v),
        performance: performance
      });
    });
  }

  ngOnInit(): void {
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
