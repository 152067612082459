import { Injectable } from '@angular/core';
import { Utilities } from '../shared/utilities';

const APP_PREFIX = '[ACB-ALCON] ';

@Injectable({
  providedIn: 'root'
})
export class ClientStorageService {

  private _localStorageListener: {[key: string]: {(evt: StorageEvent):void}} = {};

  public updateLocalStorageListener(key:string, callBack?: {(key: string | null, newValue: string | null, oldValue: string | null): void}, isRemoval: boolean = false): void {
    if (isRemoval) {
      window.removeEventListener('storage', this._localStorageListener[key]);
    } else {
      if (this._localStorageListener[key] || !callBack) return;
      window.addEventListener('storage', this._localStorageListener[key] = (evt: StorageEvent): void => {
        if (evt.storageArea == localStorage) callBack(evt.key, evt.newValue, evt.oldValue);
      });
    }
  }

  public mergeAndSetItem(key: string, value: {}, clientStorageType: ClientStorageType = ClientStorageType.sessionOnly): void {
    let storage = this.resolveStorage(clientStorageType);
    this.setItemInternal(key, {...this.getItemInternal(key, storage), ...value}, storage);
  }

  public setItem(key: string, value: any, clientStorageType: ClientStorageType = ClientStorageType.sessionOnly): void {
    this.setItemInternal(key, value, this.resolveStorage(clientStorageType));
  }

  private setItemInternal(key: string, value: any, storage: Storage): void {
    storage.setItem(ClientStorageService.createScopeKey(key), JSON.stringify(value));
  }

  public getItem(key: string, clientStorageType: ClientStorageType = ClientStorageType.storeLocal): any {
    return {
      ...(clientStorageType === ClientStorageType.storeLocal ? this.getItemInternal(key, localStorage) : {}),
      ...Utilities.getShallowObjectCopyWithoutNullProperties(this.getItemInternal(key, sessionStorage))
    };
  }

  public getItemInternal(key: string, storage: Storage): any {
    // rgnx does not consider date serializable, strictStateSerializability will fail
    return JSON.parse(storage.getItem(ClientStorageService.createScopeKey(key)) ?? "null");
  }

  private removeItem(key: string, clientStorageType: ClientStorageType = ClientStorageType.sessionOnly): void {
    this.removeItemInternal(key,this.resolveStorage(clientStorageType));
  }

  private removeItemInternal(key: string, storage: Storage): void {
    storage.removeItem(ClientStorageService.createScopeKey(key));
  }

  private resolveStorage(clientStorageType: ClientStorageType): Storage {
    switch (clientStorageType) {
      case ClientStorageType.sessionOnly:
        return sessionStorage;
      case ClientStorageType.storeLocal:
        return localStorage;
      }
  }

  private static _isoDateFormat: RegExp = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(?:\.\d*)?Z?$/;

  public static checkIsoDateString(value: any): boolean {
    return typeof value === 'string' ? this._isoDateFormat.test(value) : false;
  }

  public static createScopeKey(key: string) {
    return `${APP_PREFIX}${key}`;
  }
}

export enum ClientStorageType {
  sessionOnly, storeLocal
}
