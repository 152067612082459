import { Component, OnInit, AfterViewInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { first, takeUntil} from 'rxjs/operators';
import { Store, select, ActionsSubject } from '@ngrx/store';
import { ofType } from '@ngrx/effects';
import * as fromRoot from './../../store/'
import { AppWindowService } from '@services/app-window.service';
import { AppAction, userLoginFailure, userLoginSuccessComplete } from '@app-store/app-session/app-session.actions';
import { updateThemeId } from '@app-store/user-preferences/user-preferences.actions';
import { Subject } from 'rxjs';
import { selectExternalLinks } from '@app-store/app-session/app-session.selectors';
import { AuthenticationService } from '@services/authentication.service';

@Component({
  selector: 'acb-alcon-login-page',
  template: `
    <form [formGroup]="form">
      <fieldset class="k-form-fieldset">
        <div [hidden]="!error" class="acb-validation-errors">
          {{ error }}
        </div>
        <div class="acb-login-banner-logo-wrapper"><img src="assets/images/logo.svg" type="image/svg+xml" width="240" height="100" /></div>
        <kendo-formfield class="acb-login-username">
          <kendo-label [for]="username" text="Username"></kendo-label>
          <input formControlName="username" autocomplete="username" kendoTextBox #username required autofocus />
          <kendo-formhint>&nbsp;</kendo-formhint>
          <kendo-formerror>Error: Username is required</kendo-formerror>
        </kendo-formfield>
        <kendo-formfield class="acb-login-password">
          <kendo-label [for]="password" text="Password">
          </kendo-label>
          <input type="password" formControlName="password" autocomplete="current-password" kendoTextBox #password required/>
          <kendo-formhint>&nbsp;</kendo-formhint>
          <kendo-formerror>Error: Password is required</kendo-formerror>
        </kendo-formfield>
        <div class="acb-login-controls-bottom">
          <kendo-formfield class="acb-login-checkbox">
            <div class="k-checkbox-wrap">
              <input #keepMeLoggedInCheckBox type="checkbox" kendoCheckBox formControlName="keepMeLoggedInCheckBox" />
              <kendo-label [for]="keepMeLoggedInCheckBox" class="k-checkbox-label" text="Keep me logged in"></kendo-label>
            </div>
          </kendo-formfield>
          <div class="k-form-buttons">
            <button class="k-button k-primary" (click)="login()">Login</button>
          </div>
          <div class="acb-login-forgot-password">
            <a href="forgot-password">Forgot Password?</a>
          </div>
        </div>
      </fieldset>
    </form>

    <a id="acb-privacy-link" href="{{privacyPolicyUrl}}" target="_blank"  *ngIf="privacyPolicyUrl">ACB Privacy Policy</a>

  `,
  styleUrls: ['./login-page.component.scss']
})
export class LoginPageComponent implements OnInit, OnDestroy, AfterViewInit  {

  public error?: string;
  public returnUrl?: string;
  //TODO: use common method with footer-main
  public privacyPolicyUrl: string | undefined;

  public destroy$: Subject<void> = new Subject<void>();

  public form: UntypedFormGroup = new UntypedFormGroup({
    username: new UntypedFormControl(),
    password: new UntypedFormControl(),
    keepMeLoggedInCheckBox: new UntypedFormControl()
  });

  constructor(
    private store: Store,
    private route: ActivatedRoute,
    private router: Router,
    private actionsSubject$: ActionsSubject,
    private appWindowService: AppWindowService,
    private changeDetectorRef: ChangeDetectorRef,
    private authenticationService: AuthenticationService
    ) {
      //TODO: Configuration is loaded after authentication now. PrivacyPolicyUrl wil not have a value until after login.  We need an anonymous configuration mechanism...
      store.select(selectExternalLinks).pipe(first(x => x?.PrivacyPolicyUrl)).subscribe(x => {
        this.privacyPolicyUrl = x.PrivacyPolicyUrl;
      });
    }

  ngAfterViewInit(): void {
    document.querySelector('body')?.classList.add('acb-login');
  }

  ngOnDestroy(): void {
    document.querySelector('body')?.classList.remove('acb-login');
    this.destroy$.next();
    this.destroy$.complete();
  }

  public ngOnInit(): void {
    //this.returnUrl = this.route.snapshot.params["returnUrl"];
    this.store.pipe(select(fromRoot.AppSessionSelectors.selectDoKeepMeLoggedIn), first()).subscribe(x => {
      this.form.patchValue({ keepMeLoggedInCheckBox: x });
    });
  }

  public login() {

    this.error = undefined;
    this.appWindowService.showLoadingOverlay();
    this.form.markAllAsTouched();

    const _handleError = (message: string) => {
      this.appWindowService.hideLoadingOverlay();
      this.error = message;
    }
    this.actionsSubject$
      .pipe(ofType(userLoginSuccessComplete.type, userLoginFailure.type), takeUntil(this.destroy$))
      .subscribe(
        (action: AppAction) => {
          if (action?.type === userLoginSuccessComplete.type) {
            this.authenticationService.navigateToRedirect()
              .then(x => { if (!x) this.router.navigate(['/']); return x;})
              .finally(() => { this.appWindowService.hideLoadingOverlay(); });
          } else {
            _handleError("Incorrect username or password.");
          }
        },
        // This will only happen if/when service lets errors through
        (error: any) => {
          _handleError("Network/system error");
        }
      );

    this.store.dispatch(fromRoot.AppSessionActions.logInUser({
      payload: {
        username: this.form.get('username')?.value ?? "",
        password: this.form.get('password')?.value ?? "",
        doPersistAuthorization: this.form.get('keepMeLoggedInCheckBox')?.value ?? false
      }
    }));
  }

  public clearForm(): void {
    this.store.dispatch(updateThemeId({ data: {themeId: 1 }}));
    this.form.reset();
  }
}
