import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromAppPersistedSettings from './app-persisted-settings.state';
import { JsonUtilities } from 'src/app/shared/json-utilities';

export const selectAppPersistedSettingsState = createFeatureSelector<fromAppPersistedSettings.AppPersistedSettingsState>(
  fromAppPersistedSettings.appPersistedSettingsFeatureKey
);

export const selectAccessTokenInfo = createSelector(
  selectAppPersistedSettingsState,
  (state: fromAppPersistedSettings.AppPersistedSettingsState) => (state ? JsonUtilities.convertDatesAndCopy(state.accessTokenInfo) : null)
);




