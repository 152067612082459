import { AfterViewInit, ChangeDetectorRef, Component, ComponentFactoryResolver, ComponentRef, EventEmitter, Injector, OnDestroy, OnInit, Output, ViewChild, ViewContainerRef } from '@angular/core';
import { Store } from '@ngrx/store';
import { ClaimEditService } from '@services/claim-edit.service';
import { ClaimFormBaseService } from '@services/claim-form-base.service';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { EditClaimProductsComponent } from './edit-claim-products.component';

@Component({
  selector: 'acb-alcon-edit-claim-products-dialog',
  template: `
     <kendo-dialog-titlebar class="acb-edit-commitment-products-titlebar" (close)="onClose()">
      Edit Products
    </kendo-dialog-titlebar>
    <ng-container #editClaimProductsContainer></ng-container>
    <kendo-dialog-actions>
      <button kendoButton class="acb-cancel" (click)="onCancel()"><span class="k-icon k-i-cancel"></span>Cancel</button>
      <button kendoButton [primary]="true" (click)="onApply()" [disabled]="!valid" ><span class="k-icon k-i-checkmark"></span>Apply</button>
    </kendo-dialog-actions>
  `,
  styleUrls: ['./edit-claim-products-dialog.component.scss']
})
export class EditClaimProductsDialogComponent implements OnInit, AfterViewInit, OnDestroy  {

  @ViewChild('editClaimProductsContainer', { read:ViewContainerRef }) editClaimProductsContainer?: ViewContainerRef;

  @Output() cancel: EventEmitter<any> = new EventEmitter();
  @Output() apply: EventEmitter<any> = new EventEmitter();

  private _claimEditServiceSubscription?:Subscription;
  private _claimEditService?: ClaimEditService;
  public set claimEditService(value:ClaimEditService) {
    this._claimEditService = value;
    this._claimEditServiceSubscription?.unsubscribe();
    this._claimEditServiceSubscription = this._claimEditService.validityChange$.pipe(takeUntil(this._destroy$)).subscribe(x => {
      this.valid = x.products;
      this.changeDetectionRef.detectChanges();
    });
  }

  public valid: boolean = true;

  private _destroy$: Subject<void> = new Subject<void>();


  constructor(
    store:Store,
    private resolver: ComponentFactoryResolver,
    private changeDetectionRef : ChangeDetectorRef
  ) { }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    // Need to pass service to edit details component, provider context is lost going through window service...
    // TODO: Clean up by moving to feature NGRX?
    if (this.editClaimProductsContainer && this._claimEditService) {
      const factory = this.resolver.resolveComponentFactory(EditClaimProductsComponent);

      const injector: Injector = Injector.create({ providers: [{ provide: ClaimFormBaseService, useValue: this._claimEditService }]});
      injector.get(ClaimFormBaseService);

      const ref: ComponentRef<EditClaimProductsComponent> =  this.editClaimProductsContainer.createComponent(factory, undefined, injector);
      ref.instance.doShowLegend = false;

      this.changeDetectionRef.detectChanges();
    }
  }

  public onCancel() {
    this.cancel.emit(null);
  }

  public onApply() {
    if (this.valid)
      this.apply.emit(null);
  }

  public onClose() {
    this.cancel.emit(null);
  }

  ngOnDestroy() {
    this._destroy$.next();
    this._destroy$.complete();
  }
}
