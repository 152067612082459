import { SelectSearchBaseService } from './select-search-base';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { AppUxService } from './app-ux.service';

@Injectable({
  providedIn: 'root'
})
export class PayeeSelectSearchService extends SelectSearchBaseService {
  constructor(protected http: HttpClient, protected router: Router, protected store: Store, appUxService: AppUxService) {
    super(http, router, store, 'PayeeSelectSearch', appUxService);
  }
}
