import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { combineLatest, fromEvent, Observable } from 'rxjs';
import { FeatureModel } from '@alcon-db-models/FeatureModel';
import { Store } from '@ngrx/store';
import { selectAccessRoles, selectApplicationInfo, selectFeatureMap, selectImpersonationStatus, selectReports } from 'src/app/store/app-session/app-session.selectors';
import { first, map } from 'rxjs/operators';
import { MenuEvent } from '@progress/kendo-angular-menu';
import { Router } from '@angular/router';
import { AccessRole, FeatureType, StatusCode, VisibilityType } from '@alcon-db-models/Enums';
import { PersonWithRolesModel } from '@alcon-db-models/PersonWithRolesModel';
import { AccessRoleModel } from '@alcon-db-models/AccessRoleModel';
import { ReportModel } from '@alcon-db-models/ReportModel';
import { JsonUtilities } from 'src/app/shared/json-utilities';
import { BusinessCommon } from 'src/app/shared/business-common';
import { ApplicationInfoModel } from '@alcon-db-models/ApplicationInfoModel';
import { Utilities } from 'src/app/shared/utilities';
import { NgResizeObserver, ngResizeObserverProviders } from 'ng-resize-observer';
import { ButtonDirective } from '@progress/kendo-angular-buttons';


@Component({
  selector: 'acb-alcon-menu-main',
  templateUrl: './menu-main.component.html',
  styleUrls: ['./menu-main.component.scss'],
  providers: [...ngResizeObserverProviders]
})
export class MenuMainComponent implements OnInit {

  @ViewChild("hamburgerMenuButton", { read: ButtonDirective }) public hamburgerMenuButton?: ButtonDirective;
  @ViewChild("hamburgerMenu") public hamburgerMenu?: ElementRef;

  public featureMap$: Observable<FeatureModel[]> = new Observable<FeatureModel[]>();
  public latest$: Observable<FeatureModel[]> = new Observable<FeatureModel[]>();
  public featuresForMenu$?: Observable<FeatureForMenu[]>;
  public applicationInfo: ApplicationInfoModel = new ApplicationInfoModel();
  public doShowHumburgerMenu: boolean = false;

  public featureType = FeatureType;

  private _reports: ReportModel[] = [];

  constructor(
    private store: Store,
    private router: Router,
    private resize$: NgResizeObserver
  ) {

    fromEvent(window, 'resize').subscribe((event) => {
      this.closeHamburgerMenu();
    });

    store.select(selectApplicationInfo).pipe(first()).subscribe(x => {
      this.applicationInfo = JsonUtilities.convertDatesAndCopy(x);
    });
    store.select(selectReports).pipe(first()).subscribe(x => {
      this._reports = x;
    });
    this.featuresForMenu$ = combineLatest([
      this.store.select(selectFeatureMap),
      this.store.select(selectImpersonationStatus)
    ]).pipe(map(([featureMap, status]) => {

      // We only want menu features visible to user
      const menuItemFeatures: any = BusinessCommon.getPersonFeatures(featureMap, status.currentPerson)
        .filter(x => x.featureType == FeatureType.Resource || x.featureType == FeatureType.Route || x.featureType == FeatureType.Container)
        .sort((a,b) => (a.sortOrder ?? Number.MAX_SAFE_INTEGER) - (b.sortOrder ?? Number.MAX_SAFE_INTEGER));

      // Link URL is different for reports...
      menuItemFeatures.forEach((x:any) => {
        if (x.reportID) {
          const report = this._reports.find(y => y.reportID == x.reportID);
          if (report?.code) {
            x.uri = x.uri?.replace(/\/$/, '') + "/" + report?.code ?? '';
          }
        }
      })

      // Add Revert if impersonating
      if (status.isImpersonating) {
        menuItemFeatures.push({
          accessRoles: [ AccessRole.AllRoles ],
          displayName: "Revert",
          featureType: FeatureType.Route,
          uri: '/revert',
        });
      } 

      return menuItemFeatures.map((y:any) => new FeatureForMenu(y));
    }));
  }

  private closeHamburgerMenu() {
    if (this.hamburgerMenuButton?.selected) this.hamburgerMenuButton.selected = false;
    this.doShowHumburgerMenu = false;
  }

  // HACK: we're only using this for resources and nested folders.  Extract to more generic logic if other types are needed
  public getMenuItemClass(menuItem:any) {
    const feature = menuItem.data as FeatureForMenu;
    if (!feature || !feature.parentFeatureID || (feature.featureType != FeatureType.Container && feature.featureType != FeatureType.Resource)) return "";
    return feature.featureType == FeatureType.Container ? 'acb-menu-item-child-container' : 'acb-menu-item-resource';
  }

  // private getAccessibleFeatures(features: FeatureModel[], person: PersonWithRolesModel): FeatureModel[] {

    // const personAccessRoleIDs: number[] = [
    //   ...person.accessRoles ?? [],
    //   ...person.accessRoles?.map(x => roles.find(y => y.accessRoleID == x)?.childAccessRoleIDs)
    //     .reduce((acc, val) => acc?.concat(val ?? 0), []) ?? []
    // ];

    // const checkAccess = (feature: FeatureModel) => {
    //   // Collapse Access Role "tree".  We're assuming only one level, no child groups...
    //   const featureAccessRoles = [...new Set(feature.accessRoles?.reduce((a,v) => a.concat([v, ...roles.find(x => x.accessRoleID == v)?.childAccessRoleIDs ?? []]), <number[]>[]))];
    //   const accessRoleIntersect = personAccessRoleIDs.filter(x => featureAccessRoles.includes(x));
    //   const doCheckBusinessRoles: boolean = accessRoleIntersect.length == 1 && accessRoleIntersect[0] == AccessRole.SalesRep;
    //   // Has access if feature and person Access Roles intersect, and if Sales Rep is the only match and there are feature Business Roles specified, Business Roles must intersect
    //   return Boolean(accessRoleIntersect.length) &&
    //     (!doCheckBusinessRoles || !feature.businessRoles?.length || (person.businessRoles ?? []).some(x => feature.businessRoles!.includes(x) ?? false));
    // }

    // return features.filter(feature =>
    //   feature.statusCode == StatusCode.Active &&
    //   feature.visibilityType == VisibilityType.Visible &&
    //   checkAccess(feature)
    // ).map(x => JsonUtilities.convertDatesAndCopy(x));
  // }

  getTopLevel(featureForMenu: FeatureForMenu[]) {
    return featureForMenu?.length ?  featureForMenu.filter(x => !x.parentFeatureID) : [];
  }

  getChildren(parentFeatureID: number | null | undefined, featureForMenu: FeatureForMenu[]) {
    return parentFeatureID && featureForMenu?.length ? featureForMenu.filter(x => x.parentFeatureID == parentFeatureID) : [];
  }

  ngOnInit(): void {
  }

  public onMenuItemSelect(event: MenuEvent) {
    this.navigateFeature(event?.item?.data as FeatureModel);
  }

  public navigateFeature(feature?: FeatureModel) {
    this.closeHamburgerMenu();
    if (!feature?.featureType) return;
    switch (feature.featureType) {
      case FeatureType.Route:
        if (this.router.url.toLowerCase() == feature.uri?.toLowerCase()) {
          //HACK: Hack to reload page. Overloading coming-soon to serve as temp swap, passing in page title
          this.router.navigateByUrl('/coming-soon', { skipLocationChange: true, state: { pageTitle: feature.displayName }}).finally(() => {
            this.router.navigate([ feature.uri ]);
          }); 
        } else {
          this.router.navigate([ feature.uri ]);
        }
        break;
      case FeatureType.Resource:
        let url = feature.uri;
        if (feature.resourceInternalFileName && feature.resource && this.applicationInfo.userUploadsWebFolder && this.applicationInfo.userUploadsResourcesFolder) {
          url = Utilities.normalizeUrl([
            this.applicationInfo.userUploadsWebFolder,
            this.applicationInfo.userUploadsResourcesFolder,
            feature.resourceInternalFileName,
            feature.resource
          ]);
        }
        if (url) window.open(url, "_blank");
        break;
    }
  }

  onHamburgerMenuButtonSelectedChange(isSelected: boolean) {
    if(isSelected && this.hamburgerMenu?.nativeElement) this.hamburgerMenu.nativeElement.scrollTop = 0;
    this.doShowHumburgerMenu = isSelected;
  }
}

export class FeatureForMenu extends FeatureModel {
  constructor(feature: FeatureModel) {
    super();
    Object.assign(this, feature);
  }
  get menuText() {
    return this.displayName;
  }
}
