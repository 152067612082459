import { ChangeDetectorRef, Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl, UntypedFormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UploadFileBase } from 'src/app/components/upload-file.base.component';
import { AppUxService } from '@services/app-ux.service';
import { selectResourceTypes } from '@app-store/app-session/app-session.selectors';
import { first } from 'rxjs/operators';
import { UserPreferenceEnabledGridBaseComponent } from 'src/app/components/user-preference-enabled-grid.base.component';
import { GridComponent, GridDataResult, SelectionEvent } from '@progress/kendo-angular-grid';
import { SandboxFileUploadSearchBindingDirective } from 'src/app/directives/sandbox-file-upload-search-binding.directive';
import { PageExpansionService } from '@services/page-expansion.service';
import { AppWindowService } from '@services/app-window.service';
import { SearchFilterService } from '@services/search-filter.service';
import { Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { ExcelExportData } from '@progress/kendo-angular-excel-export';
import { SandboxFileUploadSearchService } from '@services/sandbox-file-upload-search.service';
import { SandboxUploadFilesSearchModel } from '@alcon-db-models/SandboxUploadFilesSearchModel';

@Component({
  selector: 'acb-alcon-sandbox-file-upload-search',
  templateUrl: './sandbox-file-upload-search.component.html',
  styleUrls: [
    './sandbox-file-upload-search.component.scss'
  ],
  providers: [
    SearchFilterService
  ]
})
export class SandboxFileUploadSearchComponent extends UserPreferenceEnabledGridBaseComponent   implements OnInit {

    @ViewChild('resultsGrid') public resultsGrid?: GridComponent;
    @ViewChild(SandboxFileUploadSearchBindingDirective) public bindingDirective?: SandboxFileUploadSearchBindingDirective;
  
    public formTitle = "Upload File";

    public selectedItem: SandboxUploadFilesSearchModel | undefined | null;
  
    constructor(
      public sandboxFileUploadSearchService: SandboxFileUploadSearchService,
      protected appUxService: AppUxService,
      store: Store,
      pageExpansionService: PageExpansionService,
      private appWindowService: AppWindowService,
      changeDetectorRef: ChangeDetectorRef,
      searchFilterService: SearchFilterService,
      router: Router
    ) {
      super(store, pageExpansionService, changeDetectorRef, searchFilterService, router);
      this.searchForm = new UntypedFormGroup({ });
    }

    public selectFirst(doSelectOnlyIfNoSelection:boolean = true) {
      if (!doSelectOnlyIfNoSelection || !this.selectedKeys.length ) {  
        this.selectedKeys = [];     
        this.sandboxFileUploadSearchService.pipe(first()).subscribe((x) => {
          //Hack: Not sure why the data in x isn't current. BehaviorSubject issue? We'll fetch the data from the grid instead and wait a bit to make sure the grid is repopulated first. Fix this? 
          setTimeout(() => {
            let gridData = (this.resultsGrid?.data as GridDataResult)?.data;
            this.selectedKeys = [gridData?.length ? gridData[0].alconSandboxFileUploadID : 0];
          }, 100);
        });
        this.bindingDirective?.resetPage();
      }
    }
  
    ngOnInit(): void {
    }
  
  
    public onSelectedChange(event: SelectionEvent) {
      this.selectedItem = event.selectedRows?.length ? event.selectedRows[0].dataItem : null;
      // const boundItems: CustomerSearchWithDefaultModel[] = this.bindingDirective?.customerSearchService?.value?.data ?? [];
      // this.selectedItems = [
      //   ...this.selectedItems.filter(x => x.customerID ? this.selectedKeys.includes(x.customerID) : false),
      //   ...this.selectedKeys.filter(x => !this.selectedItems.some(y => y.customerID == x)).map(x => boundItems.find(y => y.customerID == x) ?? {}).filter(x => x != null)
      // ]
      // this.activateableSelectedKeys = this.selectedItems.filter(x => x.customerTypeID == CustomerType.AlternativePayee && x.visibilityTypeID != VisibilityType.Visible && x.customerID).map(x => x.customerID ?? 0);
      // this.deactivateableSelectedKeys = this.selectedItems.filter(x => x.customerTypeID == CustomerType.AlternativePayee && x.visibilityTypeID != VisibilityType.Hidden && x.customerID).map(x => x.customerID ?? 0);
  
    }
  
  
    public allData(): Observable<ExcelExportData> {
      return this.bindingDirective ?
        this.sandboxFileUploadSearchService.searchForExcelExport(this.bindingDirective.getSearchRequest(true)) :
        of({});
    }
  
  }
  
