import { ActivityForDropdownModel } from '@alcon-db-models/ActivityForDropdownModel';
import { AuditCodeWithGroupModel } from '@alcon-db-models/AuditCodeWithGroupModel';
import { StatusCode } from '@alcon-db-models/Enums';
import { FundForDropdownModel } from '@alcon-db-models/FundForDropdownModel';
import { ProductForDropdownModel } from '@alcon-db-models/ProductForDropdownModel';
import { StaticTypeModel } from '@alcon-db-models/StaticTypeModel';
import { StatusCodeWithGroupsModel } from '@alcon-db-models/StatusCodeWithGroupsModel';
import { TerritoryForDropdownModel } from '@alcon-db-models/TerritoryForDropdownModel';
import { Injectable } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { selectAuditCodesWithGroups, selectCurrentPerson, selectStaticTypes, selectStatusCodesWithGroups } from '@app-store/app-session/app-session.selectors';
import { Store } from '@ngrx/store';
import { BehaviorSubject, Observable, ReplaySubject, Subject } from 'rxjs';
import { filter, first, map } from 'rxjs/operators';
import { ActivityForDropdownService } from './activity-for-dropdown.service';
import { FundForDropdownService } from './fund-for-dropdown.service';
import { ProductForDropdownService } from './product-for-dropdown.service';
import { TerritoryForDropdownService } from './territory-for-dropdown.service';

@Injectable()
export class SearchFilterService {

  private _fundYears?: number[];
  public set fundYears(value:number[]) {
    this._fundYears = value;
    this.fundsForDropdownSource$.next(this._fundsForDropdownSource);
  };

  private _formGroup?: UntypedFormGroup;
  public set formGroup(value:UntypedFormGroup | undefined) {
    this._formGroup = value;
    this._formGroup?.valueChanges.subscribe(x => {
      this.fundYears = x.fundYears;
    })
  };
  public get formGroup() { return this._formGroup }

  public destroy$: Subject<void> = new Subject<void>();
  public staticTypes$: Observable<StaticTypeModel[]>;
  public statusCodes$: Observable<StaticTypeModel[]>;
  public statusCodesWithGroups$: Observable<StatusCodeWithGroupsModel[]>;
  public auditCodesWithGroups$: Observable<AuditCodeWithGroupModel[]>;
  public payTypes$: Observable<StaticTypeModel[]>;
  public expenseTypes$: Observable<StaticTypeModel[]>;
  public fundingDocumentTypes$: Observable<StaticTypeModel[]>;
  public fundsForDropdownSource$ = new Subject<FundForDropdownModel[]>();
  public fundsForDropdownByYear$ = new Subject<FundForDropdownModel[]>();
  public fundsForDropdown$ = new ReplaySubject<FundForDropdownModel[]>(1);
  public fundsYears$: Observable<number[]>;
  public territoriesForDropdownSource$ = new Subject<TerritoryForDropdownModel[]>();
  public territoriesForDropdown$ = new ReplaySubject<TerritoryForDropdownModel[]>(1);
  public activitiesForDropdown$ = new ReplaySubject<ActivityForDropdownModel[]>(1);
  // get from configuration
  //public productsForDropdown$ = new ReplaySubject<ProductForDropdownModel[]>(1);

  public isExpanded$ = new BehaviorSubject<boolean>(false);

  public search$ = new Subject<any>();

  private _fundsForDropdownSource: FundForDropdownModel[] = [];
  private _personID?: number;

  constructor(
    store: Store,
    fundForDropdownService: FundForDropdownService,
    territoryForDropdownService: TerritoryForDropdownService,
    activityForDropdownService: ActivityForDropdownService,
    productForDropdownService: ProductForDropdownService,
  ) {

    store.select(selectCurrentPerson).pipe(filter(x => Boolean(x?.personID)),first()).subscribe(x => {
      this._personID = x!.personID ?? undefined;
      const statusCodeIDs = `${StatusCode.Active},${StatusCode.Inactive}`
      let params: { personID: string, statusCodeIDs: string } | { statusCodeIDs: string } = this._personID ? { personID: this._personID.toString(), statusCodeIDs: statusCodeIDs } : { statusCodeIDs: statusCodeIDs };
      fundForDropdownService.getWithQuery(params).subscribe(x => {
        this._fundsForDropdownSource = x;
        this.fundsForDropdownSource$.next(x)
      });
    })

    activityForDropdownService.getAll().pipe(first()).subscribe(x => this.activitiesForDropdown$.next(x));
    // get from configuration
    // productForDropdownService.getAll().pipe(first()).subscribe(x => this.productsForDropdown$.next(x));

    this.fundsForDropdownByYear$.subscribe(x => this.fundsForDropdown$.next(x));
    this.fundsForDropdownSource$.pipe(map(x => x.filter(x => {
      return this?._fundYears?.length ? this?._fundYears.some(y => y == x.year) : true
    }))).subscribe(x => this.fundsForDropdownByYear$.next(x));

    this.territoriesForDropdownSource$.subscribe(x => this.territoriesForDropdown$.next(x));
    territoryForDropdownService.getAll().subscribe(x => this.territoriesForDropdownSource$.next(x));

    this.staticTypes$ = store.select(selectStaticTypes);
    this.statusCodesWithGroups$ = store.select(selectStatusCodesWithGroups);
    this.auditCodesWithGroups$ = store.select(selectAuditCodesWithGroups);
    this.statusCodes$ = this.staticTypes$.pipe(map(x => x.filter(y => y.tableName == 'StatusCode')));
    this.payTypes$ = this.staticTypes$.pipe(map(x => x.filter(y => y.tableName == 'PayType')));
    this.expenseTypes$ = this.staticTypes$.pipe(map(x => x.filter(y => y.tableName == 'ExpenseType')));
    this.fundingDocumentTypes$ = this.staticTypes$.pipe(map(x => x.filter(y => y.tableName == 'FundingDocumentType')));
    this.fundsYears$ = this.fundsForDropdownSource$.pipe(map(x => x.map(y => y.year as number).filter((value, index, self) => value && self.indexOf(value) === index)));
  }
}
