import { CommitmentWithDetailsModel } from '@alcon-db-models/CommitmentWithDetailsModel';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';
import { PageChangeEvent } from '@progress/kendo-angular-pager';
import { CommitmentWithDetailsService } from '@services/commitment-with-details.service';
import { CommitmentReviewService } from '@services/commitment-review.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { first, map } from 'rxjs/operators';
import { selectCurrentPerson, selectStaticTypes } from '@app-store/app-session/app-session.selectors';
import { StaticTypeModel } from '@alcon-db-models/StaticTypeModel';
import { HttpErrorResponse } from '@angular/common/http';
import { AppUxService } from '@services/app-ux.service';
import { ServiceResponse } from 'src/app/shared/acb-stream';
import { CommitmentReviewRequestModel } from '@alcon-db-models/CommitmentReviewRequestModel';
import { CommitmentReviewResultModel } from '@alcon-db-models/CommitmentReviewResultModel';

@Component({
  selector: 'acb-alcon-review-commitments-window',
  template: `
    <div [hidden]="!(loadingInternal$ | async)" class="acb-local-loading-indicator">
      <span class="k-icon k-i-loading"></span>
    </div>
    <div
      [fxHide]="(loading$ | async)"
      #wrapper
      class="acb-wrapper"
      fxLayout="row"
      fxLayout.lt-md="column"
      [ngClass]="{
        'acb-denying': form.controls.action.value == 'deny',
        'acb-approving': form.controls.action.value == 'approve'
      }"
      >
      <div class="acb-preview" fxLayout="column" fxFlex.gt-sm >
        <div class="acb-inner">
          <acb-alcon-view-commitment-details [commitment]="commitment$ | async"></acb-alcon-view-commitment-details>
          <acb-alcon-view-commitment-organizations [commitmentOrganizations]="commitment$ | async"></acb-alcon-view-commitment-organizations>
          <div class="acb-outer-section" fxLayout="column" fxLayoutGap="1em">
            <acb-alcon-view-commitment-review-history [commitment]="commitment$ | async"></acb-alcon-view-commitment-review-history>
          </div>
        </div>
      </div>
      <div [class]="sectionClass + ' acb-controls'" fxFlex.gt-sm="22em">
        <div fxLayout="column" style="width: 100%; height: 100%;">
          <div class="acb-section-alt-title" fxLayout="row" fxLayoutGap=".75em">
            <span class="acb-section-alt-title-label">{{ sectionTitle.label }}</span>
            <span class="acb-section-alt-title-entity">{{ sectionTitle.entity }}</span>
            <span fxFlex></span>
            <span class="acb-section-alt-title-info">{{ sectionTitle.info }}</span>
          </div>
          <kendo-datapager
            *ngIf="total > 1"
            style="white-space: nowrap;"
            [pageSize]="1"
            [skip]="index"
            [total]="total"
            (pageChange)="onPageChange($event)"
            >
            <ng-template kendoDataPagerTemplate let-totalPages="totalPages" let-currentPage="currentPage">
              <div class="acb-data-pager-wrapper" style="width: 100%">
                <kendo-datapager-prev-buttons></kendo-datapager-prev-buttons>
                <kendo-slider
                    [showButtons]="false"
                    [tickPlacement]="'after'"
                    [max]="totalPages"
                    [min]="1"
                    (valueChange)="sliderChange($event)"
                    [value]="currentPage"
                    style="width:100%"
                    >
                </kendo-slider>
                <kendo-datapager-next-buttons></kendo-datapager-next-buttons>
              </div>
            </ng-template>
          </kendo-datapager>

          <hr class="acb-seperator" *ngIf="total > 1"/>


          <form [formGroup]="form" fxLayout="column" fxLayoutGap="2em">
            <div>
              <kendo-label [ngClass]="'acb-action-label'" text="Action"></kendo-label>
              <div
                fxLayout="row"
                class="acb-radio-button-formfield"
                >
                <div fxFlex (click)="denyActionButton.click()">
                  <input class="acb-action-label" type="radio" name="action" value="deny" #denyActionButton formControlName="action"/>
                  <div class="k-button" ><span class="k-icon k-i-close-outline"></span>Deny</div>
                </div>
                <div fxFlex (click)="approveActionButton.click()">
                  <input type="radio" name="action" value="approve" #approveActionButton formControlName="action"/>
                  <div class="k-button"><span class="k-icon k-i-check-outline"></span>Approve</div>
                </div>
              </div>
            </div>
            <kendo-formfield *appFieldExtentions [fxHide]="form.controls.action.value != 'deny'">
              <kendo-label [for]="comment" text="Comment" [optional]="false"></kendo-label>
              <textarea
                formControlName="comment"
                kendoTextArea
                #comment
                rows="3"
                style="margin-top: .5em; resize: none; width: 100%"
                ></textarea>
            </kendo-formfield>
            <div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="1em"  class='acb-control-buttons'>
              <button
                *ngIf="total > 1"
                kendoButton
                (click)="onApplyToAll()"
                >Apply to All</button>
            </div>
          </form>
          <hr class="acb-seperator">
          <div fxFlex></div>
        </div>
        <div fxLayout="row" class="acb-review-button-wrapper" fxLayout="row">
          <button class="acb-cancel"  kendoButton (click)="onCancel()" [look]="'clear'" fxFlex class="acb-cancel">Cancel</button>
          <button kendoButton (click)="onSubmit()" [primary]="true" [look]="'clear'" fxFlex [primary]="true">Submit{{total > 1 ? ' All' : ''}}</button>
        </div>
      </div>
    </div>
  `,
  styleUrls: ['./review-commitments-window.component.scss']
})
export class ReviewCommitmentsWindowComponent implements OnInit {

  @Output() closed: EventEmitter<any> = new EventEmitter();
  @Output() submitted: EventEmitter<any> = new EventEmitter();

  @Input() reviewMode: ReviewMode = 'review';
  @Input() comment: string = '';

  public formValues: { commitmentID: number, comment: string, action: string, commitment: CommitmentWithDetailsModel | undefined }[] = [];

  private _commitmentIDs: number[] = [];
  @Input() public set commitmentIDs(value: number[]) {
    this._commitmentIDs = Array.isArray(value) ? value.sort() : [];
    this.index = 0;
    this.total = this._commitmentIDs.length;
    this.formValues = this._commitmentIDs.map(x => ({ commitmentID: x, comment: this.comment, action: this.getDefaultAction(), commitment: undefined }));
  }

  private getDefaultAction() {
    return this.reviewMode == 'approve' || this.reviewMode == 'deny' ? this.reviewMode : 'ignore';
  }

  public commitment$: BehaviorSubject<CommitmentWithDetailsModel> = new BehaviorSubject<CommitmentWithDetailsModel>({});
  public loading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  loadingInternal$: Observable<boolean> = this.commitmentWithDetailsService.loading$;
  public total:number = 0;
  public staticTypes$: Observable<StaticTypeModel[]>;
  public statusCodes$: Observable<StaticTypeModel[]>;

  public get sectionClass(): string {
    switch (this.formValues[this.index]?.action) {
      case "approve":
        return 'acb-section-02';
      case "deny":
        return 'acb-section-07';
      default: // Ignore
        return 'acb-section-05';
    }
  }

  public get sectionTitle(): { label?:string, entity?:string, info?:string } {
    const title = ({ label: '', entity: '', info: '' })
    const values = this.formValues[this.index] ?? {};
    switch (values?.action) {
      case "approve":
        title.label = 'Approving';
        break
      case "deny":
        title.label = 'Denying';
        break
      case "cancel":
        title.label = 'Canceling';
        break
      default:
        title.label = 'Reviewing';
        break
    }
    if (values?.commitmentID) {
      title.label += ":";
      title.entity = values?.commitmentID.toString();
    }
    title.info = this.total > 1 ? " (" + (this.index + 1).toString() + "/" + (this.total).toString() + ")" : "";
    return title;
  }

  private _index: number = 0;
  public set index(value: number) {
    this._index = value;
    this.loadByIndex(this._index);
  }
  public get index(): number {
    return this._index;
  }

  public form: UntypedFormGroup = new UntypedFormGroup({
    commitmentID: new UntypedFormControl(),
    comment: new UntypedFormControl(),
    action: new UntypedFormControl()
  });

  constructor(
    private commitmentWithDetailsService: CommitmentWithDetailsService,
    private commitmentReviewService: CommitmentReviewService,
    protected store: Store,
    private appUxService: AppUxService
  ) {
    this.form.valueChanges.pipe().subscribe(x => {
      this.formValues[this.index] = {...this.formValues[this.index], ...this.form.getRawValue() ?? {}, };
    });
    this.commitment$.pipe().subscribe(x => {
      let formValue = this.formValues.find(y => y.commitmentID === x.commitmentID);
      if (formValue)
        formValue.commitment = x;
    });

    this.staticTypes$ = this.store.select(selectStaticTypes);
    this.statusCodes$ = this.staticTypes$.pipe(map(x => x.filter(y => y.tableName == 'StatusCode')));
  }

  private loadByIndex(index: number) {
    const commitmentID: number | undefined = this._commitmentIDs.length ? this._commitmentIDs[index] : undefined;
    if (commitmentID) {
      //TODO: Create proper init/reset function
      const fv = this.formValues[index] ?? (this.formValues[index] = { commitmentID: commitmentID, comment: this.comment, action: this.getDefaultAction(), commitment: undefined });
      fv.commitmentID = commitmentID;
      if (fv?.commitment) {
        this.commitment$.next(fv?.commitment);
      } else {
        this.commitmentWithDetailsService.getByKey(commitmentID).pipe(first()).subscribe(x => {
          this.commitment$.next(x);
          this.loading$.next(false);
        });
      }
      this.form.patchValue(fv);
    }
  }

  public sliderChange(pageIndex: number): void {
    this.index = (pageIndex - 1);
  }

  public onPageChange(e: PageChangeEvent): void {
    this.index = e.skip;
  }

  public onClearCommitment() {
    this.form.patchValue({ comment: '', action: this.getDefaultAction() });
  }

  public onApplyToAll() {
    //TODO:  there must be a better way.  Rest syntax?
    this.formValues.forEach(x => Object.assign(x, {
      comment: this.formValues[this.index].comment,
      action: this.formValues[this.index].action
    }));
  }

  public onCancel() {
    this.closed.emit(null);
  }

  public onSubmit() {

    //TODO: Move this to base class
    let personID: number | null = null;
    this.store.select(selectCurrentPerson).pipe(first()).subscribe(x => {
      personID = x?.personID ?? null
    })

    //TODO: Throw and handle error?
    if (!personID)
      return;

    let approvedStatusCodeID: number | null = null;
    let deniedStatusCodeID: number | null = null;

    this.statusCodes$.pipe(first()).subscribe(x => {
      approvedStatusCodeID = x.find(y => y.code == 'approved')?.id ?? null;
      deniedStatusCodeID = x.find(y => y.code == 'denied')?.id ?? null;
    })

    let isValid: boolean = true;
    for (let i = 0; i < this.formValues.length; i++) {
      const value = this.formValues[i];
      if (value.action == 'deny' && !(value.comment ?? '').trim()) {
        isValid = false;
        break;
      }
    }

    if (!isValid) {
      this.appUxService.openErrorDialog(['All denials must include comment']);
      return;
    }

    this.commitmentReviewService.postReview(this.formValues.map(x => ({
        personID: personID,
        commitmentID: x.commitmentID,
        statusCodeID: x.action == 'approve' ? approvedStatusCodeID : x.action == 'deny' ? deniedStatusCodeID : null,
        comment: x.comment
      }))).subscribe((x:ServiceResponse<CommitmentReviewRequestModel[]>) => {
        if (x.hasError) {
          this.appUxService.openErrorDialog(x.errorMessage);
        } else {
          const results = x.response as CommitmentReviewResultModel[];
          if (!Boolean(results?.length) || results.some(y => !y.wasSuccessful)) {
            const goodResults = results.filter(z => z.wasSuccessful);
            const badResults = results.filter(z => !z.wasSuccessful);
            const errorMessages = badResults.map(z => `${z.commitmentID}: ${z.message}`);
            errorMessages.unshift(`${goodResults.length} successfully updated. ${badResults.length} failed:`);
            this.appUxService.openErrorDialog(errorMessages);
            this.submitted.emit();
          } else {
            this.submitted.emit();
          }
        }
      });
  }

  ngOnInit(): void {
  }
}

export type ReviewMode = 'review' | 'approve' | 'deny';
