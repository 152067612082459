import { ClaimWithDetailsModel } from '@alcon-db-models/ClaimWithDetailsModel';
import { ClaimClaimRelationshipType, StatusCode } from '@alcon-db-models/Enums';
import { FeatureModel } from '@alcon-db-models/FeatureModel';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Optional, Output } from '@angular/core';
import { selectBusinessRules } from '@app-store/app-session/app-session.selectors';
import { selectCurrentPersonFeatures } from '@app-store/root.selectors';
import { Store } from '@ngrx/store';
import { ClaimSubjectBaseService } from '@services/claim-subject-base.service';
import { Subject } from 'rxjs';
import { first, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'acb-alcon-view-claim-details',
  template: `
    <div fxLayout="row wrap" fxFill fxLayoutAlign="stretch stretch">
      <div class="acb-details-layout" fxFlex="60%" gdColumns="repeat(auto-fill, minmax(8em, max-content))">
        <div class="acb-view-field-claim-id">
          <div>
            <div class="acb-value">{{ claim?.claimID }}</div>
          </div>
        </div>
        <div class="acb-view-field acb-view-field-commitment-id">
          <kendo-label text="Commitment"></kendo-label>
          <button (click)="onCommitmentClick()" >{{ claim?.commitmentID }}</button>
        </div>
        <div class="acb-view-field">
          <kendo-label text="Status"></kendo-label>
          <div style="font-weight:bold" [ngStyle]="{'color': getStatusColor(claim) }">{{ claim?.displayStatus }}</div>
        </div>
        <div class="acb-view-field" *ngIf="canEditAfterAudit && claim?.displayStatus != claim?.status">
          <kendo-label text="ACB Status"></kendo-label>
          <div style="font-weight:bold" [ngStyle]="{'color': getStatusColor(claim) }">{{ claim?.status }}</div>
        </div>
        <div class="acb-view-field">
          <kendo-label text="Claimed"></kendo-label>
          <div style="font-weight:bold" [ngStyle]="getClaimedStyle(claim)">{{ (claim?.claimedAmount ? claim?.claimedAmount : 0) | currency }}</div>
        </div>
        <div class="acb-view-field">
          <kendo-label text="Paid"></kendo-label>
          <div [ngStyle]="getPaidStyle(claim)">{{ (claim?.paidAmount ? claim?.paidAmount : 0) | currency }}</div>
        </div>
        <div class="acb-view-field">
          <kendo-label text="Committed"></kendo-label>
          <div>{{ claim?.commitmentAmount | currency }}</div>
        </div>
        <div class="acb-view-field acb-view-field-related-claim-id" *ngIf="getReversalClaim() as rc">
          <kendo-label text="Reversing"></kendo-label>
          <button (click)="onReverseClaimClick()" >{{ rc }}</button>
        </div>
        <div class="acb-view-field acb-span-2">
          <kendo-label text="Activity"></kendo-label>
          <div>{{ claim?.activity }}</div>
        </div>
        <div class="acb-view-field">
          <kendo-label text="Pay Type"></kendo-label>
          <div>{{ claim?.payType ? claim?.payType : 'Unknown' }}</div>
        </div>
        <div class="acb-view-field" *ngIf="claim?.deduction">
          <kendo-label text="Deduction"></kendo-label>
          <div>{{ claim?.deduction }}</div>
        </div>
        <div class="acb-view-field">
          <kendo-label text="Invoice"></kendo-label>
          <div>{{ claim?.invoice }}</div>
        </div>
        <div class="acb-view-field" *ngIf="claim?.documentNumber">
          <kendo-label text="Document Number"></kendo-label>
          <div>{{ claim?.documentNumber }}</div>
        </div>
        <div class="acb-view-field" *ngIf="claim?.fundIsVenueAware">
          <kendo-label text="Benefits Rec."></kendo-label>
          <div>{{ claim?.wereBenefitsReceived ? 'Yes' : 'No' }}</div>
        </div>
        <div class="acb-view-field">
          <kendo-label text="Claim Date"></kendo-label>
          <div>{{ claim?.creationDate | date: 'M/d/yyyy' }}</div>
        </div>
        <div class="acb-view-field" *ngIf="!doShowLessForReview">
          <kendo-label text="Received Date"></kendo-label>
          <div>{{ claim?.receivedDate | date: 'M/d/yyyy' }}</div>
        </div>
        <div class="acb-view-field" *ngIf="!doShowLessForReview">
          <kendo-label text="Start Date"></kendo-label>
          <div>{{ claim?.startDate | date: 'M/d/yyyy' }}</div>
        </div>
        <div class="acb-view-field" *ngIf="!doShowLessForReview">
          <kendo-label text="End Date"></kendo-label>
          <div>{{ claim?.endDate | date: 'M/d/yyyy' }}</div>
        </div>
        <!-- List doctors only if in Lens and only when reviewing.  -->
        <!-- TODO: we need a real view mode enum, doShowLessForReview is confusing now that we're showing doctors if true   -->
        <div fx class="acb-view-field" [ngClass.lg]="'acb-span-3'" [ngClass.md]="'acb-span-2'" [ngClass.gt-lg]="'acb-span-4'" *ngIf="doesUseClaimDoctors && doShowLessForReview">
          <kendo-label text="Doctors"></kendo-label>
          <div class="acb-doctors">
            <span class="acb-doctor" *ngFor="let doctor of (claim!.doctors ? claim!.doctors : []); let isLast = last">
              <span class="acb-doctor-name" *ngIf="doctor.doctor">{{doctor.doctor}}</span>
              <span class="acb-doctor-code" *ngIf="doctor.doctorCode"> ({{doctor.doctorCode}})</span>
              <span class="acb-doctor-sep" *ngIf="!isLast">,&nbsp; </span>
            </span>
          </div>
        </div>
        <ng-container *ngIf="canEditAfterAudit && wasAudited">
          <div class="acb-view-field acb-span-2">
            <kendo-label text="Activity Description"></kendo-label>
            <div>{{ claim?.activityDescription }}</div>
          </div>
          <div class="acb-view-field">
            <kendo-label text="# Ads"></kendo-label>
            <div>{{ claim?.numberOfAds }}</div>
          </div>
          <div class="acb-view-field acb-span-2">
            <kendo-label text="Current Audit Codes"></kendo-label>
            <div [innerHTML]="getLastHistoryAuditCodes()"></div>
          </div>
        </ng-container>
      </div>
      <div class="acb-view-comment-wrapper" fxFlex fxLayout="column" fxLayoutGap="1em">
        <div class="acb-view-comment" fxFlex>
          <kendo-label text="Claim Comment"></kendo-label>
          <ng-container *ngIf="claim?.comments?.length">
            <div>{{ claim!.comments![claim!.comments!.length - 1].commentBody }}</div>
          </ng-container>
        </div>
        <div class="acb-view-comment" fxFlex>
          <kendo-label text="Commitment Comment"></kendo-label>
          <ng-container *ngIf="claim?.commitmentComments?.length">
            <div>{{ claim!.commitmentComments![claim!.commitmentComments!.length - 1].commentBody }}</div>
          </ng-container>
        </div>
        <div class="acb-view-comment-wrapper" fxFlex fxLayout="column" fxLayoutGap="1em" *ngIf="canEditAfterAudit && wasAudited">
          <div class="acb-view-comment" fxFlex>
            <kendo-label text="Current Status Comment"></kendo-label>
            <div>{{ getLastHistoryComment() }}</div>
          </div>
        </div>
      </div>
    </div>
  `,
  styleUrls: ['./view-Claim-details.component.scss']
})
export class ViewClaimDetailsComponent implements OnInit, OnDestroy {

  private _claim?: ClaimWithDetailsModel | null | undefined;
  @Input() public set claim(value: ClaimWithDetailsModel | null | undefined) {
    this._claim = value;
    this.wasAudited = value?.statusCodeID != StatusCode.Canceled
      && value?.statusCodeID != StatusCode.Inprocess
  };
  public get claim() { return this._claim; }

  @Input() public doShowLessForReview: boolean = false;

  @Output() viewCommitment: EventEmitter<number|undefined> = new EventEmitter();
  @Output() viewClaim: EventEmitter<number|undefined> = new EventEmitter();

  public wasAudited: boolean = false;
  public canEditAfterAudit: boolean = false;
  public doesUseClaimDoctors: boolean = false;
  public currentUserFeatures$ = this.store.select(selectCurrentPersonFeatures);

  private destroy$: Subject<void> = new Subject<void>();

  constructor(
    private store: Store,
    @Optional() private claimSubjectBaseService?: ClaimSubjectBaseService
  ) {
    if (claimSubjectBaseService) {
      claimSubjectBaseService.claim$.pipe(takeUntil(this.destroy$)).subscribe(x => {
        this.claim = x
      });
    }

    store.select(selectBusinessRules).pipe(first()).subscribe(x => {
      this.doesUseClaimDoctors = Boolean(x.DoesUseClaimDoctors);
    });

    this.currentUserFeatures$.pipe(first(x => x?.length > 0)).subscribe(x => {
      this.canEditAfterAudit = x.some((x:FeatureModel) => x.code == 'caneditafteraudit');
    });
  }

  getStatusColor(claim?: ClaimWithDetailsModel | null) {
    switch (claim?.statusCodeID as StatusCode) {
      case StatusCode.Paid:
      case StatusCode.Partialpaid:
          return '#00AE44';
      case StatusCode.Denied:
        return '#9B1A2F';
      case StatusCode.Inprocess:
        return '#0063b7';
      case StatusCode.Closed:
        return '#FF6B12';
      case StatusCode.Canceled:
      case StatusCode.Void:
        return '#9B1A2F';
      default:
        return '#484844';
    }
  }

  getPaidStyle(claim?: ClaimWithDetailsModel | null) {
    return claim?.paidAmount ? { color: '#00AE44', 'font-weight': 'bold' }  : {};
  }

  getClaimedStyle(claim?: ClaimWithDetailsModel | null) {
    return (claim?.claimedAmount ?? 0) < 0 ? { color: '#FF6B12', 'font-weight': 'bold' }  : {};
  }


  public getLastHistoryAuditCodes() {
    return this.claim?.history?.length ? this.claim.history[this.claim.history.length - 1].auditCodes : null;
  }

  public getLastHistoryComment() {
    return this.claim?.history?.length ? this.claim.history[this.claim.history.length - 1].eventComment : null;
  }

  public getReversalClaim() {
    var rc = this.claim?.relatedClaims?.find(x => x.claimClaimRelationshipTypeID == ClaimClaimRelationshipType.Reversal);
    return rc?.relatedClaimID;
  }

  ngOnInit(): void {
  }

  onCommitmentClick() {
    this.viewCommitment.emit(this.claim?.commitmentID ?? undefined);
  }

  onReverseClaimClick() {
    this.viewClaim.emit(this.getReversalClaim() ?? undefined);
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
