import { CommitmentWithDetailsModel } from '@alcon-db-models/CommitmentWithDetailsModel';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { RowArgs } from '@progress/kendo-angular-grid';

@Component({
  selector: 'acb-alcon-select-commitment-dialog',
  template: `
    <kendo-dialog-titlebar class="acb-select-commitment-titlebar" (close)="cancel.emit()">
      Select Commitment
    </kendo-dialog-titlebar>
    <acb-alcon-select-commitment
      #selectCommitment
      (selectedChange)="onCommitmentSelectedChange($event)"
      [routedCommitmentID] = "commitmentID"
      (viewCommitment)="viewCommitment.emit($event)"
      [doShowLegend]='false'
    ></acb-alcon-select-commitment>
    <kendo-dialog-actions>
      <button kendoButton class="acb-cancel" (click)="cancel.emit()"><span class="k-icon k-i-cancel"></span>Cancel</button>
      <button kendoButton [primary]="true" [disabled]="!isSelected" (click)="onSelect()" ><span class="k-icon k-i-checkmark"></span>Select</button>
    </kendo-dialog-actions>
  `,
  styleUrls: ['./select-commitment-dialog.component.scss']
})
export class SelectCommitmentDialogComponent implements OnInit {

  @Input() commitmentID?: number;

  @Output() cancel: EventEmitter<any> = new EventEmitter();
  @Output() commitmentSelected: EventEmitter<CommitmentWithDetailsModel | null> = new EventEmitter();
  @Output() viewCommitment: EventEmitter<any> = new EventEmitter();

  private _selectedCommitment?: CommitmentWithDetailsModel;

  constructor() { }

  get isSelected():boolean {
    return Boolean(this._selectedCommitment?.commitmentID);
  }

  ngOnInit(): void {
  }

  public onCommitmentSelectedChange(event:RowArgs[]) {
    this._selectedCommitment = event?.length ? event[0].dataItem : undefined;
  }

  onSelect() {
    this.commitmentSelected.emit(this._selectedCommitment);
  }

}
