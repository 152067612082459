import { Injectable } from '@angular/core';
import { EntityCollectionServiceBase, EntityCollectionServiceElementsFactory } from '@ngrx/data';
import { ActivityForDropdownModel } from '@alcon-db-models/ActivityForDropdownModel'

@Injectable({
  providedIn: 'root'
})
export class ActivityForDropdownService extends EntityCollectionServiceBase<ActivityForDropdownModel> {
  constructor(serviceElementsFactory: EntityCollectionServiceElementsFactory) {
    super('ActivityForDropdown', serviceElementsFactory);
  }
}
