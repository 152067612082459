import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { ofType } from '@ngrx/effects';
import { ActionsSubject, Store } from '@ngrx/store';
import { first } from 'rxjs/operators';
import { revertImpersonation, updateCurrentPerson } from './../store/app-session/app-session.actions'

@Component({
  selector: 'acb-alcon-revert-page',
  template: '',
  styles: [ ]
})
export class RevertPageComponent {
  constructor(
    store: Store,
    actionsSubject$: ActionsSubject,
    router: Router
  ) {
    actionsSubject$
      .pipe(ofType(updateCurrentPerson.type), first())
      .subscribe(() => router.navigate(['/']));
    store.dispatch(revertImpersonation());
  }
}
