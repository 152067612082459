import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ContentLayoutComponent, SkeletonLayoutComponent } from './layouts/layout.module'
import { LogoutPageComponent } from './containers/logout-page/logout-page.component'
import { LoginPageComponent } from './containers/login-page/login-page.component';
import { RevertPageComponent } from './components/revert-page.component';
import { PrintClaimPageComponent } from './containers/claims/print-claim-page/print-claim-page.component';
import { MasterGuard } from './guards/master.guard';
import { LoginGuard } from './guards/login.guard';
import { AnonymousCommitmentReviewPageComponent } from './containers/external/anonymous-commitment-review-page/anonymous-commitment-review-page.component';
import { userPreferencesResolver } from './resolvers/user-preferences.resolver';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/home',
    pathMatch: 'full'
  },
  {
    path: 'home',
    data: { featureMapCode: 'home' },
    component: ContentLayoutComponent,
    loadChildren: () => import('./containers/home-page/home-page.module').then(m => m.HomePageModule),
    canActivate: [MasterGuard],
    resolve: { isReady: userPreferencesResolver }
  },
  {
    path: 'logout',
    component: LogoutPageComponent,
    data: { allowAnonymous: true }
  },
  {
    path: 'login',
    component: LoginPageComponent,
    canActivate: [LoginGuard],
    data: { allowAnonymous: true }
  },
  {
    path: 'forgot-password',
    component: SkeletonLayoutComponent,
    loadChildren: () => import('./containers/forgot-password-page/forgot-password-page.module').then(m => m.ForgotPasswordPageModule),
    data: { allowAnonymous: true }
  },
  {
    path: 'profile',
    data: { featureMapCode: 'profile' },
    component: ContentLayoutComponent,
    loadChildren: () => import('./containers/profile/profile.module').then(m => m.ProfileModule),
    resolve: { isReady: userPreferencesResolver }
  },
  {
    path: 'admin',
    data: { featureMapCode: 'admin' },
    component: ContentLayoutComponent,
    loadChildren: () => import('./containers/admin/admin-feature.module').then(m => m.AdminFeatureModule),
    canActivate: [MasterGuard],
    resolve: { isReady: userPreferencesResolver }
  },
  {
    path: 'fund-management',
    data: { featureMapCode: 'fundmanagement' },
    component: ContentLayoutComponent,
    loadChildren: () => import('./containers/fund-management/fund-management-feature.module').then(m => m.FundManagementFeatureModule),
    canActivate: [MasterGuard],
    resolve: { isReady: userPreferencesResolver }
  },
  {
    path: 'commitments',
    data: { featureMapCode: 'commitments' },
    component: ContentLayoutComponent,
    loadChildren: () => import('./containers/commitments/commitments.module').then(m => m.CommitmentsModule),
    canActivate: [MasterGuard],
    resolve: { isReady: userPreferencesResolver }
  },
  {
    path: 'claims',
    data: { featureMapCode: 'claims' },
    component: ContentLayoutComponent,
    loadChildren: () => import('./containers/claims/claims.module').then(m => m.ClaimsModule),
    canActivate: [MasterGuard],
    resolve: { isReady: userPreferencesResolver }
  },
  {
    path: 'reports',
    data: { featureMapCode: 'reports' },
    component: ContentLayoutComponent,
    loadChildren: () => import('./containers/reports/reports.module').then(m => m.ReportsModule),
    canActivate: [MasterGuard],
    resolve: { isReady: userPreferencesResolver }
  },
  {
    path: 'sandbox',
    data: { featureMapCode: 'sandbox' },
    component: ContentLayoutComponent,
    loadChildren: () => import('./containers/sandbox/sandbox-feature.module').then(m => m.SandboxFeatureModule),
    canActivate: [MasterGuard],
    resolve: { isReady: userPreferencesResolver }
  },
  {
    path: 'coming-soon',
    component: ContentLayoutComponent,
    loadChildren: () => import('./containers/coming-soon/coming-soon.module').then(m => m.ComingSoonModule),
    resolve: { isReady: userPreferencesResolver }
  },
  {
    path: 'print/claim/:id',
    data: { featureMapCode: 'printclaim' },
    component: PrintClaimPageComponent,
    canActivate: [MasterGuard],
    resolve: { isReady: userPreferencesResolver }
  },
  {
    path: 'revert',
    component: RevertPageComponent
  },
  {
    path: 'external/approve_all_commitments',
    component: AnonymousCommitmentReviewPageComponent,
    data: { reviewMode: 'approve-all', allowAnonymous: true },
  },
  {
    path: 'external/approve_commitment',
    component: AnonymousCommitmentReviewPageComponent,
    data: { reviewMode: 'approve', allowAnonymous: true },
  },
  {
    path: 'external/deny_commitment',
    component: AnonymousCommitmentReviewPageComponent,
    data: { reviewMode: 'deny', allowAnonymous: true },
  },
  {
    path: '**',
    redirectTo: '/home'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload' })],
  exports: [RouterModule],
})
export class AppRoutingModule { }
