import { AccessRoleModel } from '@alcon-db-models/AccessRoleModel';
import { AccessRole, BusinessRole, FeatureMandateType, FeatureType, StatusCode, StatusCodeGroup, VisibilityType } from '@alcon-db-models/Enums';
import { FeatureModel } from '@alcon-db-models/FeatureModel';
import { ReportModel } from '@alcon-db-models/ReportModel';
import { ResourceModel } from '@alcon-db-models/ResourceModel';
import { StatusCodeWithGroupsModel } from '@alcon-db-models/StatusCodeWithGroupsModel';
import { TeamModel } from '@alcon-db-models/TeamModel';
import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { selectAccessRoles, selectReports, selectStaticTypes, selectStatusCodesWithGroups, selectTeams } from '@app-store/app-session/app-session.selectors';
import { Store } from '@ngrx/store';
import { FileInfo, UploadComponent } from '@progress/kendo-angular-upload';
import { AppUxService } from '@services/app-ux.service';
import { Observable, Subject } from 'rxjs';
import { map, takeUntil, first } from 'rxjs/operators';
import { getFeatures } from 'src/app/containers/admin/manage-features-page/store/feature.selectors';
import { Utilities } from 'src/app/shared/utilities';
import { StaticTypeModel } from '../../../../../../../Libraries/ACB.Alcon.Data/Exports/StaticTypeModel';
import { EditFeatureType, ViewMode } from '../components.module';
import { UploadFileBase } from '../upload-file.base.component';



@Component({
  selector: 'acb-alcon-edit-feature',
  templateUrl: './edit-feature.component.html',
  styleUrls: ['./edit-feature.component.scss']
})
export class EditFeatureComponent extends UploadFileBase implements OnDestroy, OnInit {

  @ViewChild('fileUpload') public fileUpload?: UploadComponent;

  @Input() featuresForSelected: FeatureModel[] = [];
  @Input() viewMode: ViewMode = 'new';
  @Input() editFeatureType: EditFeatureType = 'normal';

  private _feature: FeatureModel = {};
  @Input() set feature(value: FeatureModel) {
    this._feature = value;
    this.checkFeatureMandateTypeRestriction();
    this.checkFeatureTypeRestriction();
  };
  get feature() { return this._feature }

  private _featureMandateTypeWhiteList: FeatureMandateType[] = [];
  @Input()  set featureMandateTypeWhiteList(value: FeatureMandateType[]) {
    this._featureMandateTypeWhiteList = value;
    if (this._featureMandateTypeWhiteList?.length) {
      this.featureMandateTypes$ = this.staticTypes$.pipe(map(x => x.filter(y => y.tableName == 'FeatureMandateType' && this._featureMandateTypeWhiteList.some(z => y.id == z))));
      this.checkFeatureMandateTypeRestriction();
    }
  }

  private _featureTypeWhiteList: FeatureType[] = [];
  @Input() set featureTypeWhiteList(value: FeatureType[]) {
    this._featureTypeWhiteList = value;
    if (this._featureTypeWhiteList?.length) {
      this.featureTypes$ = this.staticTypes$.pipe(map(x => x.filter(y => y.tableName == 'FeatureType' && this._featureTypeWhiteList.some(z => y.id == z))));
      this.checkFeatureTypeRestriction();
    }
  }

  @Output() saveFeature: EventEmitter<FeatureModel> = new EventEmitter();
  @Output() cancel: EventEmitter<any> = new EventEmitter();
  @Output() selectUpload: EventEmitter<any> = new EventEmitter();
  @Output() selectExistingFile: EventEmitter<any> = new EventEmitter();
  @Output() removeFile: EventEmitter<any> = new EventEmitter();

  public FeatureType = FeatureType;
  public selectedFeatureType?: FeatureType;
  // public selectedResource?: { resource: string, resourceID: number, internalFileName: string, isStaged: boolean };

  public destroy$: Subject<void> = new Subject<void>();
  public accessRoles$: Observable<StaticTypeModel[]>;
  public staticTypes$: Observable<StaticTypeModel[]>;
  public businessRoles$: Observable<StaticTypeModel[]>;
  public teams$: Observable<TeamModel[]>;
  public featureMandateTypes$: Observable<StaticTypeModel[]>;
  public featureTypes$: Observable<StaticTypeModel[]>;
  public visibilityTypes$: Observable<StaticTypeModel[]>;
  public reports$: Observable<ReportModel[]>;
  public statusCodesWithGroups$: Observable<StatusCodeWithGroupsModel[]>;
  public featureStatusCodes$: Observable<StatusCodeWithGroupsModel[]>;

  public editFeatureForm: UntypedFormGroup = new UntypedFormGroup({
    featureID: new UntypedFormControl(),
    featureMapID: new UntypedFormControl(),
    code: new UntypedFormControl(),
    displayName: new UntypedFormControl(),
    alternativeName: new UntypedFormControl(),
    displayDescription: new UntypedFormControl(),
    internalNote: new UntypedFormControl(),
    uri: new UntypedFormControl(),
    data: new UntypedFormControl(),
    sortOrder: new UntypedFormControl(),
    reportID: new UntypedFormControl(),
    delegateFeatureID: new UntypedFormControl(),
    parentFeatureID: new UntypedFormControl(),
    featureType: new UntypedFormControl(),
    featureMandateType: new UntypedFormControl(),
    statusCode: new UntypedFormControl(),
    visibilityType: new UntypedFormControl(),
    accessRoles: new UntypedFormControl(),
    businessRoles: new UntypedFormControl(),
    teamIDs: new UntypedFormControl(),
    resourceID: new UntypedFormControl(),
    resource: new UntypedFormControl(),
    resourceInternalFileName: new UntypedFormControl(),
    resourceIsStaged: new UntypedFormControl(),
    canEdit: new UntypedFormControl()
  });

  private accessRoleLookup: AccessRoleModel[] = [];

  constructor(
    protected appUxService: AppUxService,
    protected store: Store,
    protected changeDetectorRef: ChangeDetectorRef
  ) {

    super(appUxService, store, changeDetectorRef);

    this.staticTypes$ = this.store.select(selectStaticTypes);
    this.featureMandateTypes$ = this.staticTypes$.pipe(map(x => x.filter(y => y.tableName == 'FeatureMandateType')));
    this.featureTypes$ = this.staticTypes$.pipe(map(x => x.filter(y => y.tableName == 'FeatureType')));
    this.accessRoles$ = this.staticTypes$.pipe(map(x => x.filter(y => y.tableName == 'AccessRole')));
    this.businessRoles$ = this.staticTypes$.pipe(map(x => x.filter(y => y.tableName == 'BusinessRole' && y.visibilityTypeID == VisibilityType.Visible)));
    this.visibilityTypes$ = this.staticTypes$.pipe(map(x => x.filter(y => y.tableName == 'VisibilityType')));
    this.teams$ = this.store.select(selectTeams);
    this.reports$ = this.store.select(selectReports);
    this.statusCodesWithGroups$ = store.select(selectStatusCodesWithGroups);
    this.featureStatusCodes$ = this.statusCodesWithGroups$.pipe(map(x => x.filter(y => y.statusCodeGroups?.some(z => z == StatusCodeGroup.Active))));

    this.store.select(selectAccessRoles).pipe(first()).subscribe(x => {
      this.accessRoleLookup = x;
    })

    this.editFeatureForm.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(x => {

      const noEmitOption = { emitEvent:false, onlySelf: true };

      if ((this.selectedFeatureType = this.editFeatureForm.value.featureType) != FeatureType.Route) {
        this.editFeatureForm.patchValue({ uri: null, reportID: null }, noEmitOption);
      }

      const businessRolesFormControl = this.editFeatureForm.controls.businessRoles as UntypedFormControl;

      // disable Sales Roles control if Sales Rep access role not selected.  Must inspect role group children
      if (this.editFeatureForm.value.accessRoles?.some((y:AccessRole) => {
          return y == AccessRole.SalesRep ||
            this.accessRoleLookup.find(z => z.accessRoleID == y)?.childAccessRoleIDs?.some(z => z == AccessRole.SalesRep);
        })) {
        businessRolesFormControl.enable(noEmitOption);
      } else {
        businessRolesFormControl.disable(noEmitOption);
        this.editFeatureForm.patchValue({ businessRoles: null }, noEmitOption);
      }

      const businessRolesValue = this.editFeatureForm.value.businessRoles;
      const teamIDsFormControl = this.editFeatureForm.controls.teamIDs as UntypedFormControl;

      // disable AM Teams control if AM business role not selected.  Must inspect role group children
      if ((!businessRolesValue?.length && businessRolesFormControl.enabled) || businessRolesValue?.some((y:BusinessRole) => y == BusinessRole.Am)) {
        teamIDsFormControl.enable(noEmitOption);
      } else {
        teamIDsFormControl.disable(noEmitOption);
        this.editFeatureForm.patchValue({ teamIDs: null }, noEmitOption);
      }

      this.changeDetectorRef.detectChanges();
    })

  }

  private checkFeatureMandateTypeRestriction() {
    // we'll cancel/close this dialog if the "@Input() feature" has a different mandateType
    // if (this._featureMandateTypeWhiteList?.length && this.feature && this.feature.featureMandateType && !this._featureMandateTypeWhiteList.some(x => x == this.feature.featureMandateType)) {
    //   window.setTimeout(() => this.cancel.emit(), 100);
    //   this.feature = new FeatureModel();
    // }
    if (this._featureMandateTypeWhiteList?.length == 1) {
      this.editFeatureForm.patchValue({ featureMandateType: this._featureMandateTypeWhiteList[0] });
      (this.editFeatureForm.controls.featureMandateType as UntypedFormControl).disable();
    }
  }

  private checkFeatureTypeRestriction() {
    // we'll cancel/close this dialog if the "@Input() feature" has a different mandateType
    if (this._featureTypeWhiteList?.length && this.feature && this.feature.featureType && !this._featureTypeWhiteList.some(x => x == this.feature.featureType)) {
      window.setTimeout(() => this.cancel.emit(), 100);
      this.feature = new FeatureModel();
    }
    if (this._featureTypeWhiteList?.length == 1) {
      this.editFeatureForm.patchValue({ featureType: this._featureTypeWhiteList[0] });
      (this.editFeatureForm.controls.featureType as UntypedFormControl).disable();
    }
  }

  public createUrl(): string | undefined {

    const formValue = this.editFeatureForm.value

    if (!formValue.resourceID || !formValue.resource || !formValue.resourceInternalFileName || !this.applicationInfo.userUploadsWebFolder)
      return undefined;

    let folder: string | undefined;
    if (formValue.resourceIsStaged) {
      folder = this.applicationInfo.userUploadsStagingFolder ?? undefined;
    } else {
      folder = this.applicationInfo.userUploadsResourcesFolder ?? undefined;
    }

    if (!folder)
      return undefined;

    return Utilities.normalizeUrl([
      this.applicationInfo.userUploadsWebFolder,
      folder,
      window.encodeURIComponent(formValue.resourceInternalFileName),
      window.encodeURIComponent(formValue.resource)
    ]);
  }

  ngOnInit(): void {

    this.editFeatureForm.setValue({...this.editFeatureForm.getRawValue(), ...this.feature });

    let code: UntypedFormControl = this.editFeatureForm.get('code') as UntypedFormControl;
    if(code) {
      if (this.feature?.featureID)
        code.disable();
      else
        code.enable();
    }

    if (this.viewMode == 'new') this.setFromDefaults();
  }

  private setFromDefaults() {

    const defaultValues: any = {
      featureMandateType: FeatureMandateType.User,
      statusCode: StatusCode.Active,
      visibilityType: VisibilityType.Visible,
      accessRoles: [AccessRole.AllRoles]
    };

    switch (this.editFeatureType) {
      case 'normal':
        break;
      case 'resource-only':
        defaultValues.parentFeatureID = this.featuresForSelected.find(x => !x.parentFeatureID)?.featureID;
        defaultValues.featureType = FeatureType.Resource;
        break;
    }

    this.editFeatureForm.reset(undefined, { onlySelf: true, emitEvent: false });
    this.editFeatureForm.patchValue(defaultValues, { onlySelf: true, emitEvent: true });
  }

  /*
      featureID: new FormControl(),
    featureMapID: new FormControl(),
    code: new FormControl(),
    displayName: new FormControl(),
    alternativeName: new FormControl(),
    displayDescription: new FormControl(),
    internalNote: new FormControl(),
    uri: new FormControl(),
    data: new FormControl(),
    sortOrder: new FormControl(),
    reportID: new FormControl(),
    delegateFeatureID: new FormControl(),
    parentFeatureID: new FormControl(),
    featureType: new FormControl(),
    featureMandateType: new FormControl(),
    statusCode: new FormControl(),
    visibilityType: new FormControl(),
    accessRoles: new FormControl(),
    businessRoles: new FormControl(),
    teamIDs: new FormControl(),
    resourceID: new FormControl(),
    resource: new FormControl(),
    resourceInternalFileName: new FormControl(),
    resourceIsStaged: new FormControl(),

  */

  public onSaveFeature() {
    this.saveFeature.emit(this.editFeatureForm.getRawValue() as FeatureModel);
  }

  public onUploadClick() {
    this.selectUpload.emit();
  }

  public onSelectClick() {
    this.selectExistingFile.emit();
  }

  public onRemoveClick() {
    this.editFeatureForm.patchValue({ resource: undefined, resourceID: undefined });

  }

  public onValueChange(event: FileInfo[]): void {
    const formResourceInfo: { resourceID: undefined | null | number, resource: undefined | null | string, resourceInternalFileName: undefined | null | string, resourceIsStaged: undefined | null | boolean  }  = { resourceID: undefined, resource: undefined, resourceInternalFileName: undefined, resourceIsStaged: undefined };
    if (event?.length && this.stagedResources?.length && Utilities.stripUid(event[0].uid) == this.stagedResources[0].code) {
      // we should never have more than 1
      formResourceInfo.resourceID = this.stagedResources[0].resourceID;
      formResourceInfo.resource = this.stagedResources[0].displayName;
      formResourceInfo.resourceInternalFileName = this.stagedResources[0].internalFileName;
      formResourceInfo.resourceIsStaged = true;
    }
    this.stagedResources.length = 0;
    this.editFeatureForm.patchValue(formResourceInfo);

    this.fileUpload?.clearFiles();
    this._files = [];

    this.changeDetectorRef.detectChanges();
  }

  public onViewImage() {
    const url = this.createUrl();
    if (url)
      window.open(url, "_blank");
  }


  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

}
