import { ChangeDetectorRef, Component, HostBinding, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { environment } from '@environments/environment';
import { NumericTextBoxComponent } from '@progress/kendo-angular-inputs';
import * as moment from 'moment';

@Component({
  selector: 'acb-alcon-phasing-month',
  template: `
    <ng-container *ngIf="form; let form" [formGroup]="form">
      <div [formArrayName]="arrayName" fxLayout="row" fxLayoutAlign="start center">
        <kendo-numerictextbox
          #monthTextBox
          [decimals]="2"
          [format]="'c'"
          [min]="0"
          [max]="fieldMaxDollar"
          [value]="0"
          [spinners]="false"
          [formControlName]="controlName"
          [autoCorrect]="true"
          [step]="100"
          (valueChange)="monthTextBox.value = monthTextBox.value ? monthTextBox.value : 0"
          >
        </kendo-numerictextbox>
        <kendo-label fxLayout fxLayoutAlign="center center">
          {{ monthName }}
        </kendo-label>
      </div>
    <ng-container>
  `,
  styleUrls: ['./phasing-month.component.scss']
})
export class PhasingMonthComponent implements OnInit, OnChanges {

  @HostBinding('class.acb-is-activity-month') get t(){
    return this.isActivityMonth;
  };

  @ViewChild('monthTextBox') monthTextBox?: NumericTextBoxComponent;

  @Input() form?: UntypedFormGroup;
  @Input() arrayName: string | number | null = null;
  @Input() controlName: string | number | null = null;
  @Input() isActivityMonth: boolean = false;

  private _month?: number;
  @Input() set month(value: number | string | null | undefined) {
    const num = Number.isNaN(Number(value)) ? undefined : Math.floor(Number(value));
    if (this._month !== num) {
      this._month = num;
      this._monthName = this._month === undefined ? undefined : moment.monthsShort(this._month);
    }
  }

  private _monthName?: string;
  public get monthName(): string | undefined {
    return this._monthName;
  }

  public fieldMaxDollar = environment.fieldMaxDollar;

  constructor(private changeDetectorRef: ChangeDetectorRef,) { }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.isActivityMonth && this.monthTextBox?.value && !this.isActivityMonth) {
      if (this.form && this.arrayName && (this.controlName || this.controlName ===0)) {
        const control = this.form.get(this.arrayName.toString())?.get(this.controlName.toString());
        if (control) {
          // TODO: This is a hack.  Switch from ngOnChanges to a local service
          setTimeout(() => { try { control.patchValue(0); } catch(err){} })
        }
      }
    }
  }

}
