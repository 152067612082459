import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'acb-alcon-filter-chooser[additionalParametersSpec][additionalParameters]',
  template: `
    <div>
      <acb-alcon-filter-chooser-field *ngFor='let paramSpec of additionalParametersSpec' [parameterSpec]="paramSpec" [parameter]="getParam(paramSpec.code)" [form]="form">
      </acb-alcon-filter-chooser-field>
    </div>
  `,
  styleUrls: ['./filter-chooser.component.scss']
})
export class FilterChooserComponent implements OnInit {

  @Input() public additionalParameters: WidgetParameter[] = [];
  @Input() public additionalParametersSpec: WidgetParameterSpec[] = [];

  form?: UntypedFormGroup;
  constructor() { }

  getParam(code: string | null | undefined) {
    return code ? (this?.additionalParameters as any)[code] ?? '' : '';
  }

  ngOnInit(): void {

    if (!this.additionalParametersSpec)
      return;

    const group: any = {};
    this.additionalParametersSpec.forEach(x => {
      if (x.code) {
        group[x.code] = new UntypedFormControl(this.getParam(x.code) ?? '');
      }
    });
    this.form = new UntypedFormGroup(group);
    this.form.valueChanges.subscribe(() => {
      for (const field in this.form?.controls) {
        const control = this.form?.get(field);
        (this.additionalParameters as any)[field] = control?.value;
      }
    });
  }
}

export class WidgetParameterSpec {
  code?: string;
  label?: string;
  group?: string;
  type?: 'choices' | 'bit';
  choices?: string[];
  hidden: boolean = false;
}

export class WidgetParameter {
  code?: string;
  value?: string;
}
