import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'acb-alcon-hierarchy-tree',
  template: `

  `,
  styleUrls: ['./hierarchy-tree.component.scss']
})
export class HierarchyTreeComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}



