import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'acb-alcon-create-commitment-success[commitmentName]',
  template: `
    <!--
    <div class="acb-feature-wrapper" fxLayout="column" fxLayoutAlign="center center" [ngClass.lt-md]="'acb-small'">
      <div class="acb-inner" fxLayout="column" fxLayoutAlign="start start">
        <span class="acb-success-feature-text">Commitment <span class="acb-success-name">{{commitmentName}}</span> Successfully Submitted!</span>
        <div fxLayout="row wrap" class="acb-botton-row" >
          <button kendoButton (click)="createAnother.emit(false)" class="k-button acb-create-another" fxFlex="0 0 calc(40% - 2em)">Create Another</button>
          <button kendoButton [routerLink]="['/commitments/manage']" class="k-button acb-goto-manage" fxFlex="0 0 calc(40% - 2em)">Manage Commitments</button>
          <button kendoButton (click)="createAnother.emit(true)" class="k-button acb-create-another" fxFlex="0 0 calc(40% - 2em)">Create Another (Copy)</button>
          <button kendoButton [routerLink]="['/']" class="k-button acb-goto-dashboard" fxFlexOffset="2em" fxFlex="0 0 calc(40% - 4em)">Dashboard</button>
        </div>
        <div fxFlex="0 1 5vh"></div>
      </div>
    <div>
    -->
    <div class="acb-success-view" fxLayout="column" fxLayoutAlign="center center" [ngClass.sm]="'acb-small'" [ngClass.lt-sm]="'acb-tiny'">
      <div class="acb-success-view-inner" fxLayout="column" fxLayoutAlign="center center">
        <div fxLayout="row" class="acb-top-row">
          <div class="acb-success-feature-icon"></div>
          <div class="acb-success-feature-text">Commitment <span class="acb-success-name">{{commitmentName}}</span> Successfully Submitted!</div>
        </div>
        <div fxLayout="row wrap" fxLayout.xs="column" class="acb-botton-row" fxLayoutGap="1em"  fxLayoutAlign="center center">
          <button kendoButton (click)="createAnother.emit(false)" class="k-button acb-create-another" fxFlex.xs="3em" fxFlex.gt-xs="0 0 calc(48% - 2em)">Create Another</button>
          <button kendoButton [routerLink]="['/commitments/manage']" class="k-button acb-goto-manage" fxFlex.xs="3em" fxFlex.gt-xs="0 0 calc(48% - 2em)">Manage Commitments</button>
          <button kendoButton (click)="createAnother.emit(true)" class="k-button acb-create-another" fxFlex.xs="3em" fxFlex.gt-xs="0 0 calc(48% - 2em)">Create Another (Copy)</button>
          <button kendoButton [routerLink]="['/']" class="k-button acb-goto-dashboard" fxFlexOffset.gt-xs="2em"  fxFlex.xs="3em" fxFlex.gt-xs="0 0 calc(48% - 3em)">Dashboard</button>
        </div>
      </div>
    </div>

  `,
  styleUrls: ['./create-commitment-success.component.scss']
})
export class CreateCommitmentSuccessComponent implements OnInit {

  @Input() commitmentName?:string | null = null;
  @Output() createAnother: EventEmitter<boolean> = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }
}
