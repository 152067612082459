import { UserInfoModel } from '@alcon-db-models/UserInfoModel';
import { Injectable } from '@angular/core';
import { EntityCollectionServiceBase, EntityCollectionServiceElementsFactory } from '@ngrx/data';

@Injectable({
  providedIn: 'root'
})
export class UserInfoService extends EntityCollectionServiceBase<UserInfoModel> {
  constructor(serviceElementsFactory: EntityCollectionServiceElementsFactory) {
    super('UserInfo', serviceElementsFactory);
  }
}

