import { ActivityForDropdownModel } from '@alcon-db-models/ActivityForDropdownModel';
import { AttachementModel } from '@alcon-db-models/AttachementModel';
import { CommitmentDetailAttachmentModel } from '@alcon-db-models/CommitmentDetailAttachmentModel';
import { CommitmentDetailPhaseModel } from '@alcon-db-models/CommitmentDetailPhaseModel';
import { CommitmentDetailProductModel } from '@alcon-db-models/CommitmentDetailProductModel';
import { CommitmentExceptionModel } from '@alcon-db-models/CommitmentExceptionModel';
import { CommitmentWithDetailsModel } from '@alcon-db-models/CommitmentWithDetailsModel';
import { DraftModel } from '@alcon-db-models/DraftModel';
import { DraftWithDetailsModel } from '@alcon-db-models/DraftWithDetailsModel';
import { ExceptionType, StatusCode } from '@alcon-db-models/Enums';
import { FundSearchWithDefaultModel } from '@alcon-db-models/FundSearchWithDefaultModel';
import { ProductForDropdownModel } from '@alcon-db-models/ProductForDropdownModel';
import { TerritoryForCommitmentWithBalanceModel } from '@alcon-db-models/TerritoryForCommitmentWithBalanceModel';

import { HttpParams } from '@angular/common/http';
import { ChangeDetectorRef, Injectable } from '@angular/core';
import { AbstractControl, UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, ValidationErrors, Validators } from '@angular/forms';
import { productsForDropdown, selectBusinessRules, selectStaticTypes } from '@app-store/app-session/app-session.selectors';
import { Store } from '@ngrx/store';
import { BehaviorSubject, from, Observable, of, Subject, Subscription } from 'rxjs';
import { debounceTime, filter, first, map, takeUntil } from 'rxjs/operators';
import { StaticTypeModel } from '../../../../../../Libraries/ACB.Alcon.Data/Exports/StaticTypeModel';
import { TerritoryForDropdownWithCompositeNameModel } from '../components/core/core.module';
import { JsonUtilities } from '../shared/json-utilities';
import { Utilities } from '../shared/utilities';
import { AcbValidators } from '../shared/validators';
import { ActivityForDropdownService } from './activity-for-dropdown.service';
import { CommitmentSubjectBaseService } from './commitment-subject-base.service';
import { CommitmentUpsertServiceService } from './commitment-upsert-service.service';
import { CommitmentWithDetailsService } from './commitment-with-details.service';
import { DraftWithDetailsService } from './draft-with-details.service';
import { TerritoryForCommitmentWithBalanceService } from './territory-for-commitment-with-balance.service';


class TerritorySpec {
  constructor(
    public customerID: number,
    public fundID: number,
    public excludeCommitmentID?: number
  ) {}
}

class ValidityChange {
  constructor(
    public form: boolean,
    public details: boolean,
    public products: boolean,
    public phases: boolean,
    public customer: boolean,
    public payee: boolean,
    public fund: boolean,
  ) {}
}

@Injectable()
export class CommitmentFormBaseService extends CommitmentSubjectBaseService {

  doesUseCommitmentActivities: boolean = false;
  commitmentActivitiesRequired: boolean = false;
  doesUseCommitmentProducts: boolean = false;
  commitmentProductsRequired: boolean = false;
  areCommitmentActivityRulesDeterminedByFund: boolean = false;

  public form: UntypedFormGroup;
  public readonly territories$: BehaviorSubject<TerritoryForCommitmentWithBalanceModel[]> = new BehaviorSubject<TerritoryForCommitmentWithBalanceModel[]>([]);
  public readonly validityChange$: BehaviorSubject<ValidityChange> = new BehaviorSubject<ValidityChange>({ form: true, details: true, products: true, phases: true, customer: true, payee: true, fund: true });
  public readonly activities$: Subject<ActivityForDropdownModel[]> = new BehaviorSubject(<ActivityForDropdownModel[]>[]);

  public products: ProductForDropdownModel[] = [];
  public customerUpdated: Subject<boolean> = new Subject();
  public payeeUpdated: Subject<boolean> = new Subject();
  public fundUpdated: Subject<boolean> = new Subject();

  private _territories?: TerritoryForCommitmentWithBalanceModel[];
  private _activities?: ActivityForDropdownModel[];
  private fb: UntypedFormBuilder = new UntypedFormBuilder();
  private _lastTerritorySpec?:TerritorySpec;

  private _exceptionTypes: StaticTypeModel[] = [];

  constructor(
    store: Store,
    //TODO: clean this up
    protected commitmentWithDetailsService: CommitmentWithDetailsService,
    draftWithDetailsService: DraftWithDetailsService,
    commitmentUpsertService : CommitmentUpsertServiceService,
    private territoryForCommitmentWithBalanceService: TerritoryForCommitmentWithBalanceService,
    private activityForDropdownService: ActivityForDropdownService,
    private changeDetectorRef: ChangeDetectorRef
  ) {
    super(commitmentWithDetailsService,store,commitmentUpsertService,draftWithDetailsService);

    store.select(selectStaticTypes).pipe(first(x => Boolean(x)), map(x => x.filter(y => y.tableName == 'ExceptionType'))).subscribe(x => this._exceptionTypes = x ?? []);

    store.select(selectBusinessRules).pipe(first()).subscribe(x => {
      this.doesUseCommitmentActivities = x.DoesUseCommitmentActivities;
      this.commitmentActivitiesRequired = x.DoesUseCommitmentActivities && x.CommitmentActivitiesRequired;
      this.doesUseCommitmentProducts = x.DoesUseCommitmentProducts;
      this.commitmentProductsRequired = x.DoesUseCommitmentProducts && x.CommitmentProductsRequired;
      this.areCommitmentActivityRulesDeterminedByFund = x.AreCommitmentActivityRulesDeterminedByFund;
    })

    this.activities$.pipe(takeUntil(this._destroy$)).subscribe(x => {
      this._activities = x
    });

    if (this.doesUseCommitmentActivities) {
      activityForDropdownService.getAll().pipe(takeUntil(this._destroy$)).subscribe(x => {
        this.activities$.next(x);
      });
    }

    const commitmentDetailsForm = new UntypedFormGroup({
        startDate: new UntypedFormControl(null, [Validators.required, this.startDateEndDateValidator]),
        endDate: new UntypedFormControl(null, [Validators.required, this.startDateEndDateValidator]),
        amount: new UntypedFormControl(null, [Validators.required, Validators.min(.01), this.validateBalance]),
        territoryID: new UntypedFormControl(null, [Validators.required]),
        activityID: this.commitmentActivitiesRequired ? new UntypedFormControl(null, [Validators.required]) : new UntypedFormControl(),
        event: new UntypedFormControl(),
        venue: new UntypedFormControl(),
        venueCity: new UntypedFormControl(),
        venueStateProvinceCodeID: new UntypedFormControl(),
        estimatedAttendeeCount: new UntypedFormControl(),
        comment: new UntypedFormControl(),
      },
      [
        AcbValidators.requireSuccessiveDates('startDate', 'endDate'),
        AcbValidators.requireSameYear('startDate', 'endDate')
      ]
    );

    this.commitment$.pipe(takeUntil(this._destroy$)).subscribe(x => {
      const fields = [commitmentDetailsForm.get('event'), commitmentDetailsForm.get('venue'), commitmentDetailsForm.get('venueCity'), commitmentDetailsForm.get('venueStateProvinceCodeID'), commitmentDetailsForm.get('estimatedAttendeeCount')];
      fields.forEach(x => x?.clearValidators());
      if (x.fundIsVenueAware) {
        fields.forEach(x => x?.setValidators(Validators.required));
      }
      if (this.areCommitmentActivityRulesDeterminedByFund) {
        this.doesUseCommitmentActivities = x.fundDoesUseCommitmentActivities ?? false;
        this.commitmentActivitiesRequired = (x.fundDoesUseCommitmentActivities ?? false) && (x.fundCommitmentActivitiesRequired ?? false);
      }
      (this.form?.controls.commitmentDetails as UntypedFormGroup)?.controls.activityID.setValidators(this.commitmentActivitiesRequired ? [Validators.required] : []);

      this.updateActivities();
    })


    const commitmentProducts = this.doesUseCommitmentProducts ?  new UntypedFormArray([], this.validateProductSums) : new UntypedFormArray([]);
    const commitmentPhases = new UntypedFormArray([], this.validatePhaseSums);
    //const commitmentFiles = new FormControl(null);

    this.form = new UntypedFormGroup({
      commitmentDetails: commitmentDetailsForm,
      commitmentProducts: commitmentProducts,
      commitmentPhases: commitmentPhases,
    });

    this.form.statusChanges.pipe(takeUntil(this._destroy$),debounceTime(250)).subscribe(x => {
      this.onValidityChange();
    });

    if (this.doesUseCommitmentProducts) {
      store.select(productsForDropdown).pipe(first()).subscribe(x => {
        this.products = [...x].sort((a, b) => (a?.displayName ?? "").localeCompare(b?.displayName ?? ""));
      });
    }

    this.territories$.pipe(takeUntil(this._destroy$)).subscribe(x => {
      this._territories = x;
    });
  }

  // --------------------------------------------------------------------------
  public validateDetails(): boolean {
    const commitmentDetailsForm =  this.form.controls.commitmentDetails;
    commitmentDetailsForm.markAllAsTouched();
    commitmentDetailsForm?.updateValueAndValidity();
    return commitmentDetailsForm?.valid ?? false;
  }

  // --------------------------------------------------------------------------
  public validatePhasing(): boolean {
    const commitmentPhaseForm = this.form.controls.commitmentPhases;
    commitmentPhaseForm?.markAllAsTouched();
    commitmentPhaseForm?.updateValueAndValidity();
    return commitmentPhaseForm?.valid ?? false;
  }

  // --------------------------------------------------------------------------
  public validateProducts(): boolean {
    if(!this.doesUseCommitmentProducts) return true;
    const commitmentProductsArray = this.form.controls.commitmentProducts as UntypedFormArray;
    commitmentProductsArray?.markAllAsTouched();
    commitmentProductsArray?.updateValueAndValidity();
    return commitmentProductsArray?.valid ?? false;
  }

  private startDateEndDateValidator = ((control: AbstractControl):ValidationErrors | null => {
    const fundYear = this._commitment?.fundYear;
    const controlYear = control?.value?.getFullYear ? control.value.getFullYear() : null;
    const isPostAudit = this._commitment?.fundIsPostAudit ?? false;
    if (fundYear && controlYear) {
      if (controlYear > fundYear) return { "maxError": true };
      if (controlYear < fundYear && !isPostAudit) return { "minError": true };
    }
    return null;
  }).bind(this);

  // --------------------------------------------------------------------------
  private recalcPhasing(previousCommitmentAmount: number, nextCommitmentAmount: number) {

    if (!this._commitment?.startDate || !this._commitment?.endDate) return;
    const startIndex = this._commitment.startDate.getMonth();
    const endIndex = this._commitment.endDate.getMonth();

    const commitmentPhaseForm = this.form.controls.commitmentPhases as UntypedFormArray;
    if (!commitmentPhaseForm?.controls?.length) return;

    const control = commitmentPhaseForm.controls[0];
    const amounts = control.value?.amounts as number[];
    if (!amounts?.length) return;

    let sum = 0;
    amounts.forEach((x,i) => {
      let val = 0;
      if (i >= startIndex && i <= endIndex) {
        val = i < endIndex
          ? Math.floor((previousCommitmentAmount ? nextCommitmentAmount * ((x ?? 0) / previousCommitmentAmount) : 0) * 100) / 100
          : Math.round(((nextCommitmentAmount - sum) * 100) + Number.EPSILON) / 100;
      }
      sum += amounts[i] = val;
    });

    control.patchValue({ amounts: amounts });

    this.applyPhasesFromForm();
    this.validatePhasing();
  }

  // --------------------------------------------------------------------------
  private recalcProducts(previousCommitmentAmount: number, nextCommitmentAmount: number) {

    const commitmentProductsForm = this.form.controls.commitmentProducts as UntypedFormArray;
    if (!commitmentProductsForm?.controls?.length) return;

    const productControls = commitmentProductsForm?.controls;
    if (!productControls?.length) return;

    let sum = 0;
    productControls.forEach((x,i) => {
      let val = 0;
      if (i < productControls!.length - 1) {
        sum += val = Math.floor((previousCommitmentAmount ? nextCommitmentAmount * ((x.value.amount ?? 0) / previousCommitmentAmount) : 0) * 100) / 100;
      } else {
        val = Math.round(((nextCommitmentAmount - sum) * 100) + Number.EPSILON) / 100;
      }
      x.patchValue( { amount: val });
    });

    this.applyProductsFromForm();
    this.validateProducts();
  }

  // --------------------------------------------------------------------------
  private _activityForDropdownSubscription: Subscription | undefined;
  public updateActivities(fund?: FundSearchWithDefaultModel) {
    this._activityForDropdownSubscription?.unsubscribe();
    if (fund?.doesUseCommitmentActivities) {
      if (fund?.fundID) {
        this._activityForDropdownSubscription = this.activityForDropdownService.getWithQuery("fundID=" + fund.fundID).pipe(first(y => Boolean(y))).subscribe(y => {
          this._activityForDropdownSubscription = undefined;
          this.activities$.next(y);
        });

      } else {
        this.activities$.next([]);
      }
    } else if (this.doesUseCommitmentActivities && this._commitment?.fundID) {
      this._activityForDropdownSubscription = this.activityForDropdownService.getWithQuery("fundID=" + this._commitment?.fundID).pipe(first(y => Boolean(y))).subscribe(y => {
        this._activityForDropdownSubscription = undefined;
        this.activities$.next(y);
      });
    } else {
      this.activities$.next([]);
    }
  }

  // --------------------------------------------------------------------------
  public applyDetailsFromForm() {

    const detailsFormControls = (this.form.controls.commitmentDetails as UntypedFormGroup)?.controls;
    if (this._commitment && detailsFormControls) {

      const previousCommitmentAmount = this._commitment?.amount ?? 0;
      const nextCommitmentAmount = detailsFormControls?.amount?.value ?? 0;

      const territory = this._territories?.find(x => x.territoryID == detailsFormControls.territoryID.value);
      const activity = this._activities?.find(x => x.activityID == detailsFormControls.activityID.value);
      //const costHasChanged = detailsFormControls.amount.value != this._commitment.amount;

      let doRecalcPhasing: boolean = false;
      let doRecalcProducts: boolean = false;

      const phases = [...this._commitment?.phases ?? []];
      // TODO: detailsFormControls.startDate maybe null if initialized from clone or draft.  Smells bad
      if (detailsFormControls.startDate?.value && this._commitment.startDate && (detailsFormControls.startDate.value?.getTime() != this._commitment.startDate?.getTime() || detailsFormControls.endDate.value?.getTime() != this._commitment.endDate?.getTime())) {
        if (phases?.length && phases[0].amounts?.length) {
          phases.forEach(x => x.amounts?.fill(0));
        } else {
          phases.length = 0;
          phases.push(this.createDefaultPhase());
        }
        if (detailsFormControls.startDate.value?.getMonth && detailsFormControls.startDate.value?.getMonth() == detailsFormControls.endDate.value?.getMonth() && phases[0]?.amounts) {
          phases[0].amounts[detailsFormControls.startDate.value.getMonth()] = this._commitment.amount ?? 0;
        }
      } else if (previousCommitmentAmount != nextCommitmentAmount) {
        doRecalcPhasing = true;
      }

      if (previousCommitmentAmount != nextCommitmentAmount) {
        doRecalcProducts = true;
      }

      const commitment = {
        ...JsonUtilities.convertDatesAndCopy(this._commitment),
        amount: detailsFormControls.amount.value ?? 0,
        startDate: detailsFormControls.startDate.value,
        endDate: detailsFormControls.endDate.value,
        event: detailsFormControls.event.value,
        venue: detailsFormControls.venue.value,
        venueCity: detailsFormControls.venueCity.value,
        venueStateProvinceCodeID: detailsFormControls.venueStateProvinceCodeID.value,
        estimatedAttendeeCount: detailsFormControls.estimatedAttendeeCount.value,
        activityID: activity?.activityID,
        activity: activity?.displayName,
        territoryID: territory?.territoryID,
        territory: territory?.displayName,
        territoryCode: territory?.code,
        territoryPerson: territory?.primarySalesPerson,
        territorySuggestedName: territory?.displayName,
        comments:  [{ commentBody: detailsFormControls.comment.value }],
        phases: phases,
      };

      this.updateExceptionsInternal(commitment);

      this.commitment$.next(commitment);

      if (doRecalcPhasing) this.recalcPhasing(previousCommitmentAmount, nextCommitmentAmount);
      if (doRecalcProducts) this.recalcProducts(previousCommitmentAmount, nextCommitmentAmount);

      this.changeDetectorRef.detectChanges();
    }
  }

  private _firstCustomerID: number | undefined | null;
  public updateExceptions() {
    if (this._commitment) this.updateExceptionsInternal(this._commitment);
  }
  private updateExceptionsInternal(commitment: CommitmentWithDetailsModel) {

    this._firstCustomerID = this._firstCustomerID ?? commitment.customerID;

    const detailsFormControls = (this.form.controls.commitmentDetails as UntypedFormGroup)?.controls;
    const territory = this._territories?.find(x => x.territoryID == detailsFormControls.territoryID.value);

    let exceptions: CommitmentExceptionModel[] = commitment.exceptions ?? [];

    exceptions = commitment.territoryID != territory?.territoryID ?
      (exceptions.filter(x => x.exceptionTypeID != ExceptionType.InvalidTerritory && x.exceptionTypeID != ExceptionType.CustomerTerritoryMismatch) ?? []) :
      exceptions;

    exceptions = commitment.customerID != this._firstCustomerID ?
      (exceptions.filter(x => x.exceptionTypeID != ExceptionType.InvalidCustomer && x.exceptionTypeID != ExceptionType.CustomerTerritoryMismatch) ?? []) :
      exceptions;

    return this.applyCommitmentExceptions(commitment, exceptions);
  }

  // --------------------------------------------------------------------------
  public applyProductsFromForm() {

    if (!this.doesUseCommitmentProducts) return;

    const commitment: CommitmentWithDetailsModel | undefined = JsonUtilities.convertDatesAndCopy(this._commitment);
    if (!commitment)
      return;

    const productArray = this.form.controls.commitmentProducts as UntypedFormArray  ;
    const productArrayControls: UntypedFormGroup[] | undefined = productArray?.controls as any[];
    if (!productArray || !productArrayControls)
      return;

    //commitment.products = commitment.products?.filter(x => productArrayControls.some(y => y.controls?.productID?.value == x.productID));
    const newList: CommitmentDetailProductModel[] = [];
    productArrayControls.filter(x => x.controls?.productID?.value).forEach(x => {
      const productID = x.controls?.productID?.value;
      const product = this.products.find(y => y.productID == productID);
      newList.push({
        ...(commitment.products?.find(y => y.productID == productID) ?? {}),
        productID: productID,
        product: product?.displayName,
        productCode: product?.code,
        amount: x.controls?.amount?.value ?? 0
      })
    });
    commitment.products = newList;

    this.commitment$.next(commitment);
  }

  // --------------------------------------------------------------------------
  public applyPhasesFromForm() {

    const commitment: CommitmentWithDetailsModel | undefined = JsonUtilities.convertDatesAndCopy(this._commitment);
    if (!commitment)
      return;

    const phaseArray = this.form.controls.commitmentPhases as UntypedFormArray;
    const phaseArrayControls: UntypedFormGroup[] | undefined = phaseArray?.controls as any[];
    if (!phaseArray || !phaseArrayControls)
      return;

    const newList: CommitmentDetailPhaseModel[] = [];
    phaseArrayControls.map(x => x as UntypedFormGroup).forEach((x,i) => {
      const phase = commitment.phases?.length ?? 0 > i ? commitment.phases![i] : new CommitmentDetailPhaseModel();
      newList.push({
        ...phase,
        year: (commitment.startDate ?? new Date).getFullYear() + (phase.yearIncrement ?? 0),
        amounts: (x.controls.amounts as UntypedFormArray).controls.map(y => (y as UntypedFormControl).value ?? 0)
      })
    })

    commitment.phases = newList;

    this.commitment$.next(commitment);
  }

  // --------------------------------------------------------------------------
  public applyCommitmentExceptions(commitment:CommitmentWithDetailsModel, exceptions:CommitmentExceptionModel[]): CommitmentWithDetailsModel {
    commitment.exception = exceptions.map(x => this._exceptionTypes.find(y => y.id == x.exceptionTypeID)?.displayName).join(', ');
    commitment.hasException = Boolean(exceptions.length);
    commitment.exceptions = exceptions;
    return commitment;
  }

  // --------------------------------------------------------------------------
  public updateCommitment(commitment:CommitmentWithDetailsModel | undefined) {
    //test
    // this._commitment = commitment ? commitment : new CommitmentWithDetailsModel();
    // this.commitment$.next(this._commitment) ;
    this.commitment$.next(commitment ? commitment : new CommitmentWithDetailsModel()) ;
  }

  // --------------------------------------------------------------------------
  public updateAttachments(attachments:AttachementModel[]) {
    this.commitment$.next({
      ...this._commitment,
      attachments: attachments
    });
  }

  // --------------------------------------------------------------------------
  public updateCustomer(customer:any) {

    // const exceptions: CommitmentExceptionModel[] = customer?.customerID != this._commitment?.customerID ?
    //   (this._commitment?.exceptions?.filter(x => x.exceptionTypeID != ExceptionType.InvalidCustomer) ?? []) :
    //   [];

    const payee = customer?.billToCustomerID
      ?
      {
        payeeCustomerID: customer?.billToCustomerID,
        payeeCustomerCode: customer?.billToCustomerCode,
        payee: customer?.billToCustomer,
        payeeLocation: {
          locationLine1: customer?.billToLocationLine1,
          locationLine2: customer?.billToLocationLine2,
          city: customer?.billToCity,
          postalCodeValue: customer?.billToPostalCodeValue,
          stateProvinceCode: customer?.billToStateProvinceCodeID,
          countryCode: customer?.billToCountryCodeID
        }
      }
      :
      {
        payeeCustomerID: customer?.customerID,
        payeeCustomerCode: customer?.customerCode ?? customer?.code,
        payee: customer?.customer ?? customer?.displayName,
        payeeLocation: {
          locationLine1: customer?.locationLine1,
          locationLine2: customer?.locationLine2,
          city: customer?.city,
          postalCodeValue: customer?.postalCodeValue,
          stateProvinceCode: customer?.stateProvinceCodeID,
          countryCode: customer?.countryCodeID,
        }
      };

    let commitment: CommitmentWithDetailsModel = {
      ...this._commitment,
      customerID: customer?.customerID,
      customerCode: customer?.customerCode ?? customer?.code,
      customer: customer?.customer ?? customer?.displayName,
      customerLocation: {
        locationLine1: customer?.locationLine1,
        locationLine2: customer?.locationLine2,
        city: customer?.city,
        postalCodeValue: customer?.postalCodeValue,
        stateProvinceCode: customer?.stateProvinceCodeID,
        countryCode: customer?.countryCodeID,
      },
      ...payee,
      billToCustomerID: customer?.billToCustomerID,
      billToCustomerCode: customer?.billToCustomerCode,
      billTo: customer?.billToCustomer,
      billToLocation: {
        locationLine1: customer?.billToLocationLine1,
        locationLine2: customer?.billToLocationLine2,
        city: customer?.billToCity,
        postalCodeValue: customer?.billToPostalCodeValue,
        stateProvinceCode: customer?.billToStateProvinceCodeID,
        countryCode: customer?.billToCountryCodeID
      },
      fundID: null,
      fund: null,
      fundCode: null,
      fundYear: null,
      fundIsPostAudit: null,
      fundIsVenueAware: null,
      fundDoesUseCommitmentActivities: null,
      fundCommitmentActivitiesRequired: null,
      activityID: null,
      territoryID: null,
      territory: null,
      territoryCode: null,
      territoryPerson: null,
      territoryPersonID: null,
      territorySuggestedName: null,
      fundingTerritorySuggestedName: null
    };

    //commitment = this.applyCommitmentExceptions(commitment, exceptions);
    commitment = this.updateExceptionsInternal(commitment);

    this.commitment$.next(commitment);
    this.refreshTerritories(commitment);

    this.updateFormDetails(commitment, true);

    this.customerUpdated.next(true);
  }

  // --------------------------------------------------------------------------
  public updatePayee(payee:any) {
    this.commitment$.next({
      ...this._commitment,
      ...{
        payeeCustomerID: payee?.customerID,
        payeeCustomerCode: payee?.customerCode ?? payee?.code,
        payee: payee?.customer ?? payee?.displayName,
        payeeLocation: {
          locationLine1: payee?.locationLine1,
          locationLine2: payee?.locationLine2,
          city: payee?.city,
          postalCodeValue: payee?.postalCodeValue,
          stateProvinceCode: payee?.stateProvinceCodeID ?? payee?.stateProvinceCode,
          countryCode: payee?.countryCodeID ?? payee?.countryCode,
        }
      }
    });
    this.payeeUpdated.next(true);
  }

  // --------------------------------------------------------------------------
  public updateFund(fund?:FundSearchWithDefaultModel, personID?: number) {

    this.updateActivities(fund);

    // Setting fund clears territory, as well
    //const exceptions: CommitmentExceptionModel[] = this._commitment?.exceptions?.filter(x => x.exceptionTypeID != ExceptionType.InvalidFund && x.exceptionTypeID != ExceptionType.InvalidTerritory) ?? [];

    let commitment: CommitmentWithDetailsModel = {
      ...{},
      ...this._commitment,
      ...{
        fundID:fund?.fundID,
        fundCode:fund?.code,
        fund:fund?.displayName,
        fundYear:fund?.year,
        fundIsPostAudit: fund?.isPostAudit,
        fundIsVenueAware: fund?.isVenueAware,
        fundDoesUseCommitmentActivities: fund?.doesUseCommitmentActivities,
        fundCommitmentActivitiesRequired: fund?.commitmentActivitiesRequired
      },
      activityID: null,
      territoryID: null,
      territory: null,
      territoryCode: null,
      territoryPerson: null,
      territoryPersonID: null,
      territorySuggestedName: null,
      fundingTerritorySuggestedName: null
    };

    //commitment = this.applyCommitmentExceptions(commitment, exceptions);
    commitment = this.updateExceptionsInternal(commitment);

    this.commitment$.next(commitment);

    this.refreshTerritories(commitment, (commitmentTerritory?:TerritoryForDropdownWithCompositeNameModel,territories?:TerritoryForDropdownWithCompositeNameModel[]) => {
      if (personID) {
        const commitmentDetails = this.form.controls.commitmentDetails as UntypedFormGroup;
        if (commitmentDetails && territories) {

          const minRole = Math.min.apply(Math, territories.filter(x => x.primaryBusinessRoleID).map(x => x.primaryBusinessRoleID ?? 999));
          const minTerritories = territories.filter(x => x.primaryBusinessRoleID == minRole);
          const territory = Boolean(minTerritories?.length) ? minTerritories.find(x => x.primarySalesPersonID == personID) ?? minTerritories[0] : undefined;

          this.commitment$.next({
            ...commitment,
            territoryID: territory?.territoryID,
            territory: territory?.compositeName,
            territorySuggestedName: territory?.displayName,
            territoryCode: territory?.code,
            territoryPerson: territory?.primarySalesPerson,
            territoryPersonID: territory?.primarySalesPersonID
          });

          commitmentDetails.patchValue({ territoryID:  territory?.territoryID });
          commitmentDetails.updateValueAndValidity();
        }
      }
      this.updateFormDetails(commitment, true);
    });


    this.fundUpdated.next(true);
  }

  // --------------------------------------------------------------------------
  public resetForm() {
    if (this._commitment)
      this.updateForm(this._commitment);
  }

  // --------------------------------------------------------------------------
  public addProduct() {

    const productArray: UntypedFormArray | undefined = this.form.controls.commitmentProducts as UntypedFormArray;
    if (!productArray?.controls)
      return;

    if (!this._commitment)
      return;

    const fg = this.fb.group({
      productID: new UntypedFormControl(null),
      amount: new UntypedFormControl(0),
      percent: new UntypedFormControl(0),
    })
    productArray.push(fg);
    productArray.markAllAsTouched();
    productArray.updateValueAndValidity();
  }

  // --------------------------------------------------------------------------
  public removeProduct(productID?:number) {
    const productArray: UntypedFormArray | undefined = this.form.controls.commitmentProducts as UntypedFormArray;
    if (!productArray?.controls)
      return;

    const index = productArray.controls.findIndex(x => (x as UntypedFormGroup)?.controls?.productID?.value == productID);
    if (index >= 0) productArray.removeAt(index);
    productArray.markAllAsTouched();
    productArray.updateValueAndValidity();
  }

  // --------------------------------------------------------------------------
  public refreshTerritories(commitment:CommitmentWithDetailsModel, callBack?:Function):void {

    if (!commitment.customerID || !commitment.fundID) {
      this.territories$.next([]);
      if (callBack) callBack();
      return;
    }

    const territorySpec:TerritorySpec = new TerritorySpec(commitment.customerID, commitment.fundID, commitment.commitmentID ?? undefined);

    // if (Utilities.areEqualShallow(territorySpec, this._lastTerritorySpec)) {
    //   if (callBack) {
    //     this.territories$.pipe(first()).subscribe(x => {
    //       callBack(x.find(y => y.territoryID == commitment.territoryID), x);
    //     });
    //   }
    // } else {
      this._lastTerritorySpec = territorySpec;
      let params = new HttpParams();
      params = params.set('customerID', territorySpec.customerID!.toString());
      params = params.set('fundID', territorySpec.fundID!.toString());
      if (territorySpec.excludeCommitmentID) params = params.set('commitmentID', String(territorySpec.excludeCommitmentID));
      this.territoryForCommitmentWithBalanceService.getWithQuery(params.toString()).pipe(first()).subscribe(x => {
        this.territories$.next(x);
        if (callBack) callBack(x.find(y => y.territoryID == commitment.territoryID),x);
      });
    // }
  }

  // --------------------------------------------------------------------------
  private validateBalance =  ((control: AbstractControl):ValidationErrors | null => {

    return null;

    // const details = (this.form?.controls?.commitmentDetails as FormGroup);
    // if (!details) return null;

    // const cost = details.controls.amount.value ?? 0;
    // const territoryID = details.controls.territoryID.value;
    // if (!territoryID) return null;

    // const territory = this._territories?.find(x => x.territoryID == territoryID);
    // if (!territory) return null;

    // let balance = territory?.balance ?? 0;
    // if (balance <= 0 && territory?.primaryBusinessRoleID == BusinessRole.Am) {
    //   balance = Math.max.apply(0, this._territories?.filter(x => x.primaryBusinessRoleID == BusinessRole.Rsd).map(x => x.balance ?? 0) ?? []);
    // }
    // balance -= cost;

    // return balance < 0 ? { "balanceNegative": true } : null;

  }).bind(this);


  // --------------------------------------------------------------------------
  private validateProductSums = ((control: AbstractControl):ValidationErrors | null => {
    const productValues = (this.form?.controls?.commitmentProducts as UntypedFormArray)?.controls?.map((x:any) => x?.controls?.amount?.value ?? 0) ?? [0];
    const sum: number = productValues?.length ? productValues.reduce((a,v) => a + v) : 0;
    return this.validateSums(Math.round((sum * 100) + Number.EPSILON) / 100, control.errors);
  }).bind(this);

  // --------------------------------------------------------------------------
  private validatePhaseSums = ((control: AbstractControl):ValidationErrors | null => {
    let phasingTotal = 0;
    let phaseValues = [0];
    const phaseArray = (this.form?.controls?.commitmentPhases as UntypedFormArray);
    if (phaseArray?.length) {
      // for each phase (year)
      phaseArray.controls.forEach(phase => {
        // grab the amount from each month, sum, and then add to total
        phaseValues = ((phase as UntypedFormGroup).controls.amounts as UntypedFormArray).controls.map((x:any) => x?.value ?? 0) ?? [0];
        phasingTotal += phaseValues?.length ? phaseValues.reduce((a,v) => a + v) : 0;
      })
    }
    return this.validateSums(Math.round((phasingTotal * 100) + Number.EPSILON) / 100, control.errors);
  }).bind(this);

  // --------------------------------------------------------------------------
  private validateSums(sum:number,currentError:ValidationErrors | null):ValidationErrors | null {

    const errorKey: string =  "sumDoesNotMatchTotal";
    let err: ValidationErrors | null = null;

    const commitmentAmount: number = (this.form?.controls?.commitmentDetails as UntypedFormGroup)?.controls?.amount?.value ?? 0;

    if (commitmentAmount != sum)  {
      err = {};
      err[errorKey] = true;
    } else if (currentError?.length) {
      const clearedErr = {...currentError};
      delete clearedErr[errorKey];
      err = clearedErr;
    }

    return err;
  }

  // --------------------------------------------------------------------------
  protected updateForm(commitment:CommitmentWithDetailsModel, doValidate:boolean = true) {

    this.refreshTerritories(commitment, (x:TerritoryForDropdownWithCompositeNameModel | undefined) => {

      this.updateFormDetails(commitment, false);
      this.updateFormProducts(commitment, false);
      this.updateFormPhases(commitment, false);
      this.updateFormAttachments(commitment, false);

      if (doValidate) {
        this.form.markAllAsTouched();
        this.form.updateValueAndValidity();

      }

      this.onValidityChange();
    });

  }

   // --------------------------------------------------------------------------
  protected updateFormDetails(commitment:CommitmentWithDetailsModel, doValidate:boolean = true) {

    this.form.controls.commitmentDetails.patchValue({
      amount: commitment.amount,
      startDate: commitment.startDate,
      endDate: commitment.endDate,
      event: commitment.event,
      venue: commitment.venue,
      venueCity: commitment.venueCity,
      venueStateProvinceCodeID: commitment.venueStateProvinceCodeID,
      estimatedAttendeeCount: commitment.estimatedAttendeeCount,
      territoryID: commitment.territoryID,
      activityID: commitment.activityID,
      comment: commitment.comments?.length ? commitment.comments[0].commentBody : undefined
    });
    if (doValidate) {
      this.form.controls.commitmentDetails.markAllAsTouched();
      this.form.controls.commitmentDetails.updateValueAndValidity();
    }
  }

  // --------------------------------------------------------------------------
  protected updateFormProducts(commitment:CommitmentWithDetailsModel, doValidate:boolean = true) {

    if (!this.doesUseCommitmentProducts) return;

    const commitmentProductsFormArray = this.form.controls.commitmentProducts as UntypedFormArray;
    if (!commitmentProductsFormArray)
      return;

    if (!commitment) {
      commitmentProductsFormArray.reset();
      while(commitmentProductsFormArray.length !== 0) {
        commitmentProductsFormArray.removeAt(0);
      }
      return;
    }

    const products: CommitmentDetailProductModel[] = commitment?.products ?? [];

    let i = commitmentProductsFormArray.length;
    while (i--) {
      const productGroup = commitmentProductsFormArray.controls[i] as UntypedFormGroup;
      if (!products.some(x => x.productID == productGroup.controls?.productID?.value)) {
        commitmentProductsFormArray.removeAt(i);
      }
    }

    products.forEach(x => {
      const productGroup = commitmentProductsFormArray.controls.find((y:any) => y.controls?.productID?.value == x.productID) as UntypedFormGroup;
      if (productGroup) {
        productGroup.patchValue({
          productID: x.productID,
          amount: x.amount,
          percent: x.amount && this._commitment?.amount ? x.amount / this._commitment.amount * 100: 0
        });
      } else if (x.productID != null) {
        const fg = this.fb.group({
          productID: new UntypedFormControl(x.productID, [Validators.required]),
          amount: new UntypedFormControl(x.amount, [Validators.required]),
          percent: new UntypedFormControl(x.amount && this._commitment?.amount ? x.amount / this._commitment.amount * 100: 0),
        });
        commitmentProductsFormArray.push(fg);
      }
    });

    if(doValidate) {
      commitmentProductsFormArray.markAllAsTouched();
      commitmentProductsFormArray.updateValueAndValidity();
    }
  }

  // --------------------------------------------------------------------------
  protected updateFormPhases(commitment:CommitmentWithDetailsModel, doValidate:boolean = true) {



    const commitmentPhasesFormArray: UntypedFormArray | undefined = this.form.controls.commitmentPhases as UntypedFormArray;

    if (!commitmentPhasesFormArray) return;

    if (!commitment) {
      commitmentPhasesFormArray.reset();
      while(commitmentPhasesFormArray.length !== 0) {
        commitmentPhasesFormArray.removeAt(0);
      }
      return;
    }

    const phases: CommitmentDetailPhaseModel[] = commitment?.phases ?? [this.createDefaultPhase()];

    let i = commitmentPhasesFormArray.length;
    while (i--) {
      const phaseGroup = commitmentPhasesFormArray.controls[i] as UntypedFormGroup;
      if (!phases.some(x => x.yearIncrement == phaseGroup.controls?.yearIncrement?.value)) {
        commitmentPhasesFormArray.removeAt(i);
      }
    }

    phases.forEach(x => {
      const phaseGroup = commitmentPhasesFormArray.controls.find((y:any) => y.controls?.yearIncrement?.value == x.yearIncrement) as UntypedFormGroup;
      if (phaseGroup) {
        (phaseGroup.controls.amounts as UntypedFormArray).controls.forEach((y,i) => y.setValue(x.amounts ? x.amounts[i] ?? 0 : 0));
      } else if (x.yearIncrement != null) {
        const fg = this.fb.group({
          yearIncrement: new UntypedFormControl(x.yearIncrement, [Validators.required]),
          amounts: new UntypedFormArray(new Array(12).fill(0).map((y,i) => {
            return new UntypedFormControl(x.amounts?.length || 0 > i ? x!.amounts![i] : y);
          })),
        });
        commitmentPhasesFormArray.push(fg);
      }
    });

    if (doValidate) {
      commitmentPhasesFormArray.markAllAsTouched();
      commitmentPhasesFormArray.updateValueAndValidity();
      commitmentPhasesFormArray.controls.forEach(x => {
        const fa = ((x as UntypedFormGroup)?.controls?.amounts as UntypedFormArray);
        if (fa) {
          fa.markAllAsTouched();
          fa.updateValueAndValidity();
        }
      });
    }
  }

  // --------------------------------------------------------------------------
  protected updateFormAttachments(commitment:CommitmentWithDetailsModel, doValidate:boolean = true) {

    const commitmentAttachmentsFormArray: UntypedFormArray | undefined = this.form.controls.commitmentAttachments as UntypedFormArray;
    if (!commitmentAttachmentsFormArray) return;

    commitmentAttachmentsFormArray.reset();
    while(commitmentAttachmentsFormArray.length !== 0) {
      commitmentAttachmentsFormArray.removeAt(0);
    }

    const attachments: CommitmentDetailAttachmentModel[] = commitment?.attachments ?? [];
    if (!commitment || !attachments)
      return;

    attachments.forEach(x => {

      if (x.resourceID) {
        const fg = this.fb.group({
          resourceID: new UntypedFormControl(x.resourceID),
          displayName: new UntypedFormControl(x.displayName),
          createdByPerson: new UntypedFormControl(x.createdByPerson),
          createdByPersonID: new UntypedFormControl(x.createdByPersonID),
        });
        commitmentAttachmentsFormArray.push(fg);
      }
    });

    if (doValidate) {
      commitmentAttachmentsFormArray.markAllAsTouched();
      commitmentAttachmentsFormArray.updateValueAndValidity();
    }
  }

  // --------------------------------------------------------------------------
  public createDraftCommitmentFromCurrent(name?: string, description?: string, draftID?: string) : Promise<DraftWithDetailsModel|null|undefined> {
    return this._commitment ? this.createDraftCommitment(this._commitment, name, description, draftID) : Promise.resolve(null);
  }

  // --------------------------------------------------------------------------
  private onValidityChange() {

    const validity = {
      form: this.form.valid,
      details: this.form.controls.commitmentDetails.valid,
      products: !this.doesUseCommitmentProducts || this.form.controls.commitmentProducts.valid,
      phases: this.form.controls.commitmentPhases.valid,
      customer: Boolean(this._commitment?.customerID),
      payee: Boolean(this._commitment?.payeeCustomerID),
      fund: Boolean(this._commitment?.fundID)
    };
    this.validityChange$.next(validity);
  }


}
