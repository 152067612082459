import { TransactionHistorySearchRequestModel } from '@alcon-db-models/TransactionHistorySearchRequestModel';
import { ChangeDetectorRef, Directive, OnDestroy, OnInit } from '@angular/core';
import { GridComponent, GridDataResult } from '@progress/kendo-angular-grid';
import { FilterDescriptor } from '@progress/kendo-data-query';
import { TransactionHistorySearchService } from '@services/transaction-history-search.service';
import { UserPreferencesService } from '@services/user-preferences.service';
import { Subscription } from 'rxjs';
import { UserPreferenceEnabledDataBindingDirective } from './user-preference-enabled-data-binding.directive';

@Directive({
  selector: '[transactionHistorySearchBindingDirective]'
})
export class TransactionHistorySearchBindingDirective extends UserPreferenceEnabledDataBindingDirective implements OnInit, OnDestroy {

  protected preferenceKey: string = "TransactionHistory";

  private serviceSubscription?: Subscription;

  constructor(
    grid: GridComponent,
    userPreferencesService: UserPreferencesService,
    changeDetectorRef: ChangeDetectorRef,
    private transactionHistorySearchService: TransactionHistorySearchService
    ) {
    super(grid, userPreferencesService, changeDetectorRef);
  }

  public ngOnInit(): void {

    this.transactionHistorySearchService.next([] as any as GridDataResult);

    this.serviceSubscription = this.transactionHistorySearchService.subscribe((result) => {
      this.grid.loading = false;
      this.grid.data = result;
      this.notifyDataChange();
      this.grid.loading = false;
    });

    super.ngOnInit();
    this.rebind();
  }

  public ngOnDestroy(): void {
    if (this.serviceSubscription) {
        this.serviceSubscription.unsubscribe();
    }
    super.ngOnDestroy();
  }

  public rebind(): void {
    this.grid.loading = true;
    this.transactionHistorySearchService.query(this.getSearchRequest());
  }

  public getSearchRequest(doIgnorePagination: boolean = false): TransactionHistorySearchRequestModel {

    const filters: FilterDescriptor[] = this.state.filter?.filters as FilterDescriptor[];

    const getValue = function(col:string) {
      let descriptor: FilterDescriptor;
      return (descriptor = filters?.find(x => x.field == col) as FilterDescriptor) ? descriptor?.value : null
    };

    return ({
      personID: null,
      take: doIgnorePagination ? null : this.state.take,
      skip: doIgnorePagination ? 0 : this.state.skip,
      sortColumn: this.state.sort?.[0]?.dir ? this.state.sort?.[0]?.field : null,
      sortAscending: this.state.sort?.[0]?.dir ? this.state.sort[0].dir != 'desc' : null,
      fundYear: getValue('fundYear'),
      fundID: getValue('fundID'),
      maxTransactionDate: getValue('maxTransactionDate'),
      minTransactionDate: getValue('minTransactionDate'),
      minAmount: getValue('minAmount'),
      maxAmount: getValue('maxAmount'),
      transactionTypeIDs: getValue('transactionTypeIDs')
    });
  }
}

