import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommitmentWithDetailsModel } from '@alcon-db-models/CommitmentWithDetailsModel'
import { BehaviorSubject, Observable } from 'rxjs';
import { CommitmentWithDetailsService } from '@services/commitment-with-details.service';
import { first } from 'rxjs/operators';
import { WindowComponent } from '@progress/kendo-angular-dialog';
import { ViewSubjectWindow } from 'src/app/components/components.module';
import { Store } from '@ngrx/store';
import { selectBusinessRules } from '@app-store/app-session/app-session.selectors';

@Component({
  selector: 'acb-alcon-view-commitment-window',
  template: `
    <ng-container *ngIf="commitment$ | async; let commitmentData">
       <div [hidden]="isLoading" style="padding: 1em;"  [ngClass.xs]="'acb-tiny'">

        <acb-alcon-view-commitment-details [commitment]="commitmentData"></acb-alcon-view-commitment-details>
        <acb-alcon-view-commitment-organizations [commitmentOrganizations]="commitmentData"></acb-alcon-view-commitment-organizations>

        <!-- TODO: We really should us a template here... -->

        <ng-container *ngIf="doesUseCommitmentProducts">
          <div fxLayout="row wrap"> 
            <acb-alcon-view-commitment-phasing [commitment]="commitmentData" fxFlex="0 0 auto"></acb-alcon-view-commitment-phasing>
            <acb-alcon-section-with-legend
              class="acb-products-section acb-section-11"
              #section
              [sectionTitle]="'Products'"
              style="width: 100%; height: 100%;"
              [isCollapsible]="true"
              fxFlex="0 0 calc(50% - .5em)"
              fxFlex.xs="0 0 100%"
              >
              <acb-alcon-view-commitment-products [commitment]="commitmentData"></acb-alcon-view-commitment-products>
            </acb-alcon-section-with-legend>
            <acb-alcon-section-with-legend
              class="acb-attachments-section acb-section-06"
              #section
              [sectionTitle]="'Attachments'"
              style="width: 100%; height: 100%;"
              [isCollapsible]="true"
              fxFlex="0 0 calc(50% - .5em)"
              fxFlex.xs="0 0 100%"
              >
              <acb-alcon-view-commitment-attachments [commitment]="commitmentData" [doShowFieldLabel]="false"></acb-alcon-view-commitment-attachments>
            </acb-alcon-section-with-legend>
          </div>
        </ng-container>

        <ng-container *ngIf="!doesUseCommitmentProducts">
          <div fxLayout="row wrap" fxLayoutAlign="start start">
            <acb-alcon-view-commitment-phasing 
              [commitment]="commitmentData"
              fxFlex="0 0 60%"
              fxFlex.xs="0 0 100%"
              ></acb-alcon-view-commitment-phasing>
            <acb-alcon-section-with-legend
              class="acb-attachments-section acb-no-products acb-section-06"
              #section
              [sectionTitle]="'Attachments'"
              style="width: 100%; height: 100%;"
              [isCollapsible]="true"
              fxFlex="0 0 calc(40% - 1em)"
              fxFlex.xs="0 0 100%"
              fxFlexOffset.xs="0"
              >
              <acb-alcon-view-commitment-attachments [commitment]="commitmentData" [doShowFieldLabel]="false"></acb-alcon-view-commitment-attachments>
            </acb-alcon-section-with-legend>
          </div>
        </ng-container>

        <div class="acb-outer-section">
          <acb-alcon-view-commitment-review-history [commitment]="commitmentData"></acb-alcon-view-commitment-review-history>
        </div>

      </div>
    </ng-container>
    <div [hidden]="!(loading$ | async)" class="acb-local-loading-indicator">
      <span class="k-icon k-i-loading"></span>
    </div>
  `,
  styleUrls: ['./view-commitment-window.component.scss']
})
export class ViewCommitmentWindowComponent implements OnInit, AfterViewInit, ViewSubjectWindow  {

  @Output() loading: EventEmitter<boolean> = new EventEmitter();
  @Input() windowComponent?: WindowComponent;
  @Input() isOpen: boolean = false;
  @Input() public set commitmentID(value: number | undefined) {
    this.commitment$ = this.commitmentWithDetailsService.getByKey(value);
  };

  @Output() closed: EventEmitter<any> = new EventEmitter();

  doesUseCommitmentActivities: boolean = false;
  commitmentActivitiesRequired: boolean = false;
  doesUseCommitmentProducts: boolean = false;
  commitmentProductsRequired: boolean = false;
  areCommitmentActivityRulesDeterminedByFund: boolean = false;

  public commitment$: Observable<CommitmentWithDetailsModel> = new Observable;
  public loading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  public isLoading:boolean = false;

  constructor(
    store: Store,
    private commitmentWithDetailsService: CommitmentWithDetailsService
    ) {

      store.select(selectBusinessRules).pipe(first()).subscribe(x => {
        this.doesUseCommitmentActivities = x.DoesUseCommitmentActivities;
        this.commitmentActivitiesRequired = x.DoesUseCommitmentActivities && x.CommitmentActivitiesRequired;
        this.doesUseCommitmentProducts = x.DoesUseCommitmentProducts;
        this.commitmentProductsRequired = x.DoesUseCommitmentProducts && x.CommitmentProductsRequired;
        this.areCommitmentActivityRulesDeterminedByFund = x.AreCommitmentActivityRulesDeterminedByFund;
      })

    this.commitmentWithDetailsService.loading$.subscribe(x => {
      this.loading.emit(this.isLoading = x);
      this.loading$.next(x);
    });

  }

  ngOnInit(): void {
  }

  public ngAfterViewInit() {
  }

  public closeWindow() {
    this.isOpen = false;
    this.closed.emit(null);
  }

  public openWindow() {
    this.isOpen = true;
  }


}
