import { EntityMetadataMap, EntityDataModuleConfig } from '@ngrx/data';
import { FundAllocatedModel } from '@alcon-db-models/FundAllocatedModel'
import { TerritoryWithBalanceModel } from '@alcon-db-models/TerritoryWithBalanceModel'
import { FundForDropdownModel } from '@alcon-db-models/FundForDropdownModel'
import { TerritoryForDropdownModel } from '@alcon-db-models/TerritoryForDropdownModel'
import { CommitmentWithDetailsModel } from '@alcon-db-models/CommitmentWithDetailsModel';
import { ClaimWithDetailsModel } from '@alcon-db-models/ClaimWithDetailsModel';
import { CustomerWithLocationModel } from '@alcon-db-models/CustomerWithLocationModel';
import { ActivityForDropdownModel } from '@alcon-db-models/ActivityForDropdownModel';
import { UserInfoModel } from '@alcon-db-models/UserInfoModel'
import { CustomerInfoModel } from '@alcon-db-models/CustomerInfoModel';
import { PersonModel } from '@alcon-db-models/PersonModel';
import { PersonWithRolesModel } from '@alcon-db-models/PersonWithRolesModel';
import { DashboardForDropdownModel } from '@alcon-db-models/DashboardForDropdownModel';
import { DashboardItemForDashboardModel } from '@alcon-db-models/DashboardItemForDashboardModel';
import { PayTypeForDropdownModel } from '@alcon-db-models/PayTypeForDropdownModel';
import { ProductForDropdownModel } from '@alcon-db-models/ProductForDropdownModel';
import { ClaimReviewModel } from '@alcon-db-models/ClaimReviewModel';
import { ClaimDuplicateModel } from '@alcon-db-models/ClaimDuplicateModel';
import { CustomerWithDetailsModel } from '@alcon-db-models/CustomerWithDetailsModel';
import { CustomerForDropdownModel } from '@alcon-db-models/CustomerForDropdownModel';
import { HierarchyCompareModel } from '@alcon-db-models/HierarchyCompareModel';
import { TerritoryForCommitmentWithBalanceModel } from '@alcon-db-models/TerritoryForCommitmentWithBalanceModel';
import { FundWithDetailsModel } from '@alcon-db-models/FundWithDetailsModel';
import { FundGroupForDropdownModel } from '@alcon-db-models/FundGroupForDropdownModel';
import { ClaimDetailAttachmentModel } from '@alcon-db-models/ClaimDetailAttachmentModel';
import { AuditInstructionWithDetailsModel } from '@alcon-db-models/AuditInstructionWithDetailsModel';
import { DashboardItemForDropdownModel } from '@alcon-db-models/DashboardItemForDropdownModel';


const entityMetadata: EntityMetadataMap = {
  FundAllocated: { selectId: (m: FundAllocatedModel) => m.fundID!.toString() },
  TerritoryWithBalance: { selectId: (m: TerritoryWithBalanceModel) => m.territoryID!.toString() },
  TerritoryForCommitmentWithBalance: { selectId: (m: TerritoryForCommitmentWithBalanceModel) => m.territoryID!.toString() },
  FundForDropdown: { selectId: (m: FundForDropdownModel) => m.fundID!.toString() },
  TerritoryForDropdown: { selectId: (m: TerritoryForDropdownModel) => m.territoryID!.toString() },
  CustomerForDropdown: { selectId: (m: CustomerForDropdownModel) => m.customerID!.toString() },
  ProductForDropdown: { selectId: (m: ProductForDropdownModel) => m.productID!.toString() },
  CommitmentWithDetails: { selectId: (m: CommitmentWithDetailsModel) => m.commitmentID!.toString() },
  CustomerWithLocation: { selectId: (m: CustomerWithLocationModel) => m.customerID!.toString() },
  ClaimWithDetails: { selectId: (m: ClaimWithDetailsModel) => m.claimID!.toString() },
  ActivityForDropdown: { selectId: (m: ActivityForDropdownModel) => m.activityID!.toString() },
  FundGroupForDropdown: { selectId: (m: FundGroupForDropdownModel) => m.fundGroupID!.toString() },
  UserInfo: { selectId: (m: UserInfoModel) => m.applicationUserID!.toString() },
  CustomerInfo: { selectId: (m: CustomerInfoModel) => m.customerID!.toString() },
  Person:  { selectId: (m: PersonModel) => m.personID!.toString() },
  PersonWithRoles:  { selectId: (m: PersonWithRolesModel) => m.personID!.toString() },
  DashboardForDropdown: { selectId: (m: DashboardForDropdownModel) => m.dashboardID!.toString() },
  DashboardItemForDashboard: { selectId: (m: DashboardItemForDashboardModel) => m.dashboardDashboardItemID!.toString() },
  DashboardItemForDropdown: { selectId: (m: DashboardItemForDropdownModel) => m.dashboardItemID!.toString() },
  PayTypeForDropdown: { selectId: (m: PayTypeForDropdownModel) => m.payTypeID!.toString() },
  // TODO: ^ update above to use String(m?.[ID]) ?? undefined
  ClaimReview:  { selectId: (m: ClaimReviewModel) => String(m?.claimID) ?? undefined },
  ClaimDuplicate:  { selectId: (m: ClaimDuplicateModel) => String(m?.claimID) ?? undefined },
  CustomerWithDetails: { selectId: (m: CustomerWithDetailsModel) => String(m?.customerID) ?? undefined },
  FundWithDetails: { selectId: (m: FundWithDetailsModel) => String(m?.fundID) ?? undefined },
  ClaimDetailAttachment: { selectId: (m: ClaimDetailAttachmentModel) => String(m?.code) ?? undefined },
  AuditInstructionWithDetails: { selectId: (m: AuditInstructionWithDetailsModel) => String(m?.auditInstructionID) ?? undefined },
  // HierarchyCompare: { selectId: (m: HierarchyCompareModel) => String(m?.id) ?? undefined },
};

const pluralNames = {
    Fund: 'Funds',
    FundAllocated: 'FundsAllocated',
    TerritoryWithBalance: 'TerritoriesWithBalance',
    TerritoryForCommitmentWithBalance: 'TerritoriesForCommitmentWithBalance',
    FundForDropdown: 'FundsForDropdown',
    TerritoryForDropdown: 'TerritoriesForDropdown',
    CustomerForDropdown: 'CustomersForDropdown',
    ProductForDropdown: 'ProductsForDropdown',
    ActivityForDropdown: 'ActivitiesForDropdown',
    FundGroupForDropdown: 'FundGroupsForDropdown',
    PayTypeForDropdown: 'PayTypesForDropdown',
    DashboardForDropdown: 'DashboardsForDropdown',
    DashboardItemForDashboard: 'DashboardItemsForDashboard',
    DashboardItemForDropdown: 'DashboardItemsForDropdown',
    CommitmentWithDetails: 'CommitmentsWithDetails',
    ClaimReview: 'ClaimReviews',
    ClaimDuplicate: 'ClaimDuplicates',
    ClaimDetailAttachment: 'ClaimDetailAttachments'
    // HierarchyCompare: 'HierarchyCompares'
  };

export const entityConfig: EntityDataModuleConfig = {
  entityMetadata,
  pluralNames: pluralNames
};
