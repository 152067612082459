import { Directive, ElementRef } from '@angular/core';

@Directive({
  selector: '[acbAlconDefaultField]'
})
export class DefaultFieldDirective {

  constructor(el: ElementRef) {
  }

}
