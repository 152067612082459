import { ThirdPartySearchRequestModel } from '@alcon-db-models/ThirdPartySearchRequestModel';
import { Directive, OnDestroy, OnInit } from '@angular/core';
import { DataBindingDirective, GridComponent } from '@progress/kendo-angular-grid';
import { FilterDescriptor } from '@progress/kendo-data-query';
import { ThirdPartySearchService } from '@services/third-party-search.service';
import { Subscription } from 'rxjs';

@Directive({
  selector: '[thirdPartySearchBindingDirective]'
})
export class ThirdPartySearchBindingDirective extends DataBindingDirective implements OnInit, OnDestroy {

  private serviceSubscription?: Subscription;

  constructor(private thirdPartySearchService: ThirdPartySearchService, grid: GridComponent) {
    super(grid);
  }

  public ngOnInit(): void {
    this.serviceSubscription = this.thirdPartySearchService.subscribe((result) => {
      this.grid.loading = false;
      this.grid.data = result;
      this.notifyDataChange();
      this.grid.loading = false;
    });

    super.ngOnInit();
  }

  public ngOnDestroy(): void {
    if (this.serviceSubscription) {
        this.serviceSubscription.unsubscribe();
    }
    super.ngOnDestroy();
  }

  public rebind(): void {
    this.grid.loading = true;
    this.thirdPartySearchService.query(this.getSearchRequest());
  }

  public getSearchRequest(doIgnorePagination: boolean = false): ThirdPartySearchRequestModel {

    const filters: FilterDescriptor[] = this.state.filter?.filters as FilterDescriptor[];

    const getValue = function(col:string) {
      let descriptor: FilterDescriptor;
      return (descriptor = filters?.find(x => x.field == col) as FilterDescriptor) ? descriptor?.value : null
    };

    return ({
      personID: null,
      take: doIgnorePagination ? null : this.state.take,
      skip: doIgnorePagination ? 0 : this.state.skip,
      sortColumn: this.state.sort?.[0]?.dir ? this.state.sort?.[0]?.field : null,
      sortAscending: this.state.sort?.[0]?.dir ? this.state.sort[0].dir != 'desc' : null,
      displayName: getValue('displayName'),
      taxCode: getValue('taxCode'),
      city: getValue('city'),
      state: getValue('state'),
    });
  }
}

