import { CommitmentWithDetailsModel } from '@alcon-db-models/CommitmentWithDetailsModel';
import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { DialogService } from '@progress/kendo-angular-dialog';
import { CustomerWithLocationService } from '@services/customer-with-location.service';
import { BehaviorSubject, Subject } from 'rxjs';
import { debounceTime, first, take, takeUntil } from 'rxjs/operators';
import { CommitmentUpsertServiceService } from '@services/commitment-upsert-service.service'
import { AppWindowService } from '@services/app-window.service';
import { Utilities } from 'src/app/shared/utilities';
import { CommitmentEditService } from '@services/commitment-edit.service';
import { selectCurrentPerson } from '@app-store/app-session/app-session.selectors';
import { WindowNotification } from '../window-notifications/window-notifications.component';
import { UntypedFormGroup } from '@angular/forms';
import { StatusCode } from '@alcon-db-models/Enums';
import { AttachementModel } from '@alcon-db-models/AttachementModel';
import { TerritoryForCommitmentWithBalanceModel } from '@alcon-db-models/TerritoryForCommitmentWithBalanceModel';

@Component({
  selector: 'acb-alcon-edit-commitment-host',
  template: `
    <div fxLayout="column" style="height: 100%">
      <acb-alcon-window-notifications [windowNotifications]="notifications">
      </acb-alcon-window-notifications>
      <div style="overflow:auto; height: 100%; padding: 2em">
        <acb-alcon-edit-commitment
          (selectCustomer)="onEditCustomer()"
          (selectPayee)="onEditPayee()"
          (newPayee)="onNewPayee()"
          (selectFund)="onEditFund()"
          (editDetails)="onEditDetails()"
          (editProducts)="onEditProducts()"
          (editPhasing)="onEditPhasing()"
          (editAttachments)="onEditAttachments()"
        ></acb-alcon-edit-commitment>
      </div>
    </div>
  `,
  styleUrls: ['./edit-commitment-host.component.scss']
})
export class EditCommitmentHostComponent implements OnInit, OnDestroy {

  public commitment?: CommitmentWithDetailsModel;
  public destroy$: Subject<void> = new Subject<void>();

  public isFundingChangePending: boolean = false;

  private _originalCost:number = 0;
  private _originalFundID?:number;
  private personID?:number;
  private territories: TerritoryForCommitmentWithBalanceModel[] = [];
  private _attachements: AttachementModel[] = [];

  public notifications:WindowNotification[] = [];

  constructor(
    private store: Store,
    private route: ActivatedRoute,
    private customerWithLocationService: CustomerWithLocationService,
    private commitmentUpsertServiceService: CommitmentUpsertServiceService,
    private dialogService: DialogService,
    private appWindowService : AppWindowService,
    private commitmentEditService: CommitmentEditService,
    private changeDetectionRef: ChangeDetectorRef
  ) {

    this.store.select(selectCurrentPerson).pipe(first()).subscribe(x => {
      this.personID = x?.personID ?? undefined;
    })

    this.commitmentEditService.territories$.pipe(takeUntil(this.destroy$)).subscribe(x => {
      this.territories = [...x];
    });

    // We're assuming that the subject won't change
    this.commitmentEditService.commitment$.pipe(first()).subscribe(x => {
      this._originalCost = this._originalCost ? this._originalCost : x?.amount ?? 0;
      this._originalFundID = x.fundID ?? undefined;
    })

    this.commitmentEditService.commitment$.pipe(takeUntil(this.destroy$)).subscribe(x => {
      this.commitment = x;
      this._attachements = [...x.attachments ?? []];
    })

    this.commitmentEditService.validityChange$.pipe(takeUntil(this.destroy$),debounceTime(100)).subscribe(x => {

      this.commitmentEditService.updateExceptions();

      let wn:WindowNotification[] = []
      if (!x.details) {
        const commitmentDetails = (this.commitmentEditService.form.controls.commitmentDetails as UntypedFormGroup);
        if (commitmentDetails) {
          if (commitmentDetails.errors?.datesNotConsecutive) {
            wn.push({ type:'error', isVisible:true, message:'End Date must follow Start Date.'});
          }
          if (commitmentDetails.errors?.notSameYear) {
            wn.push({ type:'error', isVisible:true, message:'Start Date and End Date years must match.'});
          }
          if (commitmentDetails.controls?.territoryID?.errors?.required && x.fund && x.customer) {
            wn.push({ type:'error', isVisible:true, message:'Territory selection required.'});
          }
          if (commitmentDetails.controls?.activityID?.errors?.required && x.fund && x.customer) {
            wn.push({ type:'error', isVisible:true, message:'Activity selection required.'});
          }

        }
      }
      if (!x.phases) {
        wn.push({ type:'error', isVisible:true, message:'Phasing setup insufficient. Please project entire commitment cost.'});
      }
      if (!x.products) {
        wn.push({ type:'error', isVisible:true, message:'Product setup insufficient. Please distribute entire commitment cost over one or more products.'});
      }
      if (!x.customer) {
        wn.push({ type:'error', isVisible:true, message:'Customer selection required.'});
      }
      if (!x.payee) {
        wn.push({ type:'error', isVisible:true, message:'Payee selection required.'});
      }
      if (!x.fund) {
        wn.push({ type:'error', isVisible:true, message:'Fund selection required.'});
      }
      if (this.commitment?.hasException) {
        wn.push({ type:'error', isVisible:true, message: this.commitment.exception ?? "Unknown commitment exception." })
      }
      wn = this.updateReapprovalWarningNotification(wn);
      wn = this.updateMismatchedFundActivityYearNotification(wn);
      this.notifications = wn
    });
    this.commitmentEditService.form.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(x => {
      let wn:WindowNotification[] = this.notifications;
      wn = this.updateReapprovalWarningNotification(wn);
      wn = this.updateMismatchedFundActivityYearNotification(wn);
      this.notifications = wn
    })
  }

  private updateReapprovalWarningNotification(wn?:WindowNotification[]): WindowNotification[] {
    const msg = 'Cost adjusted or Fund changed. Commitment may reset to requested status when saved and require reapproval.';
    wn = [...(wn ?? this.notifications).filter(x => x.message != msg)];
    const commitmentDetails = (this.commitmentEditService.form.controls.commitmentDetails as UntypedFormGroup);
    if (
      commitmentDetails &&
      this.commitment &&
      this.commitment.statusCodeID == StatusCode.Approved &&
      ((commitmentDetails.controls.amount?.value != this._originalCost) || (this.commitment.fundID != this._originalFundID))
    ) {
      wn.push({ type:'warning', isVisible:true, message:msg});
      this.isFundingChangePending = true;
    } else {
      this.isFundingChangePending = false;
    }
    return wn;
  }

  private updateMismatchedFundActivityYearNotification(wn?:WindowNotification[]): WindowNotification[] {
    const msg = 'Fund year does not match activity year.';
    wn = [...(wn ?? this.notifications).filter(x => x.message != msg)];
    const commitmentDetails = (this.commitmentEditService.form.controls.commitmentDetails as UntypedFormGroup);
    if (commitmentDetails && this.commitment && this.commitment.fundYear && commitmentDetails.controls.startDate?.value?.getFullYear) {
      const startYear = commitmentDetails.controls.startDate.value.getFullYear();
      if (startYear > this.commitment.fundYear || (!this.commitment.fundIsPostAudit && startYear < this.commitment.fundYear))
        wn.push({ type:'warning', isVisible:true, message:msg});
    }
    return wn;
  }

  public onEditCustomer() {
    const dialogSpec = this.appWindowService.openSelectCustomer(this.commitment?.customerID);
    dialogSpec?.component?.cancel?.pipe(take(1)).subscribe(() => dialogSpec?.dialogRef.close());
    dialogSpec?.component?.select?.pipe(take(1)).subscribe(x => {
      dialogSpec?.dialogRef.close();
      if (x)
        this.commitmentEditService.updateCustomer(x);
    });

  }

  public onEditPayee() {
    if (!this.commitment)
      return;
    const dialogSpec = this.appWindowService.openSelectPayee(this.commitment);
    dialogSpec?.component?.cancel?.pipe(take(1)).subscribe(() => dialogSpec?.dialogRef.close());
    dialogSpec?.component?.payeeSelected?.pipe(take(1)).subscribe(x => {
      dialogSpec?.dialogRef.close();
      if (x)
        this.commitmentEditService.updatePayee(x);
    });
  }

  public onNewPayee() {
    const dialogSpec = this.appWindowService.openCreatePayee("thirdparty");
    dialogSpec?.component?.cancel?.pipe(take(1)).subscribe(() => dialogSpec?.dialogRef.close());
    dialogSpec?.component?.saved?.pipe(take(1)).subscribe(x => {
      dialogSpec?.dialogRef.close();
      if (x)
        this.commitmentEditService.updatePayee(x);
    });
  }

  public onEditFund() {

    let fundYears: number[] | undefined = undefined;
    if (this.commitment?.startDate?.getFullYear)
      fundYears = [this.commitment.startDate.getFullYear() - 1, this.commitment.startDate.getFullYear()];

    const dialogSpec = this.appWindowService.openSelectFund(this.commitment?.customerID ?? undefined, this.commitment?.fundID, undefined, fundYears);

    dialogSpec?.component?.cancel?.pipe(take(1)).subscribe(() => dialogSpec?.dialogRef.close());
    dialogSpec?.component?.select?.pipe(take(1)).subscribe(x => {
      if (x) {
        this.commitmentEditService.updateFund(x, this.personID);
      }
      dialogSpec?.dialogRef.close();
    });
  }

  public onEditDetails() {
    const dialogSpec = this.appWindowService.openEditCommitmentDetails();
    const component = dialogSpec?.component;
    const dialogRef = dialogSpec?.dialogRef;
    const commitment = this.commitment ?
      new BehaviorSubject(Utilities.deepCopyObject(this.commitment)) :
      undefined;

    if (dialogSpec?.component)
      dialogSpec.component.commitmentEditService = this.commitmentEditService;

    if (component && dialogRef) {
      component.cancel.pipe(take(1)).subscribe(x => {
        this.commitmentEditService.resetForm();
        dialogRef.close();
      })
      component.apply.pipe(take(1)).subscribe(x => {
        this.commitmentEditService.applyDetailsFromForm();
        dialogRef.close();
      })
    }
  }

  public onEditProducts() {
    const dialogSpec = this.appWindowService.openEditCommitmentProducts();
    const component = dialogSpec?.component;
    const dialogRef = dialogSpec?.dialogRef;

    if (component && dialogRef) {
      component.commitmentEditService = this.commitmentEditService;
      component.cancel.pipe(take(1)).subscribe(x => {
        this.commitmentEditService.resetForm();
        dialogRef.close();
      })
      component.apply.pipe(take(1)).subscribe(x => {
        this.commitmentEditService.applyProductsFromForm();
        dialogRef.close();
      })
    }
  }

  public onEditPhasing() {
    const dialogSpec = this.appWindowService.openEditCommitmentPhasing();
    const component = dialogSpec?.component;
    const dialogRef = dialogSpec?.dialogRef;

    if (component && dialogRef) {
      component.commitmentEditService = this.commitmentEditService;
      component.cancel.pipe(take(1)).subscribe(x => {
        this.commitmentEditService.resetForm();
        dialogRef.close();
      })
      component.apply.pipe(take(1)).subscribe(x => {
        this.commitmentEditService.applyPhasesFromForm();
        dialogRef.close();
      })
    }
  }

  public onEditAttachments() {
    const dialogSpec = this.appWindowService.openSelectCommitmentAttachments();
    const component = dialogSpec?.component;
    const dialogRef = dialogSpec?.dialogRef;

    if (component && dialogRef) {
      component.attachments = [...this._attachements];

      component.subjectAttachmentPath =  this.commitment?.code ? './UserUploads/commitments/' + window.encodeURIComponent(this.commitment?.code) : undefined;
      component.canRemoveExistingAttachments = true;

      component.cancel.pipe(take(1)).subscribe(x => {
        dialogRef.close();
      })
      component.apply.pipe(take(1)).subscribe(x => {
        this.commitmentEditService.updateAttachments(x);
        dialogRef.close();
      });
    }
  }

  ngOnInit(): void {
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
