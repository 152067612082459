import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable, of, throwError } from 'rxjs';
import { AuthenticationService } from '@data-services/authentication.service';
import { Store } from '@ngrx/store';
import { first, switchMap, catchError, tap, filter, timeout } from 'rxjs/operators';
import { combineLatest } from 'rxjs';
import { selectDoKeepMeLoggedIn, selectMemoryOnlyRefreshToken } from '@app-store/app-session/app-session.selectors';
import { selectAccessTokenInfo } from '@app-store/app-persisted-settings/app-persisted-settings.selectors';

@Injectable({ providedIn: 'root' })
export class AuthenticationGuard implements CanActivate {

  constructor(
    private router: Router,
    private authenticationService: AuthenticationService,
    private store: Store
  ) { }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

      const url = ((next as any)?._routerState.url as string);

      const selectMemoryOnlyRefreshToken$ = this.store.select(selectMemoryOnlyRefreshToken).pipe(filter(x => Boolean(x)));
      const selectDoKeepMeLoggedIn$ = this.store.select(selectDoKeepMeLoggedIn).pipe(filter(x => x != null));
      const selectAccessTokenInfo$ = this.store.select(selectAccessTokenInfo).pipe(filter(x => Boolean(x)));
      let _doKeepMeLoggedIn: boolean = false;

      return combineLatest([selectAccessTokenInfo$, selectMemoryOnlyRefreshToken$,selectDoKeepMeLoggedIn$]).pipe(
        timeout(500),
        first(),
        switchMap(([tokenInfo, memoryOnlyRefreshToken, doKeepMeLoggedIn]) => {
          _doKeepMeLoggedIn = doKeepMeLoggedIn;
          const token = this.authenticationService.checkToken(tokenInfo) ? tokenInfo?.token : null;
          if (token) {
            return of(true);
          }
          return this.authenticationService.refreshTokenWith(doKeepMeLoggedIn, memoryOnlyRefreshToken);
        }),
        catchError(e => {
          return e.name == 'TimeoutError' ? this.authenticationService.refreshTokenWith(_doKeepMeLoggedIn, null) : throwError(e);
        }),
        catchError(e => {
          return of(false);
        }),
        tap(x => {
          if (!x) {
            this.authenticationService.redirectUrl = url;
            this.router.navigate(['./logout'])
          }
        })
      )
  }
}




