import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule, RouterOutlet } from '@angular/router';

import { SkeletonLayoutComponent } from './skeleton-layout/skeleton-layout.component';
import { ContentLayoutComponent } from './content-layout/content-layout.component';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MenuModule } from '@progress/kendo-angular-menu';

import { ComponentsModule } from './../components/components.module'

export { SkeletonLayoutComponent, ContentLayoutComponent }
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

@NgModule({
  declarations: [ SkeletonLayoutComponent, ContentLayoutComponent ],
  imports: [
    CommonModule,
    RouterModule,
    MenuModule,
    BrowserAnimationsModule,
    ComponentsModule,
    FontAwesomeModule
  ],
  exports: [
    ContentLayoutComponent,
    SkeletonLayoutComponent
  ]
})
export class LayoutModule { }
