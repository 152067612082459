
import { Injectable } from '@angular/core';
import { EntityCollectionServiceBase, EntityCollectionServiceElementsFactory } from '@ngrx/data';
import { TerritoryForCommitmentWithBalanceModel } from '@alcon-db-models/TerritoryForCommitmentWithBalanceModel'

@Injectable({
  providedIn: 'root'
})
export class TerritoryForCommitmentWithBalanceService extends EntityCollectionServiceBase<TerritoryForCommitmentWithBalanceModel> {
  constructor(serviceElementsFactory: EntityCollectionServiceElementsFactory) {
    super('TerritoryForCommitmentWithBalance', serviceElementsFactory);
  }
}
