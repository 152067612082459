import { PersonWithRolesModel } from '@alcon-db-models/PersonWithRolesModel';
import { Injectable } from '@angular/core';
import { EntityCollectionServiceBase, EntityCollectionServiceElementsFactory } from '@ngrx/data';

@Injectable({
  providedIn: 'root'
})
export class PersonWithRolesService extends EntityCollectionServiceBase<PersonWithRolesModel> {
  constructor(serviceElementsFactory: EntityCollectionServiceElementsFactory) {
    super('PersonWithRoles', serviceElementsFactory);
  }
}


