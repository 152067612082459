import { ClaimWithDetailsModel } from '@alcon-db-models/ClaimWithDetailsModel';
import { SourceType } from '@alcon-db-models/Enums';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { GridComponent } from '@progress/kendo-angular-grid';
import { SortDescriptor } from '@progress/kendo-data-query';
import { CommitmentWithDetailsService } from '@services/commitment-with-details.service';
import { CompactMode, ViewMode } from '../components.module';
import { ViewAttachmentsBaseComponent } from '../view-attachments.base.component';

@Component({
  selector: 'acb-alcon-view-claim-attachments',
  template: `
    <label class='acb-alcon-display-item-label' *ngIf="doShowFieldLabel">Attachments</label>
    <kendo-grid
      *ngIf="gridView$ | async as gridViewData"
      #statusTrackingGrid
      class="acb-results-grid acb-display-grid"
      [data]= "gridViewData"
      kendoGridSelectBy="claimDetailAttachmentID"
      [pageable]="false"
      [sortable]="viewMode != 'edit'"
      [reorderable]="false"
      [selectable] = "false"
      [resizable]="true"
      [sort]="sort"
      (sortChange)="onSortChange($event)"
      >
      <kendo-grid-checkbox-column
        [width]="30"
        [reorderable]="false"
        [resizable]="false"
        *ngIf="viewMode == 'edit'"
        [headerClass]="'acb-grid-command-column-header'"
        [class]="'acb-grid-command-column'"
        [showSelectAll]="true"
        >
        <ng-template kendoGridHeaderTemplate>
          <input class="k-checkbox" kendoGridSelectAllCheckbox>
        </ng-template>
      </kendo-grid-checkbox-column>
      <kendo-grid-column title="Name" field="displayName" class="acb-grid-cell-with-view">
        <ng-template kendoGridCellTemplate let-dataItem>
          <button (click)="onViewImage(dataItem)">
            {{ dataItem.displayName }}
          </button>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column title="Attached By" field="resourceCreatedByPerson" [width]="110" *ngIf="viewMode != 'new'" [hidden]="viewMode === 'edit' || compactMode == 'compact'" >
        <ng-template kendoGridCellTemplate let-dataItem>
          <span class="acb-claim-uploaded-by" *ngIf="dataItem.sourceType == SourceType.Claim">{{ dataItem.createdByPerson }}</span>
          <span class="acb-commitment-uploaded-by" *ngIf="dataItem.sourceType == SourceType.Commitment">{{ 'Commitment' }}</span>
          <span class="acb-audit-uploaded-by" *ngIf="dataItem.sourceType == SourceType.Audit && dataItem.createdByPerson">{{ 'Audit ( ' + dataItem.createdByPerson + ' )' }}</span>
          <span class="acb-audit-uploaded-by" *ngIf="dataItem.sourceType == SourceType.Audit && !dataItem.createdByPerson">{{ 'Audit' }}</span>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column title="Date" field="creationDate" [width]="90" *ngIf="viewMode != 'new'"  [hidden]="viewMode === 'edit' || compactMode == 'compact'">
        <ng-template kendoGridCellTemplate let-dataItem>
          {{dataItem.creationDate | date: 'M/d/yyyy'}}
        </ng-template>
      </kendo-grid-column>
    </kendo-grid>
  `,
  styleUrls: ['./view-claim-attachments.component.scss']
})
export class ViewClaimAttachmentsComponent extends ViewAttachmentsBaseComponent implements OnInit {

  @ViewChild('statusTrackingGrid') statusTrackingGrid?: GridComponent;

  @Input() doShowFieldLabel: boolean = true;
  @Input() viewMode: ViewMode = 'search';
  @Input() compactMode: CompactMode = 'normal';

  private _claim?: ClaimWithDetailsModel;
  @Input() set claim(value: ClaimWithDetailsModel | undefined) {
    this._claim = value;
    this.attachmentPath = value?.code ? './UserUploads/claims/' + window.encodeURIComponent(value.code) : './UserUploads/_in';
    // TODO: clean this up, have part in base and part in derived class.  Messy, confusing
    this.commitmentAttachmentPath = value?.commitmentCode ? './UserUploads/commitments/' + window.encodeURIComponent(value.commitmentCode) : null;

    this.loadData(value?.attachments ?? undefined);
  };
  get claim(): ClaimWithDetailsModel | undefined {
    return this._claim;
  }

  public sort: SortDescriptor[] = [{
    field: 'claimDetailAttachmentID',
    dir: 'asc'
  }];

  public SourceType = SourceType;

  constructor(private commitmentWithDetailsService: CommitmentWithDetailsService) {
    super();
  }

  ngOnInit(): void {
  }

  public onSortChange(sort: SortDescriptor[]): void {
    this.sort = sort;
    this.loadData(this._claim?.attachments ?? undefined);
  }
}
