import { Injectable } from '@angular/core';
import { EntityCollectionServiceBase, EntityCollectionServiceElementsFactory } from '@ngrx/data';
import { ProductForDropdownModel } from '@alcon-db-models/ProductForDropdownModel';

@Injectable({
  providedIn: 'root'
})
export class ProductForDropdownService extends EntityCollectionServiceBase<ProductForDropdownModel> {
  constructor(serviceElementsFactory: EntityCollectionServiceElementsFactory) {
    super('ProductForDropdown', serviceElementsFactory);
  }
}
