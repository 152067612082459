import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { ApplicationConfigurationModel } from '@alcon-db-models/ApplicationConfigurationModel';
import { HttpClient } from '@angular/common/http';
import { environment } from '@environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AppConfigService {

  constructor(protected http: HttpClient) { }

  public loadInitialConfiguration(): Promise<ApplicationConfigurationModel|undefined> {
    return this.http.get<ApplicationConfigurationModel>(Location.joinWithSlash(environment.baseApiUrl, 'application/configuration'))
      .toPromise();
  };
}
