import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { LocationModel } from '@alcon-db-models/LocationModel';
import { Observable } from 'rxjs';
import { StaticTypeModel } from '@alcon-db-models/StaticTypeModel';
import { Store } from '@ngrx/store';
import { selectStaticTypes } from '@app-store/app-session/app-session.selectors';
import { find, first, map } from 'rxjs/operators';

// TODO: Extend display-info-card?
@Component({
  selector: 'acb-alcon-display-address',
  template: `
    <div class="acb-card-wrapper" (click)="onClick($event); $event.stopPropagation();" [ngClass]="{'acb-expanded':isExpanded, 'acb-expandable':!isExpandedOnly}">
      <span class="acb-expand-indicator"></span>
      <ng-container *ngIf="!isExpanded">
        <label class='acb-alcon-display-item-label'>{{ label }}</label>
        <label class='acb-value acb-top-value-label'>{{ name }}&nbsp;</label>
      </ng-container>
      <ng-container *ngIf="isExpanded">
        <label class='acb-alcon-display-item-label'>{{ label }}</label>
        <table>
          <tbody>
            <tr><td>ID:</td><td>{{ code }}</td></tr>
            <tr><td>Name:</td><td>{{ name }}</td></tr>
            <tr *ngIf="lines?.length"><td>Address:</td><td>{{ lines[0] }}</td></tr>
            <tr *ngIf="lines?.length && lines.length > 1"><td></td><td>{{ lines[1] }}</td></tr>
            <tr *ngIf="lines?.length && lines.length > 2"><td></td><td>{{ lines[2] }}</td></tr>
            <tr *ngIf="cityStateZipValue"><td></td><td>{{ cityStateZipValue }}</td></tr>
            <tr *ngIf="countryValue"><td></td><td>{{ countryValue }}</td></tr>
          </tbody>
        </table>
      </ng-container>
  `,
  styleUrls: ['./display-address.component.scss']
})
export class DisplayAddressComponent implements OnInit {

  @Output() expandStatusChange: EventEmitter<boolean> = new EventEmitter();

  @Input() label?: string | null;
  @Input() code?: string | null;
  @Input() name?: string | null;

  private _isExpandedOnly: boolean = false;
  @Input() public set isExpandedOnly(value: boolean) {
    this._isExpandedOnly = value;
    this.isExpanded = value;
  };
  public get isExpandedOnly(): boolean {
    return this._isExpandedOnly;
  }

  private _location:LocationModel | null | undefined = {};
  @Input() public set location(value: LocationModel | null | undefined) {

    this.lines = [];
    this._location = value;

    if (value?.locationLine1)
      this.lines.push(value.locationLine1)
    if (value?.locationLine2)
      this.lines.push(value.locationLine2)
    if (value?.locationLine3)
      this.lines.push(value.locationLine3)

    let cityStateZipLabels: string[] = [], cityStateZipValues: string[] = [];

    if (value?.city) {
      cityStateZipLabels.push('City');
      cityStateZipValues.push(value.city);
    }

    //TODO: Move state and country info to location model, should have to look these up.
    //Note, stateProvinceCode and countryCode are actually stateProvinceCodeID and countryCodeID

    if (value?.stateProvinceCode) {
      let state: string | null = null;
      this.stateProvinceCodes$.pipe(first()).subscribe(x => {
        state = x.find(y => y.id == value.stateProvinceCode)?.displayName ?? null;
      });
      if (state) {
        cityStateZipLabels.push('State');
        cityStateZipValues.push(state);
      }
    }

    if (value?.countryCode) {
      this.countryCodes$.pipe(first()).subscribe(x => {
        this.countryValue = x.find(y => y.id == value.countryCode)?.displayName ?? null;
      });
    }

    if (value?.postalCodeValue) {
      cityStateZipLabels.push('Zip');
      cityStateZipValues.push(value.postalCodeValue);
    }

    this.cityStateZipLabel = cityStateZipLabels.join(', ');
    this.cityStateZipValue = cityStateZipValues.join(', ');
  }
  public get location(): LocationModel | null | undefined { return this._location; }

  public staticTypes$: Observable<StaticTypeModel[]> = this.store.select(selectStaticTypes);
  public stateProvinceCodes$: Observable<StaticTypeModel[]> = this.staticTypes$.pipe(map(x => x.filter(y => y.tableName == 'StateProvinceCode')));
  public countryCodes$: Observable<StaticTypeModel[]> =  this.staticTypes$.pipe(map(x =>  x.filter(y => y.tableName == 'CountryCode')));

  public cityStateZipLabel: string = 'City, State, Zip';
  public cityStateZipValue: string = '';
  public countryValue: string | null = null;

  public lines: string[] = [];

  public isExpanded: boolean = false;

  constructor(private store: Store) { }

  ngOnInit(): void {
  }

  public toggle() {
    this.isExpanded = !this.isExpanded;
  }

  public expand() {
    this.isExpanded = true;
  }

  public collapse() {
    this.isExpanded = false;
  }

  public onClick(event: any) {
    if (!this.isExpandedOnly)
      this.toggle();
      //this.expandStatusChange.emit(!this.isExpanded);
  }

}
