<acb-alcon-search-filters
  [title]="'Search'"
  [formGroup]="searchForm"
  (formReset)="onSearch()"
  (filterChosen)="onSearch()"
  (filtersExpandChanged)="onFiltersExpandChanged($event)"
  #searchFilters
  [fxHide]="this.pageExpanded$ | async"
  [doShowEpansionAlert]="doShowEpansionAlert"
>
  <p featureText>
      <strong>Commitment Search.</strong>
      To expand the search option for other commitments, click on the right corner box to allow you to search for additional fields.
      <br>
      <br>
      To see additional field options on the grid below, click on the Column Lines to the left to add or remove columns.
  </p>
  <span featureKeyHeader>
    Action Key
  </span>
  <div featureKey *ngIf="searchFlavor === 'manage'">
    <div class='acb-action-button-legend' fxLayout="row wrap" fxLayoutAlign="start start" fxLayoutGap=".5em" fxLayoutGap.xs="2em">
      <div class="acb-grid-edit" fxFlex="7em" *ngIf="canEditOwnCommitments || canEditCommitments">
        <span class="k-icon k-i-edit"></span> &nbsp;=&nbsp; Edit
      </div>
      <div class="acb-grid-copy" fxFlex="7em" *ngIf="canCloneCommitments">
        <span class="k-icon k-i-copy"></span> &nbsp;=&nbsp; Clone
      </div>
      <div class="acb-grid-claim" fxFlex="7em" *ngIf="canClaimCommitments">
        <span class="k-icon k-i-file-add"></span> &nbsp;=&nbsp; Claim
      </div>
      <div class="acb-grid-reverse" fxFlex="7em" *ngIf="canReverseCommitments">
        <span class="k-icon k-i-rewind-sm"></span> &nbsp;=&nbsp; Reversal
      </div>
      <div class="acb-grid-view" fxFlex="7em">
        <span class="k-icon k-i-eye"></span> &nbsp;=&nbsp; View
      </div>
    </div>
  </div>

  <ng-container filterSectionBody>

    <kendo-formfield *appFieldExtentions fxFlex [fxHide]="searchFilters.isExpanded">
      <kendo-label [for]="name" text="ID or Name"></kendo-label>
      <input formControlName="name" kendoTextBox #name autocomplete="off" />
      <kendo-formhint>ID, Customer, Territory, or Fund </kendo-formhint>
    </kendo-formfield>

    <ng-container *ngIf="searchFlavor != 'review' && searchFlavor != 'close'">
      <kendo-formfield *appFieldExtentions fxFlex="0 0 10em" fxFlex.lt-sm="auto" [fxHide]="searchFilters.isExpanded" [fxHide.lt-sm]="true">
        <kendo-label [for]="statusCode" text="Status Code"></kendo-label>
        <kendo-combobox
          #statusCode
          formControlName="_statusCode"
          [data]="commitmentStatusCodes$ | async"
          [textField]="'displayName'"
          [valueField]="'statusCodeID'"
          [suggest]="true"
          >
        </kendo-combobox>
        <kendo-formhint>Commitment status</kendo-formhint>
      </kendo-formfield>
    </ng-container>

    <!-- =================================================================================================== -->

    <kendo-formfield *appFieldExtentions fxFlex="0 0 calc(14% - 2em)" fxFlex.md="0 0 calc(25% - 2em)" fxFlex.sm="0 0 calc(28% - 2em)" fxFlex.xs="grow" [fxHide]="!searchFilters.isExpanded">
      <kendo-label [for]="commitmentIDs" text="Commitment IDs"></kendo-label>
      <kendo-textbox formControlName="commitmentIDs" #commitmentIDs autocomplete="off" multipleIntegerTextbox></kendo-textbox>
      <kendo-formhint>One or more, ex: 10, 20, 30</kendo-formhint>
    </kendo-formfield>

    <kendo-formfield *appFieldExtentions fxFlex="0 0 calc(27% - 2em)" fxFlex.md="0 0 calc(37.5% - 2em)" fxFlex.sm="0 0 calc(36% - 2em)" fxFlex.xs="grow" [fxHide]="!searchFilters.isExpanded">
      <kendo-label [for]="customerCode" text="Customer Number"></kendo-label>
      <input formControlName="customerCode" kendoTextBox #customerCode autocomplete="off" />
      <kendo-formhint>Customer's code</kendo-formhint>
    </kendo-formfield>

    <kendo-formfield *appFieldExtentions fxFlex="0 0 calc(27% - 2em)" fxFlex.md="0 0 calc(37.5% - 2em)" fxFlex.sm="0 0 calc(36% - 2em)" fxFlex.xs="grow" [fxHide]="!searchFilters.isExpanded">
      <kendo-label [for]="customerName" text="Customer Name"></kendo-label>
      <input formControlName="customerName" kendoTextBox #customerName autocomplete="off" />
      <kendo-formhint>Typical name</kendo-formhint>
    </kendo-formfield>

    <kendo-formfield *appFieldExtentions fxFlex="0 0 calc(27% - 2em)" fxFlex.md="0 0 calc(37.5% - 2em)" fxFlex.sm="0 0 calc(36% - 2em)" fxFlex.xs="grow" [fxHide]="!searchFilters.isExpanded">
      <kendo-label [for]="payeeName" text="Payee Name"></kendo-label>
      <input formControlName="payeeName" kendoTextBox #payeeName autocomplete="off" />
      <kendo-formhint>Typical name</kendo-formhint>
    </kendo-formfield>

    <!-- =================================================================================================== -->

    <kendo-formfield *appFieldExtentions fxFlex="0 0 calc(16% - 2em)" fxFlex.lt-lg="0 0 calc(25% - 2em)" fxFlex.xs="0 0 calc(50% - 2em)" [fxHide]="!searchFilters.isExpanded">
      <kendo-label [for]="startDate" text="Start Date"></kendo-label>
      <kendo-datepicker formControlName="startDate" #startDate [format]="{ displayFormat: 'MM/dd/yyyy', inputFormat: 'MM/dd/yy'  }"></kendo-datepicker>
      <kendo-formhint>Activity start date</kendo-formhint>
    </kendo-formfield>

    <kendo-formfield *appFieldExtentions fxFlex="0 0 calc(16% - 2em)" fxFlex.lt-lg="0 0 calc(25% - 2em)" fxFlex.xs="0 0 calc(50% - 2em)" [fxHide]="!searchFilters.isExpanded">
      <kendo-label [for]="endDate" text="End Date"></kendo-label>
      <kendo-datepicker formControlName="endDate" #endDate [format]="{ displayFormat: 'MM/dd/yyyy', inputFormat: 'MM/dd/yy'  }"></kendo-datepicker>
      <kendo-formhint>Activity end date</kendo-formhint>
    </kendo-formfield>

    <kendo-formfield *appFieldExtentions fxFlex="0 0 calc(14% - 2em)" fxFlex.lt-lg="0 0 calc(25% - 2em)" fxFlex.xs="0 0 calc(50% - 2em)" [fxHide]="!searchFilters.isExpanded">
      <kendo-label [for]="minAmount" text="Min Amount"></kendo-label>
      <kendo-numerictextbox [spinners]="false" [decimals]="2" [format]="'c2'" [min]="0" #minAmount [formControlName]="'minAmount'"></kendo-numerictextbox>
      <kendo-formhint>Minimum commited amount</kendo-formhint>
    </kendo-formfield>

    <kendo-formfield *appFieldExtentions fxFlex="0 0 calc(14% - 2em)" fxFlex.lt-lg="0 0 calc(25% - 2em)" fxFlex.xs="0 0 calc(50% - 2em)" [fxHide]="!searchFilters.isExpanded">
      <kendo-label [for]="maxAmount" text="Max Amount"></kendo-label>
      <kendo-numerictextbox [spinners]="false" [decimals]="2" [format]="'c2'" [min]="0" #maxAmount [formControlName]="'maxAmount'"></kendo-numerictextbox>
      <kendo-formhint>Maximum commited amount</kendo-formhint>
    </kendo-formfield>

    <!-- =================================================================================================== -->
    <ng-container *ngIf="this.fundsYears$ | async as fundsYearsData">
      <kendo-formfield
        *appFieldExtentions
        fxFlex="0 0 calc(13% - 2em)"
        fxFlex.md="0 0 calc(16% - 2em)"
        fxFlex.sm="0 0 calc(25% - 2em)"
        fxFlex.xs="grow"
        [fxHide]="!searchFilters.isExpanded"
        >
        <kendo-label [for]="fundYears" text="Fund Years"></kendo-label>
        <kendo-multiselect

          formControlName="fundYears"
          [data]="fundsYearsData"
          [valuePrimitive]="true"
          #fundYears
          [autoClose]="false"
          [ngClass.lt-lg]="'acb-multiselect-small'"
          >
        </kendo-multiselect>
        <kendo-formhint>&nbsp;</kendo-formhint>
      </kendo-formfield>
    </ng-container>

    <ng-container *ngIf="this.fundsForDropdown$ | async as fundsForDropdownData">
      <kendo-formfield *appFieldExtentions fxFlex="0 0 calc(22% - 2em)" fxFlex.md="0 0 calc(34% - 2em)" fxFlex.sm="0 0 calc(75% - 2em)" fxFlex.lt-sm="grow" [fxHide]="!searchFilters.isExpanded">
        <kendo-label [for]="funds" text="Funds"></kendo-label>
        <kendo-multiselect
          formControlName="funds"
          [data]="fundsForDropdownData"
          [textField]="'displayName'"
          [valueField]="'fundID'"
          #funds
          [autoClose]="false"
          >
        </kendo-multiselect>
        <kendo-formhint>&nbsp;</kendo-formhint>
      </kendo-formfield>
    </ng-container>

    <!-- always showing this now because of Fund "Force Commitment Activities", Alcon1028-1479  -->
    <!-- <ng-container *ngIf="this.doesUseCommitmentActivities"> -->
      <ng-container *ngIf="this.activityForDropdownModel$ | async as activityForDropdownData">
        <kendo-formfield *appFieldExtentions fxFlex="0 0 calc(22% - 2em)" fxFlex.md="0 0 calc(34% - 2em)" fxFlex.sm="0 0 calc(75% - 2em)" fxFlex.lt-sm="grow" [fxHide]="!searchFilters.isExpanded">
          <kendo-label [for]="activities" text="Activities"></kendo-label>
          <kendo-multiselect
            formControlName="activities"
            [data]="activityForDropdownData"
            [textField]="'displayName'"
            [valueField]="'activityID'"
            #activities
            [autoClose]="false"
            >
          </kendo-multiselect>
          <kendo-formhint>&nbsp;</kendo-formhint>
        </kendo-formfield>
      </ng-container>
    <!-- </ng-container> -->

    <ng-container *ngIf="this.territoriesForDropdown$ | async as territoriesForDropdownData">
      <kendo-formfield *appFieldExtentions fxFlex="0 0 calc(21% - 2em)" fxFlex.md="0 0 calc(34% - 2em)" fxFlex.sm="0 0 calc(69% - 2em)"  fxFlex.lt-sm="grow" [fxHide]="!searchFilters.isExpanded">
        <kendo-label [for]="territories" text="Territories"></kendo-label>
        <kendo-multiselect
          formControlName="territories"
          [data]="territoriesForDropdownData"
          [textField]="'code'"
          [valueField]="'territoryID'"
          #territories
          [autoClose]="false"
          >
        </kendo-multiselect>
        <kendo-formhint>&nbsp;</kendo-formhint>
      </kendo-formfield>
    </ng-container>

    <ng-container *ngIf="searchFlavor != 'review' && searchFlavor != 'close' && (this.commitmentStatusCodes$ | async) as commitmentStatusCodesValue">
      <kendo-formfield
        *appFieldExtentions
        fxFlex="0 0 calc(16% - 2em)" fxFlex.md="0 0 calc(16% - 2em)"
        fxFlex.sm="0 0 calc(31% - 2em)"
        fxFlex.xs="grow"
        [fxHide]="!searchFilters.isExpanded"
        >
        <kendo-label [for]="statusCodes" text="Status"></kendo-label>
        <kendo-multiselect
          formControlName="statusCodes"
          [data]="commitmentStatusCodesValue"
          [textField]="'displayName'"
          [valueField]="'statusCodeID'"
          #statusCodes
          [autoClose]="false"
          [ngClass.lt-lg]="'acb-multiselect-small'"

          >
        </kendo-multiselect>
        <kendo-formhint>&nbsp;</kendo-formhint>
      </kendo-formfield>
    </ng-container>

    <ng-container *ngIf="searchFlavor != 'search'">
      <kendo-formfield *appFieldExtentions fxFlex="8em" [fxHide]="!searchFilters.isExpanded">
        <kendo-label [for]="byMe" text="By Me"></kendo-label>
        <kendo-dropdownlist
          #byMe
          formControlName="byMe"
          [defaultItem]="undefined"
          [data]="byMeList"
          [textField]="'name'"
          [valueField]="'value'"
          [valuePrimitive]="true"
          >
        </kendo-dropdownlist>
        <kendo-formhint>&nbsp;</kendo-formhint>
      </kendo-formfield>
    </ng-container>

    <ng-container *ngIf="searchFlavor != 'search'">
      <kendo-formfield *appFieldExtentions [fxHide]="!searchFilters.isExpanded" fxLayout="row" fxLayoutAlign="start center">
        <div fxLayout="row" fxLayoutGap=".75em" fxLayoutAlign="start center" class="acb-checkbox-row">
          <input type="checkbox" class="k-checkbox" #excludeCanceledAndDenied formControlName="excludeCanceledAndDenied" [checked]="true" >
          <kendo-label [for]="excludeCanceledAndDenied" text="Exclude Canceled and Denied?"></kendo-label>
        </div>
        <kendo-formhint>&nbsp;</kendo-formhint>
      </kendo-formfield>
    </ng-container>
  </ng-container>
</acb-alcon-search-filters>

<kendo-grid
  #resultsGrid
  [ngStyle.gt-xs]="{'margin-top': '2em'}"
  commitmentSearchBindingDirective
  [pageable]="{
    buttonCount: 4,
    pageSizes: true
  }"
  [sortable]="true"
  [reorderable]="true"
  [pageSize]="10"
  [selectable] = "{
    enabled: true,
    mode: 'multiple'
  }"
  [resizable]="true"
  kendoGridSelectBy="commitmentID"
  [(selectedKeys)] = "selectedKeys"
  class="acb-results-grid"
  [searchFlavor] = "searchFlavor"
  (selectedKeysChange)="onResultsGridSelectedKeysChange($event)"
  >
  <ng-template kendoGridToolbarTemplate>
    <div fxLayout="row" fxLayoutAlign=" center" >
      <div fxFlexAlign="center" class="acb-toolbar-adjacent">
        <kendo-grid-column-chooser [autoSync]="true" [allowHideAll]="false"></kendo-grid-column-chooser>
        <button kendoButton type="button" [icon]="'reset'" (click)="onResetGridPrefs()" [className]="'acb-teal'" fxFlexOffset="1em" >Reset Grid</button>
      </div>
      <ng-template #placeholder1><div fxFlex='1 0 12em' fxFlex.xs='.75 1 auto'></div></ng-template>
      <div fxFlex='2.5 1 auto' fxFlex.xs='.75 1 auto'></div>
      <kendo-toolbar
        *ngIf="searchFlavor === 'manage' || searchFlavor === 'review' || searchFlavor === 'close'"
        [overflow]="true"
        fxFlex="0 1 38em"
        fxFlex.xs="0 0 4em"
        [fxHide]="!hasSelected"
        [ngStyle.xs]="{'margin':'0'}"
        >
        <kendo-toolbar-button [text]="'Clear (' + this.selectedKeys.length.toString() + ')'" [icon]="'apply-format'" (click)="onClearButtonClick()" [className]="'acb-toolbar-button-clear'" ></kendo-toolbar-button>
        <kendo-toolbar-button
          *ngIf="searchFlavor === 'manage' || searchFlavor === 'close'"
          [text]="'Close (' + closableSelectedKeys.length + ')'"
          [icon]="'close'"
          (click)="onCloseButtonClick()"
          [className]="'acb-warning'"
          [disabled]="!closableSelectedKeys.length"
          ></kendo-toolbar-button>

        <kendo-toolbar-button
          *ngIf="searchFlavor === 'review'"
          [text]="'Review'"
          [icon]="'aggregate-fields'"
          (click)="onReview()"
          [className]="'acb-primary'"
          ></kendo-toolbar-button>

        <kendo-toolbar-button
          *ngIf="searchFlavor === 'review'"
          [text]="'Approve'"
          [icon]="'check-outline'"
          (click)="onReviewApprove()"
          [className]="'acb-good'"
          ></kendo-toolbar-button>

        <kendo-toolbar-button
          *ngIf="searchFlavor === 'review'"
          [text]="'Deny'"
          [icon]="'close-outline'"
          (click)="onReviewDeny()"
          [className]="'acb-danger'"
          ></kendo-toolbar-button>

        <kendo-toolbar-button
          *ngIf="searchFlavor === 'manage'"
          [text]="'Cancel (' + cancellableSelectedKeys.length + ')'"
          [icon]="'cancel-outline'"
          (click)="onCancelButtonClick()"
          [className]="'acb-danger'"
          [disabled]="!cancellableSelectedKeys.length"
          ></kendo-toolbar-button>

      </kendo-toolbar>
      <div fxFlex='1 1 auto'></div>
      <div fxFlexAlign="center" class="acb-toolbar-adjacent">
        <button kendoGridExcelCommand type="button" icon="file-excel" class="k-button">Export<span [fxHide.xs]="true">&nbsp;to Excel</span></button>
        <button kendoButton type="button" [icon]="expandToggleIcon" class="k-button" (click)="onToggleExpand()" fxFlexOffset="1em" ></button>
      </div>
    </div>
  </ng-template>
  <kendo-grid-command-column
    [width]="30"
    [locked]="commandColumnIsLocked"
    [reorderable]="false"
    [includeInChooser]="false"
    [resizable]="false"
    *ngIf="searchFlavor === 'manage' || searchFlavor === 'close'"
    [headerClass]="'acb-grid-command-column-header'"
    >
    <ng-template kendoGridCellTemplate let-dataItem="dataItem" let-rowIndex="rowIndex">
      <input [kendoGridSelectionCheckbox]="rowIndex" kendoCheckBox />
    </ng-template>
  </kendo-grid-command-column>
  <kendo-grid-command-column
    [width]="30"
    [locked]="commandColumnIsLocked"
    [reorderable]="false"
    [includeInChooser]="false"
    [resizable]="false"
    *ngIf="searchFlavor === 'manage' && (canEditOwnCommitments || canEditCommitments)"
    [headerClass]="'acb-grid-command-column-header'"
    >
    <ng-template kendoGridHeaderTemplate>
      <span class="k-icon k-i-edit"></span>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem="dataItem" let-rowIndex="rowIndex">
      <button kendoGridEditCommand [style.visibility]="getVisibility(dataItem?.canEdit)" class="acb-in-grid-edit-button"  [icon]="'edit'" look="clear" (click)="onEditCommitment($event, dataItem)"></button>
    </ng-template>
  </kendo-grid-command-column>
  <kendo-grid-command-column
    [width]="30"
    [locked]="commandColumnIsLocked"
    [reorderable]="false"
    [includeInChooser]="false"
    [resizable]="false"
    *ngIf="searchFlavor === 'manage' && canCloneCommitments"
    [headerClass]="'acb-grid-command-column-header'"
    >
    <ng-template kendoGridHeaderTemplate>
      <span class="k-icon k-i-copy"></span>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem="dataItem" let-rowIndex="rowIndex">
      <button kendoButton [style.visibility]="getVisibility(dataItem?.canCopy)" class="acb-in-grid-copy-button" [icon]="'copy'" look="clear" (click)="onCopy($event, dataItem)"></button>
    </ng-template>
  </kendo-grid-command-column>
  <kendo-grid-command-column
    [width]="30"
    [locked]="commandColumnIsLocked"
    [reorderable]="false"
    [includeInChooser]="false"
    [resizable]="false"
    *ngIf="searchFlavor === 'manage' && canClaimCommitments"
    [headerClass]="'acb-grid-command-column-header'"
    >
    <ng-template kendoGridHeaderTemplate>
      <span class="k-icon k-i-file-add"></span>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem="dataItem" let-rowIndex="rowIndex">
      <button kendoButton [style.visibility]="getVisibility(dataItem?.canClaim)" class="acb-in-grid-claim-button" [icon]="'file-add'" look="clear" (click)="onClaim($event, dataItem)"></button>
    </ng-template>
  </kendo-grid-command-column>
  <kendo-grid-command-column
    [width]="30"
    [locked]="commandColumnIsLocked"
    [reorderable]="false"
    [includeInChooser]="false"
    [resizable]="false"
    *ngIf="searchFlavor === 'manage' && canReverseCommitments"
    [headerClass]="'acb-grid-command-column-header'"
    >
    <ng-template kendoGridHeaderTemplate>
      <span class="k-icon k-i-rewind-sm"></span>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem="dataItem" let-rowIndex="rowIndex">
      <button kendoButton [style.visibility]="getVisibility(dataItem?.canReverse)" class="acb-in-grid-reverse-button" [icon]="'rewind-sm'" look="clear" (click)="onReverse($event, dataItem)"></button>
    </ng-template>
  </kendo-grid-command-column>
  <kendo-grid-checkbox-column
    *ngIf="searchFlavor === 'review'"
    [width]="42"
    [locked]="commandColumnIsLocked"
    [reorderable]="false"
    [includeInChooser]="false"
    [resizable]="false"
    [showSelectAll]="true"
    >
    <ng-template kendoGridHeaderTemplate>
      <input title="Select All" class="k-checkbox" kendoGridSelectAllCheckbox (selectAllChange)="onSelectAllChange($event)">
    </ng-template>
  </kendo-grid-checkbox-column>
  <kendo-grid-column title="Comm ID" field="commitmentID" [width]="100" class="acb-grid-cell-with-view" [includeInChooser]="false" [resizable]="false" [locked]= "isGridIdentifierAndActionColumnsLocked">
    <ng-template kendoGridCellTemplate let-dataItem>
      <button (click)="onViewCommitment($event, dataItem)" [class]="{'acb-view-button-with-exception': dataItem.hasException}">
        {{dataItem.commitmentID}}
      </button>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column title="Status" field="status" [width]="90">
  </kendo-grid-column>
  <kendo-grid-column title="Cust #" field="customerCode" [width]="100">
  </kendo-grid-column>
  <kendo-grid-column title="Customer" field="customer" [width]="200">
  </kendo-grid-column>
  <kendo-grid-column title="Payee" field="payee" [width]="200">
  </kendo-grid-column>
  <kendo-grid-column title="Fund" field="fund" [width]="240">
  </kendo-grid-column>
  <!-- <kendo-grid-column *ngIf="this.doesUseCommitmentActivities" title="Activity" field="activity" [width]="240">
  </kendo-grid-column> -->
  <!-- always showing this now because of Fund "Force Commitment Activities", Alcon1028-1479  -->
  <kendo-grid-column title="Activity" field="activity" [width]="240">
  </kendo-grid-column>
  <kendo-grid-column title="Amount" field="amount" [format]="'{0:c}'" class="acb-grid-column-currency" [width]="110">
  </kendo-grid-column>
  <kendo-grid-column title="Claimed" field="claimed" format="{0:c}" class="acb-grid-column-currency" [width]="110" *ngIf="searchFlavor != 'review'">
  </kendo-grid-column>
  <kendo-grid-column title="Paid" field="approved" format="{0:c}" class="acb-grid-column-currency" [width]="110" *ngIf="searchFlavor != 'review'">
  </kendo-grid-column>
  <kendo-grid-column title="Unused" field="unused" format="{0:c}" class="acb-grid-column-currency" [width]="110" *ngIf="searchFlavor != 'review'">
  </kendo-grid-column>
  <kendo-grid-column title="Returned" field="returned" format="{0:c}" class="acb-grid-column-currency" [width]="110" *ngIf="searchFlavor != 'review'">
  </kendo-grid-column>
  <kendo-grid-column title="Adjusted" field="adjusted" format="{0:c}" class="acb-grid-column-currency" [width]="110" *ngIf="searchFlavor != 'review'">
  </kendo-grid-column>
  <kendo-grid-column title="Available" field="available" format="{0:c}" class="acb-grid-column-currency" [width]="110" *ngIf="searchFlavor != 'review'">
  </kendo-grid-column>
  <kendo-grid-column title="Utilized" field="utilized" format="{0:p2}" class="acb-grid-column-currency" [width]="90" *ngIf="searchFlavor != 'review'">
  </kendo-grid-column>
  <kendo-grid-column title="Start" field="startDate" [width]="90" class="acb-grid-column-date">
    <ng-template kendoGridCellTemplate let-dataItem>
      {{dataItem.startDate | date: 'M/d/yyyy'}}
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column title="End" field="endDate" [width]="90" class="acb-grid-column-date">
    <ng-template kendoGridCellTemplate let-dataItem >
      {{dataItem.endDate | date: 'M/d/yyyy'}}
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column title="Territory" field="territoryCode" [width]="110" [hidden]="true">
    <ng-template kendoGridCellTemplate let-dataItem >
      {{ dataItem.territoryCode }} - {{ dataItem.territoryPerson }}
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column title="Funded By" field="fundingTerritoryCode" [width]="110" [hidden]="true">
    <ng-template kendoGridCellTemplate let-dataItem>
      <ng-container *ngIf="dataItem.fundingTerritoryID">
        {{ dataItem.fundingTerritoryCode }} - {{ dataItem.fundingTerritoryPerson }}
      </ng-container>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column title="Expire" field="expirationDate" [width]="110" [hidden]="true" class="acb-grid-column-date" *ngIf="searchFlavor != 'review'">
    <ng-template kendoGridCellTemplate let-dataItem>
      {{dataItem.expirationDate | date: 'M/d/yyyy'}}
    </ng-template>
  </kendo-grid-column>

  <ng-container *ngIf="isEventAndVenueAware">
    <kendo-grid-column title="Event" field="event" [width]="200" [hidden]="true">
    </kendo-grid-column>
    <kendo-grid-column title="Venue" field="venue" [width]="200" [hidden]="true">
    </kendo-grid-column>
    <kendo-grid-column title="Venue City" field="venueCity" [width]="200" [hidden]="true">
    </kendo-grid-column>
    <kendo-grid-column title="V. State" field="venueStateProvinceCode" [width]="90" [hidden]="true">
    </kendo-grid-column>
    <kendo-grid-column title="V. ENA" field="estimatedAttendeeCount" [width]="90" [hidden]="true">
    </kendo-grid-column>
  </ng-container>

  <kendo-grid-column title="Exception" field="exception" [width]="200" *ngIf="searchFlavor == 'manage' || searchFlavor == 'exception'" [hidden]="searchFlavor == 'manage'">
  </kendo-grid-column>
  <kendo-grid-column title="Comment" field="commitmentComment" [width]="300" [hidden]="true">
  </kendo-grid-column>
  <kendo-grid-column title="Created By" field="createdByPerson" [width]="160" [hidden]="true" *ngIf="searchFlavor == 'manage' || searchFlavor == 'exception'">
  </kendo-grid-column>
  <kendo-grid-column title="Impersonated By" field="impersonatedByPerson" [width]="160" [hidden]="true" *ngIf="searchFlavor == 'manage' || searchFlavor == 'exception'">
  </kendo-grid-column>
  <ng-template kendoPagerTemplate let-totalPages="totalPages" let-currentPage="currentPage">
    <kendo-pager-prev-buttons></kendo-pager-prev-buttons>
    <kendo-pager-numeric-buttons [buttonCount]="10" fxHide.lt-md="true"></kendo-pager-numeric-buttons>
    <kendo-pager-numeric-buttons [buttonCount]="5" fxHide="true" fxShow.sm="true"></kendo-pager-numeric-buttons>
    <kendo-pager-info></kendo-pager-info>
    <kendo-pager-next-buttons></kendo-pager-next-buttons>
    <kendo-pager-page-sizes ngClass.lt-md='acb-small-pager-page-sizes' [pageSizes]="[5,10,20,40]" ></kendo-pager-page-sizes>
  </ng-template>
  <kendo-grid-excel [fileName]="'Commitments.xlsx'" [fetchData]="allData" >
  </kendo-grid-excel>
</kendo-grid>
