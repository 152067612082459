import { BannerNotificationModel } from '@alcon-db-models/BannerNotificationModel';
import { BannerNotificationTypeModel } from '@alcon-db-models/BannerNotificationTypeModel';
import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { selectBannerNotificationTypes } from '@app-store/app-session/app-session.selectors';
import { Store } from '@ngrx/store';
import { first } from 'rxjs/operators';

@Component({
  selector: 'acb-alcon-display-notification',
  template: `
    <div class="acb-banner-wrapper {{this.className}}" [ngClass]="{ 'acb-is-clickable': this.linkPath }"  (click)="onClick()">
      <div class="acb-banner-title" *ngIf="bannerNotification?.title">
        {{ bannerNotification?.title }}
      </div>
      <div class="acb-banner-message" *ngIf="bannerNotification?.message">
        {{ bannerNotification?.message }}
      </div>
    </div>
  `,
  styleUrls: ['./display-notification.component.scss']
})
export class DisplayNotificationComponent implements OnInit {

  private bannerNotificationTypes: BannerNotificationTypeModel[] = [];
  private infoBannerNotificationTypeID?: number;
  private commitmentWaitingApprovalBannerNotificationTypeID?: number;
  private draftCommitmentsBannerNotificationTypeID?: number;
  private draftClaimsBannerNotificationTypeID?: number;

  public linkPath?: string;
  public className?: string;

  private _bannerNotification?: BannerNotificationModel;
  @Input() set bannerNotification(value: BannerNotificationModel | undefined) {
    this._bannerNotification = value;
    if (!this.bannerNotificationTypes?.length || !value?.bannerNotificationTypeID) return;
    switch (value.bannerNotificationTypeID) {
      case this.infoBannerNotificationTypeID:
        this.linkPath = undefined;
        this.className = 'acb-banner-info';
        break;
      case this.commitmentWaitingApprovalBannerNotificationTypeID:
        this.linkPath = '/commitments/review';
        this.className = 'acb-banner-commitment-review';
        break;
      case this.draftCommitmentsBannerNotificationTypeID:
        this.linkPath = '/commitments/drafts';
        this.className = 'acb-banner-draft-commitment';
        break;
      case this.draftClaimsBannerNotificationTypeID:
        this.linkPath = '/claims/drafts';;
        this.className = 'acb-banner-draft-claim';
        break;
      }
  }
  get bannerNotification() { return this._bannerNotification }

  constructor(store: Store, private router: Router) {
    store.select(selectBannerNotificationTypes).pipe(first()).subscribe(x => {
      this.bannerNotificationTypes = x;
      this.infoBannerNotificationTypeID = x.find(y => y.code == 'info')?.bannerNotificationTypeID ?? undefined;
      this.commitmentWaitingApprovalBannerNotificationTypeID = x.find(y => y.code == 'commitmentwaitingapproval')?.bannerNotificationTypeID ?? undefined;
      this.draftCommitmentsBannerNotificationTypeID = x.find(y => y.code == 'draftcommitments')?.bannerNotificationTypeID ?? undefined;
      this.draftClaimsBannerNotificationTypeID = x.find(y => y.code == 'draftclaims')?.bannerNotificationTypeID ?? undefined;
    });
  }

  ngOnInit(): void {

  }

  public onClick() {
    if (!this.linkPath) return;
    this.router.navigate([this.linkPath]);
  }
}
