import { Component, OnInit } from '@angular/core';
import { selectExternalLinks } from '@app-store/app-session/app-session.selectors';
import { Store } from '@ngrx/store';
import { first } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'acb-alcon-footer-main',
  templateUrl: './footer-main.component.html',
  styleUrls: ['./footer-main.component.scss']
})
export class FooterMainComponent implements OnInit {

  public env = environment;
  public privacyPolicyUrl: string | undefined;

  constructor(store: Store) {
    store.select(selectExternalLinks).pipe(first()).subscribe(x => {
      this.privacyPolicyUrl = x.PrivacyPolicyUrl;
    });
  }

  ngOnInit(): void {
  }

}
