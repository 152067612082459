import { Injectable } from '@angular/core';
import { throwError, of, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { PersonWithRolesModel } from '@alcon-db-models/PersonWithRolesModel';
import { ApplicationConfigurationModel } from '@alcon-db-models/ApplicationConfigurationModel';
import { AccessControlBaseService } from './access-controlled-base.service';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { FeatureModel } from '@alcon-db-models/FeatureModel';
import { ApplicationErrorModel } from '@alcon-db-models/ApplicationErrorModel';
import { ReportRequestModel } from '@alcon-db-models/ReportRequestModel';
import { GridResultModel } from '@alcon-db-models/GridResultModel';
import { BannerNotificationModel } from '@alcon-db-models/BannerNotificationModel';

@Injectable({
  providedIn: 'root'
})
export class ApplicationService extends AccessControlBaseService {

  constructor(protected http: HttpClient, protected router: Router, protected store: Store) {
    super();
  }

  public getLoggedInIdentity(): Promise<PersonWithRolesModel | undefined> {
    return this.get<PersonWithRolesModel>('application/identity').pipe(catchError((e) => {
      return throwError("application/identity failed");
      }),
    ).toPromise();
  }

  public loadInitialConfiguration(): Promise<ApplicationConfigurationModel | undefined> {
    return this.get<ApplicationConfigurationModel>('application/configuration').toPromise();
  };

  public getFeatureMap(): Promise<FeatureModel[] | undefined> {
    return this.get<FeatureModel[]>('application/feature-map').pipe(catchError((e) => {
        return throwError("application/feature-map failed");
      }),
    ).toPromise();
  }

  public updateFeature(feature: FeatureModel ): Promise<boolean | undefined> {
    return this.put<boolean>('application/update-feature', feature).pipe(catchError((e) => {
        return throwError("application/update-feature");
      }),
    ).toPromise();
  }

  public addFeature(feature: FeatureModel ): Promise<FeatureModel | undefined> {
    return this.post<FeatureModel>('application/add-feature', feature).pipe(catchError((e) => {
        return throwError(e);
      }),
    ).toPromise();
  }

  public deleteFeature(feature: FeatureModel ): Promise<boolean> {
    return Promise.resolve(false);
  }

  public logError(error: ApplicationErrorModel ) {
    try {
      return this.post<number>('application/LogError', error).toPromise();
    } catch(err) {
      return of(0).toPromise();
    }
  }

  public getReport(request: ReportRequestModel): Observable<GridResultModel> {
    return this.post<GridResultModel>('application/get-report', request);
  }

  public getBannerNotifications(): Observable<BannerNotificationModel[]> {
    return this.get<BannerNotificationModel[]>('application/Banner-Notifications');
  }

  public getReversibleClaimsForCommitment(commitmentID: number): Observable<number[]> {
    return this.get<number[]>('application/Reversible-Claims-For-Commitment', { commitmentID: commitmentID });
  }

}


