import { ChangeDetectorRef, Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { UploadFileBase } from '../upload-file.base.component';
import { AppUxService } from '@services/app-ux.service';
import { Store } from '@ngrx/store';
import { UploadComponent, UploadEvent } from '@progress/kendo-angular-upload';

@Component({
  selector: 'acb-alcon-sandbox-file-upload',
  templateUrl: './sandbox-file-upload.component.html',
  styleUrls: ['./sandbox-file-upload.component.scss']
})
export class SandboxFileUploadComponent extends UploadFileBase implements OnInit {

  @ViewChild('fileUpload') public fileUpload?: UploadComponent;

  @Output() fileUploaded: EventEmitter<any> = new EventEmitter();

  constructor(
    protected appUxService: AppUxService,
    protected store: Store,
    protected changeDetectorRef: ChangeDetectorRef
  ) {

    super(appUxService, store, changeDetectorRef);
  }

  ngOnInit(): void {
  }

  public onValueChange(event: any): void {
    // this.attachments = [
    //   ...this.attachments,
    //   ...this.stagedResources.filter(x => !this.attachments.some(y => y.code == x.code)).map(x => ({
    //     ...(({ createdByPersonID, creationDate, ...rest }) => rest)(x),
    //     ...{
    //       resourceCreatedByPersonID: x.createdByPersonID,
    //       attachmentRole: AttachmentRole.Pop
    //     }
    //   }))
    // ];
    // this.stagedResources.length = 0;
    // this.selectedChange.emit(this.attachments);
  }

  public onFileUploaded() {
    
  }

  public onUpload (event:UploadEvent) {
    // if (event.files?.length != 1)
    //   return;
    // this.fileUploaded.emit();
    // this.appUxService.showModalOverlay();
    super.onUpload(event);
  }

  public onComplete (event:UploadEvent) {
    // this.appUxService.hideModalOverlay(); 
    this.files = [];
    this.fileUpload?.clearFiles();
    this.fileUploaded.emit();
  }




}
