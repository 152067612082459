import { FundSearchWithDefaultRequestModel } from '@alcon-db-models/FundSearchWithDefaultRequestModel';
import { Directive } from '@angular/core';
import { GridComponent } from '@progress/kendo-angular-grid';
import { FilterDescriptor } from '@progress/kendo-data-query';
import { AppUxService } from '@services/app-ux.service';
import { FundSelectSearchService } from '@services/fund-select-search.service';
import { SelectSearchBindingBaseDirective } from './select-search-binding.base.directive';

@Directive({
  selector: '[fundSelectSearchBinding]'
})
export class FundSelectSearchBindingDirective extends SelectSearchBindingBaseDirective {

  constructor(private fundSelectSearchService: FundSelectSearchService, grid: GridComponent, appUxService: AppUxService) {
    super(fundSelectSearchService, grid, appUxService);
  }

  public getSearchRequest(doIgnorePagination: boolean = false): FundSearchWithDefaultRequestModel {

    this.beforeBind.emit(null);

    this.grid.loading = true;

    const filters: FilterDescriptor[] = this.state.filter?.filters as FilterDescriptor[];

    const getValue = function(col:string) {
      let descriptor: FilterDescriptor;
      return (descriptor = filters?.find(x => x.field == col) as FilterDescriptor) ? descriptor?.value : null
    };

    return ({
      personID: null,
      take: doIgnorePagination ? null : this.state.take,
      skip: doIgnorePagination ? 0 : this.state.skip,
      sortColumn: this.state.sort?.[0]?.dir ? this.state.sort?.[0]?.field : null,
      sortAscending: this.state.sort?.[0]?.dir ? this.state.sort[0].dir != 'desc' : null,
      fundYears: getValue('fundYears'),
      fundNameOrYear: getValue('fundNameOrYear'),
      customerID: getValue('customerID'),
      prependFundID: getValue('prependFundID'),
      doShowHidden: getValue('doShowHidden')
    });
  }



}


