import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { GridComponent } from '@progress/kendo-angular-grid';
import { FilterDescriptor } from '@progress/kendo-data-query';
import { BehaviorSubject } from 'rxjs';
import { SelectSearchBindingBaseDirective } from '../directives/select-search-binding.base.directive';

@Component({
  selector: 'acb-alcon-select-base',
  template: '',
  styles: [ ]
})
export class SelectBaseComponent implements OnInit {

  public resultsGrid?: GridComponent;
  public bindingDirective?: SelectSearchBindingBaseDirective;

  public searchForm:UntypedFormGroup;
  public isValid: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public selectedKeys: number[] = [];

  constructor(searchForm:UntypedFormGroup) {
    this.searchForm = searchForm;
  }

  public validate(): boolean {
    return false;
  }

  ngOnInit(): void {
  }

  public updateFilter(): void {

    if (!this.bindingDirective || !this.searchForm)
      return;

    const form = this.searchForm;
    let filters:FilterDescriptor[] = [];

    Object.keys(form.controls).forEach((fieldName: string) => {
      const field = form.get(fieldName);
      if (field) {
        let val = field.value;
        if (val && typeof val === 'string') {
          // FIX ME: Escape strings better?  Or use regex?  For TSQL LIKE comparison...
          val = val ? '%' + val.replace('[','[[').replace(']',']]').replace('%','[%]').replace('_','[_]') + '%' : undefined;
        }
        filters.push({ field: fieldName, operator: 'eq', value: val })
      }
    });

    this.bindingDirective.filter = { filters: filters, logic: 'and' };
  }

  public rebind() {
    this.selectedKeys.length = 0;
    this.bindingDirective?.rebind();
  }

  public onSearch() {
    if (this.resultsGrid && this.bindingDirective) {
      this.bindingDirective.skip = 0;
      this.rebind();
    }
  }
}
