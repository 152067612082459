import { ClaimWithDetailsModel } from '@alcon-db-models/ClaimWithDetailsModel';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ClaimEditService } from '@services/claim-edit.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ViewMode } from '../components.module';
import { WindowNotification } from '../window-notifications/window-notifications.component';

@Component({
  selector: 'acb-alcon-edit-claim-host',
  template: `
    <div fxLayout="column">
      <acb-alcon-window-notifications [windowNotifications]="notifications">
      </acb-alcon-window-notifications>
      <div style="overflow:auto; height: 100%; padding: 2em">
        <acb-alcon-edit-claim
          [claimID]="claimID"
          [claim] = "claim"
          [viewMode]="viewMode"
          (viewCommitment)="viewCommitment.emit($event)"
          (viewClaim)="viewClaim.emit($event)"
          (editDetails)="onEditDetails()"
          (loading)="loading.emit($event)"
          (claimLoaded)="claimLoaded.emit($event)"
          (valid)="valid.emit($event)"
        ></acb-alcon-edit-claim>
      </div>
    </div>
  `,
  styleUrls: ['./edit-claim-host.component.scss']
})
export class EditClaimHostComponent implements OnInit, OnDestroy {

  public notifications:WindowNotification[] = [];

  @Input() viewMode: ViewMode = 'edit'
  @Input() validateClaimOnLoad: Boolean = false;
  @Input() claimID?: number;

  private _claim?: ClaimWithDetailsModel;
  @Input() set claim(value) {
    this._claim = value;
  };
  get claim() {
    return this._claim;
  }

  @Output() viewCommitment: EventEmitter<any> = new EventEmitter();
  @Output() viewClaim: EventEmitter<any> = new EventEmitter();
  @Output() loading: EventEmitter<boolean> = new EventEmitter();
  @Output() claimLoaded: EventEmitter<ClaimWithDetailsModel> = new EventEmitter();
  @Output() claimChanged: EventEmitter<ClaimWithDetailsModel> = new EventEmitter();
  @Output() valid: EventEmitter<boolean> = new EventEmitter();

  public destroy$: Subject<void> = new Subject<void>();

  constructor(
    private claimEditService: ClaimEditService
  ) {
    claimEditService.claim$.pipe(takeUntil(this.destroy$)).subscribe(x => {
      this.claimChanged.emit(x);
    })
  }

  ngOnInit(): void {
  }

  public onEditDetails() {
    // const dialogSpec = this.appWindowService.openEditClaimDetails();
    // const component = dialogSpec?.component;
    // const dialogRef = dialogSpec?.dialogRef;

    // if (dialogSpec?.component)
    //   dialogSpec.component.claimEditService = this.claimEditService;

    // if (component && dialogRef) {
    //   component.cancel.pipe(take(1)).subscribe(x => {
    //     this.claimEditService.resetForm();
    //     dialogRef.close();
    //   })
    //   component.apply.pipe(take(1)).subscribe(x => {
    //     this.claimEditService.applyDetailsFromForm();
    //     dialogRef.close();
    //   })
    // }
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
