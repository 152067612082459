import { Component, EventEmitter, forwardRef, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ControlValueAccessor, DefaultValueAccessor, UntypedFormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ComboBoxComponent } from '@progress/kendo-angular-dropdowns';

export type BatchRole = 'From' | 'To';
export type BatchSubjectType = 'territory' | 'fund';

export class BatchSubject {
  constructor(
    public subjectID: string,
    public nativeID: number,
    public name: string,
    public batchSubjectType: BatchSubjectType,
    public available = 0,
    public isOrphan: boolean = false
  ) {}
}

export class BatchSubjectWithAdjustment extends BatchSubject {
  constructor(
    subjectID: string,
    nativeID: number,
    name: string,
    batchSubjectType: BatchSubjectType,
    available = 0,
    public adjusted = 0,
    public projected = 0
  ) {
    super(subjectID, nativeID, name, batchSubjectType, available)
  }
}

@Component({
  selector: 'acb-alcon-territory-with-balance-formfield',
  template: `
    <ng-container *ngIf="filteredSubjects && formGroup">
      <kendo-formfield *appFieldExtentions  fxFlex [formGroup]="formGroup">
        <kendo-label [for]="subject" [text]="batchRole"></kendo-label>
        <kendo-combobox
          [data]="filteredSubjects"
          [textField]="'name'"
          [valueField]="'subjectID'"
          [virtual]="{ itemHeight: 28 }"
          [filterable]="true"
          (filterChange)="onFilter($event)"
          (selectionChange)="this.selectionChange.emit($event)"
          [formControlName]="this.formControlName"
          #subject
          [popupSettings]="{
            popupClass: 'acb-territory-dropdown-popup',
            width: 450
          }"
          >
          <ng-template kendoComboBoxHeaderTemplate let-dataItem>
            <div class="acb-dropdown-popup-column-titles acb-territory"><span class="twbff-dropdown-title-territory">Fund / Territory</span><span class="twbff-dropdown-title-balance">Available</span></div>
          </ng-template>
          <ng-template kendoComboBoxItemTemplate let-dataItem>
            <div class="acb-dropdown-popup-column-value" [ngClass]="{ 'acb-territory': dataItem.batchSubjectType == 'territory', 'acb-fund': dataItem.batchSubjectType == 'fund'}" fxLayout="row">
              <span class="acd-display-name">{{ dataItem.name }}</span>
              <span fxFlex></span>
              <span class="acd-display-balance" [ngClass]="{'acd-red': dataItem.available < 0}">{{ dataItem.available | currency }}</span>
            </div>
          </ng-template>
          <ng-template kendoComboBoxFooterTemplate let-dataItem>
          </ng-template>
        </kendo-combobox>
        <kendo-formhint>&nbsp;</kendo-formhint>
        <kendo-formerror>Subject is required</kendo-formerror>
      </kendo-formfield>
    </ng-container>
    <div class="acb-territory-details" [style.display]="subject?.value ? 'flex' : 'none' ">
      <table>
        <tbody>
          <tr class="acb-territory-detail-row acb-avail-amount">
            <td class="acb-territory-detail-value" [ngClass]="{ 'acb-red': selectedSubjectWithAdjustment && selectedSubjectWithAdjustment.available < 0 }">{{ selectedSubjectWithAdjustment?.available | currency }}</td>
            <td class="acb-territory-detail-label">Avail</td>
          </tr>
          <tr class="acb-territory-detail-row">
            <td class="acb-territory-detail-value" [ngClass]="{ 'acb-red': selectedSubjectWithAdjustment && selectedSubjectWithAdjustment.adjusted < 0 }">{{ this.selectedSubjectWithAdjustment?.adjusted | currency }}</td>
            <td class="acb-territory-detail-label">Adj</td>
          </tr>
          <tr class="acb-territory-detail-row acb-step-amount">
            <td class="acb-territory-detail-value" [ngClass]="{ 'acb-red': selectedSubjectWithAdjustment && selectedSubjectWithAdjustment.projected < 0 }">{{ this.selectedSubjectWithAdjustment?.projected | currency }}</td>
            <td class="acb-territory-detail-label">Step</td>
          </tr>
        </tbody>
      </table>
    </div>
  `,
  styleUrls: ['territory-with-balance-formfield.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => TerritoryWithBalanceFormfieldComponent),
    }
  ]
})
export class TerritoryWithBalanceFormfieldComponent implements OnInit, ControlValueAccessor  {

  @ViewChild('subject') public subject?: ComboBoxComponent;
  @ViewChild('subject') public valueAccessor?: DefaultValueAccessor;

  private _subjects?: BatchSubject[] | null;
  @Input() set subjects(value: BatchSubject[] | undefined | null) {
    this.filteredSubjects = this._subjects = value;
  }
  get subjects() { return this._subjects; }

  @Input() formControlName: string = '';
  @Input() selectedSubjectWithAdjustment?: BatchSubjectWithAdjustment | null;
  @Input() formGroup?: UntypedFormGroup;
  @Input() batchRole: BatchRole = "From";

  //@Output() filterChange = new EventEmitter<any>();
  @Output() selectionChange = new EventEmitter<any>();

  public filteredSubjects?: BatchSubject[] | null;

  public value?: BatchSubject;

  constructor() { }
  writeValue(obj: any): void {
    this.subject?.writeValue(obj);
  }
  registerOnChange(fn: any): void {
    this.subject?.registerOnChange(fn);
  }
  registerOnTouched(fn: any): void {
    this.subject?.registerOnTouched(fn);
  }

  setDisabledState(isDisabled: boolean) {
    this.subject?.setDisabledState(isDisabled);
  }

  ngOnInit(): void {
  }

  public onFilter(value: string) {
    this.filteredSubjects = value ? (this.subjects?.filter(x => x.name!.toLowerCase().indexOf(value.toLowerCase()) > -1) ?? undefined) : this.subjects;
  }

}
