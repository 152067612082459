import { Component, OnInit, Input, Output, EventEmitter, ViewChild, forwardRef } from '@angular/core';
import { TreeItem, TreeViewComponent } from '@progress/kendo-angular-treeview';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

// Note:  Decided nopt to use this control for its intended purpose, feature association selctor.  Not 100% working.  Might come in handy later.
// Based partly on https://stackblitz.com/edit/angular-gpfyck?file=app%2Fapp.component.ts

@Component({
  selector: 'acb-alcon-drop-down-tree',
  templateUrl: './drop-down-tree.component.html',
  styleUrls: ['./drop-down-tree.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DropDownTreeComponent),
      multi: true
    }
  ]
})
export class DropDownTreeComponent implements OnInit, ControlValueAccessor {

  constructor() {

    this.selectionChange = this.treeView?.selectionChange
  }

  @ViewChild('treeview')
  treeView?: TreeViewComponent;

  @Input()
  nodes?: any[];

  @Input()
  textField?: string;

  @Input()
  childrenField?: string;

  @Output()
  selectionChange?: EventEmitter<TreeItem>;

  onChange: any = () => {};
  onTouched: any = () => {};

  private _selectedKeys: string[] = [""];
  public get selectedKeys(): string[] {
    return this._selectedKeys;
  }

  public set selectedKeys(v: string[]) {
    this._selectedKeys = v;

    this.onChange(v);
    this.onTouched();
  }

  public expandedKeys: string[] = [""];

  public show: boolean = false;

  writeValue(keys: any): void {
    this.selectedKeys = Array.isArray(keys) ? keys : [keys];
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  ngOnInit(): void {
  }

  public onSelection({ index }: any): void {
    this.show = false;
  }

  private expandBySelection() {
  }
}
