  <kendo-treelist
    kendoTreeListExpandable
    [kendoTreeListHierarchyBinding]="features"
    [resizable] = "false"
    idField = "featureID"
    childrenField="children"
    scrollable="none"
    [sortable]="true"
    filterable="menu"
    [rowClass]="getRowClass"
    [initiallyExpanded]="initiallyExpanded"
    [columnMenu]="{ sort: true, columnChooser: true, filter: true }"
    >
    <kendo-treelist-messages [noRecords]="isLoading ? 'Loading...' : 'No matching records' "></kendo-treelist-messages>
    <ng-template kendoTreeListToolbarTemplate>
      <button kendoButton (click)="createFeature.emit($event)">{{ labelForCreateNew }}</button>
    </ng-template>
    <kendo-treelist-column field="displayName" title="Name" [expandable]="true">
      <ng-template kendoTreeListCellTemplate let-dataItem >
          <span class="acb-feature-row-icon k-treelist-ignore-click"></span>
          <span class="acb-feature-row-name">{{dataItem.displayName}}</span>
      </ng-template>
    </kendo-treelist-column>
    <kendo-treelist-column field="displayDescription" title="Description" media="xl" [hidden]="true"></kendo-treelist-column>
    <kendo-treelist-column field="uri" title="URI" media="md" [hidden]="true"></kendo-treelist-column>
    <kendo-treelist-column field="sortOrder" title="Sort Oder" media="lg" [width]="120"></kendo-treelist-column>
    <kendo-treelist-column field="accessRoles" title="Roles" [class]="{'acb-feature-column-access-role-list-compact': doShowAllAccessRoles }" media="sm">
      <ng-template kendoTreeListCellTemplate let-isNew="isNew" let-cellContext="cellContext" let-dataItem="dataItem">
        <div *ngIf="dataItem.accessRoles?.length < 2 || doShowAllAccessRoles; else elseBlock" class="acb-access-role-list" >
          <span *ngFor="let accessRoleID of dataItem.accessRoles; let isLast = last">{{ this.lookUpAccessRoleName(accessRoleID, true) }}{{ isLast ? '' : ', '  }}</span>
        </div>
        <ng-template #elseBlock>
          {{ dataItem.accessRoles?.length }} Roles Assigned
          <button kendoButton [icon]="'question'" look="flat"></button>
        </ng-template>
      </ng-template>
    </kendo-treelist-column>
    <kendo-treelist-column title="Active" field="statusCode" [width]="80" class="acb-user-grid-yesno-column">
      <ng-template kendoTreeListCellTemplate let-dataItem>
        <span [ngClass]="{'acb-user-grid-value-no': dataItem.statusCode != StatusCode.Active  }">{{ dataItem.statusCode == StatusCode.Active ? 'Yes' : 'No'}}</span>
      </ng-template>
    </kendo-treelist-column>
    <!-- <kendo-treelist-column title="Visible" field="visibilityType" [width]="80" class="acb-user-grid-yesno-column">
      <ng-template kendoTreeListCellTemplate let-dataItem>
        <span [ngClass]="{'acb-user-grid-value-no': dataItem.visibilityType != VisibilityType.Visible  }">{{ dataItem.visibilityType == VisibilityType.Visible ? 'Yes' : 'No'}}</span>
      </ng-template>
    </kendo-treelist-column> -->
    <kendo-treelist-command-column [width]="80">
      <ng-template kendoTreeListCellTemplate let-isNew="isNew" let-cellContext="cellContext" let-dataItem="dataItem">
          <button *ngIf="dataItem.canEdit"
            [kendoTreeListEditCommand]="cellContext"
            icon="edit" title="Edit" look="flat"
            (click)="editFeature.emit({pointerEvent: $event, dataItem: dataItem, cellContext: cellContext, isNew: isNew})">
          </button>
          <!-- <button *ngIf="dataItem.featureMandateType === FeatureMandateType.User"
            [kendoTreeListRemoveCommand]="cellContext" icon="delete" title="Remove" look="flat">
          </button> -->
      </ng-template>
    </kendo-treelist-command-column>
  </kendo-treelist>
