import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { throwError, Observable, BehaviorSubject } from 'rxjs';
import { catchError, first, map, tap } from 'rxjs/operators';
import { GridResultModel } from './../../../../../../exports/acb-alcon-data/GridResultModel';
import { GridDataResult } from '@progress/kendo-angular-grid';
import { ReportRequestModel } from '@alcon-db-models/ReportRequestModel';
import { ApplicationService } from './application.service';
import { ExcelExportData } from '@progress/kendo-angular-excel-export';
import { ServiceResponse, firstWithTimeout, getErrorMessage, firstWithLoadingOverlayAndErrorHandling } from '../shared/acb-stream';
import { JsonUtilities } from '../shared/json-utilities';
import { AppUxService } from './app-ux.service';

@Injectable({
  providedIn: 'root'
})
export class ReportResultsService extends BehaviorSubject<GridDataResult> {

  public loading: boolean = false;

  constructor(
      protected http: HttpClient,
      protected router: Router,
      protected store: Store,
      private applicationService: ApplicationService,
      private appUxService: AppUxService
    ) {
    super((<GridDataResult>{ data: [], total: 0 }));
  }

  public query(searchRequest: ReportRequestModel): void {
    this.fetch(searchRequest).pipe(first()).subscribe(x => super.next(x));
  }

  protected fetch(searchRequest: ReportRequestModel): Observable<GridDataResult> {
    this.loading = true;
    return this.search(searchRequest).pipe(
      map(x => (<GridDataResult>{ data: x.value, total: x.total})),
      tap(() => this.loading = false)
    );
  }

  private createParams(searchRequest: ReportRequestModel) {
    let params: { [param: string]: string | string[]; } = {};
    Object.keys(searchRequest).filter(x => (searchRequest as any)[x] != null).forEach(x => params[x] = (searchRequest as any)[x]);
    return params;
  }

  public search(searchRequest: ReportRequestModel): Observable<GridResultModel> {
    return this.applicationService.getReport(searchRequest).pipe(catchError((e) => throwError("application/identity failed")));
  }

  public searchWithErrorHandlingAndLoadingIndicator(searchRequest: ReportRequestModel): Observable<GridResultModel> {
    //let params = this.createParams(searchRequest);
    return this.applicationService.getReport(searchRequest).pipe(
      firstWithLoadingOverlayAndErrorHandling<GridResultModel>(),
      tap(x => {
        if (x.hasError) {
          this.appUxService.openErrorDialog(x.errorMessage);
        }
      }),
      map(x => x.response as GridResultModel)
    );
  }

  public searchForExcelExport(searchRequest: ReportRequestModel): Observable<ExcelExportData> {
    return this.searchWithErrorHandlingAndLoadingIndicator(searchRequest).pipe(
      map(x => {
        let data = x?.value ?? undefined;
        data = x?.value?.map(y => JsonUtilities.convertDatesAndCopy(y));
        return ({ data: data });
      })
    )
  }
}


