import { ClaimWithDetailsModel } from '@alcon-db-models/ClaimWithDetailsModel';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { selectBusinessRules } from '@app-store/app-session/app-session.selectors';
import { Store } from '@ngrx/store';
import { ClaimWithDetailsService } from '@services/claim-with-details.service';
import { Observable } from 'rxjs';
import { first } from 'rxjs/operators';

@Component({
  selector: 'acb-alcon-view-claim',
  template: `
    <ng-container *ngIf="claim$ | async; let claimData">
      <div [hidden]="isLoading" style="padding: 1em;">
        <acb-alcon-view-claim-details
          [claim]="claimData"
          (viewCommitment)="onViewCommitment($event)"
          (viewClaim)="onViewClaim($event)">
        </acb-alcon-view-claim-details>

        <div fxLayout="row wrap" fxLayoutAlign="start start" [ngClass.gt-xs]="'acb-intra-form-margin-correction'">
          <div
            class="acb-commitment acb-view-mode"
            fxLayout="row"
            fxFlex="1 0 16em"
            fxFlex.lt-sm="grow"
            [ngClass]="{ 'acb-expanded': commitmentDisplay.isExpanded }"
            [ngClass.gt-xs]="{ 'acb-expanded': commitmentDisplay.isExpanded, 'acb-fx-intra-form-margin-controlled-container': true }"
            >
            <div class="acb-details-layout-section acb-section2" fxFlex fxLayout="column">
              <acb-alcon-display-info-card
                #commitmentDisplay
                [label]="'Commitment'"
                [headerValue]="claimData?.commitmentID ? claimData?.commitmentID?.toString() : ''"
                [nameAndValues] = "[
                  ['ID', claimData?.commitmentID ? claimData?.commitmentID?.toString() : '', true],
                  ['Customer', claimData?.customer, false],
                  ['Fund', claimData?.fund, false]
                ]"
                [isExpandedOnly]="false"
                fxFlex
                ></acb-alcon-display-info-card>
            </div>
          </div>

          <div
            class="acb-payee acb-view-mode"
            fxLayout="row"
            fxFlex="1 0 16em"
            fxFlex.lt-sm="grow"
            [ngClass]="{ 'acb-expanded': payeeDisplay.isExpanded }"
            [ngClass.gt-xs]="{ 'acb-expanded': payeeDisplay.isExpanded, 'acb-fx-intra-form-margin-controlled-container': true }"
            >
            <div class="acb-details-layout-section acb-section3" fxFlex fxLayout="column">
              <acb-alcon-display-address
                #payeeDisplay
                [label]="'Payee'"
                [code]="claimData?.payeeCustomerCode"
                [name]="claimData?.payee"
                [location]="claimData?.payeeLocation"
                [isExpandedOnly]="false"
                fxFlex
                ></acb-alcon-display-address>
            </div>
          </div>
        </div>

        <div fxLayout="row" fxLayoutGap="1em" *ngIf="doesUseClaimDoctors">
          <acb-alcon-section-with-legend
            class="acb-doctors-section acb-section-10"
            #section
            [sectionTitle]="'Doctors'"
            style="width: 100%; height: 100%;"
            [isCollapsible]="true"
            >
            <acb-alcon-view-claim-doctors [claim]="claimData">
            </acb-alcon-view-claim-doctors>
          </acb-alcon-section-with-legend>

          <acb-alcon-section-with-legend
            class="acb-attachments-section acb-section-11"
            #section
            [sectionTitle]="'Products'"
            style="width: 100%; height: 100%;"
            [isCollapsible]="true"
            >
            <acb-alcon-view-claim-products [claim]="claimData">
            </acb-alcon-view-claim-products>
          </acb-alcon-section-with-legend>
        </div>

        <acb-alcon-section-with-legend *ngIf="!doesUseClaimDoctors"
          class="acb-attachments-section acb-section-10"
          #section
          [sectionTitle]="'Products'"
          style="width: 100%; height: 100%;"
          [isCollapsible]="true"
          >
          <acb-alcon-view-claim-products [claim]="claimData">
          </acb-alcon-view-claim-products>
        </acb-alcon-section-with-legend>

        <acb-alcon-section-with-legend
          class="acb-attachments-section acb-section-06"
          #section
          [sectionTitle]="'Attachments'"
          style="width: 100%; height: 100%;"
          [isCollapsible]="true"
          >
          <acb-alcon-view-claim-attachments [claim]="claimData" [doShowFieldLabel]="false"></acb-alcon-view-claim-attachments>
        </acb-alcon-section-with-legend>

        <div class="acb-outer-section">
          <acb-alcon-view-claim-review-history [claim]="claimData"></acb-alcon-view-claim-review-history>
        </div>
      </div>
    </ng-container>
  `,
  styleUrls: ['./view-claim.component.scss']
})
export class ViewClaimComponent implements OnInit {

  doesUseClaimDoctors: boolean = false;

  @Input() public set claimID(value: number | undefined) {
    this.loading.emit(this.isLoading = true);
    this.claim$ = this.claimWithDetailsService.getByKey(value);
    this.claim$.pipe(first()).subscribe(x => {
      this.claimChanged.emit(x);
      this.loading.emit(this.isLoading = false);
    });
  };

  @Output() viewCommitment: EventEmitter<number|undefined> = new EventEmitter();
  @Output() viewClaim: EventEmitter<number|undefined> = new EventEmitter();
  @Output() loading: EventEmitter<boolean> = new EventEmitter();
  @Output() claimChanged: EventEmitter<ClaimWithDetailsModel> = new EventEmitter();

  public isLoading:boolean = true;
  public claim$: Observable<ClaimWithDetailsModel> = new Observable;

  constructor(
    private claimWithDetailsService: ClaimWithDetailsService,
    private store: Store
    ) {
    //this.claimWithDetailsService.loading$.subscribe(x => this.loading.emit(this.isLoading = x));
    store.select(selectBusinessRules).pipe(first()).subscribe(x => {
      this.doesUseClaimDoctors = x.DoesUseClaimDoctors;
    })
  }

  ngOnInit(): void {
  }

  public onViewCommitment(commitmentID:number | undefined) {
    this.viewCommitment.emit(commitmentID);
  }

  public onViewClaim(claimID:number | undefined) {
    this.viewClaim.emit(claimID);
  }

}
