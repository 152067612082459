import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { GroupDescriptor,process, State } from '@progress/kendo-data-query';
import { GridComponent } from '@progress/kendo-angular-grid';
import { timer } from 'rxjs';
import { first } from 'rxjs/operators';
import { ViewMode } from '../components.module';

@Component({
  selector: 'acb-alcon-view-phasing-year',
  template: `
    <ng-container *ngIf="phaseActualYear && chartData && gridData">
      <div class="acb-phase-year acb-alcon-display-item-label" *ngIf="viewMode === 'view'">
        {{ year }} Phase vs. Actual
      </div>
      <div fxLayout="row wrap" fxLayoutGap="1.5em" fxLayoutAlign="start stretch">
        <div fxFlex="1 0 12em" fxFlexAlign="stretch" *ngIf="viewMode === 'view'">
          <kendo-chart
            #chart
            [categoryAxis]="{ categories: chartData!.categories, labels: { rotation: 'auto', color: '#3799a5' }}"
            [valueAxis]="{ labels: { format: '{0:c0}', font: '75% Open Sans', color: '#3799a5' }}"
            [transitions]="false"
            [ngStyle]="{'height':'100%'}"
            [chartArea]="{opacity: 0}"
            >
            <kendo-chart-tooltip>
            </kendo-chart-tooltip>
            <kendo-chart-series>
              <kendo-chart-series-item type="line" [data]="chartData!.phaseAmounts">
                <kendo-chart-series-item-tooltip>
                  <ng-template let-value="value">
                      Projection: {{ value | currency }}
                  </ng-template>
                </kendo-chart-series-item-tooltip>
              </kendo-chart-series-item>
              <kendo-chart-series-item type="column" [data]="chartData!.actualAmounts">
                <kendo-chart-series-item-tooltip>
                  <ng-template let-value="value">
                      Actual: {{ value | currency }}
                  </ng-template>
                </kendo-chart-series-item-tooltip>
              </kendo-chart-series-item>
            </kendo-chart-series>
          </kendo-chart>
        </div>
        <div class="acb-phase-actual-grid-wrapper" fxLayout>
          <kendo-grid
            class="acb-phase-actual-grid"
            [data]="gridData"
            [sortable]="false"
            [scrollable]="'none'"
            [groupable]="{ enabled: true, showFooter: true }"
            [group]="group"
            #grid
            >
            <kendo-grid-column field="month" title="" [groupable]="false" [width]="40">
              <ng-template kendoGridFooterTemplate>
                <span style="font-weight: normal4">Total</span>
              </ng-template>
            </kendo-grid-column>
            <kendo-grid-column field="phaseAmount" title="Proj" [groupable]="false" [format]="'{0:c}'"  [style]="{'text-align': 'right'}">
              <ng-template kendoGridGroupHeaderColumnTemplate let-group="group" let-aggregates="aggregates">
                <div style="width:100%; text-align: right">
                  {{ aggregates.phaseAmount.sum | currency }}
                </div>
              </ng-template>
              <ng-template kendoGridFooterTemplate>
                {{ phaseActualYear!.phaseTotal | currency }}
              </ng-template>
            </kendo-grid-column>
            <kendo-grid-column field="actualAmount" title="Actual" [groupable]="false" [format]="'{0:c}'"  [style]="{'text-align': 'right'}">
              <ng-template kendoGridGroupHeaderColumnTemplate let-group="group" let-aggregates="aggregates">
                <div style="width:100%; text-align: right">
                  {{ aggregates.actualAmount.sum | currency }}
                </div>
              </ng-template>
              <ng-template kendoGridFooterTemplate>
                {{ phaseActualYear!.actualTotal | currency }}
              </ng-template>
            </kendo-grid-column>
            <kendo-grid-column field="performanceAmount" title="Perf" [groupable]="false" [format]="'{0:p0}'"  [style]="{'text-align': 'right'}">
              <ng-template kendoGridCellTemplate let-dataItem>
                <span class="acb-grid-na" *ngIf="dataItem.performanceAmount == undefined; else hasValue;">--</span>
                <ng-template #hasValue><span [ngStyle]="{'color': getColorByPercent(dataItem.performanceAmount, true)}">{{dataItem.performanceAmount | percent}}</span></ng-template>
              </ng-template>
              <ng-template kendoGridGroupHeaderColumnTemplate let-index="index" let-group="group" let-aggregates="aggregates">
                <div style="width:100%; text-align: right">
                  <span *ngIf="getPerfomanceAggregate(index,group,aggregates) != undefined; else hasNoValue;" [ngStyle]="{'color': getColorByPercent(getPerfomanceAggregate(index,group,aggregates), true)}">
                    {{ getPerfomanceAggregate(index,group,aggregates) | percent }}
                  </span>
                  <ng-template #hasNoValue><span class="acb-grid-na">--</span></ng-template>
                </div>
              </ng-template>
              <ng-template kendoGridFooterTemplate>
                <span *ngIf="phaseActualYear?.performance != undefined; else hasNoValue;" [ngStyle]="{'color': getColorByPercent(phaseActualYear!.performance, true)}">
                  {{ phaseActualYear!.performance | percent }}
                </span>
                <ng-template #hasNoValue><span class="acb-grid-na">--</span></ng-template>
              </ng-template>
            </kendo-grid-column>
            <kendo-grid-column field="quarter" title="Quarter" [groupable]="false" [hidden]="true">
              <ng-template kendoGridGroupHeaderTemplate let-value="value">
                {{value}}
              </ng-template>
            </kendo-grid-column>
          </kendo-grid>
        </div>

      </div>
   </ng-container>
  `,
  styleUrls: ['./view-phasing-year.component.scss']
})
export class ViewPhasingYearComponent implements OnInit {

  @ViewChild('grid') private grid?: GridComponent;

  @Input() viewMode:ViewMode = 'view';

  public aggregates: any[] = [{ field: 'phaseAmount', aggregate: 'sum' }, { field: 'actualAmount', aggregate: 'sum' }, { field: 'performanceAmount', aggregate: 'average' }];
  public group: GroupDescriptor[] = [{field: 'quarter', aggregates: this.aggregates }]
  public state: State = { group: this.group };
  public gridData?: any;
  public chartData?: { categories: string[], phaseAmounts: number[], actualAmounts: {}[] };
  public year?: number;

  private _phaseActualYear?: PhaseActualYear;
  @Input() set phaseActualYear(value: PhaseActualYear | undefined) {

    if (this._phaseActualYear == value)
      return;

    this._phaseActualYear = value ?? undefined;
    if (!value) {
      this.gridData = undefined;
      this.chartData = undefined;
      this.year = undefined;
      return;
    }

    this.gridData = process(value?.quarterAndMonths ?? [], this.state);
    this.chartData = {
      categories: value.quarterAndMonths?.map(x => x.month) ?? [],
      phaseAmounts: value.quarterAndMonths?.map(x => x.phaseAmount) ?? [],
      actualAmounts: value.quarterAndMonths?.map(x => ({ value: x.actualAmount, color: this.getColor(x.phaseAmount, x.actualAmount) })) ?? []
    }
    this.year = value?.year;
  }
  get phaseActualYear() {
    return this._phaseActualYear;
  }

  constructor() { }

  public getPerfomanceAggregate(index:number,group:any,aggregates:any) {
    if (group.items) {
      const nums = (group.items as any[]).map(x => x.performanceAmount).filter(x => x != undefined);
      return nums.length ? nums.reduce((a,v) => a + v) / nums.length : undefined;
    }
    return undefined;
  }

  private getColor = (p:number, a:number) => this.getColorByPercent(a ? p ? a / p : 1 : 0);

  getColorByPercent = (v?:number, isText:boolean = false) => {
    const C1 = "#007483";
    const C2 = "#003595";
    const C3 = isText ? "#999996" : "#BBBBB6";
    if (v == undefined) return C2;
    if (v > .9 && v < 1.1) return C2;
    if (v >= 1.1) return C1;
    return C3
  }

  ngOnInit(): void {

    timer(100,100).pipe(first(() => !!this.grid)).subscribe(() => {
      this.gridData.data.forEach((_:any, i:number) => {
        this.grid?.collapseGroup(String(i));
      });
    })
  }

}

export class PhaseActualYear {
  constructor(
    public year: number,
    public quarterAndMonths: {
      quarter: string,
      quarterNumber: number,
      month: string,
      monthNumber: number,
      phaseAmount: number,
      actualAmount: number,
      performanceAmount?: number
    }[],
    public phaseTotal: number,
    public actualTotal: number,
    public performance?: number
  ) { }
}

