import { FundWithDetailsModel } from '@alcon-db-models/FundWithDetailsModel';
import { Injectable } from '@angular/core';
import { EntityCollectionServiceBase, EntityCollectionServiceElementsFactory } from '@ngrx/data';

@Injectable({
  providedIn: 'root'
})
export class FundWithDetailsService extends EntityCollectionServiceBase<FundWithDetailsModel> {
  constructor(serviceElementsFactory: EntityCollectionServiceElementsFactory) {
    super('FundWithDetails', serviceElementsFactory);
  }
}

