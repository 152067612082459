import { Component, Input, OnInit } from '@angular/core';
import { Observable, of } from 'rxjs';

@Component({
  selector: 'acb-alcon-dashboard-widget-chart',
  template: `
  `,
  styles: [
  ]
})
export class DashboardWidgetChartComponent implements OnInit {
  
  @Input() public series$: Observable<any> = of([]);

  constructor() { }

  ngOnInit(): void {
  }

}
