import { CommitmentSearchWithDefaultRequestModel } from '@alcon-db-models/CommitmentSearchWithDefaultRequestModel';
import { Directive } from '@angular/core';
import { GridComponent } from '@progress/kendo-angular-grid';
import { FilterDescriptor } from '@progress/kendo-data-query';
import { AppUxService } from '@services/app-ux.service';
import { CommitmentSelectSearchService } from '@services/commitment-select-search.service';
import { SelectSearchBindingBaseDirective } from './../directives/select-search-binding.base.directive'

@Directive({
  selector: '[commitmentSelectSearchBinding]'
})
export class CommitmentSelectSearchBindingDirective extends SelectSearchBindingBaseDirective {

  constructor(commitmentSelectSearchService: CommitmentSelectSearchService, grid: GridComponent, appUxService: AppUxService) {
    super(commitmentSelectSearchService, grid, appUxService);
  }

  public getSearchRequest(doIgnorePagination: boolean = false): CommitmentSearchWithDefaultRequestModel {

    this.beforeBind.emit(null);

    const filters: FilterDescriptor[] = this.state.filter?.filters as FilterDescriptor[];

    const getValue = function(col:string) {
      let descriptor: FilterDescriptor;
      return (descriptor = filters?.find(x => x.field == col) as FilterDescriptor) ? descriptor?.value : null
    };

    return ({
      personID: null,
      take: doIgnorePagination ? null : this.state.take,
      skip: doIgnorePagination ? 0 : this.state.skip,
      sortColumn: this.state.sort?.[0]?.dir ? this.state.sort?.[0]?.field : null,
      sortAscending: this.state.sort?.[0]?.dir ? this.state.sort[0].dir != 'desc' : null,
      name: getValue('name'),
      customerCodeOrName: getValue('customerCodeOrName'),
      commitmentID: getValue('commitmentID'),
      prependCommitmentID: getValue('prependCommitmentID')
    });
  }

}



