import { CommitmentOrganizationsModel } from '@alcon-db-models/CommitmentOrganizationsModel';
import { CustomerSearchWithDefaultModel } from '@alcon-db-models/CustomerSearchWithDefaultModel';
import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { selectStaticTypes } from '@app-store/app-session/app-session.selectors';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map, first } from 'rxjs/operators';
import { StaticTypeModel } from '../../../../../../../Libraries/ACB.Alcon.Data/Exports/StaticTypeModel';

@Component({
  selector: 'acb-alcon-select-payee-dialog',
  template: `
    <kendo-dialog-titlebar class="acb-select-payee-titlebar" (close)="cancel.emit()">
      Select Payee
    </kendo-dialog-titlebar>
    <acb-alcon-select-payee
      [viewMode]="'edit'"
      [payee] = "selectedPayee"
      [commitmentOrganizations]="commitmentOrganizations"
      (payeeSelected)="onPayeeSelected($event)"
      [doShowLegend]="false"
      >
    </acb-alcon-select-payee>
    <kendo-dialog-actions>
      <button kendoButton class="acb-cancel" (click)="cancel.emit()"><span class="k-icon k-i-cancel"></span>Cancel</button>
      <button kendoButton [primary]="true" [disabled]="!isSelected" (click)="onSelect()" ><span class="k-icon k-i-checkmark"></span>Select</button>
    </kendo-dialog-actions>
  `,
  styleUrls: ['./select-payee-dialog.component.scss']
})
export class SelectPayeeDialogComponent implements OnInit {

  //TODO: Clean up location info.  Currently hacking stateProvinceCode and countryCode enums into expanded values from store static models.
  //Backend should return this data in the location

  public staticTypes$: Observable<StaticTypeModel[]> = this.store.select(selectStaticTypes);
  public stateProvinceCodes$: Observable<StaticTypeModel[]> = this.staticTypes$.pipe(map(x => x.filter(y => y.tableName == 'StateProvinceCode')));
  public countryCodes$: Observable<StaticTypeModel[]> =  this.staticTypes$.pipe(map(x =>  x.filter(y => y.tableName == 'CountryCode')));
  private states: StaticTypeModel[] = [];
  private countries: StaticTypeModel[] = [];

  private _commitmentOrganizations?: CommitmentOrganizationsModel;
  @Input() public set commitmentOrganizations(value:CommitmentOrganizationsModel | undefined) {
    this._commitmentOrganizations = value;
    if (!this.selectedPayee) {

      let stateInfo = this.states.find(y => y.id == this.commitmentOrganizations?.payeeLocation?.stateProvinceCode);
      let countryInfo = this.countries.find(y => y.id == this.commitmentOrganizations?.payeeLocation?.countryCode);

      this.selectedPayee = {
        customer: this.commitmentOrganizations?.payee,
        customerID: this.commitmentOrganizations?.payeeCustomerID,
        customerCode: this.commitmentOrganizations?.payeeCustomerCode,
        ...this.commitmentOrganizations?.payeeLocation,
        stateProvinceCodeID: stateInfo?.id,
        stateProvinceCode: stateInfo?.code,
        stateProvince: stateInfo?.displayName,
        countryCodeID: countryInfo?.id,
        countryCode: countryInfo?.code,
        country: countryInfo?.displayName
      };
    }
  }
  public get commitmentOrganizations():CommitmentOrganizationsModel | undefined {
    return this._commitmentOrganizations;
  }

  @Input() public set payee(value: CommitmentOrganizationsModel) {  this.selectedPayee = value }


  @Output() cancel: EventEmitter<any> = new EventEmitter();
  @Output() payeeSelected: EventEmitter<CustomerSearchWithDefaultModel | null> = new EventEmitter();

  get isSelected():boolean {
    return Boolean(this.selectedPayee?.customerID);
  }

  public selectedPayee?: CustomerSearchWithDefaultModel;

  constructor(private changeDetectorRef: ChangeDetectorRef, private store: Store) {
    this.stateProvinceCodes$.pipe(first()).subscribe(x => { this.states = x; });
    this.countryCodes$.pipe(first()).subscribe(x => { this.countries = x; });
  }

  getPayee():CustomerSearchWithDefaultModel {
    let stateInfo = this.states.find(y => y.id == this.commitmentOrganizations?.payeeLocation?.stateProvinceCode);
    let countryInfo = this.countries.find(y => y.id == this.commitmentOrganizations?.payeeLocation?.countryCode);

    return ({
      customer: this.commitmentOrganizations?.payee,
      customerID: this.commitmentOrganizations?.payeeCustomerID,
      customerCode: this.commitmentOrganizations?.payeeCustomerCode,
      ...this.commitmentOrganizations?.payeeLocation,
      stateProvinceCodeID: stateInfo?.id,
      stateProvinceCode: stateInfo?.code,
      stateProvince: stateInfo?.displayName,
      countryCodeID: countryInfo?.id,
      countryCode: countryInfo?.code,
      country: countryInfo?.displayName
    });
  }

  onPayeeSelected(payee:any) {
    this.selectedPayee = payee;
  }

  onSelect() {
    this.payeeSelected.emit(this.selectedPayee);
  }

  ngOnInit(): void {
  }

}
