import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'acb-alcon-request-claim-void',
  template: `
    <kendo-dialog-titlebar class="acb-request-name-titlebar" (close)="onCancel()">
      {{getTitle()}}
    </kendo-dialog-titlebar>
    <form [formGroup]="form" style="width: 100%;" class="k-form">
      <acb-alcon-section-with-legend [doShowLegend]="false" class="acb-section-05">
        <div fxLayout="column">
          <kendo-formfield *appFieldExtentions>
            <kendo-label [for]="comment" [optional]="false">Void Comment</kendo-label>
            <textarea formControlName="comment" kendoTextArea #comment rows="3" style="resize: vertical;"></textarea>
          </kendo-formfield>
          <kendo-formfield *appFieldExtentions>
            <kendo-label [for]="doReissueClaim" class="acb-form-label-and-checkbox" text="Reissue Claim(s)?"><input formControlName="doReissueClaim" type="checkbox" kendoCheckBox #doReissueClaim /></kendo-label>
          </kendo-formfield>
        </div>
      </acb-alcon-section-with-legend>
    </form>
    <kendo-dialog-actions>
      <button kendoButton class="acb-cancel" (click)="onCancel()" >Cancel</button>
      <button kendoButton [primary]="true" (click)="onSave()" [disabled]="this.form.invalid" >Save</button>
    </kendo-dialog-actions>
  `,
  styleUrls: ['./request-claim-void.component.scss']
})
export class RequestClaimVoidComponent implements OnInit {


  @Input() claimCount: number = 0;

  @Output() cancel: EventEmitter<any> = new EventEmitter();
  @Output() save: EventEmitter<any> = new EventEmitter();

  public form: UntypedFormGroup = new UntypedFormGroup({
    comment: new UntypedFormControl(null, [Validators.required]),
    doReissueClaim: new UntypedFormControl(false, [Validators.required])
  });

  constructor() { }

  getTitle() {
    return `Void ${this.claimCount} Claim${this.claimCount > 1 ? "s" : ""}?`
  }

  ngOnInit(): void {
    if (!this.claimCount) this.onCancel();
  }

  public onCancel() {
    this.cancel.emit(null);
  }

  public onSave() {

    this.save.emit(this.form.value);

  }


}
