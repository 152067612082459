<kendo-grid 
    #resultsGrid 
    lcpComparisonSearchBindingDirective 
    [pageable]="{
        buttonCount: 4,
        pageSizes: true
        }" 
    [sortable]="true" 
    [reorderable]="true" 
    [pageSize]="10" 
    [selectable]="false"
    [resizable]="true"    
    class="acb-results-grid" 
    (beforeBind)="updateFilter"
    >
    <ng-template kendoGridToolbarTemplate>
        <div fxLayout="row" fxLayoutAlign=" center">
            <div fxFlexAlign="center" class="acb-toolbar-adjacent">
                <kendo-grid-column-chooser [autoSync]="true" [allowHideAll]="false"></kendo-grid-column-chooser>
                <button kendoButton type="button" [icon]="'reset'" (click)="onResetGridPrefs()" [className]="'acb-teal'"
                    fxFlexOffset="1em">Reset Grid</button>
            </div>
            <div fxFlex='1.25 1 auto' fxFlex.xs='.75 1 auto'></div>
            <div fxFlex='1 1 auto'></div>
            <div fxFlexAlign="center" class="acb-toolbar-adjacent">
                <button kendoGridExcelCommand type="button" icon="file-excel" class="k-button">Export to Excel</button>
            </div>
        </div>
    </ng-template>
    <kendo-grid-column title="Channel" field="channel" [width]="120">
    </kendo-grid-column>
    <kendo-grid-column title="Cust #" field="customerNumber" [width]="80">
    </kendo-grid-column>
    <kendo-grid-column-group title="Sandbox">
        <kendo-grid-column title="Customer" field="sandbox_CustomerName" [width]="120">
        </kendo-grid-column>
        <kendo-grid-column title="AM Reps" field="sandbox_AMSalesReps" [width]="120">
            <ng-template kendoGridCellTemplate let-dataItem>
                <div [innerHTML]="formatCommaSpace(dataItem.sandbox_AMSalesReps)"></div>
            </ng-template>
        </kendo-grid-column>
        <kendo-grid-column title="AM Additions" field="sandbox_AMAdditions" [width]="120" class="acb-lcp-addition-column">
            <ng-template kendoGridCellTemplate let-dataItem>
                <div [innerHTML]="formatList(dataItem.sandbox_AMAdditions)"></div>
            </ng-template>
        </kendo-grid-column>
        <kendo-grid-column title="RSD Reps" field="sandbox_RSDSalesReps" [width]="120">
            <ng-template kendoGridCellTemplate let-dataItem>
                <div [innerHTML]="formatCommaSpace(dataItem.sandbox_RSDSalesReps)"></div>
            </ng-template>
        </kendo-grid-column>
        <kendo-grid-column title="RSD Additions" field="sandbox_RSDAdditions" [width]="120" class="acb-lcp-addition-column">
            <ng-template kendoGridCellTemplate let-dataItem>
                <div [innerHTML]="formatList(dataItem.sandbox_RSDAdditions)"></div>
            </ng-template>
        </kendo-grid-column>
        <kendo-grid-column title="ASD Reps" field="sandbox_ASDSalesReps" [width]="120">
            <ng-template kendoGridCellTemplate let-dataItem>
                <div [innerHTML]="formatCommaSpace(dataItem.sandbox_ASDSalesReps)"></div>
            </ng-template>
        </kendo-grid-column>
        <kendo-grid-column title="ASD Additions" field="sandbox_ASDAdditions" [width]="120" class="acb-lcp-addition-column">
            <ng-template kendoGridCellTemplate let-dataItem>
                <div [innerHTML]="formatList(dataItem.sandbox_ASDAdditions)"></div>
            </ng-template>
        </kendo-grid-column>
    </kendo-grid-column-group>
    <kendo-grid-column-group title="Production">
        <kendo-grid-column title="Customer" field="production_CustomerName" [width]="120">
        </kendo-grid-column>
        <kendo-grid-column title="AM Reps" field="production_AMSalesReps" [width]="120">
            <ng-template kendoGridCellTemplate let-dataItem>
                <div [innerHTML]="formatCommaSpace(dataItem.production_AMSalesReps)"></div>
            </ng-template>
        </kendo-grid-column>
        <kendo-grid-column title="AM Deletions" field="production_AMDeletions" [width]="120" class="acb-lcp-deletion-column">
            <ng-template kendoGridCellTemplate let-dataItem>
                <div [innerHTML]="formatList(dataItem.production_AMDeletions)"></div>
            </ng-template>
        </kendo-grid-column>
        <kendo-grid-column title="RSD Reps" field="production_RSDSalesReps" [width]="120">
            <ng-template kendoGridCellTemplate let-dataItem>
                <div [innerHTML]="formatCommaSpace(dataItem.production_RSDSalesReps)"></div>
            </ng-template>
        </kendo-grid-column>
        <kendo-grid-column title="RSD Deletions" field="production_RSDDeletions" [width]="120" class="acb-lcp-deletion-column">
            <ng-template kendoGridCellTemplate let-dataItem>
                <div [innerHTML]="formatList(dataItem.production_RSDDeletions)"></div>
            </ng-template>
        </kendo-grid-column>
        <kendo-grid-column title="ASD Reps" field="production_ASDSalesReps" [width]="120">
            <ng-template kendoGridCellTemplate let-dataItem>
                <div [innerHTML]="formatCommaSpace(dataItem.production_ASDSalesReps)"></div>
            </ng-template>
        </kendo-grid-column>
        <kendo-grid-column title="ASD Deletions" field="production_ASDDeletions" [width]="120" class="acb-lcp-deletion-column">
            <ng-template kendoGridCellTemplate let-dataItem>
                <div [innerHTML]="formatList(dataItem.production_ASDDeletions)"></div>
            </ng-template>
        </kendo-grid-column>
    </kendo-grid-column-group>
    <kendo-grid-column-group title="Comparison">
        <kendo-grid-column title="Cust. Match?" field="doCustomersMatch" [width]="80" class="acb-user-grid-yesno-column">
            <ng-template kendoGridCellTemplate let-dataItem>
                <span [ngClass]="{'acb-user-grid-value-no': !dataItem.doCustomersMatch  }">{{ dataItem.doCustomersMatch ? 'Yes' : 'No'}}</span>
            </ng-template>
        </kendo-grid-column>
        <kendo-grid-column title="AMs Match?" field="doAMsMatch" [width]="80" class="acb-user-grid-yesno-column">
            <ng-template kendoGridCellTemplate let-dataItem>
                <span [ngClass]="{'acb-user-grid-value-no': !dataItem.doAMsMatch  }">{{ dataItem.doAMsMatch ? 'Yes' : 'No'}}</span>
            </ng-template>
        </kendo-grid-column>
        <kendo-grid-column title="RSDs Match?" field="doRSDsMatch" [width]="80" class="acb-user-grid-yesno-column">
            <ng-template kendoGridCellTemplate let-dataItem>
                <span [ngClass]="{'acb-user-grid-value-no': !dataItem.doRSDsMatch  }">{{ dataItem.doRSDsMatch ? 'Yes' : 'No'}}</span>
            </ng-template>
        </kendo-grid-column>
        <kendo-grid-column title="ASDs Match?" field="doASDsMatch" [width]="80" class="acb-user-grid-yesno-column">
            <ng-template kendoGridCellTemplate let-dataItem>
                <span [ngClass]="{'acb-user-grid-value-no': !dataItem.doASDsMatch  }">{{ dataItem.doASDsMatch ? 'Yes' : 'No'}}</span>
            </ng-template>
        </kendo-grid-column>
        <!-- <kendo-grid-column title="S. Match?" field="sandbox_IsUnique" [width]="80" class="acb-user-grid-yesno-column">
            <ng-template kendoGridCellTemplate let-dataItem>
                <span [ngClass]="{'acb-user-grid-value-no': dataItem.sandbox_IsUnique  }">{{ !dataItem.sandbox_IsUnique ? 'Yes' : 'No'}}</span>
            </ng-template>
        </kendo-grid-column>
        <kendo-grid-column title="P. Match?" field="production_IsUnique" [width]="80" class="acb-user-grid-yesno-column">
            <ng-template kendoGridCellTemplate let-dataItem>
                <span [ngClass]="{'acb-user-grid-value-no': dataItem.production_IsUnique  }">{{ !dataItem.production_IsUnique ? 'Yes' : 'No'}}</span>
            </ng-template>
        </kendo-grid-column> -->
    </kendo-grid-column-group>

    <ng-template kendoPagerTemplate let-totalPages="totalPages" let-currentPage="currentPage">
        <kendo-pager-prev-buttons></kendo-pager-prev-buttons>
        <kendo-pager-numeric-buttons [buttonCount]="10" fxHide.lt-md="true"></kendo-pager-numeric-buttons>
        <kendo-pager-numeric-buttons [buttonCount]="5" fxHide="true" fxShow.sm="true"></kendo-pager-numeric-buttons>
        <kendo-pager-info></kendo-pager-info>
        <kendo-pager-next-buttons></kendo-pager-next-buttons>
        <kendo-pager-page-sizes ngClass.lt-md='acb-small-pager-page-sizes'
            [pageSizes]="[5,10,20,40]"></kendo-pager-page-sizes>
    </ng-template>
    <kendo-grid-excel fileName="LcpComparison.xlsx" [fetchData]="allData">
    </kendo-grid-excel>

</kendo-grid>