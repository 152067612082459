import { BannerNotificationModel } from '@alcon-db-models/BannerNotificationModel';
import { Component, HostBinding, OnInit } from '@angular/core';
import { ApplicationService } from '@services/application.service';
import { first } from 'rxjs/operators';

@Component({
  selector: 'acb-alcon-display-notifications',
  template: `
    <div class="acb-notifications-wrapper" fxLayout="row wrap" fxLayout.lt-md="column" fxLayoutGap="0px" fxLayoutGap.lt-md=".25em">
      <acb-alcon-display-notification *ngFor="let notification of bannerNotifications" [bannerNotification]="notification" fxFlex>
      </acb-alcon-display-notification>
    </div>
  `,
  styleUrls: ['./display-notifications.component.scss']
})
export class DisplayNotificationsComponent implements OnInit {

  @HostBinding('class.acb-is-empty') isEmpty: boolean = true;

  public bannerNotifications: BannerNotificationModel[] = [];

  constructor(applicationService: ApplicationService) {
    applicationService.getBannerNotifications().pipe(first()).subscribe(x => {
      this.bannerNotifications = x;
      if (x?.length)
        this.isEmpty = false;
    })
  }

  ngOnInit(): void {
  }
}
