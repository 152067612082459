import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { DialogContentBase, DialogRef } from '@progress/kendo-angular-dialog';

@Component({
  selector: 'acb-alcon-request-comment',
  template: `
    <kendo-dialog-titlebar class="acb-request-name-titlebar" (close)="onCancel()">
      {{title}}
    </kendo-dialog-titlebar>
    <form [formGroup]="form" style="width: 100%;" class="k-form">
      <acb-alcon-section-with-legend [doShowLegend]="false" class="acb-section-05">
        <kendo-formfield fxFlex>
          <kendo-label [for]="comment" [optional]="false">{{commentLabel}}</kendo-label>
          <textarea formControlName="comment" kendoTextArea #comment rows="3" style="resize: vertical;"></textarea>
        </kendo-formfield>
      </acb-alcon-section-with-legend>
    </form>
    <kendo-dialog-actions>
      <button kendoButton class="acb-cancel" (click)="onCancel()" >Cancel</button>
      <button kendoButton [primary]="true" (click)="onSave()" [disabled]="this.form.invalid" >Save</button>
    </kendo-dialog-actions>
  `,
  styleUrls: ['./request-comment.component.scss']
})
export class RequestCommentComponent extends DialogContentBase implements OnInit {

  @Input() title?: string = 'Comment';
  @Input() commentLabel?: string = 'Comment';
  @Input() commentValue?: string;


  @Output() cancel: EventEmitter<any> = new EventEmitter();
  @Output() save: EventEmitter<any> = new EventEmitter();

  public form: UntypedFormGroup = new UntypedFormGroup({
    comment: new UntypedFormControl(null, [Validators.required]),
  });

  constructor(public dialog : DialogRef) {
    super(dialog);
  }

  ngOnInit(): void {
    this.form.patchValue({
      comment: this.commentValue
    })
  }

  public onCancel() {
    this.cancel.emit(null);
  }

  public onSave() {

    this.save.emit(this.form.value);

  }

}
