import { ClaimDuplicateModel } from '@alcon-db-models/ClaimDuplicateModel';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { JsonUtilities } from 'src/app/shared/json-utilities';

@Component({
  selector: 'acb-alcon-review-duplicates-window',
  template: `
    <div fxLayout="column" style="height: 100%;">
      <kendo-grid
        [resizable]="true"
        [data]="duplicates"
        [selectable] = "{
          enabled: true,
          mode: 'multiple'
        }"
        kendoGridSelectBy="claimID"
        [(selectedKeys)] = "selectedKeys"
        class="acb-results-grid"
        fxFlex
        >
        <kendo-grid-command-column [width]="32" [resizable]=false [locked]="true">
          <ng-template kendoGridCellTemplate let-dataItem="dataItem" let-rowIndex="rowIndex">
            <input [kendoGridSelectionCheckbox]="rowIndex" kendoCheckBox/>
          </ng-template>
        </kendo-grid-command-column>
        <kendo-grid-column
          title="Claim ID"
          field="claimID"
          [width]="90"
          class="acb-grid-column-integer acb-grid-cell-with-view"
          [reorderable]="false"
          [includeInChooser]="false"
          [resizable]="false"
          [locked]= "true">
          <ng-template kendoGridCellTemplate let-dataItem>
            <button (click)="onViewClaim($event, dataItem)">
              {{dataItem.claimID}}
            </button>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column title="Status" field="statusCode" [width]="90">
        </kendo-grid-column>
        <kendo-grid-column title="Customer #" field="customerCode" [width]="90">
        </kendo-grid-column>
        <kendo-grid-column title="Customer" field="customer" [width]="180">
        </kendo-grid-column>
        <kendo-grid-column title="Payee #" field="PayeeCode" [width]="90" [hidden]="true">
        </kendo-grid-column>
        <kendo-grid-column title="Payee" field="payee" [width]="180" [sortable]="false">
        </kendo-grid-column>
        <kendo-grid-column title="Claimed" field="claimedAmount" format="{0:c}" class="acb-grid-column-currency" [width]="100">
        </kendo-grid-column>
        <kendo-grid-column title="Paid" field="ApprovedAmount" format="{0:c}" class="acb-grid-column-currency" [width]="100">
        </kendo-grid-column>
        <kendo-grid-column title="Start" field="startDate" [width]="85" class="acb-grid-column-date">
          <ng-template kendoGridCellTemplate let-dataItem>
            {{dataItem.startDate | date: 'M/d/yyyy'}}
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column title="End" field="endDate" [width]="85" class="acb-grid-column-date">
          <ng-template kendoGridCellTemplate let-dataItem >
            {{dataItem.endDate | date: 'M/d/yyyy'}}
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column title="Received" field="receivedDate" [width]="85" class="acb-grid-column-date">
          <ng-template kendoGridCellTemplate let-dataItem >
            {{dataItem.receivedDate | date: 'M/d/yyyy'}}
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column title="Processed" field="processedDate" [width]="85" class="acb-grid-column-date">
          <ng-template kendoGridCellTemplate let-dataItem >
            {{dataItem.processedDate | date: 'M/d/yyyy'}}
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column title="Invoice" field="invoiceNumbers" [width]="180">
        </kendo-grid-column>
      </kendo-grid>
    </div>
    <kendo-dialog-actions>
      <button kendoButton class="acb-cancel" (click)="onCancel()"><span class="k-icon k-i-cancel"></span>Cancel</button>
      <button kendoButton [primary]="true" (click)="onSave()"><span class="k-icon k-i-checkmark"></span>Apply</button>
    </kendo-dialog-actions>
  `,
  styleUrls: ['./review-duplicates-window.component.scss']
})
export class ReviewDuplicatesWindowComponent implements OnInit {

  public yesNoList: { name: string, value: boolean | undefined }[] = [
    { name: '', value: undefined }
  , { name: 'Yes', value: true }
  , { name: 'No', value: false }
  ];

  public selectedKeys: number[] = [];

  private _duplicates: ClaimDuplicateModel[] = [];
  @Input() set duplicates(value:ClaimDuplicateModel[]) {
    this._duplicates = (JsonUtilities.convertDatesAndCopy({ value: value })?.value ?? []) as ClaimDuplicateModel[];
    this.selectedKeys = (this._duplicates.filter(x => x.isDuplicate && x.claimID).map(x => x.claimID) ?? []) as number[];
  }
  get duplicates() { return this._duplicates };

  @Output() cancel: EventEmitter<any> = new EventEmitter();
  @Output() save: EventEmitter<ClaimDuplicateModel[]> = new EventEmitter();
  @Output() viewEntity: EventEmitter<any> = new EventEmitter();

  constructor(
  ) {
  }

  ngOnInit(): void {
  }

  public onViewClaim(event: any, claim: any) {
    this.viewEntity.emit(claim?.claimID);
  }

  public onCancel() {
    this.cancel.emit(null);
  }

  public onClose() {
    this.cancel.emit(null);
  }

  public onSave() {
    this.duplicates.forEach(x => {
      x.isResolved = true;
      x.isDuplicate = this.selectedKeys.some(y => y == x.claimID);
    })
    this.save.emit(this.duplicates);
  }
}
