import { AttachementModel } from '@alcon-db-models/AttachementModel';
import { EventEmitter } from '@angular/core';
import { Component, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'acb-alcon-attachment',
  template: `
    <div *ngIf="attachment">
      <div>
      {{ attachment.displayName }}
      </div>
      <div>
      {{ attachment.resourceCreatedByPerson }}
      </div>
      <div>
          <button kendoButton [look]="'clear'" [icon]="'delete'" (click)="onDeleteClicked()">
          </button>
        </div>
    </div>
  `,
  styleUrls: ['./attachment.component.scss']
})
export class AttachmentComponent implements OnInit {

  @Input() attachment?: AttachementModel;
  @Output() delete: EventEmitter<AttachementModel> = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

  public onDeleteClicked() {
    if (this.attachment)
      this.delete.emit(this.attachment);
  }
}
