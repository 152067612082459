import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { AbstractControl, UntypedFormControl, UntypedFormGroup, ValidatorFn, Validators } from '@angular/forms';
import { BehaviorSubject, from, Observable, Subject} from 'rxjs';
import { first, map, take, takeUntil } from 'rxjs/operators';
import { FundAllocatedModel } from '@alcon-db-models/FundAllocatedModel';
import { FundSearchWithDefaultModel } from '@alcon-db-models/FundSearchWithDefaultModel';
import { TerritoryWithBalanceModel } from '@alcon-db-models/TerritoryWithBalanceModel';
import { BatchStep, FundAdjustment } from '../core/core.module';
import { TerritoryWithBalanceService } from '@services/territory-with-balance.service';
import { AppWindowService } from '@services/app-window.service';
import { TransactionType } from '@alcon-db-models/Enums';
import { StaticTypeModel } from '@alcon-db-models/StaticTypeModel';
import { Store } from '@ngrx/store';
import { selectPullbackDepositTypes, selectStaticTypes, selectTransferDepositTypes } from '@app-store/app-session/app-session.selectors';
import { BatchSubject, BatchSubjectType, BatchSubjectWithAdjustment } from './territory-with-balance-formfield.component';

@Component({
  selector: 'acb-alcon-batch-allocation-step',
  template: `
    <acb-alcon-section-with-legend [doShowLegend]="false" class="acb-section-02">
      <form [formGroup]="form" fxLayout="column">
        <div fxLayout="row wrap" fxLayoutAlign="start start" class="acb-batch-fund-and-amount-row">
          <div fxFlex="0 0 calc(65% - 2em)" fxFlex.lt-md="grow">
            <button *ngIf="!fund" (click)="onSelectFund()" kendoButton class="acb-error acb-select-fund-button" type='button'>Select Fund</button>
            <div *ngIf="fund" fxLayout="row" fxLayoutAlign="start end"  fxLayoutGap="2em">
              <div fxLayout="column" style="overflow:hidden">
                <label class="acb-fund-label">Fund</label>
                <label class='acb-fund-value'>{{ fund!.displayName }}</label>
              </div>
              <button kendoButton (click)="onSelectFund()" [ngClass.lt-md]="'acb-fund-edit-button-with-margin'" icon="edit" type='button'></button>
            </div>
          </div>
          <kendo-formfield *appFieldExtentions fxFlex="0 0 calc(35% - 2em)"  fxFlex.lt-md="grow">
            <kendo-label [for]="transactionAmount" text="Transaction Amount" style="white-space: nowrap;"></kendo-label>
            <kendo-numerictextbox formControlName="transactionAmount" #transactionAmount [spinners]="false" [decimals]="2" [format]="'c2'" [min]="0" [step]="100"></kendo-numerictextbox>
            <kendo-formhint>&nbsp;</kendo-formhint>
            <kendo-formerror>Amount is required</kendo-formerror>
          </kendo-formfield>
        </div>
        <div fxLayout="row wrap" class="acb-batch-details" *ngIf="!isOrphan">
          <kendo-formfield *appFieldExtentions required fxFlex="0 0 calc(65% - 2em)" fxFlex.lt-md="grow" style="height:70px" [ngClass]="{'k-form-field-disabled2': this.form.controls.transactionType.disabled}">
            <kendo-label text="Transaction Type"></kendo-label>
            <ul class="k-radio-list k-list-horizontal">
              <li class="k-radio-item">
                  <kendo-label class="k-radio-label" [for]="deposit" text="Deposit"><input type="radio" #deposit [value]="transactionType.Deposit" kendoRadioButton [formControlName]="'transactionType'" required/></kendo-label>
              </li>
              <li class="k-radio-item">
                  <kendo-label class="k-radio-label" [for]="reversal" text="Reversal"><input type="radio" #reversal [value]="transactionType.Reversal"  kendoRadioButton [formControlName]="'transactionType'" required/></kendo-label>
              </li>
              <li class="k-radio-item">
                  <kendo-label class="k-radio-label" [for]="transfer" text="Transfer"><input type="radio" #transfer [value]="transactionType.Transfer"  kendoRadioButton [formControlName]="'transactionType'" required/></kendo-label>
              </li>
              <li class="k-radio-item">
                <kendo-label class="k-radio-label" [for]="pullback" text="Pullback"><input type="radio" #pullback [value]="transactionType.Pullback"  kendoRadioButton [formControlName]="'transactionType'" required/></kendo-label>
              </li>
            </ul>
            <kendo-formhint>&nbsp;</kendo-formhint>
            <kendo-formerror>Transaction Type is required</kendo-formerror>
          </kendo-formfield>
          <div *ngIf="transactionType.Transfer == form.get('transactionType')?.value" fxFlex="0 0 calc(35% - 2em)" fxFlex.lt-md="grow">
            <kendo-formfield *appFieldExtentions>
              <kendo-label [for]="'depositType'" text="Deposit Type"></kendo-label>
              <kendo-dropdownlist
                #depositType
                formControlName="depositType"
                [data]="transferDepositTypes$ | async"
                [textField]="'displayName'"
                [valueField]="'id'"
                [valuePrimitive]="false"
              >
              </kendo-dropdownlist>
              <kendo-formhint>&nbsp;</kendo-formhint>
              <kendo-formerror>Type is required</kendo-formerror>
            </kendo-formfield>
          </div>
          <div *ngIf="transactionType.Pullback == form.get('transactionType')?.value" fxFlex="0 0 calc(35% - 2em)" fxFlex.lt-md="grow">
            <kendo-formfield *appFieldExtentions>
              <kendo-label [for]="'depositType'" text="Deposit Type"></kendo-label>
              <kendo-dropdownlist
                #depositType
                formControlName="depositType"
                [data]="pullbackDepositTypes$ | async"
                [textField]="'displayName'"
                [valueField]="'id'"
                [valuePrimitive]="false"
              >
              </kendo-dropdownlist>
              <kendo-formhint>&nbsp;</kendo-formhint>
              <kendo-formerror>Type is required</kendo-formerror>
            </kendo-formfield>
          </div>
        </div>
        <div fxLayout="column" class="acb-batch-details">
            <div class="acb-territory-dropdown-with-details" fxLayout="row" fxFlex="grow"
              *ngIf="form.get('transactionType')?.value == transactionType.Transfer || form.get('transactionType')?.value == transactionType.Pullback"
              >
              <span class="k-icon k-i-export" fxHide.xs></span>
              <acb-alcon-territory-with-balance-formfield
                [subjects] = "fromSubjects$ | async"
                [formControlName]="'fromSubject'"
                [selectedSubjectWithAdjustment]="this.selectedFromSubject"
                [formGroup]="form"
                (selectionChange)="onfromSubjectSelectedChange($event)"
                [batchRole]="'From'"
                #fromSubject
              ></acb-alcon-territory-with-balance-formfield>
            </div>
            <div class="acb-territory-dropdown-with-details" fxLayout="row" fxFlex="grow"
              *ngIf="form.get('transactionType')?.value == transactionType.Transfer || form.get('transactionType')?.value == transactionType.Pullback"
              >
              <span class="k-icon k-i-import" fxHide.xs></span>
              <acb-alcon-territory-with-balance-formfield
                [subjects] = "toSubjects$ | async"
                [formControlName]="'toSubject'"
                [selectedSubjectWithAdjustment]="this.selectedToSubject"
                [formGroup]="form"
                (selectionChange)="ontoSubjectSelectedChange($event)"
                [batchRole]="'To'"
                #toSubject
              ></acb-alcon-territory-with-balance-formfield>
            </div>
            <kendo-formfield *appFieldExtentions fxFlex="grow">
              <kendo-label [for]="comment" text="Comment"></kendo-label>
              <textarea formControlName="comment" kendoTextArea #comment rows="3" style="resize: vertical;"></textarea>
              <kendo-formhint>&nbsp;</kendo-formhint>
            </kendo-formfield>
        </div>
      </form>
    </acb-alcon-section-with-legend>
  `,
  styleUrls: ['./batch-allocation-step.component.scss']
})
export class BatchAllocationStepComponent implements OnInit, OnDestroy {

  @Input() fundAdjustments: FundAdjustment[] = [];
  @Input() calcBatchSubjects?: {(selectedStep: BatchStep, fromSubject:BatchSubjectWithAdjustment | undefined | null, toSubject:BatchSubjectWithAdjustment | undefined | null): void;};

  private _batchStep: BatchStep = new BatchStep();
  @Input() set batchStep(value:BatchStep) {
    this._isOrphan = value.isOrphan; 
    this._batchStep = {...value};
    this.fund = value.fund ?? undefined;
    if (value) {
      let { transactionAmount, transactionType, depositType, comment } = value;
      const fromSubject = this.getSubjectFromTerritory(value.fromTerritory);
      const toSubject = this.getSubjectFromTerritory(value.toTerritory);
      this.selectedFromSubject = fromSubject ? {...fromSubject, adjusted: 0, projected: 0 } : undefined;
      this.selectedToSubject = toSubject ? {...toSubject, adjusted: 0, projected: 0 } : undefined;
      this.calcSubjectDetails();
      this.form.patchValue({
        transactionAmount,
        transactionType,
        depositType,
        comment,
        fromSubject,
        toSubject
      });
    }
    this.changeDetectorRef.detectChanges();
  }
  get batchStep() {
    return this._batchStep;
  }

  @Output() cancel: EventEmitter<any> = new EventEmitter();
  @Output() close: EventEmitter<any> = new EventEmitter();
  @Output() submit: EventEmitter<any> = new EventEmitter();

  public form = new UntypedFormGroup({
    transactionAmount: new UntypedFormControl({value: null, disabled: true}, Validators.required),
    transactionType: new UntypedFormControl({value: null, disabled: true}, Validators.nullValidator),
    depositType: new  UntypedFormControl({value: null, disabled: true}, this.transferRequiresDepositTypeValidator()),
    fromSubject: new UntypedFormControl({value: null, disabled: true},this.territoriesRequiredValidator()),
    toSubject: new UntypedFormControl({value: null, disabled: true},this.territoriesRequiredValidator()),
    comment: new UntypedFormControl({value: null, disabled: true}, Validators.required),
  },[this.fundRequiredValidator(), this.fundUniqueValidator()]);

  public territoryWithBalance$: BehaviorSubject<TerritoryWithBalanceModel[]> = new BehaviorSubject([] as TerritoryWithBalanceModel[]);
  public fromSubjects$: BehaviorSubject<BatchSubject[]> = new BehaviorSubject([] as BatchSubject[]);
  public toSubjects$: BehaviorSubject<BatchSubject[]> = new BehaviorSubject([] as BatchSubject[]);
  //public staticTypes$: Observable<StaticTypeModel[]>;
  //public depositTypes$: Observable<StaticTypeModel[]>;
  public transferDepositTypes$: Observable<StaticTypeModel[]>;
  public pullbackDepositTypes$: Observable<StaticTypeModel[]>;
  public destroy$: Subject<void> = new Subject<void>();

  private _fund?: FundSearchWithDefaultModel;
  public set fund(value) {
    if (this.batchStep.fund?.fundID != value?.fundID) {
      this.form.controls.fromSubject.reset();
      this.form.controls.toSubject.reset();
    }
    this.batchStep.fund = this._fund = value;
    this.updateFormDisabled();
    this.updateTerritories();
    this.calcSubjectDetails();
    this.changeDetectorRef.detectChanges();
  };
  public get fund() { return this._fund; }

  public selectedFromSubject?: BatchSubjectWithAdjustment;
  public selectedToSubject?: BatchSubjectWithAdjustment;
  public transactionType = TransactionType;

  private _isOrphan:boolean = false;
  public get isOrphan() {
    return this._isOrphan;
  }
  constructor(
    private territoryWithBalanceService: TerritoryWithBalanceService,
    private appWindowService: AppWindowService,
    private store: Store,
    private changeDetectorRef: ChangeDetectorRef
  ) {
    // this.staticTypes$ = this.store.select(selectStaticTypes);
    // this.depositTypes$ =  this.staticTypes$.pipe(map(x => x.filter(y => y.tableName == 'DepositType')));
    this.transferDepositTypes$ = this.store.select(selectTransferDepositTypes);
    this.pullbackDepositTypes$ = this.store.select(selectPullbackDepositTypes);
    this.territoryWithBalance$.pipe(takeUntil(this.destroy$)).subscribe(x => {
      const subjects: BatchSubject[] = x.filter(y => Boolean(y.territoryID)).map(y => ({ subjectID: 't_' + y.territoryID, nativeID: y.territoryID ?? 0, name: y.displayName ?? '', available: y.balance ?? 0, batchSubjectType: 'territory' as BatchSubjectType, isOrphan: !y.isInCurrentHierarchy }));
      if (this.fund?.fundID) {
        subjects.unshift({ subjectID: 'f_' + this.fund.fundID, nativeID: this.fund.fundID, name: this.fund.displayName ?? '',  available: this.fund.balance ?? 0, batchSubjectType: 'fund', isOrphan: false})
      }
      this.fromSubjects$.next([...(this.isOrphan ? subjects.filter(y => y.isOrphan) : subjects.filter(y => !y.isOrphan))]);
      this.toSubjects$.next([...(this.isOrphan ? subjects : subjects.filter(y => !y.isOrphan))]);
    });

    this.form.controls.transactionType.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(x => {
      const type = this.form.controls.transactionType.value;
      if (type == TransactionType.Deposit || type == TransactionType.Reversal) {
        this.form.patchValue({
          fromSubject: null,
          toSubject: null,
          depositType: null
        });
      } else if (type == TransactionType.Pullback) {
        this.form.patchValue({
          depositType: null
        });
      }
    })

    this.form.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(x => {
      let { transactionAmount, transactionType, depositType, comment } = x;
      Object.assign(this.batchStep, { transactionAmount, transactionType, depositType, comment }, {
        fromTerritory: this.getTerritoryFromSubject(x.fromSubject),
        toTerritory: this.getTerritoryFromSubject(x.toSubject),
      });
      this.calcSubjectDetails();
      this.changeDetectorRef.detectChanges();
    });
  }

  private getTerritoryFromSubject(subject?:BatchSubject | null): TerritoryWithBalanceModel | undefined {
    return subject?.nativeID && subject?.batchSubjectType == 'territory' ? this.territoryWithBalance$.value.find(x => x.territoryID == subject.nativeID) ?? undefined : undefined;
  }

  private getSubjectFromTerritory(territory?:TerritoryWithBalanceModel | null, doExpandFund = true): BatchSubject | undefined {
    if (territory?.territoryID) {
      return {
        subjectID: 't_' + territory.territoryID,
        nativeID: territory.territoryID,
        name: territory?.displayName ?? '<unknown>',
        batchSubjectType: 'territory',
        available: territory.balance ?? 0,
        isOrphan: !territory.isInCurrentHierarchy
      }
    } else if (doExpandFund && this.batchStep.fund?.fundID) {
      return {
        subjectID: 'f_' + this.batchStep.fund.fundID,
        nativeID: this.batchStep.fund.fundID,
        name: this.batchStep.fund?.displayName ?? '<unknown>',
        batchSubjectType: 'fund',
        available: this.fundAdjustments.find(x => x.fundID == this.batchStep.fund?.fundID)?.adjustmentAmount ?? 0,
        isOrphan: false
      }
    }
    return undefined;
  }

  private updateFormDisabled() {
    if (!this._fund) {
      Object.keys(this.form.controls).forEach(key => {
        this.form.controls[key].disable();
      });
    } else {
      Object.keys(this.form.controls).forEach(key => {
        this.form.controls[key].enable();
      });
    }
  }

  private territoriesRequiredValidator(): ValidatorFn {
    return (control: AbstractControl): {[key: string]: any} | null => {
      const transType = (control.parent as UntypedFormGroup)?.controls?.transactionType?.value;
      return (!control.value && (transType == TransactionType.Transfer || transType == TransactionType.Pullback)) ?
        { required: { value: control.value } } :
        null;
    };
  }

  private transferRequiresDepositTypeValidator(): ValidatorFn {
    return (control: AbstractControl): {[key: string]: any} | null => {
      return (!control.value && (control.parent as UntypedFormGroup)?.controls?.transactionType?.value == TransactionType.Transfer) ?
        { required: { value: control.value } } :
        null;
    };
  }

  private fundRequiredValidator(): ValidatorFn {
    return (control: AbstractControl): {[key: string]: any} | null => {
      let val: { [key: string]: any } | null = null;
      if (!this.fund) {
        val = {};
        val["required"] = true;
      }
      return val;
    };
  }

  private fundUniqueValidator(): ValidatorFn {
    return (control: AbstractControl): {[key: string]: any} | null => {
      const controls = this.form.controls;
      const transType = controls.transactionType?.value;
      let val: { [key: string]: any } | null = null;
      if ((transType == TransactionType.Transfer || transType == TransactionType.Pullback) &&
      controls.fromSubject?.value?.subjectID == controls.toSubject?.value?.subjectID) {
        val = {};
        val["mustBeUnique"] = true;
      }
      return val;
    };
  }

  ngOnInit(): void {
  }

  public onClose() {
    this.close.emit();
  }

  public onCancel() {
    this.cancel.emit();
  }

  public onSelectFund() {
    const dialogSpec = this.appWindowService.openSelectFund(undefined, this.fund?.fundID, this.fundAdjustments, undefined, this.isOrphan);
    if (!dialogSpec?.component) {
      dialogSpec?.dialogRef.close();
      return;
    }
    dialogSpec.component.showAdjustments = true;
    dialogSpec.component.cancel.pipe(take(1)).subscribe(() => dialogSpec?.dialogRef.close());
    dialogSpec.component.select.pipe(take(1)).subscribe(x => {
      if (x) {
        this.onFundSelectedChange(x);
      }
      dialogSpec?.dialogRef.close();
    });
  }

  public onFundSelectedChange(selectedFund:FundSearchWithDefaultModel | null) {
    if (selectedFund?.fundID == this.fund?.fundID)
      return;
    this.fund = selectedFund ?? undefined;
  }

  private updateTerritories() {
    if (this.fund?.fundID) {
      this.territoryWithBalanceService.getWithQuery("fundID=" + this.fund.fundID).pipe(first()).subscribe(x => this.territoryWithBalance$.next(x ?? []));
    } else {
      this.territoryWithBalance$.next([]);
    }
  }

  public onfromSubjectSelectedChange(value: BatchSubject) {
    this.selectedFromSubject = { ...value, adjusted: 0, projected: 0};
    this.calcSubjectDetails();
  }

  public ontoSubjectSelectedChange(value: BatchSubject) {
    this.selectedToSubject = { ...value, adjusted: 0, projected: 0};
    this.calcSubjectDetails();
  }

  private calcSubjectDetails() {
    if (this.calcBatchSubjects) this.calcBatchSubjects(this.batchStep, this.selectedFromSubject, this.selectedToSubject);
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}

export class TerritoryWithDetails extends TerritoryWithBalanceModel {
  constructor(
    private territoryWithBalanceModel: TerritoryWithBalanceModel = new TerritoryWithBalanceModel(),
    public available: number = territoryWithBalanceModel.balance ?? 0,
    public adjusted: number = 0,
    public projected: number = 0
  ) {
    super();
    Object.assign(this, territoryWithBalanceModel);
  }
}
