<kendo-dialog-titlebar class="acb-edit-approval-rule-titlebar" (close)="onClose()">
  {{ viewMode == 'edit' ? 'Edit' : 'New' }}
</kendo-dialog-titlebar>
<acb-alcon-window-notifications [windowNotifications]="notifications" [fxHide]="!auditInstructionForm.errors">
</acb-alcon-window-notifications>
<form [formGroup]="auditInstructionForm" style="width: 100%;" class="k-form">
  <acb-alcon-section-with-legend [doShowLegend]="false" class="acb-section-05">
    <div class="acb-inner-panel" fxLayout="row wrap" fxLayoutGap="2em" fxLayoutAlign="start top">

      <kendo-formfield *appFieldExtentions fxFlex="0 0 calc(50% -2em)" fxFlex.lt-md="grow">
        <kendo-label [for]="instructionTitle" text="Title" [optional]="true"></kendo-label>
        <input formControlName="instructionTitle" kendoTextBox #instructionTitle autocomplete="off"/>
        <kendo-formhint>Instruction's title</kendo-formhint>
      </kendo-formfield>


      <kendo-formfield *appFieldExtentions fxFlex="0 0 12em" fxFlex.lt-sm="auto">
        <kendo-label [for]="startDate" text="Start Date" [optional]="true"></kendo-label>
        <kendo-datepicker
          formControlName="startDate"
          #startDate
          autocomplete="off"
          [format]="{ displayFormat: 'MM/dd/yyyy', inputFormat: 'MM/dd/yy'  }"
          >
        </kendo-datepicker>
        <kendo-formhint>&nbsp;</kendo-formhint>
      </kendo-formfield>

      <kendo-formfield *appFieldExtentions fxFlex="0 0 12em" fxFlex.lt-sm="auto">
        <kendo-label [for]="endDate" text="End Date" [optional]="true"></kendo-label>
        <kendo-datepicker
          formControlName="endDate"
          #endDate
          autocomplete="off"
          [format]="{ displayFormat: 'MM/dd/yyyy', inputFormat: 'MM/dd/yy'  }"
          >
        </kendo-datepicker>
        <kendo-formhint>&nbsp;</kendo-formhint>
      </kendo-formfield>

      <kendo-formfield *appFieldExtentions fxFlex="grow">
        <kendo-label [for]="instructionBody" text="Instruction"></kendo-label>
        <textarea
          formControlName="instructionBody"
          kendoTextArea
          #instructionBody
          rows="3"
          style="resize: vertical;"
          [required]="true">
        </textarea>
        <kendo-formhint>Instruction</kendo-formhint>
      </kendo-formfield>

      <kendo-formfield *appFieldExtentions  fxFlex="0 0 calc(50% - 2em)">
        <kendo-label [for]="customerIDs" text="Customers"></kendo-label>
        <kendo-multiselect
          formControlName="customerIDs"
          [data]="customersForDropdownData"
          [textField]="'customerSuggestedName'"
          [valueField]="'customerID'"
          [valuePrimitive]="true"
          #customerIDs
          (open)="this.onSelectCustomer($event,customerIDs)"
          (close)="$event.preventDefault();"
          >
        </kendo-multiselect>
        <kendo-formhint>&nbsp;</kendo-formhint>
      </kendo-formfield>

      <kendo-formfield *appFieldExtentions fxFlex="0 0 calc(50% - 2em)"fxFlex.lt-sm="grow">
        <kendo-label [for]="territoryIDs" text="Territories"></kendo-label>
        <kendo-multiselect
          formControlName="territoryIDs"
          [data]="territoriesForDropdownData"
          [textField]="'territorySuggestedName'"
          [valueField]="'territoryID'"
          [valuePrimitive]="true"
          #territoryIDs
          [autoClose]="false"
          >
        </kendo-multiselect>
        <kendo-formhint>&nbsp;</kendo-formhint>
      </kendo-formfield>

    </div>
  </acb-alcon-section-with-legend>
</form>

<kendo-dialog-actions>
  <button kendoButton class="acb-cancel" (click)="onCancel()"><span class="k-icon k-i-cancel"></span>Cancel</button>
  <button kendoButton [primary]="true" (click)="onSave()" [disabled]="!isValid" ><span class="k-icon k-i-checkmark"></span>{{ viewMode == 'edit' ? 'Update' : 'Save' }}</button>
</kendo-dialog-actions>


