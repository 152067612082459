import { CommitmentWithDetailsModel } from '@alcon-db-models/CommitmentWithDetailsModel';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { GridComponent } from '@progress/kendo-angular-grid';
import { SortDescriptor } from '@progress/kendo-data-query';
import { CompactMode, ViewMode } from '../components.module';
import { ViewAttachmentsBaseComponent } from '../view-attachments.base.component';

@Component({
  selector: 'acb-alcon-view-commitment-attachments',
  template: `
    <label class='acb-alcon-display-item-label' *ngIf="doShowFieldLabel">Attachments</label>
    <kendo-grid
      *ngIf="gridView$ | async as gridViewData"
      #statusTrackingGrid
      class="acb-results-grid acb-display-grid"
      [data]= "gridViewData"
      kendoGridSelectBy="commitmentDetailAttachmentID"
      [pageable]="false"
      [sortable]="viewMode != 'edit'"
      [reorderable]="false"
      [selectable] = "false"
      [resizable]="true"
      [sort]="sort"
      (sortChange)="onSortChange($event)"
      >
      <kendo-grid-checkbox-column
        [width]="30"
        [reorderable]="false"
        [resizable]="false"
        *ngIf="viewMode == 'edit'"
        [headerClass]="'acb-grid-command-column-header'"
        [class]="'acb-grid-command-column'"
        [showSelectAll]="true"
        >
        <ng-template kendoGridHeaderTemplate>
          <input class="k-checkbox" kendoGridSelectAllCheckbox>
        </ng-template>
      </kendo-grid-checkbox-column>
      <kendo-grid-column title="Name" field="displayName" class="acb-grid-cell-with-view">
        <ng-template kendoGridCellTemplate let-dataItem>
          <button (click)="onViewImage(dataItem)">
            {{ dataItem.displayName }}
          </button>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column title="Uploaded By" field="resourceCreatedByPerson" [width]="100" *ngIf="viewMode != 'new'" [hidden]="viewMode === 'edit' || compactMode == 'compact'" >
      </kendo-grid-column>
      <kendo-grid-column title="Date" field="creationDate" [width]="90" *ngIf="viewMode != 'new'"  [hidden]="viewMode === 'edit' || compactMode == 'compact'">
        <ng-template kendoGridCellTemplate let-dataItem>
          {{dataItem.creationDate | date: 'M/d/yyyy'}}
        </ng-template>
      </kendo-grid-column>
    </kendo-grid>
  `,
  styleUrls: ['./view-commitment-attachments.component.scss']
})
export class ViewCommitmentAttachmentsComponent extends ViewAttachmentsBaseComponent implements OnInit {

  @ViewChild('statusTrackingGrid') statusTrackingGrid?: GridComponent;

  @Input() doShowFieldLabel: boolean = true;
  @Input() viewMode: ViewMode = 'search';
  @Input() compactMode: CompactMode = 'normal';

  private _commitment?: CommitmentWithDetailsModel;
  @Input() set commitment(value:CommitmentWithDetailsModel | undefined) {
    this._commitment = value;
    this.attachmentPath = value?.code ? './UserUploads/commitments/' + window.encodeURIComponent(value.code) : null;
    this.loadData(this._commitment?.attachments ?? undefined);
  };
  get commitment(): CommitmentWithDetailsModel | undefined {
    return this._commitment;
  }

  public sort: SortDescriptor[] = [{
    field: 'commitmentDetailAttachmentID',
    dir: 'asc'
  }];

  constructor() {
    super()
  }

  ngOnInit(): void {
  }

  public onSortChange(sort: SortDescriptor[]): void {
    this.sort = sort;
    this.loadData(this._commitment?.attachments ?? undefined);
  }

}
