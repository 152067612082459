import { ApplicationRef, ChangeDetectorRef, Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, ReplaySubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class WindowComService implements OnDestroy {

  public close$ = new BehaviorSubject(false);
  private destroy$: Subject<void> = new Subject<void>();

  constructor(private applicationRef: ApplicationRef) { }

  public closeAllWindows() {
    this.close$.next(true);
    this.applicationRef.tick();
    this.close$.next(false);
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
