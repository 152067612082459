import { UserInfoModel } from '@alcon-db-models/UserInfoModel';
import { Injectable, OnDestroy } from '@angular/core';
import { selectCurrentPerson } from '@app-store/app-session/app-session.selectors';
import { environment } from '@environments/environment';
import { QueryParams } from '@ngrx/data';
import { Store } from '@ngrx/store';
import { Observable, of, ReplaySubject, Subject } from 'rxjs';
import { catchError, finalize, first, map, takeUntil } from 'rxjs/operators';
import { getErrorMessage, ServiceResponse, firstWithLoadingOverlayAndErrorHandling } from '../shared/acb-stream';
import { UserInfoService } from './user-info.service';

@Injectable()
export class UserSubjectBaseService implements OnDestroy {

  private _userSpec: UserSpec | undefined;
  public set userSpec(value: UserSpec | undefined) {
    this._userSpec = value;
    if (this._userSpec) {
      this.userInfoService.getWithQuery(this._userSpec as QueryParams).pipe(first()).subscribe(users => {
        this.user$.next(users?.length ? users[0] : {});
      });
    } else {
      this.user$.next(this.createUser());
    }
  };
  public get userSpec(): UserSpec | undefined {
    return this._userSpec;
  }

  public readonly user$ = new ReplaySubject<UserInfoModel>(1);
  public loading$ = new ReplaySubject<boolean>(1);
  public saving$ = new ReplaySubject<boolean>(1);
  public error$ = new ReplaySubject<any>(1);

  protected _destroy$ = new Subject<void>();
  protected _user?: UserInfoModel;

  constructor(
    private store: Store,
    private userInfoService: UserInfoService
  ) {

    this.user$.pipe(takeUntil(this._destroy$)).subscribe(x => {
      this._user = x;
    });

    this.userInfoService.loading$.pipe(takeUntil(this._destroy$)).subscribe(this.loading$);
  }

  // --------------------------------------------------------------------------
  protected createUser(user?:UserInfoModel): UserInfoModel {
    user = user ?? new UserInfoModel();
    if (!user.accessRoleIDs?.length) {
      user.accessRoleIDs = [];
    }
    return user;
  }

  public SaveUser(user?: UserInfoModel): Observable<ServiceResponse<UserInfoModel>> {

    user = user ?? this._user;
    if (!user) {
      const msg = "No user";
      this.saving$.next(false);
      this.error$.next(msg)
      return of({ hasError: true, errorMessage: [msg], response: null });
    }

    if (!user.applicationUserID)
      user.password = environment.defaultPassword;

    let personID: number | null = null;
    this.store.select(selectCurrentPerson).pipe(first()).subscribe(x => {
      personID = x?.personID ?? null
    })

    this.saving$.next(true);

    return this.userInfoService.upsert(user).pipe(
      firstWithLoadingOverlayAndErrorHandling<UserInfoModel>(),
      map(x => {
        if (x.response?.applicationUserPersonID) this.user$.next(x.response);
        this.error$.next(x.errorMessage);
        return x;
      }),
      finalize(() => {
        this.loading$.next(false);
        this.saving$.next(false);
      })
    );

    // return this.userInfoService.upsert(user).pipe(
    //   map(x => {
    //     this.user$.next(x);
    //     this.error$.next(null);
    //     return ({ success: true, result: 'Success' });
    //   }),
    //   catchError(err => {
    //     const msg = err?.error?.detail ?? err?.error?.error?.detail ?? 'unknown';
    //     this.error$.next(msg);
    //     return of({ success: false, result: msg });
    //   }),
    //   finalize(() => {
    //     this.loading$.next(false);
    //     this.saving$.next(false);
    //   })
    // );
  }

ngOnDestroy() {
    this._destroy$.next();
    this._destroy$.complete();
  }
}

export class UserSpec {
  applicationUserID?: number | undefined;
  personID?: number | undefined;
}
