import { Component, ElementRef, OnInit, NgZone } from '@angular/core';
//import { DialogTitleBarComponent, TitleBarLocalizationService } from '@progress/kendo-angular-dialog';
import { DialogTitleBarComponent } from '@progress/kendo-angular-dialog';
import { L10N_PREFIX, LocalizationService } from '@progress/kendo-angular-l10n';

@Component({
  selector: 'acb-alcon-edit-commitment-dialog-titlebar',
  template: `
    <ng-container
      kendoDialogTitleBarLocalizedMessages
      i18n-closeTitle="kendo.dialog.closeTitle|The title of the close button"
      closeTitle="Close"
      >
      <div class="k-window-title k-dialog-title">
          <ng-content></ng-content>
      </div>

      <div class="k-window-actions k-dialog-actions">
          <a
            href="#"
            role="button"
            [attr.title]="closeButtonTitle"
            [attr.aria-label]="closeButtonTitle"
            class="k-button k-bare k-button-icon k-window-action k-dialog-action k-dialog-close"
            (click)="onCloseClick($event)"
          >
              <span class="k-icon k-i-x"></span>
          </a>
      </div>
    </ng-container>
  `,
  styleUrls: ['./edit-commitment-dialog-titlebar.component.scss'],
  providers: [
    {
      provide: DialogTitleBarComponent,
      useExisting: EditCommitmentDialogTitlebarComponent
    },
    // TitleBarLocalizationService,
    // {
    //     provide: LocalizationService,
    //     useExisting: TitleBarLocalizationService
    // },
    {
        provide: L10N_PREFIX,
        useValue: 'kendo.dialog'
    }
  ]
})
export class EditCommitmentDialogTitlebarComponent extends DialogTitleBarComponent implements OnInit {

  constructor(ngZone:NgZone, hostElement:ElementRef, localizationService: LocalizationService) {
    super(ngZone, hostElement,localizationService);
  }

  ngOnInit(): void {
  }

}
