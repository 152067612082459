import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Optional, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { SuccessEvent, UploadComponent, UploadEvent, ErrorEvent, SelectEvent } from '@progress/kendo-angular-upload';
import { ResourceModel } from '@alcon-db-models/ResourceModel'
import { Utilities } from './../../shared/utilities'
import { AttachmentRole, SourceType, StatusCode } from '@alcon-db-models/Enums';
import { WizardFeatureService } from '@services/wizard-feature.service';
import { Observable, Subject } from 'rxjs';
import { AppUxService } from '@services/app-ux.service';
import { CommitmentDetailAttachmentModel } from '@alcon-db-models/CommitmentDetailAttachmentModel';
import { RemoveEvent } from '@progress/kendo-angular-grid';
import { AttachementModel } from '@alcon-db-models/AttachementModel';
import { first, map, tap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { selectApplicationInfo, selectResourceTypes } from '@app-store/app-session/app-session.selectors';
import { ViewMode } from '../components.module';
import { ClaimDetailAttachmentModel } from '@alcon-db-models/ClaimDetailAttachmentModel';
import { FileUploadService } from '@services/file-upload.service';
import { JsonUtilities } from 'src/app/shared/json-utilities';
import { UploadFileBase } from '../upload-file.base.component';
import { ApplicationInfoModel } from '@alcon-db-models/ApplicationInfoModel';

@Component({
  selector: 'acb-alcon-select-attachments',
  template: `
  <acb-alcon-section-with-legend [sectionTitle]="'Attachments'" class="acb-section-06" [doShowLegend]="doShowLegend">
    <div fxLayout="column" fxLayoutGap="1.5em" style="height:100%" >
      <div *ngIf="instructions" >
        <kendo-label class="acb-instructions-top-label"><span>Instructions (Proof of Performance)</span></kendo-label>
        <div class="acb-inserted-html" [innerHtml]="instructions"></div>
      </div>
      <div fxFlex *ngIf="attachments">
        <kendo-grid
          class="acb-results-grid acb-display-grid"
          [data]= "attachments"
          kendoGridSelectBy="commitmentDetailAttachmentID"
          [pageable]="false"
          [sortable]="false"
          [reorderable]="false"
          [selectable] = "false"
          [resizable]="true"
          (remove)="onRowRemove($event)"
          >
          <kendo-grid-column title="File" field="displayName" class="acb-grid-cell-with-view">
            <ng-template kendoGridCellTemplate let-dataItem>
              <button (click)="onViewImage($event, dataItem)">
                {{ dataItem.displayName }}
              </button>
            </ng-template>
          </kendo-grid-column>
          <kendo-grid-column
            *ngIf="viewMode == 'edit'"
            title="Uploaded By"
            field="resourceCreatedByPerson"
            [width]="200"
            >
            <ng-template kendoGridCellTemplate let-dataItem>
              {{ getUploadedBy(dataItem) }}
            </ng-template>

          </kendo-grid-column>
          <!-- <kendo-grid-column
            *ngIf="viewMode == 'edit'"
            title="Date"
            field="creationDate"
            [width]="90"
            >
            <ng-template kendoGridCellTemplate let-dataItem>
              {{dataItem.creationDate | date: 'M/d/yyyy'}}
            </ng-template>
          </kendo-grid-column> -->
          <kendo-grid-command-column [width]="40">
            <ng-template kendoGridCellTemplate let-dataItem>
              <button kendoGridRemoveCommand [look]="'clear'" [icon]="'delete'" *ngIf="dataItem.sourceType != sourceType.Commitment && (dataItem.statusCode == statusCode.Processing || canRemoveExistingAttachments)"></button>
            </ng-template>
          </kendo-grid-command-column>
        </kendo-grid>
      </div>
      <form [formGroup]="fileUploadForm">
        <kendo-upload
          #fileUpload
          formControlName="files"
          [autoUpload]="true"
          [saveUrl]="'./api/FileUpload'"
          [removeUrl]="'./api/FileDelete'"
          [multiple]="true"
          [(ngModel)]="files"
          [showFileList]="false"
          (upload)="onUpload($event)"
          (remove)="onRemove($event)"
          (success)="onSuccess($event)"
          (error)="onError($event)"
          (select)="onSelect($event)"
          (valueChange)="onValueChange($event)"
          [restrictions]="{
            allowedExtensions: extensions,
            maxFileSize: 104857600
          }"
        >
          <kendo-upload-messages select="Add File(s)" dropFilesHere="...or drop files here to upload">
          </kendo-upload-messages>
        </kendo-upload>
      </form>
    </div>
  </acb-alcon-section-with-legend>
  {{fileUploadForm.controls.files.errors?.minCount?.value}}
  `,
  styleUrls: ['./select-attachments.component.scss']
})
export class SelectAttachmentsComponent extends UploadFileBase implements OnInit, OnDestroy {

  @ViewChild('fileUpload') fileUpload?: UploadComponent;

  @Input() instructions?: string;
  @Input() fileRequired: boolean = false;
  @Input() doShowLegend: boolean = true;
  @Input() subjectAttachmentPath?: string;
  @Input() subjectDraftsPath?: string;
  @Input() claimCommitmentAttachmentPath?: string;
  @Input() canRemoveExistingAttachments?: boolean = true;
  @Input() attachments: AttachementModel[] = [];

  @Input() viewMode: ViewMode = 'edit';

  @Output() selectedChange: EventEmitter<ResourceModel[]> = new EventEmitter();

  // public fileUploadForm = new FormGroup({ files: new FormControl(null) });

  public extensions: string[] = [];
  public statusCode = StatusCode;
  public sourceType = SourceType;
  public applicationInfo: ApplicationInfoModel = new ApplicationInfoModel();

  constructor(
    protected appUxService: AppUxService,
    protected store: Store,
    private fileUploadService: FileUploadService,
    protected changeDetectorRef: ChangeDetectorRef,
    @Optional() wizardFeatureService?: WizardFeatureService,
  ) {

    super(appUxService, store, changeDetectorRef);

    store.select(selectResourceTypes).pipe(first()).subscribe(x => {
      const extensions: string[] = [];
      x.forEach(y => {
        if (y.fileExtension?.startsWith('.')) extensions.push(y.fileExtension);
        if (y.altFileExtension?.startsWith('.')) extensions.push(y.altFileExtension);
      })
      this.extensions = extensions;
    })

    wizardFeatureService?.clear.subscribe((x: boolean) => {
      try {
        if (x) {
          [...this.fileUpload?.fileList.filesFlat ?? []].forEach(x => { if (x.uid) this.fileUpload?.removeFilesByUid(x.uid); })
        }
      } catch(e) {}
      this.fileUploadForm.reset();
    });

    store.select(selectApplicationInfo).pipe(first()).subscribe(x => {
      this.applicationInfo = JsonUtilities.convertDatesAndCopy(x);
    });
  }

  // private getValidationMessage(key:string):string {
  //   switch(key) {
  //     case "invalidFileExtension":
  //       return "Type not supported.";
  //     case "invalidFiles":
  //       return "Not supported.";
  //     case "invalidMaxFileSize":
  //       return "Too large.";
  //     case "invalidMinFileSize":
  //       return "Too small.";
  //   }
  //   return "";
  // }

  // public validateForm(): boolean {
  //   this.fileUploadForm.markAllAsTouched();
  //   return this.fileUploadForm.valid;
  // }

  // public getErrors(): string[] {
  //   const errors: string[] = [];
  //   if (this.fileUploadForm.controls.files.errors)
  //     errors.push("File is required");
  //   return errors;
  // }

  public getUploadedBy(attachment: AttachementModel) {
    // TODO: Messy.  Using presence of claimCommitmentAttachmentPath to determine if attachment host is claim or commitment
    if (this.claimCommitmentAttachmentPath && (attachment as ClaimDetailAttachmentModel)?.sourceType == SourceType.Commitment) {
      return "commitment"
    }
    return attachment.resourceCreatedByPerson ?? attachment.createdByPerson;
  }


  public cloneCommitmentFiles(commitmentID:number) : Observable<ResourceModel[]> {
    return this.fileUploadService.cloneFilesForCommitmentCreate(commitmentID).pipe(
      first(),
      map(x => x.map(y => JsonUtilities.convertDatesAndCopy(y)) as ResourceModel[]),
      tap(x => {
        this.stagedResources = x;
      }),
    );
  }

  public cloneClaimFiles(claimID:number) : Observable<ResourceModel[]> {
    return this.fileUploadService.cloneFilesForCommitmentCreate(claimID).pipe(
      first(),
      map(x => x.map(y => JsonUtilities.convertDatesAndCopy(y)) as ResourceModel[]),
      tap(x => {
        this.stagedResources = x;
      }),
    );
  }

  public createUrl(attachment: AttachementModel): string | undefined {

    if (!attachment.internalFileName || !attachment.displayName)
      return undefined;

    let baseUrl:string|undefined;

    // TODO: Messy. If not draft, using presence of claimCommitmentAttachmentPath to determine if attachment host is claim or commitment
    if (attachment.statusCode == StatusCode.Draft && (attachment as ClaimDetailAttachmentModel)?.sourceType != SourceType.Commitment) {
      baseUrl = this.subjectDraftsPath;
    } else if (!(attachment as ClaimDetailAttachmentModel)?.claimDetailAttachmentID && this.claimCommitmentAttachmentPath && (attachment as ClaimDetailAttachmentModel)?.sourceType == SourceType.Commitment) {
      baseUrl = this.claimCommitmentAttachmentPath;
    } else if (this.applicationInfo.userUploadsStagingFolder) {
      baseUrl = (attachment.statusCode == StatusCode.Processing ? Utilities.normalizeUrl([this.applicationInfo.userUploadsWebFolder ?? "",this.applicationInfo.userUploadsStagingFolder ?? ""]) : this.subjectAttachmentPath);
    } else {
      baseUrl = "";
    }




    if (!baseUrl)
      return undefined;

    return Utilities.normalizeUrl([
      baseUrl,
      window.encodeURIComponent(attachment.internalFileName),
      window.encodeURIComponent(attachment.displayName)
    ]);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.fileUploadForm.get('files')?.setValidators(this.fileRequired ? [Validators.required] : []);
  }

  public onViewImage(event: any, attachement: AttachementModel) {
    const url = this.createUrl(attachement);
    if (url)
      window.open(url, "_blank");
  }

  public onRowRemove(event: RemoveEvent) {

    const attachment = (event.dataItem as AttachementModel);
    if (!attachment)
      return;

    let index = this.attachments.findIndex(x => x.code == attachment.code);
    if (index != undefined)
      this.attachments.splice(index,1);

    this.selectedChange.emit(this.attachments);
  }

  public onValueChange(event: any): void {
    this.attachments = [
      ...this.attachments,
      ...this.stagedResources.filter(x => !this.attachments.some(y => y.code == x.code)).map(x => ({
        ...(({ createdByPersonID, creationDate, ...rest }) => rest)(x),
        ...{
          resourceCreatedByPersonID: x.createdByPersonID,
          attachmentRole: AttachmentRole.Pop
        }
      }))
    ];
    this.stagedResources.length = 0;
    this.selectedChange.emit(this.attachments);
  }



  // public onSuccess(event:SuccessEvent) {
  //   if (event.operation == 'upload') {
  //     if (!Array.isArray(event.response.body))
  //       return;
  //     const r = [...event.response.body, ...this.stagedResources];
  //     // Remove duplicates...
  //     this.stagedResources = r.filter((n, i) => r.indexOf(n) === i);
  //   }
  // }

  // public onUpload (event:UploadEvent) {
  //   if (event.files?.length != 1)
  //     return;
  //   event.data = {
  //     uid: event.files[0].uid
  //   };
  // }

  // public onError(event:ErrorEvent) {
  //   if (event.operation == 'upload')
  //     this.appUxService.openErrorDialog(['Error uploading file']);
  // }

  // public onSelect(event:SelectEvent) {
  //   const invalidFiles = event.files.filter(x => x.validationErrors?.length);
  //   if (invalidFiles.length) {
  //     const messages = [...['Invalid File(s)'],...new Set(([] as string[]).concat.apply([], invalidFiles.map(x => x.name + " - " + (x.validationErrors ?? []).map(y => this.getValidationMessage(y)).reduce((a,v) => a + " " + v)    )))];
  //     this.appUxService.openErrorDialog(messages);
  //   }
  // }





  ngOnDestroy() {
    super.ngOnDestroy();
  }

}
