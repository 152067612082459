import { AuditInstructionModel } from '@alcon-db-models/AuditInstructionModel';
import { ClaimDuplicateModel } from '@alcon-db-models/ClaimDuplicateModel';
import { ClaimReviewModel } from '@alcon-db-models/ClaimReviewModel';
import { ClaimReviewRequestModel } from '@alcon-db-models/ClaimReviewRequestModel';
import { ClaimWithDetailsModel } from '@alcon-db-models/ClaimWithDetailsModel';
import { AuditCodeGroup, SpecialClaimType, StatusCode } from '@alcon-db-models/Enums';
import { HttpErrorResponse, HttpParams } from '@angular/common/http';
import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { AbstractControl, UntypedFormArray, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { selectAuditCodesWithGroups, selectCurrentPerson } from '@app-store/app-session/app-session.selectors';
import { Store } from '@ngrx/store';
import { DialogService, WindowRef, WindowService } from '@progress/kendo-angular-dialog';
import { PageChangeEvent } from '@progress/kendo-angular-pager';
import { AppUxService } from '@services/app-ux.service';
import { ClaimDuplicateService } from '@services/claim-duplicate.service';
import { ClaimEditService } from '@services/claim-edit.service';
import { ClaimFormBaseService } from '@services/claim-form-base.service';
import { ClaimReviewEntityService } from '@services/claim-review-entity.service';
import { ClaimReviewService } from '@services/claim-review.service';
import { ClaimSubjectBaseService } from '@services/claim-subject-base.service';
import { ClaimWithDetailsService } from '@services/claim-with-details.service';
import { BehaviorSubject, forkJoin, Observable, Subject, Subscription } from 'rxjs';
import { filter, first, map, take, takeUntil } from 'rxjs/operators';
import { AuditCodeWithCompositeNameModel } from 'src/app/components/core/core.module';
import { WindowNotification } from 'src/app/components/window-notifications/window-notifications.component';
import { ServiceResponse } from 'src/app/shared/acb-stream';
import { JsonUtilities } from 'src/app/shared/json-utilities';
import { ReviewDuplicatesWindowComponent } from './review-duplicates-window.component';

export type ClaimReviewMode = 'audit' | 'check';
export type ReviewStatus = 'approve' | 'deny' | 'pend' | 'close'| 'ignore';
export type CloseStatus = 'cancel' | 'void';
export type DuplicateStatus = 'unresolved' | 'some' | 'none';
export type ButtonEnableGroup = 'audit' | 'duplicate';

@Component({
  selector: 'acb-alcon-review-claims-window',
  templateUrl: 'review-claims-window.component.html',
  styleUrls: ['./review-claims-window.component.scss'],
  providers: [
    ClaimEditService,
    {
      provide: ClaimFormBaseService,
      useExisting: ClaimEditService
    },
    {
      provide: ClaimSubjectBaseService,
      useExisting: ClaimEditService
    }
  ]
})
export class ReviewClaimsWindowComponent implements OnInit, OnDestroy {

  @Input() claimReviewMode: ClaimReviewMode = 'audit';
  @Input() comment: string = '';

  @Output() closed: EventEmitter<any> = new EventEmitter();
  @Output() submitted: EventEmitter<any> = new EventEmitter();
  @Output() viewCommitment: EventEmitter<any> = new EventEmitter();
  @Output() viewClaim: EventEmitter<any> = new EventEmitter();

  @ViewChild('windowTitleBar') public windowTitleBarTemplate?: TemplateRef<any>;

  public loading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  public index$: BehaviorSubject<number> = new BehaviorSubject(0);
  public claimID?: number;
  public count: number = 0;

  public claim$: Subject<ClaimWithDetailsModel> = new Subject<ClaimWithDetailsModel>();
  public claimReview$: Subject<ClaimReviewModel> = new Subject<ClaimReviewModel>();
  public notifications$: Subject<WindowNotification[]> = new Subject<WindowNotification[]>();

  public approveAuditCodesSource$: Observable<AuditCodeWithCompositeNameModel[]>;
  public approveAuditCodes$: Observable<AuditCodeWithCompositeNameModel[]> = new Observable<AuditCodeWithCompositeNameModel[]>();

  // public approveReversalAuditCodesSource$: Observable<AuditCodeWithCompositeNameModel[]>;
  // public approveReversalAuditCodes$: Observable<AuditCodeWithCompositeNameModel[]>;

  public denyAuditCodesSource$: Observable<AuditCodeWithCompositeNameModel[]>;
  public denyAuditCodes$: Observable<AuditCodeWithCompositeNameModel[]>;

  public reviewStatusButtonEnableGroup: ButtonEnableGroup = 'audit';

  public auditInstructions: AuditInstructionModel[] = [];

  private _index: number = 0;
  public get index() { return this._index };

  private _approveAuditCodes: AuditCodeWithCompositeNameModel[] = [];
  private _denyAuditCodes: AuditCodeWithCompositeNameModel[] = [];
  private _approvedAsInvoicedAuditCodeID?: number;

  private forms: UntypedFormGroup = new UntypedFormGroup({
    claimForms: new UntypedFormArray([])
  });
  private _currentForm?: UntypedFormGroup;
  public get form() { return this._currentForm }

  private _claimIDs: number[] = [];
  @Input() public set claimIDs(value: number[]) {
    this._claimIDs = Array.isArray(value) ? value.sort() : [];
    this.syncClaims();
    this.index$.next(0);
    this.count = this._claimIDs.length;
  }

  public get isValid() {
    return this.forms.controls.claimForms.valid;
  }

  public get currentReviewStatus(): ReviewStatus {
    return this.form?.value.reviewStatus;
  }

  public get sectionClass(): string {
    switch (this.currentReviewStatus) {
      case "approve":
        return 'acb-section-02';
      case "deny":
        return 'acb-section-07';
      case 'pend':
        return 'acb-section-12';
      case 'close':
        return 'acb-section-09';
      case 'ignore':
        return 'acb-section-00';
      default:
        return 'acb-section-05';
    }
  }

  public get sectionTitle(): { label?:string, entity?:string, info?:string } {
    const title = ({ label: '', entity: '', info: '' })
    const values = this._currentForm?.value;
    switch (this.currentReviewStatus) {
      case "approve":
        title.label = 'Approving';
        break
      case "deny":
        title.label = 'Denying';
        break
      default:
        title.label = 'Reviewing';
        break
    }
    if (values?.claimID) {
      title.label += ":";
      title.entity = values?.claimID.toString();
    }
    title.info = this.count > 1 ? " (" + ((this._index ?? 0) + 1).toString() + "/" + (this.count).toString() + ")" : "";
    return title;
  }

  get duplicateStatus() {
    return this.getDuplicateStatus();
  }

  private getDuplicateStatus(form = this.form) {
    const result = { duplicateStatus: 'none' as DuplicateStatus, numResolved: 0, numUnresolved: 0, numDupes: 0 }
    // Must have a current form, be in check mode, and must be the first check
    if (form && this.claimReviewMode == 'check' && form.value.claim.statusCodeID == StatusCode.Audited) {
      result.numResolved = (form.value.duplicates as ClaimDuplicateModel[])?.filter(x => x.isResolved)?.length ?? 0;
      result.numUnresolved = (form.value.duplicates?.length ?? 0) - result.numResolved;
      result.numDupes = (form.value.duplicates as ClaimDuplicateModel[])?.filter(x => x.isDuplicate)?.length ?? 0;
      if (result.numUnresolved) result.duplicateStatus = 'unresolved' as DuplicateStatus;
      else if (result.numDupes) result.duplicateStatus = 'some' as DuplicateStatus;
    }
    return result;
  }

  private destroy$: Subject<void> = new Subject<void>();
  private _claimReviewEntityServiceSubscription?: Subscription;

  constructor(
    private store: Store,
    private claimWithDetailsService: ClaimWithDetailsService,
    private changeDetectorRef: ChangeDetectorRef,
    private claimReviewService: ClaimReviewService,
    private appUxService: AppUxService,
    private claimReviewEntityService: ClaimReviewEntityService,
    private claimDuplicateService: ClaimDuplicateService,
    private dialogService: DialogService,
    private windowService: WindowService
  ) {

    this.claim$.pipe(takeUntil(this.destroy$)).subscribe(x => {
      this.auditInstructions = x.auditInstructions ?? [];
      this.approveAuditCodes$ = (x.specialClaimTypeID == SpecialClaimType.Reversal) ?
        this.approveAuditCodes$ = this.approveAuditCodesSource$.pipe(map(y => y.filter(z => z.auditCodeGroups?.some(a => a == AuditCodeGroup.ApproveReversal)) )) :
        this.approveAuditCodes$ = this.approveAuditCodesSource$.pipe(map(y => y.filter(z => z.auditCodeGroups?.some(a => a != AuditCodeGroup.ApproveReversal)) ));
    })

    //TODO: extract to function
    this.index$.pipe(takeUntil(this.destroy$)).subscribe(x => {

      this._claimReviewEntityServiceSubscription?.unsubscribe();

      const arrayControls = (this.forms.controls.claimForms as UntypedFormArray)?.controls ?? [];

      this._currentForm = arrayControls.length > x ? arrayControls[x] as UntypedFormGroup : undefined;
      this.claimID = this._currentForm?.value.claimID ?? undefined;
      this._index = x;

      if (this._currentForm && this.claimID) {

        const form = this._currentForm;

        if(!form.value.claim) {

          form.reset();
          this.loading$.next(true);

          forkJoin([
            claimWithDetailsService.getByKey(this.claimID).pipe(first()),
            claimDuplicateService.getWithQuery('claimID='+this.claimID).pipe(first()),
            claimReviewEntityService.getByKey(this.claimID).pipe(first())
          ]).pipe(first()).subscribe(([c, d, r]) => {

            c = JsonUtilities.convertDatesAndCopy(c);
            d = (JsonUtilities.convertDatesAndCopy({ value: d })?.value ?? []) as ClaimDuplicateModel[];
            r = r ? JsonUtilities.convertDatesAndCopy(r) as ClaimReviewModel : r;

            form.patchValue({
              claimID: c.claimID ?? this.claimID,
              claim: c,
              // TODO: weird place to enforce business rule. We only care about duplicates on first check.
              duplicates: c.statusCodeID == StatusCode.Audited && this.claimReviewMode == 'check' ? d : [],
              // TODO: ugh, we're pulling these out the claim which is also attached to the FormGroup (as a lazy way to keep claim related to form).
              // This is similar to activityDescription, which is also handled poorly, but differently.  Fix this.
              numberOfAds: c.numberOfAds,
              startDate: c.startDate,
              endDate: c.endDate,
              receivedDate: c.receivedDate
            });
            this.claim$.next(c);

            if (r?.claimID === this.claimID) {
              let reviewStatus: ReviewStatus | undefined;
              const auditCodesObj = { approveAuditCodes: <number[]>[], denyAuditCodes: <number[]>[] };
              if (r.auditCodes?.length) {
                if (r.approvedAmount) {
                  auditCodesObj.approveAuditCodes = r.auditCodes ?? [];
                  reviewStatus = 'approve';
                } else {
                  auditCodesObj.denyAuditCodes = r.auditCodes ?? [];
                  reviewStatus = 'deny';
                }
              }
              form.patchValue({
                activityDescription: r.activityDescription,
                approvedAmount: r.approvedAmount,
                reviewStatus: reviewStatus,
                claimReview: r,
                ...auditCodesObj
              });
            }

            // TODO: this smells bad, have to update cross-form
            form.statusChanges.pipe(takeUntil(this.destroy$)).subscribe(() => {
              (this.forms.controls.claimForms as UntypedFormArray)?.updateValueAndValidity();
              this.forms?.updateValueAndValidity();
              this.changeDetectorRef.detectChanges();
            });

            //this._currentForm?.valueChanges.pipe(debounceTime(100),takeUntil(this.destroy$)).subscribe(() => {
            form.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(() => {
              this.onCurrentFormValueChanges()
            });

            this.onCurrentFormValueChanges();
            this.loading$.next(false);
          });
        } else {
          this.loading$.next(false);
          this.claim$.next(this._currentForm.value.claim);
          this.onCurrentFormValueChanges();
        }

      } else {
        //this.loading$.next(false);
      }

    });

    //this.approveAuditCodes$ = this.approveAuditCodesSource$ = store.select(selectAuditCodesWithGroups).pipe(first(), map(x => x.filter(y => y.auditCodeGroups?.some(z => z == AuditCodeGroup.Approve || z == AuditCodeGroup.ApprovePartialPay)).map(y => new AuditCodeWithCompositeNameModel(y))));
    this.approveAuditCodesSource$ = store.select(selectAuditCodesWithGroups).pipe(first(), map(x => x.filter(y => y.auditCodeGroups?.some(z => z == AuditCodeGroup.Approve || z == AuditCodeGroup.ApprovePartialPay)).map(y => new AuditCodeWithCompositeNameModel(y))));
    this.denyAuditCodes$ = this.denyAuditCodesSource$ = store.select(selectAuditCodesWithGroups).pipe(first(), map(x => x.filter(y => y.auditCodeGroups?.some(z => z == AuditCodeGroup.Deny)).map(y => new AuditCodeWithCompositeNameModel(y))));

    this.approveAuditCodesSource$.pipe(takeUntil(this.destroy$)).subscribe(x => {
      this._approveAuditCodes = x
      this._approvedAsInvoicedAuditCodeID = this._approveAuditCodes?.find(x => x.code === '01')?.auditCodeID ?? undefined;
    });
    this.denyAuditCodesSource$.pipe(takeUntil(this.destroy$)).subscribe(x => this._denyAuditCodes = x);
  }

  private syncClaims() {
    const arrayControls = (this.forms.controls.claimForms as UntypedFormArray)?.controls ?? [];
    let i = 0;
    let form: UntypedFormGroup;
    while (i < arrayControls.length) {
        form = arrayControls[i] as UntypedFormGroup;
        if (this._claimIDs.some(y => y == form?.value?.claimID)) {
          i++;
        } else {
          arrayControls.splice(i, 1);
        }
    }
    this._claimIDs.forEach(x => {
      if (!arrayControls.some(y => (y as UntypedFormGroup)?.value?.claimID == x)) {
        arrayControls.push(this.createClaimForm(x));
      }
    });
  }

  // --------------------------------------------------------------------------
  private createClaimForm(claimID: number) {
    return new UntypedFormGroup({
      reviewStatus: new UntypedFormControl(null, Validators.required),
      closeStatus: new UntypedFormControl(null, this.closeStatusValidator),
      claimID: new UntypedFormControl(claimID),
      approvedAmount: new UntypedFormControl(null, this.approvedAmountValidator),
      activityDescription: new UntypedFormControl(null, this.activityDescriptionValidator),
      receivedDate: new UntypedFormControl(null, this.receivedDateValidator),
      startDate: new UntypedFormControl(null, this.startDateValidator),
      endDate: new UntypedFormControl(null, this.endDateValidator),
      numberOfAds: new UntypedFormControl(null, this.numberOfAdsValidator),
      approveAuditCodes: new UntypedFormControl(null, this.approveAuditCodesValidator),
      denyAuditCodes: new UntypedFormControl(null, this.denyAuditCodesValidator),
      comment: new UntypedFormControl(null, this.commentValidator),
      claim: new UntypedFormControl(),
      duplicates: new UntypedFormControl(null,this.duplicateResolvedValidator),
      claimIsValid: new UntypedFormControl(false, this.claimIsValidValidator),
      claimReview: new UntypedFormControl()
    });
  }

  // --------------------------------------------------------------------------
  private closeStatusValidator = ((control: AbstractControl): { [key: string]: any } | null => {
    const form = control.parent as UntypedFormGroup;
    if (form?.value.reviewStatus == 'close' && !control.value) {
      return {'closeStatusRequired': 'Close type required'};
    }
    return null;
  }).bind(this);

  // --------------------------------------------------------------------------
  private approvedAmountValidator = ((control: AbstractControl): { [key: string]: any } | null => {
    const form = control.parent as UntypedFormGroup;
    if (form?.value.reviewStatus == 'approve') {
      if(!control.value) return {'approvedAmountRequired': 'Approved Amount required'};
      if (control.value > form.value?.claim?.claimedAmount ?? 0)  return {'max': 'Value exceeds claimed amount'};
    }
    return null;
  }).bind(this);

  // --------------------------------------------------------------------------
  private activityDescriptionValidator = ((control: AbstractControl): { [key: string]: any } | null => {
    const form = control.parent as UntypedFormGroup;
    if (form?.value.reviewStatus == 'approve' && !control.value) {
      return {'activityDescriptionRequired': 'Activity Description required'};
    }
    return null;
  }).bind(this);

  // --------------------------------------------------------------------------
  private receivedDateValidator = ((control: AbstractControl): { [key: string]: any } | null => {
    const form = control.parent as UntypedFormGroup;
    if (form?.value.reviewStatus == 'approve' && !control.value) {
      return {'receivedDateRequired': 'Received Date required'};
    }
    return null;
  }).bind(this);

  // --------------------------------------------------------------------------
  private startDateValidator = ((control: AbstractControl): { [key: string]: any } | null => {
    const form = control.parent as UntypedFormGroup;
    if (form?.value.reviewStatus == 'approve' && !control.value) {
      return {'startDateRequired': 'Start Date required'};
    }
    return null;
  }).bind(this);

  // --------------------------------------------------------------------------
  private endDateValidator = ((control: AbstractControl): { [key: string]: any } | null => {
    const form = control.parent as UntypedFormGroup;
    if (form?.value.reviewStatus == 'approve' && !control.value) {
      return {'endDateRequired': 'End Date required'};
    }
    return null;
  }).bind(this);

  // --------------------------------------------------------------------------
  private numberOfAdsValidator = ((control: AbstractControl): { [key: string]: any } | null => {
    const form = control.parent as UntypedFormGroup;
    if (form?.value.reviewStatus == 'approve') {
      if(!control.value) return {'numberOfAdsRequired': 'Number of Ads required'};
    }
    return null;
  }).bind(this);

  // --------------------------------------------------------------------------
  private approveAuditCodesValidator = ((control: AbstractControl): { [key: string]: any } | null => {
    const form = control.parent as UntypedFormGroup;
    if (form?.value.reviewStatus == 'approve') {
      if (!control.value?.length) return {'auditCodesRequired': 'Audit Code(s) required'};
      if (this._approveAuditCodes.some(x => x.code == '01' && x.auditCodeID == control.value) && form.value.approvedAmount != form.value.claim?.claimedAmount) {
        return {'auditCodeMismatch': 'Approved as Invoiced not allowed on partial approvals'};
      }
    }
    return null;
  }).bind(this);

  // --------------------------------------------------------------------------
  private denyAuditCodesValidator = ((control: AbstractControl): { [key: string]: any } | null => {
    const form = control.parent as UntypedFormGroup;
    if (form?.value.reviewStatus == 'deny') {
      if(!control.value?.length) return {'auditCodesRequired': 'Audit Code(s) required'};
    }
    return null;
  }).bind(this);

  // --------------------------------------------------------------------------
  private commentValidator = ((control: AbstractControl): { [key: string]: any } | null => {
    const form = control.parent as UntypedFormGroup;
    if (form?.value.reviewStatus == 'close' || form?.value.reviewStatus == 'pend') {
      if(!control.value?.trim()) return {'commentRequired': 'Comment required'};
    }
    return null;
  }).bind(this);

  // --------------------------------------------------------------------------
  private duplicateResolvedValidator = ((control: AbstractControl): { [key: string]: any } | null => {
    const form = control.parent as UntypedFormGroup;
    if (form?.value.reviewStatus != 'ignore' && (control.value as ClaimDuplicateModel[])?.some(x => !x.isResolved)) {
      return {'duplicateResolved': 'Duplicates must be resolved'};
    }
    return null;
  }).bind(this);

  // --------------------------------------------------------------------------
  private claimIsValidValidator = ((control: AbstractControl): { [key: string]: any } | null => {
    const form = control.parent as UntypedFormGroup;
    if (form?.value.reviewStatus == 'approve' || form?.value.reviewStatus == 'deny') {
      if(!control.value) return {'claimNotValid': 'Claim must be valid'};
    }
    return null;
  }).bind(this);

  // --------------------------------------------------------------------------
  private _reviewDuplicatesOpen: boolean = false;
  public openReviewDuplicates(): void {

    if (this._reviewDuplicatesOpen) return;

    const windowRef: WindowRef = this.windowService.open({
      title: 'Select Duplicate Claims',
      content: ReviewDuplicatesWindowComponent,
      width: 1400,
      minWidth: 200,
      minHeight: 100,
    });

    const component = (windowRef.content.instance as ReviewDuplicatesWindowComponent);
    if (!component) {
      windowRef.close();
      return;
    }

    this._reviewDuplicatesOpen = true;

    const window = windowRef?.window.instance;
    if (window)
      (window as any).el.nativeElement.className += "acb-window-select-duplicate-claims";

    windowRef.result.pipe(take(1)).subscribe(() => {
      this._reviewDuplicatesOpen = false;
    });

    component.duplicates = this.form?.value.duplicates ?? [];

    component.cancel.pipe(take(1)).subscribe(() => {
      windowRef.close();
    });

    component.viewEntity.pipe(takeUntil(this.destroy$)).subscribe((claimID:number) => {
      this.viewClaim.emit(claimID);
    });

    component.save.pipe(take(1)).subscribe((x) => {
      if (!this.form) return;
      x = (JsonUtilities.convertDatesAndCopy({ value: x })?.value ?? []) as ClaimDuplicateModel[];
      this.form.patchValue({ duplicates: x });
      this.forms.updateValueAndValidity();
      this.onCurrentFormValueChanges();
      this.changeDetectorRef.detectChanges();
      windowRef.close();
      this.addDefaultAuditCode();
    });
  }

  private addDefaultAuditCode() {
    if (this.duplicateStatus.duplicateStatus == 'some') {
      if (!this.form?.value.denyAuditCodes?.length) {
        const duplicateAuditCode = this._denyAuditCodes.find(x => x.auditCodeGroups?.some(y => y == AuditCodeGroup.DuplicateClaim));
        if (duplicateAuditCode?.auditCodeID) {
          this.form?.patchValue({ denyAuditCodes: [duplicateAuditCode.auditCodeID] });
        }
      }
    }
  }

  // --------------------------------------------------------------------------
  ngOnInit(): void {
  }

  // --------------------------------------------------------------------------
  public onApproveAuditCodeFilter(value: string) {
    if (!value) {
      this.approveAuditCodes$ = this.approveAuditCodesSource$;
      return;
    }
    this.approveAuditCodes$ = this.approveAuditCodesSource$.pipe(
      map(x => x
        .filter(y => y.compositeName!.toLowerCase()
          .indexOf(value.toLowerCase()) > -1)));
  }

  // --------------------------------------------------------------------------
  public onDenyAuditCodeFilter(value: string) {
    if (!value) {
      this.denyAuditCodes$ = this.denyAuditCodesSource$;
      return;
    }
    this.denyAuditCodes$ = this.denyAuditCodesSource$.pipe(
      map(x => x
        .filter(y => y.compositeName!.toLowerCase()
          .indexOf(value.toLowerCase()) > -1)));
  }

  // --------------------------------------------------------------------------
  public sliderChange(pageIndex: number): void {
    this.setIndex(pageIndex - 1);
  }

  public onPageChange(e: PageChangeEvent): void {
    this.setIndex(e.skip);
  }

  private _setIndexTO?: number;
  private setIndex(index: number) {
    window.clearTimeout(this._setIndexTO);
    this._setIndexTO = window.setTimeout(() => { this.index$.next(index); }, 100);
  }

  public onCancel() {
    this.closed.emit(null);
  }

  public onSubmit() {

    //TODO: Move this to base class
    let personID: number | null = null;
    this.store.select(selectCurrentPerson).pipe(first()).subscribe(x => {
      personID = x?.personID ?? null
    })

    //TODO: Throw and handle error?
    if (!personID)
      return;

    const getStatusCodeID = (claimForm: UntypedFormGroup): StatusCode | undefined => {
      if (claimForm?.value?.reviewStatus) {
        switch (claimForm.value.reviewStatus) {
          case 'approve':
          case 'deny':
            return this.claimReviewMode == 'audit' ? StatusCode.Audited : StatusCode.Checked;
          case 'pend':
            return StatusCode.Pending;
          case 'close':
            return claimForm.value.closeStatus == 'void' ? StatusCode.Void : StatusCode.Canceled;
          default: // Ignore
            break
        }
      }
      return undefined;
    }

    const models = (this.forms.controls.claimForms as UntypedFormArray)?.controls.filter((x: any) => getStatusCodeID(x)).map((x: any) => {

      const val: any = x.value;
      const auditCodes = val.reviewStatus == 'deny' ? val.denyAuditCodes ?? [] : val.reviewStatus == 'approve' ? val.approveAuditCodes ?? [] : [];
      const dupeStatus = this.getDuplicateStatus(x)?.duplicateStatus;
      const areDuplicatesAllowed = dupeStatus && ((!val.approvedAmount && dupeStatus != 'unresolved') || (val.approvedAmount && dupeStatus == 'none'));

      const model: ClaimReviewRequestModel = {
        personID: personID,
        claimID: val.claim.claimID,
        code: val.claim.code,
        commitmentID: val.claim.commitmentID,
        inputDate: val.claim.inputDate,
        processedDate: val.claim.processedDate,
        deductionNumber: val.claim.deduction,
        activityID: val.claim.activityID,
        activityDescription: val.activityDescription,
        payTypeID: val.claim.payTypeID,
        amount: val.claim.claimedAmount,
        payeeCustomerID: val.claim.payeeCustomerID,
        invoiceNumbers: val.claim.invoice,
        comments: val.claim.comments,
        attachments: val.claim.attachments,
        products: val.claim.products,
        // handling these through audit form, not claim detail edit
        numberOfAds: val.numberOfAds ?? val.claim.numberOfAds,
        startDate: val.startDate ?? val.claim.startDate,
        endDate: val.endDate ?? val.claim.endDate,
        receivedDate: val.receivedDate ?? val.claim.receivedDate,
        // unique to review
        reviewStatusCodeID: getStatusCodeID(x),
        auditCodes: auditCodes,
        approvedAmount: val.approvedAmount,
        reviewComment: val.comment,
        contextStatusCodeID: this.claimReviewMode == 'audit' ? StatusCode.Audited : StatusCode.Checked,
        areDuplicatesAllowed: areDuplicatesAllowed,
        doctors: val.claim.doctors ?? [],
        specialClaimTypeID: val.claim.specialClaimTypeID,
        relatedClaims: val.claim.relatedClaims,
      };
      return model;
    });

    this.claimReviewService.postReview(models).subscribe((x:ServiceResponse<boolean>) => {
      if (x.hasError) {
        this.appUxService.openErrorDialog(x.errorMessage);
      } else {
        this.submitted.emit();
      }
    });

  }

  public onClaimValidChange(isValid:boolean) {
    this.form?.patchValue({claimIsValid: isValid});
    this.form?.updateValueAndValidity();
    (this.forms.controls.claimForms as UntypedFormArray)?.updateValueAndValidity();
    this.forms?.updateValueAndValidity();
    this.changeDetectorRef.detectChanges();
  }

  public onClaimChanged(claim: ClaimWithDetailsModel | undefined) {

    if (JSON.stringify(this.form?.value.claim) == JSON.stringify(claim))
      return;

    this.form?.patchValue({claim: claim});
    if (!claim?.claimID) return;

    // TODO: weird place to enforce business rule. We only care about duplicates on first check.
    if (claim.statusCodeID != StatusCode.Audited || this.claimReviewMode != 'check') return;

    // TODO: There must be a cleaner way to handle optional params
    const query: any = {};
    if(claim?.claimID) query['claimID'] = claim.claimID;
    if(claim?.customerID) query['customerID'] = claim.customerID;
    if(claim?.startDate) query['startDate'] = claim.startDate.toISOString();
    if(claim?.endDate) query['endDate'] = claim.endDate.toISOString();
    if(claim?.invoice) query['invoiceNumbers'] = claim.invoice;
    const params = new HttpParams({ fromObject: query });

    const _form = this._currentForm;
    //this.claimDuplicateService.getWithQuery(params.toString()).pipe(debounceTime(250), first()).subscribe(x => {
    this.claimDuplicateService.getWithQuery(params.toString()).pipe(first()).subscribe(x => {
      x = x.map(y => {
        const oldDuplicate = ((_form?.value.duplicates ?? []) as ClaimDuplicateModel[]).find(z => z.claimID == y.claimID);
        let oldState = oldDuplicate ? (({ isDuplicate, isResolved }) => ({ isDuplicate, isResolved }))(oldDuplicate) : {};
        return {
          ...y,
          ...oldState
        }
      });
      _form?.patchValue({ duplicates: x });
    })
  }

  public onReviewStatusChanges() {
    if (!this._currentForm) return;
    const form = this._currentForm;
    const reviewStatus: ReviewStatus = form.value.reviewStatus;
    let valuetoPatch: any = { ...form.value };
    // Note, these overwrite form values with NULL on every value change.  If you don't always want NULL, do not set them.
    switch (reviewStatus) {
      case 'approve':
        let approveAuditCodes = form.value.approveAuditCodes as number[] ?? [];
        if (!approveAuditCodes.length) {
          const reviewApprovalCodes = form.value.claimReview?.auditCodes as number[] ?? [];
          // has approved amount?  assume last review was approval
          if (reviewApprovalCodes.length && form.value.claimReview?.approvedAmount) {
            approveAuditCodes = reviewApprovalCodes.filter(x => this._approveAuditCodes.some(y => y.auditCodeID === x));
          }
        }
        valuetoPatch = {
          ...valuetoPatch,
          approvedAmount: form.value.approvedAmount ?? form.value.claimReview?.approvedAmount,
          activityDescription: form.value.activityDescription ?? form.value.claimReview?.activityDescription ?? null,
          numberOfAds: form.value.numberOfAds ?? form.value.claim?.numberOfAds,
          receivedDate: form.value.receivedDate ?? form.value.claim?.receivedDate,
          startDate: form.value.startDate ?? form.value.claim?.startDate,
          endDate: form.value.endDate ?? form.value.claim?.endDate,
          approveAuditCodes: approveAuditCodes,
          denyAuditCodes: null,
          closeStatus: null
        }
        break;
      case 'deny':
        let denyAuditCodes = form.value.denyAuditCodes as number[] ?? [];
        if (!denyAuditCodes.length) {
          const reviewDenyCodes = form.value.claimReview?.auditCodes as number[] ?? [];
          // no approved amount?  assume last review was denial
          if (reviewDenyCodes.length && !form.value.claimReview?.approvedAmount) {
            denyAuditCodes = reviewDenyCodes.filter(x => this._denyAuditCodes.some(y => y.auditCodeID === x));
          }
        }
        valuetoPatch = {
          ...valuetoPatch,
          approvedAmount: undefined,
          activityDescription: form.value.activityDescription ?? form.value.claimReview?.activityDescription ?? null,
          numberOfAds: form.value.numberOfAds ?? form.value.claim?.numberOfAds,
          receivedDate: form.value.receivedDate ?? form.value.claim?.receivedDate,
          startDate: form.value.startDate ?? form.value.claim?.startDate,
          endDate: form.value.endDate ?? form.value.claim?.endDate,
          denyAuditCodes: denyAuditCodes,
          approveAuditCodes: null,
          closeStatus: null
        };
        break;
      case 'pend':
        valuetoPatch = {
          ...valuetoPatch,
          approvedAmount: null,
          activityDescription: null,
          numberOfAds: null,
          receivedDate: null,
          startDate: null,
          endDate: null,
          approveAuditCodes: null,
          denyAuditCodes: null,
          closeStatus: null,
        };
        break
      case 'close':
        valuetoPatch = {
          ...valuetoPatch,
          approvedAmount: null,
          activityDescription: null,
          numberOfAds: null,
          receivedDate: null,
          startDate: null,
          endDate: null,
          approveAuditCodes: null,
          denyAuditCodes: null
        };
        break
      default: // Ignore
        valuetoPatch = {
          ...valuetoPatch,
          approvedAmount: null,
          activityDescription: null,
          numberOfAds: null,
          receivedDate: null,
          startDate: null,
          endDate: null,
          approveAuditCodes: null,
          denyAuditCodes: null,
          comment: null,
          closeStatus: null
        };
        break
    }
    form.patchValue(valuetoPatch, { emitEvent: false });
  }

  private _lastReviewStatus: ReviewStatus | undefined;

  public onCurrentFormValueChanges() {

    if (!this.form) return;
    const formValue = this.form.value;
    const duplicateStatus = this.duplicateStatus;
    const notifications: WindowNotification[] = [];
    const temp = {
      isVisible: true,
      actionButtonSpec: {
        text: 'Review',
        onClick: this.onDuplicateReview,
      }
    };

    if (duplicateStatus.numResolved) {
      notifications.push({
        ...temp,
        ...(duplicateStatus.numDupes
          ? { type: 'warning', message: duplicateStatus.numDupes == duplicateStatus.numResolved ? 'All reviewed potential duplicate claims flagged as duplicate' : `${duplicateStatus.numDupes} out of ${duplicateStatus.numResolved} reviewed potential duplicate claims flagged as duplicate` }
          : { type: 'success', message: 'All potential duplicate claims flagged as not' })
      })
    }

    if (duplicateStatus.numUnresolved) {
      notifications.push({
        ...temp,
        type: 'error',
        message: `${duplicateStatus.numUnresolved} unresolved potential duplicate claim${duplicateStatus.numUnresolved > 1 ? 's' : ''} to review`,
      });
    }

    this.notifications$.next(notifications);
    this.reviewStatusButtonEnableGroup = duplicateStatus.numDupes ? 'duplicate' : 'audit';

    if (this._lastReviewStatus != formValue.reviewStatus) {
      this.onReviewStatusChanges();
    } else {
      // HACK: smells bad.  Seems like a convoluted way to trigger cross field dependant validators
      this.form.patchValue({ ...this.form.value }, { emitEvent: false });
      Object.keys(this.form.controls).forEach(key => {
        const ctrl = this.form?.controls[key];
        if (ctrl && ctrl.value && (ctrl.value.length === undefined || ctrl.value.length)) ctrl.markAllAsTouched();
      });
    }
    this._lastReviewStatus = formValue.reviewStatus;

    this.changeDetectorRef.detectChanges();
  }

  public onDuplicateReview = (() => {
    this.openReviewDuplicates();
  }).bind(this);


  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
