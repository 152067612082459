import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PageExpansionService {

  private pageExpanded = new BehaviorSubject<boolean>(false);

  public pageExpanded$ = this.pageExpanded.asObservable();

  public changePageExpanded(doExpand: boolean) {
    this.pageExpanded.next(doExpand);
  }

  public togglePageExpanded() {
    this.pageExpanded.next(!this.pageExpanded.value);
  }

  constructor() { }
}
