import { ApplicationInfoModel } from '@alcon-db-models/ApplicationInfoModel';
import { Component } from '@angular/core';
import { selectApplicationInfo } from '@app-store/app-session/app-session.selectors';
import { Store } from '@ngrx/store';
import { first } from 'rxjs/operators';
import { JsonUtilities } from 'src/app/shared/json-utilities';
import { Utilities } from 'src/app/shared/utilities';

@Component({
  selector: 'acb-alcon-header-main',
  template: `
    <div class="acb-header-main">
      <div class="acb-header-main-header">
        <div fxLayout="column" fxLayoutGap=".4em" class="acb-application-info" [ngClass]="{'acb-application-info-compact': sandboxRestoredBackupDate || sandboxRestoredDate}">
          <div *ngIf="sandboxRestoredBackupDate" class="sandbox-date"><span>Prod: </span>{{sandboxRestoredBackupDate}}</div>
          <div *ngIf="sandboxRestoredDate" class="sandbox-date"><span>Ref: </span>{{sandboxRestoredDate}}</div>
          <div *ngIf="!isProduction && isValid" [ngClass]="{ 'acb-mismatched-environments': !doEnvironmentMatch, 'acb-environment': true }">
            {{this.environment}}
          </div>
        </div>
        <a [routerLink]="['/home']" class="acb-header-logo">
          <img src="assets/images/logo-white.svg" type="image/svg+xml" alt="Alcon" width="120" height="50" />
        </a>
      </div>
      <div class="acb-menu-wrapper">
        <ng-content></ng-content>
      </div>
    </div>
  `,
  styleUrls: ['./header-main.component.scss']
})
export class HeaderMainComponent { 

  public isProduction: boolean = false;
  public isValid: boolean = false;
  public environment: string = '';
  public doEnvironmentMatch = false;
  public sandboxRestoredBackupDate: Date | null | undefined;
  public sandboxRestoredDate: Date | null | undefined;

  constructor(store: Store) {
    store.select(selectApplicationInfo).pipe(first()).subscribe(x => {
      let info = JsonUtilities.convertDatesAndCopy(x);
      this.sandboxRestoredBackupDate = info.sandboxRestoredBackupDate?.toLocaleString();
      this.sandboxRestoredDate = info.sandboxRestoreDate?.toLocaleString();
      this.doEnvironmentMatch = x?.doEnvironmentsMatch ?? false;
      if (x && x.alconDepartmentName && x.environmentDisplayName ) {
        this.isValid = true;
        this.isProduction = (x.dbEnvironmentDisplayName?.toLowerCase().includes('prod') ?? false) &&
          (x.environmentDisplayName?.toLowerCase().includes('prod') ?? false);
        this.environment = x.alconDepartmentName + " " + x.environmentDisplayName ?? '';
        if (!x.doEnvironmentsMatch) {
          this.environment += " ! " + x.dbEnvironmentDisplayName;
        }
      }
    });

  }
}
