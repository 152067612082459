// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  baseApiUrl: "./api/",
  KENDO_UI_LICENSE: "eyJhbGciOiJSUzI1NiIsInR5cCI6IkxJQyJ9.eyJwcm9kdWN0cyI6W3sidHJpYWwiOmZhbHNlLCJjb2RlIjoiS0VORE9VSVJFQUNUIiwibGljZW5zZUV4cGlyYXRpb25EYXRlIjoxNjIxODgyODAwfSx7InRyaWFsIjpmYWxzZSwiY29kZSI6IktFTkRPVUlDT01QTEVURSIsImxpY2Vuc2VFeHBpcmF0aW9uRGF0ZSI6MTYyMTg4MjgwMH0seyJ0cmlhbCI6ZmFsc2UsImNvZGUiOiJLRU5ET1VJVlVFIiwibGljZW5zZUV4cGlyYXRpb25EYXRlIjoxNjIxODgyODAwfSx7InRyaWFsIjpmYWxzZSwiY29kZSI6IktFTkRPVUlBTkdVTEFSIiwibGljZW5zZUV4cGlyYXRpb25EYXRlIjoxNjIxODgyODAwfV0sImludGVncml0eSI6InFWTEUrNUcyMmF6bUttRkYxeThOZXljT1pqST0iLCJsaWNlbnNlSG9sZGVyIjoidG5lYWxAYWNiY29vcC5jb20iLCJpYXQiOjE2MTEyMzg0ODQsImF1ZCI6InRuZWFsQGFjYmNvb3AuY29tIn0.MrmfV3uuVj-BiCSkj-INwtqdeLHvHEX5KowRfURgd1IA7SohvcueFCCuO2xNV7y3xOmyV4rZjlNt-mv4UDfMaVK2jd-EoPpNIEg54GM7ljFtnmc8qhOn9mvEuGleKVGlHGcIUHMdtIsLbUDx7FAWPZmoI6Ws7VfGdjF1lgqZDITGmaYU-vaHGTzlsqz8cC0MBHDrrn7lFFUb8OGpj_cb_SIjK6ItQ0qeHCEQK5HvddAAAhmsBy0tqaAVIDEBcjBg5vOMyneRgWq3MigK5zVsc7IWmIbpp0PRwtR0jOfmv4DLZTMOCuE83hgbE-C6dRjsq11EHjQFVNptoCsd0phK3Q",
  fieldMaxDollar: 1000000000,
  defaultPassword: "Ch@ng3M3_!",
  appVersion: require('../../package.json').version + '--dev'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
