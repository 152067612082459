import { CommitmentWithDetailsModel } from '@alcon-db-models/CommitmentWithDetailsModel';
import { ProductForDropdownModel } from '@alcon-db-models/ProductForDropdownModel';
import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormGroup } from '@angular/forms';
import { AppUxService } from '@services/app-ux.service';
import { CommitmentFormBaseService } from '@services/commitment-form-base.service';
import { BehaviorSubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { JsonUtilities } from 'src/app/shared/json-utilities';
import { EditProductsBaseComponent } from '../edit-products.base.component';

@Component({
  selector: 'acb-alcon-edit-commitment-products',
  template: `
    <form
      *ngIf="form"
      [formGroup]="form"
      style="width: 100%; height: 100%;"
      class="k-form"
      [ngClass]="{
        'acb-setup-complete': commitment?.amount === assignedAmount,
        'acb-cost-exceeded': assignedAmount > (commitment?.amount || 0),
        'acb-applied-zero': !assignedAmount
      }">
    <acb-alcon-section-with-legend [sectionTitle]="'Products'" class="acb-section-11" [doShowLegend]="doShowLegend">
      <div [fxLayout]="'column'">
        <div fxLayout="row" fxLayoutGap="1em" style="margin-bottom:1em; height: 5em;">
          <div fxFlex fxLayout="column" fxLayoutAlign="start stretch" class="acb-skeleton-field-and-label">
            <kendo-label fxLayout="row" fxLayoutAlign="start end" class="acb-progress-top-label"><span>Setup Progress</span></kendo-label>
            <div fxFlex class="acb-progress">
              <ng-container *ngIf="commitment?.amount || 0; let amount; else noAmount">
                <kendo-progressbar
                  #progressBar
                  [min]="0"
                  [max]="amount"
                  [value]="assignedAmount"
                  [label]="{ format: getProgressBarFormat, position: 'center' }"
                  emptyCssClass="acb-progressbar-empty"
                  progressCssClass="acb-progressbar-progress"
                  >
                </kendo-progressbar>
              </ng-container>
              <ng-template #noAmount>
                <div fxLayout fxLayoutAlign="center center" class="acb-no-progress">
                  <kendo-label>
                    Assign Commiment Cost to view phase progress
                  </kendo-label>
                </div>
              </ng-template>
            </div>
          </div>
          <div fxLayout="column" fxLayoutAlign="start stretch" class="acb-skeleton-field-and-label acb-reset-button-wrapper">
            <kendo-label>&nbsp;</kendo-label>
            <button fxFlex kendoButton class="acb-distribute-icon" [icon]="'align-baseline-vertical'" (click)="onDistribute()" [disabled]="assignedAmount == amount">
            </button>
          </div>
          <div fxLayout="column" fxLayoutAlign="start stretch" class="acb-skeleton-field-and-label acb-reset-button-wrapper">
            <kendo-label>&nbsp;</kendo-label>
            <button fxFlex kendoButton [icon]="'reset'" (click)="onReset()" [disabled]="!assignedAmount">
            </button>
          </div>
        </div>

        <div gdColumns="repeat(auto-fit, minmax(12em, 1fr))" fxFlex="6 0 auto" class="acb-progress-summary">
          <div class="acb-applied-row" fxLayout="row">
            <span fxFlex="0 0 6em" style="text-align:right">
              Applied:
            </span>
            <span fxFlex>
              {{ assignedAmount | currency }}
            </span>
          </div>
          <div class="acb-remaining-row" fxLayout="row">
            <span fxFlex="0 0 6em" style="text-align:right">
              Remaining:
            </span>
            <span fxFlex>
              {{ getRemaining() | currency }}
            </span>
          </div>
        </div>
        <div gdColumns="repeat(auto-fit, minmax(22em, 1fr))" gdColumns.sm="repeat(auto-fit, minmax(22em, .75fr))" gdColumns.gt-sm="repeat(auto-fit, minmax(22em, .65fr))" class="acb-edit-commitment-product-list">
          <acb-alcon-edit-commitment-product
            *ngFor="let productForm of $any(productsFormArray?.controls); index as i;"
            [form]="productForm"
            [commitmentAmount]="amount"
            [productsForDropdown$]="remainingProductsByProduct[i]"
            (delete)="onDelete($event)"
            >
          </acb-alcon-edit-commitment-product>
        </div>

        <div class="acb-add-product-button-wrapper">
          <button kendoButton (click)="onAdd()"><span class="k-icon k-i-add"></span> Add Product</button>
        </div>

      </div>
    </acb-alcon-section-with-legend>
  `,
  styleUrls: ['./edit-commitment-products.component.scss']
})
export class EditCommitmentProductsComponent extends EditProductsBaseComponent implements OnInit, OnDestroy {

  @Input() doShowLegend: boolean = true;

  public commitment?: CommitmentWithDetailsModel;
  public form: UntypedFormGroup;
  public amount: number = 0;

  constructor(
    appUxService: AppUxService,
    private commitmentFormBaseService: CommitmentFormBaseService,
    changeDetectorRef: ChangeDetectorRef
    ) {

    super(appUxService,changeDetectorRef);

    this.productsForDropdown = commitmentFormBaseService.products;
    this.form = commitmentFormBaseService.form;
    this.productsFormArray = this.form?.controls.commitmentProducts as UntypedFormArray;

    commitmentFormBaseService.commitment$.pipe(takeUntil(this.destroy$)).subscribe(x => {
      this.commitment = JsonUtilities.convertDatesAndCopy(x);
      this.amount = x.amount ?? 0;
    })
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  public onAdd = (() => {
    this.remainingProductsByProduct.push(new BehaviorSubject<ProductForDropdownModel[]>(this.productsForDropdown));
    this.commitmentFormBaseService.addProduct();
    this.onFormChanges(null);
  }).bind(this);

  public onDelete = ((productForm:UntypedFormGroup) => {

    if (!productForm)
      return;

    const productID: number | undefined = productForm.controls.productID.value;
    this.commitmentFormBaseService.removeProduct(productID);

    this.onFormChanges(null);

  }).bind(this);

  ngOnDestroy() {
    super.ngOnDestroy();
  }
}
