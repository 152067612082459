import { AfterViewInit, ChangeDetectorRef, Component, ComponentFactoryResolver, ComponentRef, EventEmitter, Injector, Input, OnDestroy, OnInit, Output, ViewChild, ViewContainerRef } from '@angular/core';
import { ClaimEditService } from '@services/claim-edit.service';
import { ClaimFormBaseService } from '@services/claim-form-base.service';
import { Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { EditClaimDetailsComponent, EditClaimMode } from './edit-claim-details.component';

@Component({
  selector: 'acb-alcon-edit-claim-details-dialog',
  template: `
    <kendo-dialog-titlebar class="acb-edit-claim-details-titlebar" (close)="onClose()">
      Edit Details
    </kendo-dialog-titlebar>
    <ng-container #editClaimDetailsContainer></ng-container>
    <kendo-dialog-actions>
      <button kendoButton class="acb-cancel" (click)="onCancel()"><span class="k-icon k-i-cancel"></span>Cancel</button>
      <button kendoButton [primary]="true" (click)="onApply()" [disabled]="!isFormValid" ><span class="k-icon k-i-checkmark"></span>Apply</button>
    </kendo-dialog-actions>
  `,
  styleUrls: ['./edit-claim-details-dialog.component.scss']
})
export class EditClaimDetailsDialogComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild('editClaimDetailsContainer', { read:ViewContainerRef }) editClaimDetailsContainer?: ViewContainerRef;

  @Input() editMode: EditClaimMode = EditClaimMode.Edit;
  @Input() canReverse: boolean = false;

  @Output() cancel: EventEmitter<any> = new EventEmitter();
  @Output() apply: EventEmitter<any> = new EventEmitter();
  @Output() viewCommitment: EventEmitter<any> = new EventEmitter();
  @Output() viewClaim: EventEmitter<any> = new EventEmitter();

  private destroy$: Subject<void> = new Subject<void>();

  private _valid: boolean = true;
  public get isFormValid():boolean {
    return this._valid;
  }

  private _claimEditService?: ClaimEditService;
  public set claimEditService(value:ClaimEditService) {
    this._claimEditService = value;
    this._claimEditService.validityChange$.pipe(takeUntil(this.destroy$)).subscribe(x => {
      this._valid = x.details;
    })
  }

  constructor(
    private resolver: ComponentFactoryResolver,
    private changeDetectionRef : ChangeDetectorRef
  ) { }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    // Need to pass service to edit details component, provider context is lost going through window service...
    // TODO: Clean up by moving to feature NGRX?
    if (this.editClaimDetailsContainer && this._claimEditService) {
      const factory = this.resolver.resolveComponentFactory(EditClaimDetailsComponent);

      const injector: Injector = Injector.create({ providers: [
        {
          provide: ClaimEditService, useValue: this._claimEditService
        },
        {
          provide: ClaimFormBaseService, useExisting: ClaimEditService
        }
      ]});
      injector.get(ClaimEditService);

      const ref: ComponentRef<EditClaimDetailsComponent> =  this.editClaimDetailsContainer.createComponent(factory, undefined, injector);
      ref.instance.editMode = this.editMode;
      ref.instance.canReverse = this.canReverse;
      ref.instance.doShowLegend = false;
      ref.instance.viewCommitment.pipe(takeUntil(this.destroy$)).subscribe(x => {
        this.viewCommitment.emit(x)
      });
      ref.instance.viewClaim.pipe(takeUntil(this.destroy$)).subscribe(x => {
        this.viewClaim.emit(x)
      });
      this.changeDetectionRef.detectChanges();
    }
  }

  public onCancel() {
    this.cancel.emit(null);
  }

  public onApply() {
    if (this.isFormValid)
      this.apply.emit(null);
  }

  public onClose() {
    this.cancel.emit(null);
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
