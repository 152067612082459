import { StaticTypeModel } from '@alcon-db-models/StaticTypeModel';
import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, UntypedFormControl, UntypedFormGroup, ValidationErrors, Validators } from '@angular/forms';
import { selectStaticTypes } from '@app-store/app-session/app-session.selectors';
import { Store } from '@ngrx/store';
import { BehaviorSubject, Observable, of, ReplaySubject, Subject } from 'rxjs';
import { catchError, first, map, takeUntil } from 'rxjs/operators';
import { ViewMode } from '../components.module';
import { CommitmentApprovalRuleSearchModel } from '@alcon-db-models/CommitmentApprovalRuleSearchModel';
import { DisplayError } from '../display-errors/display-errors.component';
import { VisibilityType } from '@alcon-db-models/Enums';
import { JsonUtilities } from 'src/app/shared/json-utilities';
import { AppUxService } from '@services/app-ux.service';
import { CommitmentApprovalRuleSearchService } from '@services/commitment-approval-rule-search.service';
import { PersonService } from '@services/person.service';
import { PersonModel } from '@alcon-db-models/PersonModel';
import { TerritoryForDropdownModel } from '@alcon-db-models/TerritoryForDropdownModel';
import { FundForDropdownModel } from '@alcon-db-models/FundForDropdownModel';
import { FundForDropdownService } from '@services/fund-for-dropdown.service';
import { TerritoryForDropdownService } from '@services/territory-for-dropdown.service';
import { CommitmentApprovalRuleUpsertService } from '@services/commitment-approval-rule-upsert.service';
import { WindowNotification } from '../window-notifications/window-notifications.component';
import { ServiceResponse } from 'src/app/shared/acb-stream';


@Component({
  selector: 'acb-alcon-edit-commitment-approval-rule',
  template: `
    <kendo-dialog-titlebar class="acb-edit-approval-rule-titlebar" (close)="onClose()">
      {{ viewMode == 'edit' ? 'Edit Rule' : 'New Rule' }}
    </kendo-dialog-titlebar>
    <acb-alcon-window-notifications [windowNotifications]="notifications" [fxHide]="!this.ruleForm.errors">
    </acb-alcon-window-notifications>
    <form [formGroup]="ruleForm" style="width: 100%;" class="k-form">
      <acb-alcon-section-with-legend [doShowLegend]="false" class="acb-section-05">
        <div class="acb-inner-panel" fxLayout="row wrap" fxLayoutGap="2em" fxLayoutAlign="start top">

          <ng-container>
            <kendo-formfield *appFieldExtentions fxFlex="0 0 10em" fxFlex.lt-sm="auto">
              <kendo-label [for]="approvalRuleTypeID" text="Type"></kendo-label>
              <kendo-combobox
              [data]="this.approvalRuleTypes$ | async"
              [textField]="'displayName'"
              [valueField]="'id'"
              [filterable]="true"
              [formControlName]="'approvalRuleTypeID'"
              [valuePrimitive]="true"
              #approvalRuleTypeID
              autocomplete="off"
              >
              </kendo-combobox>
              <kendo-formhint>&nbsp;</kendo-formhint>
              <kendo-formerror>Error: type required</kendo-formerror>
            </kendo-formfield>
          </ng-container>

          <ng-container>
            <kendo-formfield *appFieldExtentions fxFlex="0 0 16em" fxFlex.lt-sm="auto">
              <kendo-label [for]="description" text="Description" [optional]="true"></kendo-label>
              <textarea formControlName="description" kendoTextArea #description rows="1" style="resize: vertical;"></textarea>
              <kendo-formhint>&nbsp;</kendo-formhint>
              <kendo-formerror *ngIf="ruleForm.controls.description.errors?.maxlength">Error: exceeds character limit</kendo-formerror>
            </kendo-formfield>
          </ng-container>

          <kendo-formfield *appFieldExtentions fxFlex="0 0 12em" fxFlex.lt-sm="auto">
            <kendo-label [for]="startDate" text="Start Date" [optional]="true"></kendo-label>
            <kendo-datepicker
              formControlName="startDate"
              #startDate
              autocomplete="off"
              [format]="{ displayFormat: 'MM/dd/yyyy', inputFormat: 'MM/dd/yy'  }"
              >
            </kendo-datepicker>
            <kendo-formhint>&nbsp;</kendo-formhint>
          </kendo-formfield>

          <kendo-formfield *appFieldExtentions fxFlex="0 0 12em" fxFlex.lt-sm="auto">
            <kendo-label [for]="endDate" text="End Date" [optional]="true"></kendo-label>
            <kendo-datepicker
              formControlName="endDate"
              #endDate
              autocomplete="off"
              [format]="{ displayFormat: 'MM/dd/yyyy', inputFormat: 'MM/dd/yy'  }"
              >
            </kendo-datepicker>
            <kendo-formhint>&nbsp;</kendo-formhint>
          </kendo-formfield>

          <kendo-formfield *appFieldExtentions fxFlex=".125 0 12em" fxFlex.lt-sm="auto">
            <kendo-label [for]="minAmount" text="Min Amount" [optional]="true"></kendo-label>
            <kendo-numerictextbox [format]="'c0'" [min]="0" #minAmount [formControlName]="'minAmount'"></kendo-numerictextbox>
            <kendo-formhint>&nbsp;</kendo-formhint>
          </kendo-formfield>

          <kendo-formfield *appFieldExtentions fxFlex=".125 0 12em" fxFlex.lt-sm="auto">
            <kendo-label [for]="maxAmount" text="Max Amount" [optional]="true"></kendo-label>
            <kendo-numerictextbox [format]="'c0'" [min]="0" #maxAmount [formControlName]="'maxAmount'"></kendo-numerictextbox>
            <kendo-formhint>&nbsp;</kendo-formhint>
          </kendo-formfield>

          <!-- <kendo-formfield *appFieldExtentions fxFlex=".125 0 6em" fxFlex.lt-sm="auto">
            <kendo-label [for]="rank" text="Rank" [optional]="true"></kendo-label>
            <kendo-numerictextbox [min]="0" #rank [formControlName]="'rank'" [decimals]="0"></kendo-numerictextbox>
            <kendo-formhint>&nbsp;</kendo-formhint>
          </kendo-formfield> -->

          <ng-container>
            <kendo-formfield *appFieldExtentions fxFlex="0 0 18em" fxFlex.lt-sm="auto">
              <kendo-label [for]="approverPersonID" text="Person"></kendo-label>
              <kendo-combobox
              [data]="this.approverPeople$ | async"
              [textField]="'displayName'"
              [valueField]="'personID'"
              [filterable]="true"
              [formControlName]="'approverPersonID'"
              [valuePrimitive]="true"
              #approverPersonID
              autocomplete="off"
              (filterChange)="onApproverPersonDropdownFilter($event)"
              >
              </kendo-combobox>
              <kendo-formhint>&nbsp;</kendo-formhint>
              <kendo-formerror>Error: person required</kendo-formerror>
            </kendo-formfield>
          </ng-container>

          <ng-container>
            <kendo-formfield *appFieldExtentions fxFlex="0 0 20em" fxFlex.lt-sm="auto">
              <kendo-label [for]="fundID" text="Fund"></kendo-label>
              <kendo-combobox
              [data]="this.fundsForDropdown$ | async"
              [textField]="'displayName'"
              [valueField]="'fundID'"
              [filterable]="true"
              [formControlName]="'fundID'"
              [valuePrimitive]="true"
              #fundID
              autocomplete="off"
              (filterChange)="onFundDropdownFilter($event)"
              >
              </kendo-combobox>
              <kendo-formhint>&nbsp;</kendo-formhint>
            </kendo-formfield>
          </ng-container>

          <ng-container>
            <kendo-formfield *appFieldExtentions fxFlex="0 0 20em" fxFlex.lt-sm="auto">
              <kendo-label [for]="territoryID" text="Territory"></kendo-label>
              <kendo-combobox
              [data]="this.territoriesForDropdown$ | async"
              [textField]="'territorySuggestedName'"
              [valueField]="'territoryID'"
              [filterable]="true"
              [formControlName]="'territoryID'"
              [valuePrimitive]="true"
              #territoryID
              autocomplete="off"
              (filterChange)="onTerritoryDropdownFilter($event)"
              >
              </kendo-combobox>
              <kendo-formhint>&nbsp;</kendo-formhint>
            </kendo-formfield>
          </ng-container>

        </div>
      </acb-alcon-section-with-legend>
    </form>
    <kendo-dialog-actions>
      <button kendoButton class="acb-cancel" (click)="onCancel()"><span class="k-icon k-i-cancel"></span>Cancel</button>
      <button kendoButton [primary]="true" (click)="onSave()" ><span class="k-icon k-i-checkmark"></span>{{ viewMode == 'edit' ? 'Update' : 'Save' }}</button>
    </kendo-dialog-actions>
  `,
  styleUrls: ['./edit-commitment-approval-rule.component.scss']
})
export class EditCommitmentApprovalRuleComponent implements OnInit {

  @Input() public viewMode:ViewMode = "edit";

  public notifications:WindowNotification[] = [];


  private _localCommitmentApprovalRuleID?:number | null | undefined;
  @Input() set localCommitmentApprovalRuleID(value:number | null | undefined) {
    if (this._localCommitmentApprovalRuleID = value) {
      this.commitmentApprovalRuleSearchService.search({ localCommitmentApprovalRuleID: value }).pipe(first()).subscribe(x => {
        if (x.value?.length) {
          this.commitmentApprovalRuleSearch$.next((x.value as CommitmentApprovalRuleSearchModel[])[0]);
        }
      });
    }
    else {
      this.ruleForm.reset({});
      this.commitmentApprovalRuleSearch = undefined;
    }
  }

  private _title?: string;
  @Input() public set title(value: string) { this._title = value; };
  public get title(): string {
    if (this._title) {
      return this._title;
    } else {
      switch(this.viewMode) {
        case "edit":
          return "Edit"
        default:
          return "New"
      }
    }
  }

  @Output() cancel: EventEmitter<any> = new EventEmitter();
  @Output() saved: EventEmitter<CommitmentApprovalRuleSearchModel> = new EventEmitter();

  public commitmentApprovalRuleSearch$: ReplaySubject<CommitmentApprovalRuleSearchModel> = new ReplaySubject<CommitmentApprovalRuleSearchModel>(1);
  public commitmentApprovalRuleSearch: CommitmentApprovalRuleSearchModel | undefined = undefined;
  public staticTypes$: Observable<StaticTypeModel[]>;
  public visibilityTypes$: Observable<StaticTypeModel[]>;
  public approvalRuleTypes$: Observable<StaticTypeModel[]>;
  public approverPeopleSource$: Observable<PersonModel[]>;
  public approverPeople$: Observable<PersonModel[]>;
  public fundsForDropdownSource$: Observable<FundForDropdownModel[]>;
  public fundsForDropdown$: Observable<FundForDropdownModel[]>;
  public territoriesForDropdownSource$: Observable<TerritoryForDropdownModel[]>;
  public territoriesForDropdown$: Observable<TerritoryForDropdownModel[]>;

  public loading$: Observable<boolean> = of(false); //this.customerWithDetailsService.loading$;
  public destroy$: Subject<void> = new Subject<void>();
  public displayErrors$:  BehaviorSubject<DisplayError[]> = new BehaviorSubject<DisplayError[]>([]);

  public ruleForm = new UntypedFormGroup({
    localCommitmentApprovalRuleID: new UntypedFormControl(),
    approvalRuleTypeID: new UntypedFormControl(null, [Validators.required]),
    description: new UntypedFormControl(null, [Validators.maxLength(4000)]),
    startDate: new UntypedFormControl(null),
    endDate: new UntypedFormControl(null),
    minAmount: new UntypedFormControl(null),
    maxAmount: new UntypedFormControl(null),
    rank: new UntypedFormControl(null),
    approverPersonID: new UntypedFormControl(null, [Validators.required]),
    fundID: new UntypedFormControl(null),
    customerID: new UntypedFormControl(null),
    territoryID: new UntypedFormControl(null),
    businessRoleID: new UntypedFormControl(null)
  }, { validators: this.scopeValidator });

  constructor(
    private store: Store,
    private commitmentApprovalRuleSearchService: CommitmentApprovalRuleSearchService,
    private commitmentApprovalRuleUpsertService: CommitmentApprovalRuleUpsertService,
    private fundForDropdownService: FundForDropdownService,
    private territoryForDropdownService: TerritoryForDropdownService,
    private appUxService: AppUxService,
    private personService: PersonService,
    private changeDetectorRef: ChangeDetectorRef,

    ) {
    this.commitmentApprovalRuleSearch$.pipe(takeUntil(this.destroy$)).subscribe(x => {
      this.commitmentApprovalRuleSearch = x ? JsonUtilities.convertDatesAndCopy(x) : undefined;
      this.ruleForm.patchValue({...this.commitmentApprovalRuleSearch});
    });
    this.staticTypes$ = this.store.select(selectStaticTypes);
    this.visibilityTypes$ = this.staticTypes$.pipe(map(x => x.filter(y => y.tableName == 'VisibilityType')));
    this.approvalRuleTypes$ = this.staticTypes$.pipe(map(x => x.filter(y => y.tableName == 'ApprovalRuleType' && y.visibilityTypeID == VisibilityType.Visible)));
    this.approverPeople$ = this.approverPeopleSource$ = personService.getWithQuery({  });
    this.fundsForDropdown$ = this.fundsForDropdownSource$ = fundForDropdownService.getAll();
    this.territoriesForDropdown$ = this.territoriesForDropdownSource$ = territoryForDropdownService.getAll();
  }

  public scopeValidator(control: AbstractControl): ValidationErrors | null {
    if (!control.get('fundID')?.value && !control.get('customerID')?.value && !control.get('territoryID')?.value) {
      return { 'noScope': true}
    }
    return null;
  }

  public validateForm(): boolean {
    this.ruleForm.markAllAsTouched();
    return this.ruleForm.valid;
  }

  public updateErrors() {
    this.notifications.length = 0;
    if (this.ruleForm.errors?.noScope) {
      this.notifications.push({ type:'error', isVisible:true, message: 'Must select at least one Fund or Territory'});
    }
  }

  ngOnInit(): void {
  }


  public onClose() {
    this.cancel.emit(null);
  }

  public onSave() {

    if (!this.validateForm()) {
      this.updateErrors();
      return;
    }

    this.commitmentApprovalRuleUpsertService.upsertCommitmentApprovalRule({...this.ruleForm.value}).subscribe((x:ServiceResponse<CommitmentApprovalRuleSearchModel>) => {
      if (x.hasError) {
        this.appUxService.openErrorDialog(x.errorMessage);
      } else {
        this.saved.emit(x.response as CommitmentApprovalRuleSearchModel);
      }
    });
  }

  public onCancel() {
    this.cancel.emit(null);
  }

  public onApproverPersonDropdownFilter(value?: string) {
    if (!value) {
      this.approverPeople$ = this.approverPeopleSource$;
      return;
    }
    this.approverPeople$ = this.approverPeopleSource$.pipe(
      map(x => x
        .filter(y => y.displayName!.toLowerCase()
          .indexOf(value.toLowerCase()) > -1)));
  }

  public onFundDropdownFilter(value?: string) {
    if (!value) {
      this.fundsForDropdown$ = this.fundsForDropdownSource$;
      return;
    }
    this.fundsForDropdown$ = this.fundsForDropdownSource$.pipe(
      map(x => x
        .filter(y => y.displayName!.toLowerCase()
          .indexOf(value.toLowerCase()) > -1)));
  }

  public onTerritoryDropdownFilter(value?: string) {
    if (!value) {
      this.territoriesForDropdown$ = this.territoriesForDropdownSource$;
      return;
    }
    this.territoriesForDropdown$ = this.territoriesForDropdownSource$.pipe(
      map(x => x
        .filter(y => y.territorySuggestedName!.toLowerCase()
          .indexOf(value.toLowerCase()) > -1)));
  }


  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

}
