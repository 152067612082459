import { CustomerWithLocationModel } from '@alcon-db-models/CustomerWithLocationModel';
import { Injectable } from '@angular/core';
import { EntityCollectionServiceBase, EntityCollectionServiceElementsFactory } from '@ngrx/data';

@Injectable({
  providedIn: 'root'
})
export class CustomerWithLocationService extends EntityCollectionServiceBase<CustomerWithLocationModel> {
  constructor(serviceElementsFactory: EntityCollectionServiceElementsFactory) {
    super('CustomerWithLocation', serviceElementsFactory);
  }
}

