import { CommitmentWithDetailsModel } from '@alcon-db-models/CommitmentWithDetailsModel';
import { Injectable } from '@angular/core';
import { EntityActionOptions, EntityCollectionServiceBase, EntityCollectionServiceElementsFactory, QueryParams } from '@ngrx/data';
import { Observable, timer } from 'rxjs';
import { map, skipUntil } from 'rxjs/operators';
import { JsonUtilities } from '../shared/json-utilities';

@Injectable({
  providedIn: 'root'
})
export class CommitmentWithDetailsService extends EntityCollectionServiceBase<CommitmentWithDetailsModel> {
  constructor(serviceElementsFactory: EntityCollectionServiceElementsFactory) {
    super('CommitmentWithDetails', serviceElementsFactory);
    this.errors$.pipe(skipUntil(timer(0)));
  }
  getAll(): Observable<CommitmentWithDetailsModel[]> {
    return super.getAll().pipe(map(X => X.map(Y => this.mapCommitment(Y))));
  }

  getByKey(key: any, options?: EntityActionOptions): Observable<CommitmentWithDetailsModel> {
    return super.getByKey(key,options).pipe(map(x => this.mapCommitment(x)));
  }

  getWithQuery(queryParams: QueryParams | string, options?: EntityActionOptions): Observable<CommitmentWithDetailsModel[]> {
    return super.getWithQuery(queryParams, options).pipe(map(x => x.map(y => this.mapCommitment(y))));
  }

  private mapCommitment(commitment: CommitmentWithDetailsModel): CommitmentWithDetailsModel {
    commitment = JsonUtilities.convertDatesAndCopy(commitment);
    return commitment;
  }
}

