import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { UserFormBaseService } from './user-form-base.service';
import { UserInfoService } from './user-info.service';

@Injectable()
export class UserCreateService extends UserFormBaseService {

  constructor(
    store: Store,
    userInfoService: UserInfoService
  ) {
    super(store, userInfoService);
    this.form.patchValue({
      mustUpdatePassword: true,
      isActive: true,
      doEmailUser: true,
      personID: undefined,
      applicationUserID: undefined
    });
  }

  public updateForm() {
    super.updateForm(this._user, false);
    this.initForm();
  }

  public initForm() {
    this.form.patchValue({
      mustUpdatePassword: true,
      isActive: true,
      doEmailUser: true,
      personID: undefined,
      applicationUserID: undefined
    });
  }
}
