import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { AccessControlBaseService } from './access-controlled-base.service';
import { ClaimReviewRequestModel } from '@alcon-db-models/ClaimReviewRequestModel'
import { ServiceResponse, firstWithLoadingOverlayAndErrorHandling } from '../shared/acb-stream';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ClaimReviewService extends AccessControlBaseService {

  constructor(protected http: HttpClient, protected router: Router, protected store: Store) {
    super();
  }

  public postReview(models: ClaimReviewRequestModel[] ): Observable<ServiceResponse<boolean>> {
    return this.post<boolean>('ClaimReview', models).pipe(firstWithLoadingOverlayAndErrorHandling<boolean>());
  }
}
