import { ClaimSearchRequestModel } from '@alcon-db-models/ClaimSearchRequestModel';
import { ChangeDetectorRef, Directive, Input, OnDestroy, OnInit } from '@angular/core';
import { GridComponent, GridDataResult } from '@progress/kendo-angular-grid';
import { FilterDescriptor } from '@progress/kendo-data-query';
import { ClaimSearchService } from '@services/claim-search.service';
import { UserPreferencesService } from '@services/user-preferences.service';
import { Subscription } from 'rxjs';
import { UserPreferenceEnabledDataBindingDirective } from './user-preference-enabled-data-binding.directive';

@Directive({
  selector: '[claimSearchBindingDirective]'
})
export class ClaimSearchBindingDirective extends UserPreferenceEnabledDataBindingDirective implements OnInit, OnDestroy {

  @Input() preferenceKey: string = "ClaimSearch";

  private serviceSubscription?: Subscription;

  constructor(
    grid: GridComponent,
    userPreferencesService: UserPreferencesService,
    changeDetectorRef: ChangeDetectorRef,
    public claimSearchService: ClaimSearchService
    ) {
    super(grid, userPreferencesService, changeDetectorRef);
  }

  public ngOnInit(): void {

    this.claimSearchService.next([] as any as GridDataResult);

    this.serviceSubscription = this.claimSearchService.subscribe((result) => {
      this.grid.loading = false;
      this.grid.data = result;
      this.notifyDataChange();
      this.grid.loading = false;
    });

    super.ngOnInit();
  }

  public ngOnDestroy(): void {
    if (this.serviceSubscription) {
        this.serviceSubscription.unsubscribe();
    }
    super.ngOnDestroy();
  }

  public rebind(): void {
    this.grid.loading = true;
    this.claimSearchService.query(this.getSearchRequest());
  }

  public getSearchRequest(doIgnorePagination: boolean = false): ClaimSearchRequestModel {

    const filters: FilterDescriptor[] = this.state.filter?.filters as FilterDescriptor[];

    const getValue = function(col:string) {
      let descriptor: FilterDescriptor;
      return (descriptor = filters?.find(x => x.field == col) as FilterDescriptor) ? descriptor?.value : null
    };

    return ({
      personID: null,
      take: doIgnorePagination ? null : this.state.take,
      skip: doIgnorePagination ? 0 : this.state.skip,
      sortColumn: this.state.sort?.[0]?.dir ? this.state.sort?.[0]?.field : null,
      sortAscending: this.state.sort?.[0]?.dir ? this.state.sort[0].dir != 'desc' : null,
      name: getValue('name'),
      claimIDs: getValue('claimIDs'),
      commitmentIDs: getValue('commitmentIDs'),
      payeeName: getValue('payeeName'),
      invoiceNumber: getValue('invoiceNumber'),
      statusCodeIDs: getValue('statusCodeIDs'),
      doExcludeCanceledAndDenied: getValue('doExcludeCanceledAndDenied'),
      territoryIDs: getValue('territoryIDs'),
      customerCode: getValue('customerCode'),
      customerName: getValue('customerName'),
      startDate: getValue('startDate'),
      endDate: getValue('endDate'),
      fundYears: getValue('fundYears'),
      fundIDs: getValue('fundIDs'),
      activityIDs: getValue('activityIDs'),
      deduction: getValue('deduction'),
      payTypeID: getValue('payTypeID'),
      minAmount: getValue('minAmount'),
      maxAmount: getValue('maxAmount'),
      isReview: getValue('isReview'),
      minReceivedDate: getValue('minReceivedDate'),
      maxReceivedDate: getValue('maxReceivedDate'),
      minProcessedDate: getValue('minProcessedDate'),
      maxProcessedDate: getValue('maxProcessedDate'),
      minReleasedDate: getValue('minReleasedDate'),
      maxReleasedDate: getValue('maxReleasedDate'),
      minClearedDate: getValue('minClearedDate'),
      maxClearedDate: getValue('maxClearedDate'),
      canCopy: getValue('canCopy'),
      canReverse: getValue('canReverse'),
      canVoid: getValue('canVoid'),
      canCancel: getValue('canCancel'),
      canEdit: getValue('canEdit'),
      canAudit: getValue('canAudit'),
      canCheck: getValue('canCheck'),
      canPend: getValue('canPend')
    });

  }
}
