<acb-alcon-search-filters
  [title]="'Search'"
  [formGroup]="searchForm"
  (formReset)="onSearch()"
  (filterChosen)="onSearch()"
  #searchFilters
  [fxHide]="this.pageExpanded$ | async"
>
  <p featureText>
    <strong>Customer Search.</strong> To expand the search option for other commitments, click on the right corner box to allow you to search for additional fields.
    <br><br>
    To see additional field options on the grid below, click on the Column Lines to the left to add or remove columns.
  </p>
  <ng-container filterSectionBody>

    <ng-container *ngIf="searchFlavor == 'manage'">
      <kendo-formfield *appFieldExtentions fxFlex="12em" fxFlex.lt-sm="auto" [fxHide]="!searchFilters.isExpanded">
        <kendo-label [for]="customerIDs" text="Customer IDs"></kendo-label>
        <kendo-textbox formControlName="customerIDs" #customerIDs autocomplete="off" multipleIntegerTextbox></kendo-textbox>
        <kendo-formhint>One or more, ex: 10, 20, 30</kendo-formhint>
      </kendo-formfield>
    </ng-container>

    <kendo-formfield *appFieldExtentions fxFlex fxFlex.lt-sm="auto" [fxHide]="!searchFilters.isExpanded">
      <kendo-label [for]="customerCode" text="Customer Number"></kendo-label>
      <input formControlName="customerCode" kendoTextBox #customerCode autocomplete="off" />
      <kendo-formhint>&nbsp;</kendo-formhint>
    </kendo-formfield>

    <kendo-formfield *appFieldExtentions fxFlex fxFlex.lt-sm="auto">
      <kendo-label [for]="customerName" text="Customer Name"></kendo-label>
      <input formControlName="customerName" kendoTextBox #customerName autocomplete="off" />
      <kendo-formhint>&nbsp;</kendo-formhint>
    </kendo-formfield>

    <kendo-formfield *appFieldExtentions fxFlex fxFlex.lt-sm="auto" [fxHide]="!searchFilters.isExpanded">
      <kendo-label [for]="emailAddress" text="Email Address"></kendo-label>
      <input formControlName="emailAddress" kendoTextBox #emailAddress autocomplete="off" />
      <kendo-formhint>&nbsp;</kendo-formhint>
    </kendo-formfield>

    <div fxHide.sm class="acb-flex-break"></div>

    <kendo-formfield *appFieldExtentions fxFlex fxFlex.lt-sm="auto" [fxHide]="!searchFilters.isExpanded">
      <kendo-label [for]="location" text="Address"></kendo-label>
      <input formControlName="location" kendoTextBox #location autocomplete="off" />
      <kendo-formhint>&nbsp;</kendo-formhint>
    </kendo-formfield>

    <kendo-formfield *appFieldExtentions fxFlex fxFlex.lt-sm="auto" [fxHide]="!searchFilters.isExpanded">
      <kendo-label [for]="city" text="City"></kendo-label>
      <input formControlName="city" kendoTextBox #city autocomplete="off" />
      <kendo-formhint>&nbsp;</kendo-formhint>
    </kendo-formfield>

    <kendo-formfield *appFieldExtentions fxFlex="0 0 10em" fxFlex.lt-sm="auto" [fxHide]="!searchFilters.isExpanded">
      <kendo-label [for]="stateProvinceCodeID" text="State / Province"></kendo-label>
      <kendo-dropdownlist
        [data]="stateProvinceCodes$ | async"
        [textField]="'displayName'"
        [valueField]="'id'"
        [formControlName]="'stateProvinceCodeID'"
        [valuePrimitive]="true"
        #stateProvinceCodeID
        >
      </kendo-dropdownlist>
      <kendo-formhint>&nbsp;</kendo-formhint>
    </kendo-formfield>

    <kendo-formfield *appFieldExtentions fxFlex="0 0 10em" fxFlex.lt-sm="auto" [fxHide]="!searchFilters.isExpanded">
      <kendo-label [for]="postalCode" text="Postal Code"></kendo-label>
      <input formControlName="postalCode" kendoTextBox #postalCode autocomplete="off" />
      <kendo-formhint>&nbsp;</kendo-formhint>
    </kendo-formfield>

    <kendo-formfield *appFieldExtentions fxFlex="0 0 10em" fxFlex.lt-sm="auto" [fxHide]="!searchFilters.isExpanded">
      <kendo-label [for]="countryCodeID" text="Country"></kendo-label>
      <kendo-dropdownlist
        [data]="countryCodes$ | async"
        [textField]="'displayName'"
        [valueField]="'id'"
        [formControlName]="'countryCodeID'"
        [valuePrimitive]="true"
        #countryCodeID
        >
      </kendo-dropdownlist>
      <kendo-formhint>&nbsp;</kendo-formhint>
    </kendo-formfield>

    <div fxHide.sm class="acb-flex-break"></div>

    <kendo-formfield *appFieldExtentions fxFlex="0 0 10em" fxFlex.lt-sm="auto" [fxHide]="!searchFilters.isExpanded">
      <kendo-label [for]="taxCode" text="Tax ID"></kendo-label>
      <input formControlName="taxCode" kendoTextBox #taxCode autocomplete="off" />
      <kendo-formhint>&nbsp;</kendo-formhint>
    </kendo-formfield>

    <kendo-formfield *appFieldExtentions fxFlex="0 0 10em" fxFlex.lt-sm="auto" [fxHide]="!searchFilters.isExpanded">
      <kendo-label [for]="payType" text="Pay Type"></kendo-label>
      <kendo-dropdownlist
        [data]="payTypes$ | async"
        [textField]="'displayName'"
        [valueField]="'id'"
        [formControlName]="'payTypeID'"
        [valuePrimitive]="true"
        #payType
        >
      </kendo-dropdownlist>
      <kendo-formhint>Form of payment</kendo-formhint>
    </kendo-formfield>

    <ng-container *ngIf="customerViewMode == 'payee'">
      <kendo-formfield *appFieldExtentions fxFlex="0 0 14em" fxFlex.lt-sm="auto" [fxHide]="!searchFilters.isExpanded">
        <kendo-label [for]="customerTypeID" text="Customer Type"></kendo-label>
        <kendo-dropdownlist
          [data]="customerTypes$ | async"
          [textField]="'displayName'"
          [valueField]="'id'"
          [formControlName]="'customerTypeID'"
          [valuePrimitive]="true"
          #customerTypeID
          >
        </kendo-dropdownlist>
        <kendo-formhint>&nbsp;</kendo-formhint>
      </kendo-formfield>
    </ng-container>

    <kendo-formfield *appFieldExtentions fxFlex="6em" [fxHide]="!searchFilters.isExpanded">
      <kendo-label [for]="visibilityTypeID" text="Active"></kendo-label>
      <kendo-dropdownlist
        formControlName="visibilityTypeID"
        [data]="yesNoVisibilityList"
        [textField]="'name'"
        [valueField]="'value'" 
        [valuePrimitive]="true"
        #visibilityTypeID
        >
      </kendo-dropdownlist>
      <kendo-formhint>&nbsp;</kendo-formhint>
    </kendo-formfield>


    <!--
    <ng-container *ngIf="searchFlavor == 'manage'">
      <kendo-formfield *appFieldExtentions fxFlex="6em" [fxHide]="!searchFilters.isExpanded">
        <kendo-label [for]="isACHExcluded" text="ACH Excluded"></kendo-label>
        <kendo-dropdownlist
          #isACHExcluded
          formControlName="isACHExcluded"
          [defaultItem]="undefined"
          [data]="yesNoList"
          [textField]="'name'"
          [valueField]="'value'"
          [valuePrimitive]="true"
          >
        </kendo-dropdownlist>
        <kendo-formhint>&nbsp;</kendo-formhint>
      </kendo-formfield>

      <kendo-formfield *appFieldExtentions fxFlex="6em" [fxHide]="!searchFilters.isExpanded">
        <kendo-label [for]="isACHEnrolled" text="ACH Enrolled"></kendo-label>
        <kendo-dropdownlist
          #isACHEnrolled
          formControlName="isACHEnrolled"
          [defaultItem]="undefined"
          [data]="yesNoList"
          [textField]="'name'"
          [valueField]="'value'"
          [valuePrimitive]="true"
          >
        </kendo-dropdownlist>
        <kendo-formhint>&nbsp;</kendo-formhint>
      </kendo-formfield>
    </ng-container>
     -->

  </ng-container>
</acb-alcon-search-filters>

<kendo-grid
  #resultsGrid
  customerSearchBindingDirective
  [pageable]="{
    buttonCount: 4,
    pageSizes: true
  }"
  [sortable]="true"
  [reorderable]="true"
  [pageSize]="10"
  [selectable]="true"
  [resizable]="true"
  [(selectedKeys)] = "selectedKeys"
  [selectable] = "{
    enabled: true,
    mode: 'multiple'
  }"
  (selectionChange)="onSelectedChange()"
  kendoGridSelectBy="customerID"
  class="acb-results-grid"
  >
  <ng-template kendoGridToolbarTemplate>
    <div fxLayout="row" fxLayoutAlign=" center" >
      <div fxFlexAlign="center" class="acb-toolbar-adjacent">
        <kendo-grid-column-chooser [autoSync]="true" [allowHideAll]="false"></kendo-grid-column-chooser>
        <button kendoButton type="button" [icon]="'reset'" (click)="onResetGridPrefs()" [className]="'acb-teal'" fxFlexOffset="1em" >Reset Grid</button>
        <button kendoButton type="button" [icon]="'user'" (click)="onCreateNewCustomer()" [className]="'acb-primary'" fxFlexOffset="1em" >New Payee</button>
      </div>
      <div fxFlex='1.25 1 auto' fxFlex.xs='.75 1 auto'></div>
        <kendo-toolbar [overflow]="true" fxFlex="0 1 35em" fxFlex.xs="0 0 4em" [fxHide]="!hasSelected" [ngStyle.xs]="{'margin':'0'}">
          <kendo-toolbar-button [text]="'Clear (' + this.selectedKeys.length.toString() + ')'" [icon]="'apply-format'" (click)="onClearButtonClick()" [className]="'acb-toolbar-button-clear'" ></kendo-toolbar-button>
          <kendo-toolbar-button [text]="'Activate'" (click)="onActivateSelected()" [icon]="'checkmark'" [className]="'acb-good'" [disabled]="!activateableSelectedKeys.length"></kendo-toolbar-button>
          <kendo-toolbar-button [text]="'Deactivate'" (click)="onDeactivateSelected()" [icon]="'close'" [className]="'acb-warning'" [disabled]="!deactivateableSelectedKeys.length"></kendo-toolbar-button>
          <!-- <kendo-toolbar-button [text]="'Delete'" (click)="onDeactivateSelected()" [icon]="'delete'" [className]="'acb-danger'"></kendo-toolbar-button> -->
        </kendo-toolbar>
        <div fxFlex='1 1 auto'></div>
      <div fxFlexAlign="center" class="acb-toolbar-adjacent">
        <button kendoGridExcelCommand type="button" icon="file-excel" class="k-button">Export to Excel</button>
        <button kendoButton type="button" [icon]="expandToggleIcon" class="k-button" (click)="onToggleExpand()" fxFlexOffset="1em" ></button>
      </div>
    </div>
  </ng-template>
  <kendo-grid-command-column
    [width]="30"
    [locked]="true"
    [reorderable]="false"
    [includeInChooser]="false"
    [resizable]="false"
    [headerClass]="'acb-grid-command-column-header'"
    >
    <ng-template kendoGridCellTemplate let-dataItem="dataItem" let-rowIndex="rowIndex">
      <input [kendoGridSelectionCheckbox]="rowIndex" kendoCheckBox />
    </ng-template>
  </kendo-grid-command-column>  
  <kendo-grid-command-column
    [width]="50"
    [reorderable]="false"
    [includeInChooser]="false"
    [resizable]="false"
    >
    <ng-template kendoGridCellTemplate let-dataItem="dataItem" let-rowIndex="rowIndex">
      <button kendoGridEditCommand class="acb-in-grid-edit-button"  [icon]="'edit'" look="clear" (click)="onEditCustomer($event, dataItem)"></button>
    </ng-template>
  </kendo-grid-command-column>
  <kendo-grid-column title="ID" field="customerID" [width]="70" [hidden]="true" *ngIf="searchFlavor === 'manage'">
  </kendo-grid-column>
  <kendo-grid-column title="Cust #" field="code" [width]="110">
  </kendo-grid-column>
  <kendo-grid-column title="Customer" field="displayName" [width]="180">
  </kendo-grid-column>
  <kendo-grid-column title="Lens Rating" field="lensRating" [width]="180" [hidden]="true">
  </kendo-grid-column>
  <kendo-grid-column title="Lens Care Rating" field="lensCareRating" [width]="180" [hidden]="true">
  </kendo-grid-column>
  <kendo-grid-column title="Order Block" field="orderBlock" [width]="180" [hidden]="true">
  </kendo-grid-column>
  <kendo-grid-column title="SF Customer Account" field="sfCustomerAccount" [width]="180" [hidden]="true">
  </kendo-grid-column>
  <kendo-grid-column title="Address 1" field="locationLine1" [width]="180">
  </kendo-grid-column>
  <kendo-grid-column title="Address 2" field="locationLine2" [width]="120">
  </kendo-grid-column>
  <kendo-grid-column title="City" field="city" [width]="120">
  </kendo-grid-column>
  <kendo-grid-column title="State / Province" field="stateProvince" [width]="140">
  </kendo-grid-column>
  <kendo-grid-column title="Zip" field="postalCodeValue" [width]="80">
  </kendo-grid-column>
  <kendo-grid-column title="Country" field="country" [width]="110" [hidden]="true">
  </kendo-grid-column>
  <kendo-grid-column title="Tax ID" field="taxCode" [width]="100" [hidden]="true">
  </kendo-grid-column>
  <kendo-grid-column title="Batch Type" field="batchPayType" [width]="110" [hidden]="true">
  </kendo-grid-column>
  <kendo-grid-column title="Pay Type" field="payType" [width]="260" [hidden]="true">
  </kendo-grid-column>
  <kendo-grid-column title="Customer Type" field="customerType" [width]="140" *ngIf="customerViewMode === 'payee'">
  </kendo-grid-column>
  <kendo-grid-column title="Claimable" field="canBeClaimed" [width]="90" class="acb-user-grid-yesno-column" [hidden]="true">
    <ng-template kendoGridCellTemplate let-dataItem>
      <span [ngClass]="{'acb-user-grid-value-no': !dataItem.canBeClaimed}">{{dataItem.canBeClaimed ? 'Yes' : 'No'}}</span>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column title="ACH" field="isACHEnrolled" [width]="60" class="acb-user-grid-yesno-column" [hidden]="searchFlavor != 'manage'">
    <ng-template kendoGridCellTemplate let-dataItem>
      <span [ngClass]="{'acb-user-grid-value-no': !dataItem.isACHEnrolled}">{{dataItem.isACHEnrolled ? 'Yes' : 'No'}}</span>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column title="Email" field="emailAddress" [width]="180">
  </kendo-grid-column>
  <kendo-grid-column title="Created By" field="createdBy" [width]="180" [hidden]="true">
  </kendo-grid-column>
  <kendo-grid-column title="Created" field="creationDate" [width]="90" class="acb-grid-column-date"  [hidden]="true">
    <ng-template kendoGridCellTemplate let-dataItem>
      {{dataItem.creationDate | date: 'M/d/yyyy'}}
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column title="Active" field="visibilityTypeID" [width]="80" class="acb-user-grid-yesno-column">
    <ng-template kendoGridCellTemplate let-dataItem>
      <span [ngClass]="{'acb-user-grid-value-no': dataItem.visibilityTypeID != visibleVisibilityTypeID}">{{dataItem.visibilityTypeID == visibleVisibilityTypeID ? 'Yes' : 'No'}}</span>
    </ng-template>
  </kendo-grid-column>

  <ng-template kendoPagerTemplate let-totalPages="totalPages" let-currentPage="currentPage">
    <kendo-pager-prev-buttons></kendo-pager-prev-buttons>
    <kendo-pager-numeric-buttons [buttonCount]="10" fxHide.lt-md="true"></kendo-pager-numeric-buttons>
    <kendo-pager-numeric-buttons [buttonCount]="5" fxHide="true" fxShow.sm="true"></kendo-pager-numeric-buttons>
    <kendo-pager-info></kendo-pager-info>
    <kendo-pager-next-buttons></kendo-pager-next-buttons>
    <kendo-pager-page-sizes ngClass.lt-md='acb-small-pager-page-sizes' [pageSizes]="[5,10,20,40]" ></kendo-pager-page-sizes>
  </ng-template>
  <kendo-grid-excel fileName="Customers.xlsx" [fetchData]="allData">
  </kendo-grid-excel>
</kendo-grid>

