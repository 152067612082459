import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, withLatestFrom } from 'rxjs/operators';
import * as UserPreferencesActions from './user-preferences.actions';
import { selectUserPreferencesState } from './user-preferences.selectors';
import { UserPreferencesState } from './user-preferences.state'
import { Store } from '@ngrx/store';

@Injectable()
export class UserPreferencesEffects {

  updateUserPreferences$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        UserPreferencesActions.updateUserPreferences,
        UserPreferencesActions.updateThemeId,
        UserPreferencesActions.updateManageFeaturesPage_showAllAccessRoles,
        UserPreferencesActions.updateGridPreferences,
        UserPreferencesActions.updateDashboardPreferences
        ),
      withLatestFrom(this.store.select(selectUserPreferencesState)),
      map(([action,state]) => {
        return UserPreferencesActions.userPreferencesUpdated({ data: state});
        })
      )
    }
  );

  constructor(
    private actions$: Actions,
    private store: Store<UserPreferencesState>
    ) {}

}
