import { Component, Input, OnInit } from '@angular/core';

export type WindowNotificationType = 'info' | 'success' | 'warning' | 'error';

export class WindowNotificationActionButtonSpec {
  constructor(
    public text:string,
    public onClick: () => void,
  ) {
  }
}

export class WindowNotification {
  constructor(
    public type:WindowNotificationType,
    public message:string,
    public isVisible:boolean = false,
    public actionButtonSpec?: WindowNotificationActionButtonSpec
  ) {
  }
}

@Component({
  selector: 'acb-alcon-window-notifications',
  template: `
    <div fxLayout="row wrap">
      <acb-alcon-window-notification *ngFor='let notification of windowNotifications' [windowNotification] = "notification" fxFlex="grow">
      </acb-alcon-window-notification>
    </div>
  `,
  styleUrls: ['./window-notifications.component.scss']
})
export class WindowNotificationsComponent implements OnInit {

  @Input() windowNotifications:WindowNotification[] = [];

  constructor() { }

  ngOnInit(): void {
  }

}
