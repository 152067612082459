import { SearchServiceBase } from './search-service-base'
import { Injectable } from '@angular/core';
import { CustomerSearchRequestModel } from '@alcon-db-models/CustomerSearchRequestModel';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { AppUxService } from './app-ux.service';

@Injectable({
  providedIn: 'root'
})
export class ClaimFundingDispatchSearchService  extends SearchServiceBase<CustomerSearchRequestModel> {
  constructor(protected http: HttpClient, protected router: Router, protected store: Store, appUxService: AppUxService) {
    super(http, router, store, 'ClaimFundingDispatchSearch', appUxService);
  }
}

