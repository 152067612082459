import { ChangeDetectorRef, Component, HostBinding, OnDestroy, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Subject, BehaviorSubject } from 'rxjs';
import { AppWindowService } from '@data-services/app-window.service';
import { PageExpansionService } from './services/page-expansion.service'
import { NavigationEnd, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { AppWindowServiceBase } from '@services/app-window-service.base';
import { first, takeUntil } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { updateIsReady } from '@app-store/app-session/app-session.actions';
import { selectIsReady } from '@app-store/app-session/app-session.selectors';

@Component({
  selector: 'acb-alcon-root',
  template: `
  <div #modalOverlay class="acb-modal-overlay" *ngIf="showModalOverlay"></div>
  <div #loadingOverlay class="acb-alcon-page-loading-indicator" *ngIf="showLoadingOverlay"><span class="k-icon k-i-loading"></span></div>
  <div kendoWindowContainer></div>
  <div kendoDialogContainer></div>
  <router-outlet *ngIf="storeIsReady$ | async as boolean; else loading" [ngClass.lt-md]="'acb-page-expanded'"></router-outlet>
  <ng-template #loading>
    <div>Loading...</div>
  </ng-template>
  `
})
export class AppComponent implements OnInit, OnDestroy {

  // Disable all Angular animations. https://angular.io/api/animations/trigger#disabling-animations
  @HostBinding('@.disabled')

  @HostBinding('attr.app-version') appVersionAttr = environment.appVersion;

  public title = 'sonoran';
  public destroy$: Subject<void> = new Subject<void>();
  public storeIsReady$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public pageExpanded$ = this.pageExpansionService.pageExpanded$;
  public showModalOverlay:boolean = false; ;
  public showLoadingOverlay:boolean = false;

  constructor(
    public sanitizer: DomSanitizer,
    private pageExpansionService: PageExpansionService,
    public appWindowService: AppWindowService,
    private changeDetectorRef: ChangeDetectorRef,
    private store: Store,
    router: Router
    ) {

      AppWindowService.doShowModalOverlay$.pipe(takeUntil(this.destroy$)).subscribe(x => {
        this.showModalOverlay = x;
        // changeDetectorRef.detectChanges();
      });

      AppWindowServiceBase.doShowLoadingOverlay$.pipe(takeUntil(this.destroy$)).subscribe(x => {
        this.showLoadingOverlay = x;
        //changeDetectorRef.detectChanges();
      });

      router.events.subscribe((event) => {
        if(event instanceof NavigationEnd) {
          pageExpansionService.changePageExpanded(false);
        }
      })
    }

  public async ngOnInit() {
    this.storeIsReady$.next(true);
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
