import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { UserPreferenceEnabledGridBaseComponent } from '../user-preference-enabled-grid.base.component';
import { AppUxService } from '@services/app-ux.service';
import { Store } from '@ngrx/store';
import { PageExpansionService } from '@services/page-expansion.service';
import { SearchFilterService } from '@services/search-filter.service';
import { Router } from '@angular/router';
import { UntypedFormGroup } from '@angular/forms';
import { Observable, of } from 'rxjs';
import { ExcelExportData } from '@progress/kendo-angular-excel-export';
import { GridComponent } from '@progress/kendo-angular-grid';
import { LcpComparisonSearchBindingDirective } from 'src/app/directives/lcp-comparison-search-binding.directive';
import { SandboxService } from '@services/sandbox.service';
import { LcpComparisonSearchService } from '@services/lcp-comparison-search.service';

@Component({
  selector: 'acb-alcon-lcp-comparison',
  templateUrl: './lcp-comparison.component.html',
  styleUrls: ['./lcp-comparison.component.scss']
})
export class LcpComparisonComponent  extends UserPreferenceEnabledGridBaseComponent implements OnInit {


  @ViewChild('resultsGrid') public resultsGrid?: GridComponent;
  @ViewChild(LcpComparisonSearchBindingDirective) public bindingDirective?: LcpComparisonSearchBindingDirective;

  constructor(
    protected appUxService: AppUxService,
    store: Store,
    pageExpansionService: PageExpansionService,
    changeDetectorRef: ChangeDetectorRef,
    searchFilterService: SearchFilterService,
    router: Router,
    public lcpComparisonSearchService: LcpComparisonSearchService,
  ) {
    super(store, pageExpansionService, changeDetectorRef, searchFilterService, router);
    this.searchForm = new UntypedFormGroup({ });
  }

  public formatList(list:string|null|undefined) {
    return list ? list.replace(/,/gi,"<br>") : list;
  }

  public formatCommaSpace(list:string|null|undefined) {
    return list ? list.replace(/,/gi,", ") : list;
  }

  ngOnInit(): void {
  }

  public allData(): Observable<ExcelExportData> {
    return this.bindingDirective ?
      this.lcpComparisonSearchService.searchForExcelExport(this.bindingDirective.getSearchRequest(true)) :
      of({});
  }

}
