import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { DialogContentBase, DialogRef } from '@progress/kendo-angular-dialog';

@Component({
  selector: 'acb-alcon-request-review-comment',
  template: `
    <kendo-dialog-titlebar [class]="'acb-request-name-titlebar ' + titleBarStyle" (close)="onCancel()">
      {{title}}
    </kendo-dialog-titlebar>
    <form [formGroup]="form" style="width: 100%;">
      <acb-alcon-section-with-legend [doShowLegend]="false" [class]="bodyStyle">
        <div fxLayout="column" fxLayoutGap=".5em" fxLayoutAlign="stretch stretch" class="acb-body">
          <ng-container *ngIf="action == 'approve'">
            <p fxFlex>
              Approve the {{ commitmentIDs.length  > 1 ? commitmentIDs.length.toString() : '' }} selected commitment{{ commitmentIDs.length > 1 ? 's' : '' }}?
            </p>
          </ng-container>
          <ng-container *ngIf="action == 'deny'">
            <div class="acb-body-text">
              <p>
                Deny the {{ commitmentIDs.length  > 1 ? commitmentIDs.length.toString() : '' }} selected commitment{{ commitmentIDs.length > 1 ? 's' : '' }}?
              <p>
                Enter a comment below and click OK to proceed with denial, or click Review to inspect your selection.
              </p>
            </div>
            <kendo-formfield *appFieldExtentions fxFlex>
              <kendo-label [for]="comment" text="Comment" [optional]="false"></kendo-label>
              <textarea formControlName="comment" kendoTextArea #comment rows="3" style="resize: none; width: 100%"></textarea>
            </kendo-formfield>
          </ng-container>
        </div>
      </acb-alcon-section-with-legend>
    </form>
    <kendo-dialog-actions>
      <button kendoButton class="acb-cancel" (click)="onCancel()" >Cancel</button>
      <button kendoButton  (click)="onReview()" fxFlex="20%" >Review</button>
      <button kendoButton [primary]="true" (click)="onSave()" >OK</button>
    </kendo-dialog-actions>
  `,
  styleUrls: ['./request-review-comment.component.scss']
})
export class RequestReviewCommentComponent extends DialogContentBase implements OnInit {

  @Input() title: string = '';
  @Input() titleBarStyle: string = '';
  @Input() bodyStyle: string = '';
  @Input() public commitmentIDs: number[] = [];
  @Input() action?: 'approve' | 'deny';

  @Output() cancel: EventEmitter<any> = new EventEmitter();
  @Output() save: EventEmitter<string|undefined> = new EventEmitter();
  @Output() review: EventEmitter<string|undefined> = new EventEmitter();

  public form: UntypedFormGroup = new UntypedFormGroup({
    comment: new UntypedFormControl()
  });

  constructor(public dialog : DialogRef) {
    super(dialog);
  }

  ngOnInit(): void {

    if (!this.action)  {
      throw new Error('Action required')
    }

    //TODO: Clean this up
    if (this.action === 'deny') {
      if (!this.title) this.title = 'Denying';
      if (!this.titleBarStyle) this.titleBarStyle = 'alcon-titlebar-brick';
      if (!this.bodyStyle) this.bodyStyle = 'acb-section-07';
    } else if (this.action === 'approve') {
      if (!this.title) this.title = 'Approving';
      if (!this.titleBarStyle) this.titleBarStyle = 'alcon-titlebar-green';
      if (!this.bodyStyle) this.bodyStyle = 'acb-section-02';
    }
  }


  public onCancel() {
    this.cancel.emit(null);
  }

  public onSave() {
    this.save.emit(this.form.controls.comment.value ?? undefined);
  }

  public onReview() {
    this.review.emit(this.form.controls.comment.value ?? undefined);
  }
}
