import { ClaimWithDetailsModel } from '@alcon-db-models/ClaimWithDetailsModel';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'acb-alcon-view-claim-doctors',
  template: `
    <kendo-grid *ngIf="claim?.doctors; let doctorsData"
      class="acb-results-grid acb-display-grid"
      [data]= "doctorsData"
      [pageable]="false"
      [sortable]="false"
      [reorderable]="false"
      [selectable] = "false"
      [resizable]="true"
      >
      <kendo-grid-column title="Doctor" field="doctor"></kendo-grid-column>
      <kendo-grid-column title="APIN" field="doctorCode"></kendo-grid-column>
    </kendo-grid>
  `,
  styleUrls: ['./view-claim-doctors.component.scss']
})
export class ViewClaimDoctorsComponent implements OnInit {

  constructor() { }

  @Input() claim?:ClaimWithDetailsModel;

  ngOnInit(): void {
  }
}
