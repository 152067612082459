import { ClaimWithDetailsModel } from '@alcon-db-models/ClaimWithDetailsModel';
import { CommitmentOrganizationsModel } from '@alcon-db-models/CommitmentOrganizationsModel';
import { SourceType, StatusCode } from '@alcon-db-models/Enums';
import { FeatureModel } from '@alcon-db-models/FeatureModel';
import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { selectCurrentPersonFeatures } from '@app-store/root.selectors';
import { Store } from '@ngrx/store';
import { AppWindowService } from '@services/app-window.service';
import { ClaimEditService } from '@services/claim-edit.service';
import { Subject } from 'rxjs';
import { first, take, takeUntil } from 'rxjs/operators';
import { EditClaimMode } from 'src/app/components/edit-claim-details/edit-claim-details.component';
import { ViewMode } from '../components.module';

@Component({
  selector: 'acb-alcon-edit-claim',
  templateUrl: 'edit-claim.component.html',
  styleUrls: ['./edit-claim.component.scss']
})
export class EditClaimComponent implements OnInit, OnDestroy {

  @Output() viewCommitment: EventEmitter<any> = new EventEmitter();
  @Output() viewClaim: EventEmitter<any> = new EventEmitter();
  @Output() loading: EventEmitter<boolean> = new EventEmitter();
  @Output() claimLoaded: EventEmitter<ClaimWithDetailsModel> = new EventEmitter();
  @Output() valid: EventEmitter<boolean> = new EventEmitter();

  @Input() viewMode: ViewMode = 'edit'

  public editClaimModes = EditClaimMode;
  public isValid: boolean = true;
  public detailsValid: boolean = true;
  public productsValid: boolean = true;
  public payeeValid: boolean = true;
  public commitmentValid: boolean = true;
  public canEditDetails: boolean = true;
  public canEditAfterAudit: boolean = false;
  public wasAudited: boolean = false;

  public currentUserFeatures$ = this.store.select(selectCurrentPersonFeatures);

  private _claimID?: number;
  @Input() set claimID(value: number | undefined) {
    if(!this._claim) {
      this._claimID = value;
      if (value) {
        this.loading.emit(true);
        this.claimEditService.claimID = value;
      }
    }
  };

  private _claim?: ClaimWithDetailsModel;
  @Input() set claim(value) {
    this.loading.emit(true);
    this.claimEditService.updateClaim(value);
    this._claimID = value?.claimID ?? undefined;
    this._claim = value;
  };
  get claim() {
    return this._claim;
  }

  private destroy$: Subject<void> = new Subject<void>();

  constructor(
    private claimEditService: ClaimEditService,
    private changeDetectionRef: ChangeDetectorRef,
    private appWindowService: AppWindowService,
    private store: Store
  ) {
    this.claimEditService.claim$.pipe(takeUntil(this.destroy$)).subscribe(x => {
      this._claim = x;
      this._claimID = x?.claimID ?? undefined;
      this.wasAudited = x?.statusCodeID != StatusCode.Canceled
        && x?.statusCodeID != StatusCode.Inprocess;
      if (x?.claimID) {
        this.loading.emit(false);
      }
      this.claimLoaded.emit(x);
    });

    // this.claimEditService.loading$.pipe(takeUntil(this.destroy$)).subscribe(x => {
    //   this.loading.emit(x);
    // });

    this.currentUserFeatures$.pipe(first(x => x?.length > 0)).subscribe(x => {
      this.canEditAfterAudit = x.some((x:FeatureModel) => x.code == 'caneditafteraudit');
    });
  }

  ngOnInit(): void {
    this.claimEditService.validityChange$.pipe(takeUntil(this.destroy$)).subscribe(x => {
      this.valid.emit(this.isValid = x.form);
      this.detailsValid = x.details;
      this.productsValid = x.products;
      this.payeeValid = x.payee;
      this.commitmentValid = Boolean(this._claim?.commitmentID);
      // can't edit details without commitment...
      this.canEditDetails = this.commitmentValid;

      this.changeDetectionRef.detectChanges();
    });

  }

  public onEditCommitment() {
    const dialogSpec = this.appWindowService.openSelectCommitment(this._claim?.customerID);
    dialogSpec?.component?.viewCommitment?.pipe(take(1)).subscribe(x => this.viewCommitment.emit(x));
    dialogSpec?.component?.cancel?.pipe(take(1)).subscribe(() => dialogSpec?.dialogRef.close());
    dialogSpec?.component?.commitmentSelected?.pipe(take(1)).subscribe(x => {
      dialogSpec?.dialogRef.close();
      if (x)
        this.claimEditService.updateCommitmentByID(x.commitmentID);
    });
  }

  public onEditPayee() {
    if (!this._claim)
      return;
    const dialogSpec = this.appWindowService.openSelectPayee(this._claim as any as CommitmentOrganizationsModel);
    if (this._claim?.payeeCustomerID && dialogSpec?.component) {
      dialogSpec.component.payee = {
        customerID: this._claim.payeeCustomerID,
        customer: this._claim.payee,
        customerCode: this._claim.payeeCustomerCode,
      ...this._claim.payeeLocation
      };
    }
    dialogSpec?.component?.cancel?.pipe(take(1)).subscribe(() => dialogSpec?.dialogRef.close());
    dialogSpec?.component?.payeeSelected?.pipe(take(1)).subscribe(x => {
      dialogSpec?.dialogRef.close();
      if (x)
        this.claimEditService.updatePayee(x);
    });

  }


  public onEditDetails() {
    const dialogSpec = this.appWindowService.openEditClaimDetails();
    const component = dialogSpec?.component;
    const dialogRef = dialogSpec?.dialogRef;

    if (dialogSpec?.component)
      dialogSpec.component.claimEditService = this.claimEditService;

    if (component && dialogRef) {

      component.editMode = this.viewMode == 'review' ?  EditClaimMode.Review : EditClaimMode.Edit;
      //TODO:  Fix this.  Put canReverse on claim?
      component.canReverse = true;

      component.cancel.pipe(take(1)).subscribe(x => {
        this.claimEditService.resetForm();
        dialogRef.close();
      })
      component.apply.pipe(take(1)).subscribe(x => {
        this.claimEditService.applyDetailsFromForm();
        dialogRef.close();
      })
    }
  }

  public onEditReview() {
    // const dialogSpec = this.appWindowService.openEditClaimDetails();
    // const component = dialogSpec?.component;
    // const dialogRef = dialogSpec?.dialogRef;

    // if (dialogSpec?.component)
    //   dialogSpec.component.claimEditService = this.claimEditService;

    // if (component && dialogRef) {
    //   component.cancel.pipe(take(1)).subscribe(x => {
    //     this.claimEditService.resetForm();
    //     dialogRef.close();
    //   })
    //   component.apply.pipe(take(1)).subscribe(x => {
    //     this.claimEditService.applyDetailsFromForm();
    //     dialogRef.close();
    //   })
    // }
  }

  public onEditProducts() {
    const dialogSpec = this.appWindowService.openEditClaimProducts();
    const component = dialogSpec?.component;
    const dialogRef = dialogSpec?.dialogRef;

    if (component && dialogRef) {
      component.claimEditService = this.claimEditService;
      component.cancel.pipe(take(1)).subscribe(x => {
        this.claimEditService.resetForm();
        dialogRef.close();
      })
      component.apply.pipe(take(1)).subscribe(x => {
        this.claimEditService.applyProductsFromForm();
        dialogRef.close();
      })
    }
  }

  public onEditAttachments() {

    if (!this._claim?.code)
      return;

    const dialogSpec = this.appWindowService.openSelectCommitmentAttachments();
    const component = dialogSpec?.component;
    const dialogRef = dialogSpec?.dialogRef;

    if (component && dialogRef) {

      component.attachments = [
        ...this._claim?.attachments?.filter(x => x.sourceType == SourceType.Commitment) ?? [],
        ...this._claim?.attachments?.filter(x => x.sourceType != SourceType.Commitment) ?? []
      ];

      component.subjectAttachmentPath =  this._claim?.code ? './UserUploads/claims/' + window.encodeURIComponent(this._claim?.code) : undefined;
      component.claimCommitmentAttachmentPath = this._claim?.commitmentCode ? './UserUploads/commitments/' + window.encodeURIComponent(this._claim?.commitmentCode) : undefined;

      component.canRemoveExistingAttachments = !this._claim.isEditRestrictedToAttachments;

      component.cancel.pipe(take(1)).subscribe(x => {
        dialogRef.close();
      })
      component.apply.pipe(take(1)).subscribe(x => {
        this.claimEditService.updateAttachments(x);
        dialogRef.close();
      });
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
